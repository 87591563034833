// Your variable overrides
@import '~bootstrap/scss/bootstrap.scss';
@import '~react-notifications-component/dist/theme.css';

// hide nav bar when desktop toggle
.pro-sidebar {
  color: #FFFFFF;

  ::-webkit-scrollbar {
    height: 4px;
    width: 4px;
    border: 1px solid #d5d5d5;
  }
}

.pro-sidebar.sm.collapsed {
  margin-left: -80px;
}


.corpus-modal-header {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  padding: 1rem 1rem;
  border-bottom: 1px solid #dee2e6;
  border-top-left-radius: calc(0.3rem - 1px);
  border-top-right-radius: calc(0.3rem - 1px);
}

.pro-sidebar .pro-menu .pro-menu-item.pro-sub-menu .pro-inner-list-item .pro-inner-item:before {
  width: 10px;
  height: 10px;
}

.pro-icon-wrapper {
  width: 2px;
  height: 2px;
}

.menu {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  //flex-direction: column;
  //flex-flow: column wrap;
}

@media (min-width: 992px) {

  .modal-lg,
  .modal-xl {
    max-width: 95%;
  }
}

// .pro-sidebar>.pro-sidebar-inner{
// background: #44C1EA
// }

// .pro-sidebar .pro-menu>ul>.pro-sub-menu>.pro-inner-list-item{
// background-color: #44C1EA
// }

// .bg-dark {
//     background-color: #44C1EA!important;
// }

// .pro-sidebar .pro-menu.shaped .pro-menu-item>.pro-inner-item>.pro-icon-wrapper {
//     background-color: #44C1EA !important
// }

// .App {
//   text-align: center;
// }

// .App-logo {
//   height: 40vmin;
//   pointer-events: none;
// }

// @media (prefers-reduced-motion: no-preference) {
//   .App-logo {
//     animation: App-logo-spin infinite 20s linear;
//   }
// }

// .App-header {
//   background-color: #282c34;
//   min-height: 100vh;
//   display: flex;
//   flex-direction: column;
//   align-items: center;
//   justify-content: center;
//   font-size: calc(10px + 2vmin);
//   color: white;
// }

// .App-link {
//   color: #61dafb;
// }

// @keyframes App-logo-spin {
//   from {
//     transform: rotate(0deg);
//   }
//   to {
//     transform: rotate(360deg);
//   }
// }

.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}