.bolunshununmodal {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 50px 0px;
}
.modalBackground {
  width: 100vw;
  height: 100vh;
  background-color: rgba(200, 200, 200);
  position: fixed;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 9999;
}
.modalBackgroundhome {
  width: 100vw;
  height: 100vh;
  background: #000000;
  opacity: 0.55;
  position: fixed;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 9999;
}

.modalclose {
  width: 20px;
  cursor: pointer;
}

.modalContainer {
  width: 500px;
  height: 285px;
  border-radius: 16px;
  background-color: white;
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
  display: flex;
  flex-direction: column;
  padding: 25px;
  border-color: #dde4f1;
  border: 1px solid #dde4f1;
  box-shadow: 0px 0px 24px rgba(0, 0, 0, 0.06);
}

.modalContainer .title {
  display: inline-block;
  text-align: center;
  margin-top: 10px;
}

.titleCloseBtn {
  display: flex;
  justify-content: flex-end;
}

.titleCloseBtn button {
  background-color: transparent;
  border: none;
  font-size: 25px;
  cursor: pointer;
}

.modalContainer .body {
  flex: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 1.7rem;
  text-align: center;
}

.modalContainer .footer {
  flex: 20%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.modalContainer .footer button {
  min-width: 136px;
  height: 45px;
  margin: 10px;
  border: none;
  background-color: #ff8000;
  color: white;
  border-radius: 8px;
  cursor: pointer;
  font-family: "Noto Sans Bengali";
  font-weight: 600;
  font-size: 22px;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 13px 16px 10px;
}

#cancelBtn {
  background-color: crimson;
}

p.getpermission {
  font-size: 22px;
  color: #000000;
  font-family: "Noto Sans Bengali";
}

button.habtn {
  background-color: #136ee5 !important;
  color: #ffffff !important;
  max-width: 120px;
  font-size: 22px;
}
button.nabtn {
  max-width: 120px;
  font-size: 22px;
}

.hagroup {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.nagroup {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  margin: 0px 30px;
}
button.nabtn.btnsununmodal {
  padding: 0px 25px;
}
button.habtn.btnbolunmodal {
  padding: 0px 25px;
}
.hatext {
  font-family: "Noto Sans Bengali";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 21px;
  text-align: center;

  color: #136ee5;
}

.natext {
  font-family: "Noto Sans Bengali";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 21px;
  text-align: center;

  /* Dark-Orange */

  color: #e27400;
}

button.habtn.btnbolunmodal {
  background: #059514!important;
  border: 1px solid #d8e1f5;
  color: #fff !important;
}

button.nabtn.btnsununmodal {
  background: #136ee5!important;
  /* background: #000066!important; */
  border: 1px solid #d8e1f5;
  color: #fff !important;
}

button.nabtn.btnsununmodal a {
  color: #fff;
}

.recordingmodal {
  background: #059514!important;
  /* background:  #004d00!important; */
  border: 1px solid #d8e1f5;
  color: #fff !important;
}

