* {
  margin: 0px;
  padding: 0px;
}

/* .readbg {
  background: url("../../images/stts/readbg.png") no-repeat;
  background-position: center;
  width: 100%;
  height: 100vh;
  background-size: cover;
} */




/* @media screen and (max-width: 1520px) and (max-height: 722px) {
  .readbg {
    background: url("../../images/stts/readbg.png") no-repeat;
    background-position: center;
    width: 100%;
    height: 130vh;
    background-size: cover;
  }
} */


@media screen and (max-width: 720px) {
  .readbg {
    height: 130vh !important;
  }
}
