@import url("https://fonts.maateen.me/solaiman-lipi/font.css");
@import url("https://fonts.googleapis.com/css2?family=Roboto+Flex:opsz,wght@8..144,400;8..144,500;8..144,600;8..144,700&display=swap");

/* font-family: 'Roboto Flex', sans-serif; */
.hidecontent {
  display: none;
  transition: all ease-in 1s;
}

* {
  margin: 0px;
  padding: 0;
}


.readbg {
  background: url("./images/stts/readbg.png") no-repeat;
  background-position: center;
  width: 100%;
  height: 100vh;
  background-size: cover;
}

.bodycontent {
  width: 100%;
  /* max-width: 1500px; */
  max-width: 1330px;
  margin: 0 auto;
  margin-top: 30px;
}

.bodymenuitem {
  width: 100%;
  max-width: 1364px;
  /* margin: 0 auto; */
  display: flex;
  justify-content: space-between;
  gap: 30px;
  margin-bottom: 16px;
  align-items: center;
  /* max-width: 1364px; */
}

.bodymenuitembolun {
  width: 100%;
  max-width: 1330px;
  margin: 0 auto;
  display: flex;
  justify-content: space-between;
  gap: 30px;
  margin-bottom: 16px;
  align-items: center;
  /* max-width: 1364px; */
}

li.sununbtn span {
  justify-content: center;
  align-items: center;
  display: flex;
}

.boluncotopokothon {
  display: flex;
  justify-content: space-between;
  width: 100%;
  max-width: 1064px;
}

.readingrule {
  display: flex;
  /* width: 100%; */
  width: 700px;
  justify-content: flex-end;
  flex-direction: column;
  text-align: left;
  margin-top: 25px;
}

.bolonsununitem ul {
  display: flex;
}

.bolonsununitem ul li {
  list-style: none;
  padding: 20px;
  margin-right: 10px;
}

.bolunactive {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 25px;
}

.sununactive {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 30px;
}

h6.boluntitle {
  font-size: 18px;
  color: #526281;
  font-family: "Noto Sans Bengali";
}

.bolonsununitem ul li a {
  text-decoration: none;
}

.conitem ul {
  display: flex;
  justify-content: center;
  align-items: center;
}

.conitem ul li {
  list-style: none;
  /* padding: 20px; */
}

span.stopimg {
  padding: 0px 10px;
}

.conitem ul li a {
  text-decoration: none;
}

.rruleitem ul {
  display: flex;
  margin-bottom: 0;
  justify-content: flex-end;
  text-align: right;
}

.rruleitem ul li {
  list-style: none;
  /* padding: 20px; */
  cursor: pointer;
}

.rruleitem ul li:focus {
  list-style: none;
  /* padding: 20px; */
  border: 1px solid #5a5a5a;
  border-radius: 8px;
  padding: 12px 16px;
}

/* .rruleitem ul li:hover {
  background-color: #f2f8ff;
  padding: 12px 16px;
  border-radius: 8px;
} */

.rruleitem ul li:paused {
  background-color: #f2f8ff;
  padding: 12px 16px;
  border-radius: 8px;
}

a.readrule {
  font-family: "Noto Sans Bengali";
  /* font-style: normal; */
  font-weight: 600;
  font-size: 18px;
  line-height: 24px;
  margin-right: 10px;
  color: #136ee5;
}

.rruleitem ul li a {
  text-decoration: none;
}

/* Tab content start here */

.tab-list {
  height: 50px;
  display: flex;
  list-style: none;
  font-size: 18px;
  padding: 0;
  margin: 0;
}

.tabs {
  width: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  /* background: #80808013; */
  position: relative;
  cursor: pointer;
}

/* .tabs:not(:last-child) {
  border-right: 1px solid rgba(0, 0, 0, 0.3);
} */

.active-tabs {
  background: #fff;
  box-shadow: 0px 0px 12px 0px rgba(255, 87, 103, 0.55);
  -webkit-box-shadow: 0px 0px 12px 0px rgba(255, 87, 103, 0.55);
  -moz-box-shadow: 0px 0px 12px 0px rgba(255, 87, 103, 0.55);
  border-radius: 50%;
}

li.sununbtn {
  width: 52px;
  height: 52px;
  padding: 0px !important;
  /* border-radius: 50%; */
}

/* .active-tabs::before {
  content: "";
  display: block;
  position: absolute;
  top: -4px;
  left: 50%;
  transform: translateX(-50%);
  width: calc(100% + 2px);
  height: 4px;
  background: #5893f1;
} */

.content {
  background-color: #eeeded;
  display: none;
  padding: 10px;
}

.active-content {
  display: flex;
}

.innerbodyitem {
  display: flex;
  /* width: 100%;
  max-width: 1600px; */
  margin: 0 auto;
  gap: 30px;
}

.mainrontainer {
  display: flex;
  width: 100%;
  flex-direction: column;
  max-width: 1364px;
  max-height: 475px;
}

.textbar {
  display: flex;
  width: 100%;
}

.rightsidebar {
  /* display: flex; */
  width: 100%;
  background-color: #f6f9ff;
  box-shadow: 0px 0px 24px 0px rgba(0, 0, 0, 0.06);
  -webkit-box-shadow: 0px 0px 24px 0px rgba(0, 0, 0, 0.06);
  -moz-box-shadow: 0px 0px 24px 0px rgba(0, 0, 0, 0.06);
  overflow: hidden;
  border-radius: 12px;
  min-height: 480px;
  min-width: 223px;
}

.rightsidebarcontent {
  width: 100%;
}

/* =========================== Bolun content start here ============== */

.boluncontent {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  max-width: 1064px;
  min-height: 482px;
  background-color: #ffffff;
  position: relative;
  -webkit-box-shadow: 0px 0px 24px 0px rgba(0, 0, 0, 0.06);
  -moz-box-shadow: 0px 0px 24px 0px rgba(0, 0, 0, 0.06);
  box-shadow: 0px 0px 24px 0px rgba(0, 0, 0, 0.06);
  flex-direction: column;
  border-radius: 12px;
}

.nconversation {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  max-width: 1327px;
  min-height: 482px;
  background-color: #ffffff;
  position: relative;
  -webkit-box-shadow: 0px 0px 24px 0px rgba(0, 0, 0, 0.06);
  -moz-box-shadow: 0px 0px 24px 0px rgba(0, 0, 0, 0.06);
  box-shadow: 0px 0px 24px 0px rgba(0, 0, 0, 0.06);
  flex-direction: column;
  border-radius: 12px;
}

.bottomcontenttext {
  display: flex;
  width: 100%;
  justify-content: center;
  position: absolute;
  bottom: 0px;
  /* padding: 6px 0px; */
  background-color: #f6f9ff;
  /* max-width: 520px; */
  border: 1px solid #ebeff6;
  /* border-radius: 40px; */
  height: 50px;
  align-items: center;
}

/* .footerarea {
  display: flex;
  width: 100%;
  justify-content: space-between;
  background-color: #ffffff;
  position: absolute;
  bottom: 0;
  padding: 20px 0px;
  align-items: center;
}  */

.withconversation {
  display: flex;
  width: 100%;
  justify-content: center;
  position: absolute;
  bottom: -16px;

  /* height: 50px; */
  align-items: center;
}

.porastart {
  padding: 6px 20px;
  background-color: #f6f9ff;
  max-width: 520px;
  border: 1px solid #ebeff6;
  border-radius: 40px;
}

.porases {
  padding: 6px 20px;
  background-color: #f6f9ff;
  max-width: 520px;
  border: 1px solid #ebeff6;
  border-radius: 40px;
  margin-right: 170px;
}

.bodycontentitem {
  display: flex;
  justify-content: center;
  /* position: relative; */
  align-items: center;
  flex-direction: column;
}

.bodyfordoito {
  display: flex;
  flex-direction: row;
  width: 100%;
  justify-content: space-around;
}

.arekjonspeakerarea {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  width: 60%;
}

.arek {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  width: 100%;
}

.bodycontentitemcaurasel {
  display: flex;
  justify-content: center;
  /* position: relative; */
  align-items: center;
  width: 80%;
  margin: 0 auto;
}

/* .carousel-indicators button {
  display: none;
} */

.bodywithcaurasel {
  display: flex;
  justify-content: center;
  /* position: relative; */
  align-items: center;
  width: 100%;
  flex-direction: column;
}

.spagination {
  border-color: #dedede;
  border-radius: 4px;
}

.spagination:focus-visible {
  outline: none;
}

.paginationarea {
  display: flex;
  justify-content: flex-end;
  width: 86%;
}

.paginationitem+span {
  font-size: 18px;
  font-family: "SolaimanLipi";
  color: #303030;
}

span.totalpage {
  color: #bbbeca;
}


/* .carousel {
  position: relative;
  width: 100%;
} */

p.bodydylog {
  font-family: "Noto Sans Bengali", sans-serif;
  font-size: 20px;
  color: #526281;
  margin-bottom: 0px;
  font-weight: 500;
}

p.bodytextcontent {
  width: 100%;
  max-width: 795px;
  height: auto;
  font-size: 32px;
  font-family: "SolaimanLipi", Arial, sans-serif;
  color: #161a31;
  font-weight: 400;
  margin-bottom: 0px;
  text-align: center;
}

p.bodytextcontentmulti {
  width: 100%;
  max-width: 795px;
  height: auto;
  font-size: 24px;
  font-family: "SolaimanLipi", Arial, sans-serif;
  color: #161a31;
  font-weight: 400;
  margin-bottom: 0px;
  text-align: center;
}

p.sarapovabangladesh {
  width: 100%;
  max-width: 795px;
  height: auto;
  font-size: 36px;
  font-family: "SolaimanLipi", Arial, sans-serif;
  background-color: #f4f4f8;
  padding: 20px 84px;
}

p.maxtime {
  font-family: "Noto Sans Bengali";
  font-style: normal;
  font-weight: 400;
  font-size: 20px;
  line-height: 26px;
  color: #136ee5;
}

h6.kishubolun {
  font-family: "Noto Sans Bengali";
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 24px;
  color: #556170;
}

/* =========================== Bolun content start here ============== */
/* =========================== Sunun component ============== */
.sununcontent {
  /* display: flex; */
  justify-content: center;
  align-items: center;
  width: 100%;
  /* max-width: 1064px; */
  min-height: 480px;
  background-color: #ffffff;
  position: relative;
  -webkit-box-shadow: 0px 0px 24px 0px rgba(0, 0, 0, 0.06);
  -moz-box-shadow: 0px 0px 24px 0px rgba(0, 0, 0, 0.06);
  box-shadow: 0px 0px 24px 0px rgba(0, 0, 0, 0.06);
  flex-direction: column;
  border-radius: 12px;
}

.sununcontentakok {
  /* display: flex; */
  justify-content: center;
  align-items: center;
  width: 100%;
  /* max-width: 1064px; */
  min-height: 480px;
  background-color: #ffffff;
  position: relative;
  -webkit-box-shadow: 0px 0px 24px 0px rgba(0, 0, 0, 0.6);
  -moz-box-shadow: 0px 0px 24px 0px rgba(0, 0, 0, 0.6);
  box-shadow: 0px 0px 24px 0px rgba(0, 0, 0, 0.6);
  flex-direction: column;
}

/* =========================== Sunun component ============== */
/* =========================== cotopochton component ============== */

span.kotopokothon {
  font-size: 18px;
  color: #000;
  font-family: "Noto Sans Bengali";
  background-color: #136ee5;
  border: 2px solid #136ee5;
  padding: 0px 7px;
  border-radius: 24px;
  /* margin-right: 16px; */
  cursor: pointer;
  z-index: 0;
  position: absolute;
  right: 4px;
  top: 4px;
  font-size: 14px;
  color: #fff;
  font-weight: bold;
}

.btntotodik {
  border: none;
}

.afterclick {
  font-size: 18px;
  color: #000;
  font-family: "Noto Sans Bengali";
  background-color: #fff;
  /* border: 2px solid #136ee5; */
  padding: 8px 15px;
  border-radius: 24px;
  margin-left: 0px;
  cursor: pointer;
}

.afterkotopokothon {
  order: 0;
  z-index: 3;
}

.lecture {
  font-size: 18px;
  color: #000;
  font-family: "Noto Sans Bengali";
  background-color: #fff;
  border: 2px solid #44af4f;
  padding: 7px 16px;
  border-radius: 24px;
  cursor: pointer;
}

.lectureactive {
  background: #44af4f;
  border: 2px solid #44af4f;
  border-radius: 50px;
  font-family: "Noto Sans Bengali";
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  color: #ffffff;
}

.conversation {
  display: flex;
  align-items: center;
}

.lacture {
  margin-left: 30px;
}

/* =========================== cotopochton component ============== */
/* =========================== button click component ============== */

.clickbar {
  display: flex;
  width: 100%;
  margin-top: 30px;
}

.clickbaritem {
  display: flex;
  justify-content: space-between;
  width: 100%;
}

.selectareatotal {
  width: 15%;
}

.clickimg {
  /* background: url("./images/stts/wave.png") no-repeat; */
  background: url("./images/stts/newgreenplay.png") no-repeat;
  background-position: center center;
  min-width: 608px;
  min-height: 150px;
  justify-content: center;
  align-items: center;
  width: 100%;
  display: flex;
  position: relative;
}

.clickimgupdate {
  /* background: url("./images/stts/wave.png") no-repeat; */
  background: url("./images/stts/conversationgreen.png") no-repeat;
  background-position: center center;
  min-width: 608px;
  /* min-height: 160px; */
  justify-content: center;
  align-items: center;
  width: 100%;
  display: flex;
  position: relative;
}

.tooltiptext {
  /* visibility: hidden; */
  width: 296px;
  background-color: #136ee5;
  color: #fff;
  text-align: center;
  border-radius: 18px;
  padding: 5px 0;
  position: absolute;
  z-index: 1;
  bottom: 100%;
  /* right: -185%; */
  margin-left: 0px;
  min-height: 70px;
  display: flex;
  justify-content: center;
  align-items: center;
  /* top: -179px; */
}

span.tooltiptext:after {
  content: "";
  position: absolute;
  /* background: red; */
  /* width: 20px; */
  /* height: 20px; */
  /* left: 40.76%; */
  /* right: 55.31%; */
  top: 98.95%;
  /* bottom: 25.22%; */
  /* background: #44AF4F; */
  /* display: inline-block; */
  width: 0;
  height: 0;
  border-style: solid;
  border-width: 10px 10px 0 10px;
  border-color: #136ee5 transparent transparent transparent;
}

.clickimgupdate:hover .tooltiptext {
  visibility: visible;
}

.clickimglisten {
  /* background: url("./images/stts/wave.png") no-repeat; */
  /* background: url("./images/stts/listenplay.png") no-repeat; */
  background: url("./images/stts/Listen.gif") no-repeat;
  background: url("./images/stts/listenplay.png") no-repeat;
  background-position: center center;
  min-width: 608px;
  min-height: 160px;
  justify-content: center;
  align-items: center;
  width: 100%;
  display: flex;
  position: relative;
}

.clickimglistenplay {
  /* background: url("./images/stts/wave.png") no-repeat; */
  background: url("./images/stts/listenactiveplay.png") no-repeat;
  background-position: center center;
  min-width: 608px;
  min-height: 150px;
  justify-content: center;
  align-items: center;
  width: 100%;
  display: flex;
  position: relative;
}

.lacturemulti {
  background: url("./images/stts/wave.png") no-repeat;
  background-position: center center;
  min-width: 608px;
  min-height: 126px;
  justify-content: center;
  align-items: center;
  width: 100%;
  display: flex;
}

.clickimgre {
  background: url("./images/stts/wave.png") no-repeat;
  background-position: center center;
  min-width: 608px;
  min-height: 126px;
  justify-content: center;
  align-items: center;
  width: 100%;
  display: flex;
}

.clickstop {
  background: url("./images/stts/loader.gif") no-repeat;
  background-position: center center;
  min-width: 608px;
  min-height: 126px;
  justify-content: center;
  align-items: center;
  width: 100%;
  display: flex;
}

.clickstopupdate {
  background: url("./images/stts/Lecture-and-Listen-wave.gif") no-repeat;
  background-position: center center;
  min-width: 608px;
  min-height: 126px;
  justify-content: center;
  align-items: center;
  width: 100%;
  display: flex;
}

.clickstop {
  /* background: url("./images/stts/loader.gif") no-repeat; */
  /* background: url("./images/stts/newgreenstop.png") no-repeat; */
  background: url("./images/stts/newgreenstopwave.gif") no-repeat;
  background-position: center center;
  min-width: 608px;
  min-height: 150px;
  justify-content: center;
  align-items: center;
  width: 100%;
  display: flex;
  position: relative;
}

.clickstoplisten {
  /* background: url("./images/stts/loader.gif") no-repeat; */
  background: url("./images/stts/listenstop.png") no-repeat;
  background-position: center center;
  min-width: 608px;
  min-height: 150px;
  justify-content: center;
  align-items: center;
  width: 100%;
  display: flex;
  position: relative;
}

.clickstoplistenplay {
  /* background: url("./images/stts/loader.gif") no-repeat; */
  background: url("./images/stts/listenplaystop.png") no-repeat;
  background-position: center center;
  min-width: 608px;
  min-height: 150px;
  justify-content: center;
  align-items: center;
  width: 100%;
  display: flex;
  position: relative;
}

.lactureplay {
  /* background: url("./images/stts/lactureanimation.gif") no-repeat; */
  background: url("./images/stts/lactureplay.gif") no-repeat;
  background-position: center center;
  min-width: 608px;
  min-height: 126px;
  justify-content: center;
  align-items: center;
  width: 100%;
  display: flex;
}

.lactureplayre {
  /* background: url("./images/stts//Player.png") no-repeat; */
  background: url("./images/stts/doubleplay.png") no-repeat;
  background-position: center center;
  min-width: 608px;
  min-height: 126px;
  justify-content: center;
  align-items: center;
  width: 100%;
  display: flex;
}

.lactureplayrenew {
  /* background: url("./images/stts/rerecordwave.gif") no-repeat; */
  background: url("./images/stts/relactureplay.gif") no-repeat;
  background-position: center center;
  min-width: 608px;
  min-height: 126px;
  justify-content: center;
  align-items: center;
  width: 100%;
  display: flex;
}

.agiyejan {
  width: 30%;
  display: flex;
  justify-content: flex-end;
  /* align-items: center; */
  color: #ec411b;

}

.agiyejannew {
  width: 30%;
  display: flex;
  justify-content: flex-end;
  /* align-items: center; */
  position: absolute;
  top: 28px;
  right: 40px;
}



.agiyejannew a {
  color: #ec411b;
  text-decoration: none;
  font-size: 15px;
  font-family: "Noto Sans Bengali";
  width: 100%
}

.clickimg a {
  display: flex;
  justify-content: center;
  align-items: center;
}

.clickimg a img {
  padding-top: 31px;
  padding-right: 5px;
}

button.clickpoint {
  cursor: pointer;
  border: none;
  background: transparent;
}

.agiyejan a {
  color: #ec411b;
  text-decoration: none;
  font-size: 18px;
  font-family: "Noto Sans Bengali";
}

span.agiyespan {
  margin-left: 10px;
}

/* =========================== button click component ============== */
/* =========================== Right sidebar item start here ============== */

.singleitem ul {
  display: flex;
  justify-content: space-between;
  margin-bottom: 0px;
  padding: 0px;
}

.playstart {
  border: none;
  background: transparent;
}

.playreload {
  border: none;
  background: transparent;
}

.singleitem ul li {
  list-style: none;
  display: flex;
  justify-content: center;
  align-items: center;
}

.singleitemlisten ul {
  display: flex;
  justify-content: space-between;
  margin-bottom: 0px;
}

.singleitemlisten ul li {
  list-style: none;
  display: flex;
  justify-content: center;
  align-items: center;
}

.fullitem ul li {
  list-style: none;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
}

.fullitem ul li:first-child:after {
  border-right: 2px solid #f3f3f3;
  content: "";
  height: 30px;
  position: absolute;
  left: 60px;
}

.singleitem ul li:last-child {
  list-style: none;
  display: flex;
  justify-content: center;
  align-items: center;
  border: 1px solid #136ee5;
  padding: 10px 15px;
  border-radius: 50%;
  line-height: 20px;
}

ul.dropdown-menu.hanacontent.show {
  position: absolute;
  inset: auto auto 0px 0px;
  margin: 0px;
  transform: translate(0px, -46px);
}

.singleitem {
  background-color: #ffffff;
  margin: 35px 10px 0px;
  padding: 5px 10px;
  border: 1px solid #dde4f1;
  border-radius: 12px;
}

.singleitemlisten {
  /* background-color: #ffffff; */
  margin: 35px 10px 0px;
  padding: 5px 10px;
  /* border: 1px solid #dde4f1; */
  border-radius: 12px;
}

.singleitemlisten ul li:last-child {
  list-style: none;
  display: flex;
  justify-content: center;
  align-items: center;
  border: 1px solid #136ee5;
  padding: 10px 15px;
  border-radius: 50%;
  line-height: 20px;
}

li.clistenactive {
  background: #136ee5 !important;
  border: 1px solid #bedaff !important;
  color: #fff;
}

li.clisten {
  background: #bedaff;
  border: 1px solid #bedaff !important;
  color: #fff;
}

li.clisten span {
  color: #fff;
}

span.recordstart {
  font-size: 11px;
  font-family: "Noto Sans Bengali";
  color: #526281;
}

span.countitem {
  font-size: 16px;
  font-family: "Roboto Flex", sans-serif;
  color: #136ee5;
  font-weight: 700;
}

.onlyitem ul {
  display: flex;
  justify-content: flex-end;
}

span.playreload {
  display: flex;
  justify-content: center;
  align-items: center;
}

.rightsidebarwithseelct {
  display: flex;
  flex-direction: column;
  width: 20%;
}

.nomargin .form-control {
  min-height: 48px;
}

.selectarea {
  display: flex;
  flex-direction: column;
  width: 100%;
  margin: 10px 0px;
}

.selectcontroll {
  min-height: 48px;
  color: #136ee5;
  font-size: 17px;
  text-align: center;
  font-family: "Noto Sans Bengali";
  border: 1px solid #d8e1f5;
  border-radius: 9px;
  font-weight: 700;
}

.btnjomadin {
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: center;
  background-color: #d1d6e1;
  border: transparent;
  padding: 7px 0px;
  color: #919191;
  font-size: 18px;
  font-family: "Noto Sans Bengali";
  border-radius: 9px;
  cursor: default;
}

.jomadinsunun {
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: center;
  background-color: #ffffff;
  border: transparent;
  padding: 7px 0px;
  color: #c0c3c7;
  font-size: 18px;
  font-family: "Noto Sans Bengali";
  border-radius: 9px;
  border: 1px solid #d8e1f5;
}

span.disjomadin {
  margin: 0px 10px 0px 0px;
  cursor: default;
}

.selectcontroll:focus-visible {
  outline: none;
}

.btnactive {
  color: #fff;
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: center;
  background-color: #136ee5;
  border: transparent;
  padding: 7px 0px;
  font-size: 18px;
  font-family: "Noto Sans Bengali";
  border-radius: 9px;
  cursor: pointer;
}

.cothopokothongroupnone {
  display: none !important;
}

.cothopokothongroupbtn {
  display: block;
}

.cothopokothongroup {
  display: flex;
  justify-content: center;
  align-items: center;
  /* border: 1px solid #136ee5; */
  border-radius: 24px;
  margin-right: 20px;
}

/* .cothopokothongroup {
  display: flex;
  justify-content: center;
  align-items: center;
  border: 2px solid #136ee5;
  border-radius: 24px;
} */

button.btnkotopon {
  background: no-repeat;
  border: none;
  font-size: 18px;
  color: #136ee5;
  font-family: "Noto Sans Bengali";
  cursor: pointer;
  margin-right: 10px;
}

.closeditem {
  background-color: #136ee5;
  color: #fff;
  padding: 5px 9px;
  border-radius: 50%;
  font-size: 10px;
  cursor: pointer;
  border: none;
}

.closeditemactive {
  background-color: #fff;
  color: #44af4f;
  padding: 0px 7px;
  border-radius: 50%;
  font-size: 10px;
  cursor: pointer;
  border: none;
  margin-left: 10px;
  /* margin-top: -43px; */
  font-family: "Noto Sans Bengali";
  font-style: normal;
  font-weight: 600;
  font-size: 15px;
}

.kbtactive {
  background-color: #136ee5;
  color: #fff;
  border: 2px solid #136ee5;
}

/* .lectureactive {
  background-color: #e50019;
  color: #fff;
  border: 2px solid #fff;
} */

.overallcontent {
  display: flex;
  width: 100%;
}

.overallhide {
  display: none;
}

.searcharea {
  display: flex;
  /* justify-content: space-between; */
  width: 100%;
  align-items: center;
  justify-content: center;
}

input.search {
  background: #ffffff;
  border: 1px solid #c0c3c7;
  border-radius: 8px;
  font-family: "Noto Sans Bengali";
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 24px;
  padding: 13px 16px 12px;
  min-width: 340px;
}

input.search:foucs {
  padding: 12px 16px !important;
  background: #ffffff !important;
  border: 1px solid #246fdf !important;
  box-shadow: 0px 0px 12px rgba(19, 110, 229, 0.55) !important;
  border-radius: 8px !important;
}

input.search:foucs-visible {
  padding: 12px 16px !important;
  background: #ffffff !important;
  border: 1px solid #246fdf !important;
  box-shadow: 0px 0px 12px rgba(19, 110, 229, 0.55) !important;
  border-radius: 8px !important;
}

input.search:foucs-visible {
  padding: 12px 16px !important;
  background: #ffffff !important;
  border: 1px solid #246fdf !important;
  box-shadow: 0px 0px 12px rgba(19, 110, 229, 0.55) !important;
  border-radius: 8px !important;
}

input.search:disabled {
  background: #f4f4f4;
  /* Black 20 */

  border: 1px solid #e3e3e3;
  border-radius: 8px;
  color: #b0b0b0;
}

input.valid:valid {
  background: #ffffff;
  /* Green */

  border: 1px solid #00af4c;
  border-radius: 8px;
}

input.error {
  background: #ffffff;
  border: 1px solid #ec411b;
  border-radius: 8px;
  color: #161a31 !important;
}

.error::-webkit-input-placeholder {
  color: #161a31;
}

.error::-webkit-input-placeholder {
  color: #161a31;
}

.error:-moz-placeholder {
  /* FF 4-18 */
  color: #161a31;
}

.error::-moz-placeholder {
  /* FF 19+ */
  color: #161a31;
}

.error:-ms-input-placeholder {
  /* IE 10+ */
  color: #161a31;
}

.error::-ms-input-placeholder {
  /* Microsoft Edge */
  color: #161a31;
}

.error::placeholder {
  /* modern browser */
  color: #161a31;
}

/* input.search:active {
  background: #ffffff;
  border: 1px solid #8799b2;
  border-radius: 8px;
  color: #161a31;
  font-family: "Lato";
  font-style: normal;
} */

:focus {
  outline: none;
}

button.btnsarea {
  background: #136ee5;
  border-radius: 8px;
  border: none;
  padding: 13px 16px 12px;
  font-family: "Noto Sans Bengali";
  font-style: normal;
  font-weight: 600;
  font-size: 17px;
  line-height: 23px;
  text-align: center;
  color: #ffffff;
  min-width: 148px;
}

button.btnsarea:hover {
  background-color: #0c5bc2;
}

button.btnsarea:paused {
  background-color: #1a488d;
}

.loadingarea {
  position: relative;
}

.loadingarearead {
  position: relative;
}

button.btnsarea:focus {
  font-family: "Noto Sans Bengali";
  font-style: normal;
  font-weight: 600;
  font-size: 17px;
  line-height: 23px;
  text-align: center;
  color: #ffffff;
  flex: none;
  order: 0;
  flex-grow: 1;
  border: 2px solid #fff;
  outline: solid #136ee5 !important;
}

p.usertext {
  font-size: 18px;
  color: #8799b2;
}

p.usertextmulti {
  font-size: 16px;
  color: #8799b2;
}

.textarea {
  margin-right: 12px;
  flex-direction: column;
  display: flex;
  /* justify-content: center; */
}

.errornote {
  font-family: "Noto Sans Bengali";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 21px;
  color: #ec411b;
  margin-top: 5px;
}

.speakerarea {
  display: flex;
  flex-direction: row;
  /* justify-content: space-around; */
  /* width: 100%; */
  margin-bottom: 40px;
  width: 40%;
  justify-content: center;
  border-right: 2px solid #dde4f1;
}

.speakderareamulit {
  display: flex;
  flex-direction: row;
  /* justify-content: space-around; */
  /* width: 100%; */
  margin-bottom: 40px;
  width: 50%;
  justify-content: center;
  border-right: 2px solid #dde4f1;
  margin-top: 10px;
}

.singlespeaker {
  display: flex;
  flex-direction: column;
  width: 118px;
  height: 150px;
  justify-content: center;
  align-items: center;
  border: 1px solid #dde4f1;
  padding: 15px 10px;
  border-radius: 7px;
  margin-right: 16px;
  flex-wrap: nowrap;
  word-break: break-word;
}

.singlespeaker:hover {
  border: 1px solid #136ee5;
}

.singlespeaker:hover .speakertitle {
  color: #777777;
}

.singlespeaker img {
  max-width: 48px;
  margin-bottom: 16px;
}

p.speakertitle {
  margin-bottom: 0;
  color: #136ee5;
  font-size: 18px;
  font-weight: 500;
  font-family: "NOTO SANS BENGALI";
}

p.speakertitletwo {
  margin-bottom: 0px;
  font-family: "Noto Sans Bengali";
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
}

.threearea {
  display: flex;
  position: relative;
  flex-direction: column;
}

.dotedone {
  position: absolute;
  content: "";
  width: 4.8px;
  height: 4.8px;
  background: #adb1bf;
  /* left: 100px; */
  border-radius: 50%;
}

.dotetwo {
  position: absolute;
  content: "";
  width: 4.8px;
  height: 4.8px;
  background: #adb1bf;
  /* left: 100px; */
  border-radius: 50%;
  top: 8px;
}

.dotethree {
  position: absolute;
  content: "";
  width: 4.8px;
  height: 4.8px;
  background: #adb1bf;
  /* left: 100px; */
  border-radius: 50%;
  top: 16px;
}

.showmucefelun {
  display: flex;
  flex-direction: column;
  cursor: pointer;
  position: absolute;
  left: 40px;
}

.musefelun {
  background-color: #fff;
  padding: 8px 12px;
  box-shadow: 0px 2px 6px rgba(0, 0, 0, 0.16);
  border-radius: 7px;
  opacity: 0;
  position: absolute;
  width: 104px;
  right: -50px;
}

.showmucefelun:hover .musefelun {
  display: block !important;
}

span.museclick {
  color: #707070;
  font-size: 18px;
  font-family: "Noto Sans Bengali";
  font-weight: 400;
}

.threearea:hover .musefelun {
  opacity: 1;
  cursor: pointer;
}

.threearea:hover .showmucefelun {
  display: none;
}

.search:hover {
  border: 1px solid #999da7;
}

.search:focus-visible {
  border: 1px solid #246fdf !important;
  box-shadow: 0px 0px 12px rgba(19, 110, 229, 0.55);
}

.search:focus {
  border: 1px solid #246fdf !important;
  box-shadow: 0px 0px 12px rgba(19, 110, 229, 0.55);
}

.newbtntab.kotopokothon {
  display: flex;
  flex-direction: row;
}

.tabnoborder {
  border: 2px solid #136ee5;
}

.nav-pills .nav-link.active,
.nav-pills .show>.nav-link {
  color: var(--bs-nav-pills-link-active-color) !important;
  background-color: var(--bs-nav-pills-link-active-bg) !important;
  background: #136ee5 !important;
  border: 2px solid #136ee5;
  border-radius: 0px 50px 50px 0px !important;
  font-size: 18px;
  font-family: "Noto Sans Bengali";
  font-weight: 400;
  width: 105px !important;
  padding: 8px 16px 8px 30px !important;
  position: inherit;
}

li.nav-item {
  position: relative;
}

.nav-pills .nav-link {
  background: 0 0;
  border: 0;
  border-radius: var(--bs-nav-pills-border-radius);
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 8px 16px 8px 20px;
  gap: 8px;
  width: 91px;
  height: 40px;
  background: #ffffff;
  border: 2px solid #136ee5;
  border-radius: 0px 50px 50px 0px;
}

.btnakok {
  display: flex !important;
  flex-direction: column !important;
  align-items: flex-start !important;
  padding: 8px 16px 8px 32px !important;
  gap: 8px !important;
  width: 91px !important;
  height: 40px !important;
  background: #ffffff !important;
  border: 2px solid #136ee5 !important;
  border-radius: 0px 50px 50px 0px !important;
  flex: none;
  order: 1;
  flex-grow: 0;
  margin: 0px 0px;
  z-index: 2;
  position: inherit;
}

.btnakok:focus,
.btndoito:focus,
.btntoito:focus {
  box-shadow: 4px 0px 12px rgba(19, 110, 229, 0.75);
}

.btnakok:hover,
.btndoito:hover,
.btntoito:hover {
  background-color: #e4e8f0 !important;
  color: #303030 !important;
  font-family: "Noto Sans Bengali";
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
}

.btnakok:paused,
.btndoito:paused,
.btntoito:paused {
  background-color: #1a488d;
  color: #d2eaff;
  font-family: "Noto Sans Bengali";
  font-style: normal;
  font-weight: 400;
}

.btndoito {
  display: flex !important;
  flex-direction: column !important;
  align-items: flex-start !important;
  padding: 8px 16px 8px 32px !important;
  gap: 8px !important;
  width: 79px !important;
  height: 40px !important;
  background: #ffffff !important;
  border: 2px solid #136ee5 !important;
  border-radius: 0px 50px 50px 0px !important;
  flex: none;
  order: 2;
  flex-grow: 0;
  margin: 0px -17px;
  z-index: 1;
  position: inherit;
}

.btntoito {
  display: flex !important;
  flex-direction: column !important;
  align-items: flex-start !important;
  padding: 8px 16px 8px 32px !important;
  gap: 8px !important;

  width: 115px !important;
  height: 40px !important;

  background: #ffffff !important;
  border: 2px solid #136ee5 !important;
  border-radius: 0px 50px 50px 0px !important;

  /* Inside auto layout */

  flex: none;
  order: 3;
  flex-grow: 0;
  z-index: 0;
  position: inherit;
}

.btnkotopokothon {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 8px 12px 8px 16px;
  gap: 8px;

  width: 141px;
  height: 40px;

  background: #ffffff;
  border: 2px solid #136ee5;
  border-radius: 50px;

  /* Inside auto layout */

  flex: none;
  order: 0;
  flex-grow: 0;
  margin: 0px -24px;
  z-index: 3;

  font-family: "Noto Sans Bengali";
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 24px;
  /* identical to box height */

  color: #136ee5;
  position: inherit;
}

.mrighttop {
  margin-right: 20px;
}

/* ===================================== Loading Spring =========================== */
span.allloading1 {
  position: absolute;
  content: "";
  background: #fff;
  width: 4.42px;
  height: 4.42px;
  border-radius: 50%;
  right: 15px;
  top: 5px;
}

span.allloading2 {
  position: absolute;
  content: "";
  background: #fff;
  width: 3.98px;
  height: 3.98px;
  border-radius: 50%;
  right: 20px;
  top: 14px;
}

.disabledall {
  justify-content: center;
  align-items: center;
  padding: 13px 16px 12px;
  gap: 8px;
  /* position: absolute; */
  width: 148px;
  height: 48px;
  left: 2604px;
  top: 566px;
  background: #d1d6e1;
  border-radius: 8px;
  color: #919191;
  border: none;
  font-family: "Noto Sans Bengali";
  font-size: 17px;
  font-weight: 600;
}

span.allloading3 {
  position: absolute;
  content: "";
  background: #fff;
  width: 3.5px;
  height: 3.5px;
  border-radius: 50%;
  right: 17px;
  top: 22px;
}

span.allloading4 {
  position: absolute;
  content: "";
  background: #fff;
  width: 3px;
  height: 3px;
  border-radius: 50%;
  right: 10px;
  top: 25px;
}

span.allloading5 {
  position: absolute;
  content: "";
  background: #fff;
  width: 2.65px;
  height: 2.65px;
  border-radius: 50%;
  right: 5px;
  top: 20px;
}

span.allloading6 {
  position: absolute;
  content: "";
  background: #fff;
  width: 2.21px;
  height: 2.21px;
  border-radius: 50%;
  right: 6px;
  top: 13px;
}

span.allloading11 {
  position: absolute;
  content: "";
  background: #136ee5;
  width: 4.42px;
  height: 4.42px;
  border-radius: 50%;
  right: 0px;
  top: 0px;
}

span.allloading22 {
  position: absolute;
  content: "";
  background: #136ee5;
  width: 3.98px;
  height: 3.98px;
  border-radius: 50%;
  right: -2px;
  top: 8px;
}

span.allloading33 {
  position: absolute;
  content: "";
  background: #136ee5;
  width: 3.5px;
  height: 3.5px;
  border-radius: 50%;
  right: -10px;
  top: 12px;
}

span.allloading44 {
  position: absolute;
  content: "";
  background: #136ee5;
  width: 3px;
  height: 3px;
  border-radius: 50%;
  right: -19px;
  top: 7px;
}

span.allloading55 {
  position: absolute;
  content: "";
  background: #136ee5;
  width: 2.65px;
  height: 2.65px;
  border-radius: 50%;
  right: -19px;
  top: -3px;
}

span.allloading66 {
  position: absolute;
  content: "";
  background: #136ee5;
  width: 2.21px;
  height: 2.21px;
  border-radius: 50%;
  right: -8px;
  top: -8px;
}

button.clickpoint {
  justify-content: space-between;
  display: flex;
  align-items: center;
  /* position: relative; */
  /* width: 170px; */
}

.tooltiptextlisten {
  visibility: hidden;
  width: 296px;
  background-color: #136ee5;
  color: #fff;
  text-align: center;
  border-radius: 18px;
  padding: 5px 0;
  position: absolute;
  z-index: 1;
  bottom: 100%;
  /* right: -185%; */
  margin-left: 0px;
  min-height: 94px;
  display: flex;
  justify-content: center;
  align-items: center;
  /* top: -179px; */
  font-family: "Noto Sans Bengali";
  font-style: normal;
  font-weight: 700;
  font-size: 23px;
  line-height: 30px;
  color: #ffffff;
}

span.tooltiptextlisten:after {
  content: "";
  position: absolute;
  /* background: red; */
  /* width: 20px; */
  /* height: 20px; */
  /* left: 40.76%; */
  /* right: 55.31%; */
  top: 98.95%;
  /* bottom: 25.22%; */
  /* background: #44AF4F; */
  /* display: inline-block; */
  width: 0;
  height: 0;
  border-style: solid;
  border-width: 10px 10px 0 10px;
  border-color: #136ee5 transparent transparent transparent;
}

.clickimglisten:hover .tooltiptextlisten {
  visibility: visible;
}

.tooltiptext {
  /* visibility: hidden; */
  width: 296px;
  background-color: #44af4f;
  color: #fff;
  text-align: center;
  border-radius: 18px;
  padding: 5px 0;
  position: absolute;
  z-index: 1;
  bottom: 100%;
  /* right: -185%; */
  margin-left: 0px;
  /* min-height: 94px; */
  min-height: 70px;
  display: flex;
  justify-content: center;
  align-items: center;
  /* top: -179px; */
  font-family: "Noto Sans Bengali";
  font-style: normal;
  font-weight: 700;
  font-size: 23px;
  line-height: 30px;

  color: #ffffff;
  transition-duration: 5s;
  /* display: none; */
}

span.tooltiptext:after {
  content: "";
  position: absolute;
  /* background: red; */
  /* width: 20px; */
  /* height: 20px; */
  /* left: 40.76%; */
  /* right: 55.31%; */
  top: 98.95%;
  /* bottom: 25.22%; */
  /* background: #44AF4F; */
  /* display: inline-block; */
  width: 0;
  height: 0;
  border-style: solid;
  border-width: 10px 10px 0 10px;
  border-color: #44af4f transparent transparent transparent;
}

.clickimg:hover .tooltiptext {
  visibility: visible;
}

.tooltiptextstop {
  /* visibility: hidden; */
  width: 296px;
  background-color: #ec411b;
  color: #fff;
  text-align: center;
  border-radius: 18px;
  padding: 5px 0;
  position: absolute;
  z-index: 1;
  bottom: 100%;
  /* right: -185%; */
  margin-left: 0px;
  /* min-height: 94px; */
  min-height: 60px;
  display: flex;
  justify-content: center;
  align-items: center;
  /* top: -179px; */
  font-family: "Noto Sans Bengali";
  font-style: normal;
  font-weight: 700;
  font-size: 23px;
  line-height: 30px;

  color: #ffffff;
}

span.tooltiptextstop:after {
  content: "";
  position: absolute;
  /* background: red; */
  /* width: 20px; */
  /* height: 20px; */
  /* left: 40.76%; */
  /* right: 55.31%; */
  top: 98.95%;
  /* bottom: 25.22%; */
  /* background: #44AF4F; */
  /* display: inline-block; */
  width: 0;
  height: 0;
  border-style: solid;
  border-width: 10px 10px 0 10px;
  border-color: #ec411b transparent transparent transparent;
}

.clickstop:hover .tooltiptextstop {
  visibility: visible;
}

.tooltiptextstoplisten {
  visibility: hidden;
  width: 296px;
  background-color: #ec411b;
  color: #fff;
  text-align: center;
  border-radius: 18px;
  padding: 5px 0;
  position: absolute;
  z-index: 1;
  bottom: 100%;
  /* right: -185%; */
  margin-left: 0px;
  min-height: 94px;
  display: flex;
  justify-content: center;
  align-items: center;
  /* top: -179px; */
}

span.tooltiptextstoplisten:after {
  content: "";
  position: absolute;
  /* background: red; */
  /* width: 20px; */
  /* height: 20px; */
  /* left: 40.76%; */
  /* right: 55.31%; */
  top: 98.95%;
  /* bottom: 25.22%; */
  /* background: #44AF4F; */
  /* display: inline-block; */
  width: 0;
  height: 0;
  border-style: solid;
  border-width: 10px 10px 0 10px;
  border-color: #ec411b transparent transparent transparent;
}

.clickstoplisten:hover .tooltiptextstoplisten {
  visibility: visible;
}

span.stopimgbtn {
  display: flex;
  justify-content: flex-end;
}

.formobile {
  display: none;
}

.fordesktop {
  display: flex;
}

.lacturemulti .noshow {
  display: none;
}

.lactureplay .btncustom {
  display: none;
}

.btnpouse {
  border: none;
}

.btnstop {
  border: none;
}

.btnpouse {
  background: #fff;
  box-shadow: 0px 0px 12px 0px rgba(255, 87, 103, 0.55);
  -webkit-box-shadow: 0px 0px 12px 0px rgba(255, 87, 103, 0.55);
  -moz-box-shadow: 0px 0px 12px 0px rgba(255, 87, 103, 0.55);
  width: 64px;
  height: 64px;
  border-radius: 50%;
  margin: 0px 35px 0px 40px;
}

.btnpousegreen {
  background: #fff;
  box-shadow: 0px -2px 5px 10px rgba(201, 221, 248, 0.75);
  -webkit-box-shadow: 0px -2px 5px 10px rgba(201, 221, 248, 0.75);
  -moz-box-shadow: 0px -2px 5px 10px rgba(201, 221, 248, 0.75);
  width: 64px;
  height: 64px;
  border-radius: 50%;
  margin: 0px 35px 0px 40px;
  border: none;
}

.btnstop {
  background: #fff;
  width: 64px;
  height: 64px;
  border-radius: 50%;
}

.lactureplayrenew .noshow {
  display: none;
}

.lactureplayre .clickpoint {
  display: none;
}

.clickimgre .noshow {
  display: none;
}

button.greenpause {
  width: 64px;
  height: 64px;
  background: #fff;
  border-radius: 50%;
  justify-content: center;

  -moz-box-shadow: 0px 3px 0px 10px rgba(0, 175, 76, 0.45);
  box-shadow: 0px 3px 10px 14px rgba(0, 175, 76, 0.48);
  -webkit-box-shadow: 0px 3px 10px 14px rgba(0, 175, 76, 0.48);
  -moz-box-shadow: 0px 3px 10px 14px rgba(0, 175, 76, 0.48);
}

/* =========================== New file design start Here ============================ */

span.patharrow img {
  background-color: #ffffff;
  padding: 13px 10px;
  border-radius: 50%;
  border: 1.5px solid #b9d0f1;
  cursor: pointer;
}

span.patharrowmobile img {
  border-radius: 50%;

  cursor: pointer;
}

span.patharrowmobile {
  font-family: "Noto Sans Bengali";
  font-style: normal;
  font-weight: 700;
  font-size: 15px;
  line-height: 20px;
  color: #0c5bc2;
  margin-left: 10px;
}

span.homearrow {
  font-family: "Noto Sans Bengali";
  font-style: normal;
  font-weight: 700;
  font-size: 15px;
  line-height: 20px;
  color: #161a31;
  margin-left: 10px;
}

.recordtooltips {
  background-color: red;
}

.MuiBox-root.css-8atqhb {
  position: absolute;
  bottom: 0px;
}

.habtnarea {
  min-width: 180px;
  background: #fff;
  min-height: 48px;
  justify-content: center;
  align-items: center;
  display: flex;
  border-radius: 15px;
}

.clickpointplay {
  margin: 0px 10%;
}

span.hasunun {
  margin: 0px 30px 0px 10px;
  font-family: "Noto Sans Bengali";
  font-style: normal;
  font-weight: 500;
  font-size: 24px;
  line-height: 32px;
  color: #303030;
  /* display: flex; */
  /* justify-content: center; */
  /* align-items: center; */
}

span.hanadisable {
  color: #adb1bf !important;
}

.btnhatick:after {
  color: #adb1bf !important;
}

.btnnatick:after {
  color: #adb1bf !important;
}

span.nasunun {
  margin: 0px 30px 0px 10px;
  font-family: "Noto Sans Bengali";
  font-style: normal;
  font-weight: 500;
  font-size: 24px;
  line-height: 32px;
  color: #303030;
  /* display: flex; */
  /* justify-content: center; */
  /* align-items: center; */
}

.btnnatick {
  display: flex !important;
  justify-content: center;
  align-items: center !important;
  border: none !important;
  outline: none !important;
}

.btnhatick {
  display: flex !important;
  justify-content: center;
  align-items: center !important;
  border: none !important;
  outline: none !important;
}

.hanacontent.show {
  display: block;
  background: linear-gradient(180deg,
      #ffffff 0%,
      #ffffff 41%,
      #d7def1 81.2%,
      #ffffff 100%);
  transform: translate(-22px, -46px) !important;
}

.hanaitem {
  font-size: 18px;
  font-family: "Noto Sans Bengali";
  font-weight: 700 !important;
}

.mobileview {
  display: none;
}

.homereadmobile {
  display: none;
}

.bolunsunuarea {
  display: flex;
  justify-content: space-around;
  width: 100%;
}

.boluntextarea button {
  border: none;
  background: transparent;
}

.sununtextarea button {
  border: none;
  background: transparent;
}

.mobilerightsidebar {
  display: none;
}

/* =========================== New file design start Here ============================ */

/* ===================================== Loading Spring =========================== */

/* =========================== Right sidebar item start here ============== */

/* ========================== Responsive Design start here ================== */

@media only screen and (device-aspect-ratio: 16/9)
{     
  .clickstop {
    /* background: url("./images/stts/loader.gif") no-repeat; */
    /* background: url("./images/stts/newgreenstop.png") no-repeat; */
    background: url("./images/stts/newgreenstopwavesm.gif") no-repeat;
    background-position: center center;
    min-width: 608px;
    min-height: 150px;
    justify-content: center;
    align-items: center;
    width: 100%;
    display: flex;
    position: relative;
  }
  .agiyejannew.conversationskip {
    width: 20%;
}

.agiyejannew.conversationskip a {
  text-align: right;
  color: red;
}

  
.clickimg {
  /* background: url("./images/stts/wave.png") no-repeat; */
  background: url("./images/stts/newgreenplaysm.png") no-repeat;
  background-position: center center;
  min-width: 608px;
  min-height: 150px;
  justify-content: center;
  align-items: center;
  width: 100%;
  display: flex;
  position: relative;
}

.clickstoplistenplay {
  /* background: url("./images/stts/loader.gif") no-repeat; */
  background: url("./images/stts/listenplaystopsm.png") no-repeat;
  background-position: center center;
  min-width: 608px;
  min-height: 150px;
  justify-content: center;
  align-items: center;
  width: 100%;
  display: flex;
  position: relative;
}
  
  
  
  .readbg {
  background: url("./images/stts/readbg.png") no-repeat;
  background-position: center;
  width: 100%;
  height: 100vh ;
  background-size: cover;
}
.boluncontent {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  max-width: 100%;
  min-height: 380px;
  background-color: #ffffff;
  position: relative;
  box-shadow: 0px 0px 24px 0px rgba(0, 0, 0, 0.06);
  flex-direction: column;
  border-radius: 12px;
}

.rightsidebar {
  /* display: flex; */
  width: 100%;
  background-color: #f6f9ff;
  box-shadow: 0px 0px 24px 0px rgba(0, 0, 0, 0.06);
  -webkit-box-shadow: 0px 0px 24px 0px rgba(0, 0, 0, 0.06);
  -moz-box-shadow: 0px 0px 24px 0px rgba(0, 0, 0, 0.06);
  overflow: hidden;
  border-radius: 12px;
  min-height: 380px;
  min-width: 223px;
}

.rightsidebarwithseelct {
  display: flex;
  flex-direction: column;
  width: 20%;
  height: 350px;
}
.clickbar {
  display: flex;
  width: 100%;
  margin-top: 0px;
}

.footerarea {
  display: flex;
  width: 100%;
  justify-content: space-between;
  background-color: #ffffff;
  position: fixed;
  bottom: 0;
  padding: 10px 0px;
  /* max-width: 1920px; */
  align-items: center;
}
.footertext p {
  margin-right: 20px;
  margin-bottom: 0px;
  align-items: center;
  justify-content: center;
}
ul.brandlogo {
  display: flex;
  margin-bottom: 0px;
}
.footermiddle p {
  margin-bottom: 0;
}

.singleitem {
  background-color: #ffffff;
  margin: 10px 10px 0px;
  padding: 5px 10px;
  border: 1px solid #dde4f1;
  border-radius: 12px;
}
.singleitemlisten {
  /* background-color: #ffffff; */
  margin: 15px 10px 0px;
  padding: 5px 10px;
  /* border: 1px solid #dde4f1; */
  border-radius: 12px;
}
.bodycontent {
  width: 100%;
  max-width: 1180px;
  margin: 0 auto;
  margin-top: 5px;
}

.agiyejannew {
  width: 50%;
  display: flex;
  justify-content: flex-end;
  /* align-items: center; */
  position: absolute;
  top: 28px;
  right: 40px;
}
.topnavbar {
  max-width: 1200px;
  margin: 0 auto;
  display: flex;
  width: 100%;
  justify-content: space-between;
}

.clickimglistenplay {
  /* background: url("./images/stts/wave.png") no-repeat; */
  background: url("./images/stts/listenactiveplaysm.png") no-repeat;
  background-position: center center;
  min-width: 608px;
  min-height: 150px;
  justify-content: center;
  align-items: center;
  width: 100%;
  display: flex;
  position: relative;
}

}




@media screen and (max-width: 1550px) {
  /* .bodycontent {
    width: 100%;
    max-width: 1366px;
    margin: 0 auto;
    margin-top: 30px;
  } */

  .clickimg {
    width: 100%;
  }

  .clickstop {
    width: 100%;
  }

  /* .topnavbar {
    max-width: 1366px;
    margin: 0 auto;
    display: flex;
    width: 100%;
    justify-content: space-between;
  } */
}

/* 
@media only screen and (max-width: 1540px) {
  .readbg {
    background: url("./images/stts/readbg.png") no-repeat;
    background-position: center;
    width: 100%;
    height: 130vh !important;
    background-size: cover;
  }
} */


@media screen and (max-width: 1520px) and (max-height: 722px) {
  .footerarea {
    display: flex;
    width: 100%;
    justify-content: space-between;
    background-color: #ffffff;
    position: fixed;
    bottom: -25px;
    padding: 20px 0px;
    /* max-width: 1920px; */
    align-items: center;
  }

  .readbg {
    background: url("./images/stts/readbg.png") no-repeat;
    background-position: center;
    width: 100%;
    /* height: 130vh; */
    height: 100vh;
    background-size: cover;
  }

  .boluncontent {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    /* max-width: 1064px; */
    min-height: 330px;
    background-color: #ffffff;
    position: relative;
    box-shadow: 0px 0px 24px 0px rgba(0, 0, 0, 0.06);
    flex-direction: column;
    border-radius: 12px;
  }

  .singleitemlisten {
    /* background-color: #ffffff; */
    margin: 20px 20px 0px;
    padding: 5px 10px;
    /* border: 1px solid #dde4f1; */
    border-radius: 12px;
  }

  .rightsidebar {
    min-height: 330px;
  }

  .singleitem {
    background-color: #ffffff;
    margin: 18px 20px 10px;
    padding: 5px 10px;
    border: 1px solid #dde4f1;
    border-radius: 12px;
  }
}

@media screen and (max-width: 1400px) {
  .bodycontent {
    width: 100%;
    max-width: 1200px;
    margin: 0 auto;
    margin-top: 30px;
  }

  .clickimg {
    width: 100%;
  }

  .clickstop {
    width: 100%;
  }

  .agiyejan a {
    color: #ec411b;
    text-decoration: none;
    font-size: 16px;
    font-family: "Noto Sans Bengali";
  }

  .topnavbar {
    max-width: 1200px;
    margin: 0 auto;
    display: flex;
    width: 100%;
    justify-content: space-between;
  }
}

@media screen and (max-width: 1367px) and (max-height: 768px) {

  .clickstop {
    /* background: url("./images/stts/loader.gif") no-repeat; */
    /* background: url("./images/stts/newgreenstop.png") no-repeat; */
    background: url("./images/stts/newgreenstopwavesm.gif") no-repeat;
    background-position: center center;
    min-width: 608px;
    min-height: 150px;
    justify-content: center;
    align-items: center;
    width: 100%;
    display: flex;
    position: relative;
  }

  
.clickimg {
  /* background: url("./images/stts/wave.png") no-repeat; */
  background: url("./images/stts/newgreenplaysm.png") no-repeat;
  background-position: center center;
  min-width: 608px;
  min-height: 150px;
  justify-content: center;
  align-items: center;
  width: 100%;
  display: flex;
  position: relative;
}

.clickimglistenplay {
  /* background: url("./images/stts/wave.png") no-repeat; */
  background: url("./images/stts/listenactiveplaysm.png") no-repeat;
  background-position: center center;
  min-width: 608px;
  min-height: 150px;
  justify-content: center;
  align-items: center;
  width: 100%;
  display: flex;
  position: relative;
}

.clickstoplistenplay {
  /* background: url("./images/stts/loader.gif") no-repeat; */
  background: url("./images/stts/listenplaystopsm.png") no-repeat;
  background-position: center center;
  min-width: 608px;
  min-height: 150px;
  justify-content: center;
  align-items: center;
  width: 100%;
  display: flex;
  position: relative;
}

  .boluncontent {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    /* max-width: 1064px; */
    min-height: 400px;
    background-color: #ffffff;
    position: relative;
    box-shadow: 0px 0px 24px 0px rgba(0, 0, 0, 0.06);
    flex-direction: column;
    border-radius: 12px;
  }

  .singleitemlisten {
    /* background-color: #ffffff; */
    margin: 20px 20px 0px;
    padding: 5px 10px;
    /* border: 1px solid #dde4f1; */
    border-radius: 12px;
  }

  .rightsidebar {
    min-height: 330px;
  }

  .singleitem {
    background-color: #ffffff;
    margin: 18px 20px 10px;
    padding: 5px 10px;
    border: 1px solid #dde4f1;
    border-radius: 12px;
  }

  .bodycontent {
    width: 100%;
    max-width: 1200px;
    margin: 0 auto;
    margin-top: 10px;
}
.footerarea {
  display: flex;
  width: 100%;
  justify-content: space-between;
  background-color: #ffffff;
  position: fixed;
  bottom: 0;
  padding: 10px 0px;
  /* max-width: 1920px; */
  align-items: center;
}
.footertext p {
  margin-right: 20px;
  margin-bottom: 0px;
  align-items: center;
  justify-content: center;
}
ul.brandlogo {
  display: flex;
  margin-bottom: 0px;
}
.footermiddle p {
  margin-bottom: 0;
}
.agiyejannew {
  width: 50%;
  display: flex;
  justify-content: flex-end;
  /* align-items: center; */
  position: absolute;
  top: 28px;
  right: 40px;
}

}

@media screen and (max-width: 1240px) {
  .bodycontent {
    width: 100%;
    max-width: 1180px;
    margin: 0 auto;
    margin-top: 30px;
  }

  .clickimg {
    width: 80%;
  }

  .clickstop {
    width: 80%;
  }

  .agiyejan a {
    color: #ec411b;
    text-decoration: none;
    font-size: 16px;
    font-family: "Noto Sans Bengali";
  }

  .topnavbar {
    max-width: 1180px;
    margin: 0 auto;
    display: flex;
    width: 100%;
    justify-content: space-between;
  }
}

@media screen and (max-width: 1180px) {
  .bodyfordoito.withalter{    display: flex;
    flex-direction: column-reverse;
    width: 100%;
    justify-content: center;
    align-items: center;}
  .bodyfordoito {
    display: flex;
    flex-direction: column;
    width: 100%;
    justify-content: center;
    align-items: center;
  }

  .speakderareamulit {
    display: flex;
    flex-direction: row;
    /* justify-content: space-around; */
    /* width: 100%; */
    margin-bottom: 40px;
    width: 50%;
    justify-content: center;
    border-right: none !important;
    margin-top: 10px;
  }

  .bodycontent {
    width: 100%;
    max-width: 900px;
    margin: 0 auto;
    margin-top: 30px;
  }

  .clickimg {
    width: 100%;
  }

  .clickstop {
    width: 100%;
  }

  .agiyejan a {
    color: #ec411b;
    text-decoration: none;
    font-size: 11px;
    font-family: "Noto Sans Bengali";
  }

  .topnavbar {
    max-width: 1000px;
    margin: 0 auto;
    display: flex;
    width: 100%;
    justify-content: space-between;
  }

  a.readrule {
    font-family: "Noto Sans Bengali";
    /* font-style: normal;
    font-weight: 500;
    font-size: 10px; */
    line-height: 24px;
    margin-right: 5px;
    color: #136ee5;
    font-weight: 600;
    font-size: 18px;
  }

  p.bodytextcontent {
    width: 100%;
    max-width: 795px;
    height: auto;
    font-size: 25px;
    font-family: "SolaimanLipi", Arial, sans-serif;
    color: #161a31;
    font-weight: 400;
    margin-bottom: 0px;
    text-align: center;
  }

  input.search {
    background: #ffffff;
    border: 1px solid #c0c3c7;
    border-radius: 8px;
    font-family: "Noto Sans Bengali";
    font-style: normal;
    font-weight: 400;
    font-size: 18px;
    line-height: 24px;
    padding: 13px 16px 12px;
    min-width: 290px;
  }
}

@media screen and (max-width: 991px) {
  .selectareaoption {
    display: flex;
    flex-direction: row;
    width: 100%;
    justify-content: space-between;
    align-items: center;
  }

  .speakerarea {
    display: flex;
    flex-direction: row;
    /* justify-content: space-around; */
    /* width: 100%; */
    margin-bottom: 40px;
    width: 100%;
    justify-content: center;
    border-right: none;
  }

  .bodyfordoito {
    display: flex;
    flex-direction: column;
    width: 100%;
    justify-content: center;
    align-items: center;
  }

  .btnjomadin {
    padding: 10px 0px;
  }

  .selectareasm {
    width: 40%;
  }

  .buttonaeasm {
    width: 40%;
  }

  .listencomplete {
    margin-top: 30px;
  }

  .bodycontent {
    width: 100%;
    max-width: 768px;
    margin: 0 auto;
    margin-top: 30px;
  }

  .mainrontainer {
    display: flex;
    width: 100%;
    flex-direction: column;
  }

  .innerbodyitem {
    display: flex;
    margin: 0 auto;
    gap: 30px;
    flex-direction: column;
  }

  .topnavbar {
    max-width: 768px;
    margin: 0 auto;
    display: flex;
    width: 100%;
    justify-content: space-between;
  }

  .rightsidebarwithseelct {
    display: flex;
    flex-direction: column;
    width: 100%;
    margin-bottom: 80px;
  }

  .listenrighttop {
    margin-top: 40px;
  }

  .agiyejan a {
    color: #ec411b;
    text-decoration: none;
    font-size: 13px;
    font-family: "Noto Sans Bengali";
  }

  .midmenuarea .nav ul li a {
    text-decoration: none;
    font-family: Noto Sans Bengali;
    font-size: 13px;
  }

  .loginregistration ul li a {
    text-decoration: none;
    font-family: Noto Sans Bengali;
    font-size: 10px;
  }

  a.readrule {
    /* font-size: 11px; */
    font-size: 18px;
    color: #136ee5;
    font-family: "Noto Sans Bengali";
    font-weight: 600;
  }

  .agiyejannew a {
    color: #ec411b;
    text-decoration: none;
    font-size: 16px;
    font-family: "Noto Sans Bengali";
    width: 100%;
  }

  .rruleitem img {
    width: 10%;
  }

  .logoarea img {
    width: 70%;
  }

  button.btnkotopon {
    background: no-repeat;
    border: none;
    font-size: 11px;
    color: #000;
    font-family: "Noto Sans Bengali";
    cursor: pointer;
  }

  .rightsidebar {
    margin-top: 130px;
    margin-bottom: 10px;
  }

  /* .afterclick {
    font-size: 11px;
    color: #000;
    font-family: "Noto Sans Bengali";
    background-color: #fff;
    border: 1px solid #e50019;
    padding: 8px 15px;
    border-radius: 24px;
    margin-left: -8px;
    cursor: pointer;
  } */

  /* span.kotopokothon {
    font-size: 11px;
    color: #000;
    font-family: "Noto Sans Bengali";
    background-color: #fff;
    border: 1px solid #e50019;
    padding: 10px;
    border-radius: 24px;
    cursor: pointer;
  } */

  /* .lecture {
    font-size: 11px;
    color: #000;
    font-family: "Noto Sans Bengali";
    background-color: #fff;
    border: 1px solid #e50019;
    padding: 7px 15px;
    border-radius: 24px;
    cursor: pointer;
  } */

  .conitem ul {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
  }

  .lacture {
    margin-left: 0;
    margin-top: 20px;
  }

  .content {
    padding: 10px 20px;
  }

  p.bodytextcontent {
    width: 100%;
    max-width: 795px;
    height: auto;
    font-size: 20px;
    font-family: "SolaimanLipi", Arial, sans-serif;
  }

  .clickimg {
    background: url("./images/stts/newgreenplaysm.png") no-repeat;
    background-position: center center;
    min-height: 100px;
    justify-content: center;
    align-items: center;
    width: 100%;
    display: flex;
  }

  .clickimglisten {
    /* background: url("./images/stts/wave.png") no-repeat; */
    /* background: url("./images/stts/listenplay.png") no-repeat; */
    background: url("./images/stts/Listensm.gif") no-repeat;
    background-position: center center;
    min-width: 500px;
    min-height: 160px;
    justify-content: center;
    align-items: center;
    width: 100%;
    display: flex;
    position: relative;
  }

  .clickstoplisten {
    /* background: url("./images/stts/loader.gif") no-repeat; */
    background: url("./images/stts/listenstopsm.png") no-repeat;
    background-position: center center;
    min-width: 500px;
    min-height: 150px;
    justify-content: center;
    align-items: center;
    width: 100%;
    display: flex;
    position: relative;
  }

  .lacturemulti {
    background: url("./images/stts/wavesm.png") no-repeat;
    background-position: center center;
    min-height: 90px;
    justify-content: center;
    align-items: center;
    width: 100%;
    display: flex;
  }

  .clickstop {
    background: url("./images/stts/newgreenstopwavesm.gif") no-repeat;
    background-position: center center;
    min-height: 90px;
    justify-content: center;
    align-items: center;
    width: 100%;
    display: flex;
  }

  .agiyejan a img {
    width: 12%;
  }

  .agiyejan {
    width: 20%;
    display: flex;
    justify-content: flex-end;
    /* align-items: center; */
  }

  .kbtactive {
    background-color: #e50019;
    color: #fff;
    border: 2px solid #fff;
  }

  .cothopokothongroup {
    display: flex;
    justify-content: center;
    align-items: center;
    /* border: 1px solid #136ee5; */
    border-radius: 24px;
    margin-right: 0px;
  }

  .footermiddle p {
    font-size: 14px;
  }

  .readbg {
    height: 170vh;
    position: relative;
  }

  .footerarea {
    display: flex;
    width: 100%;
    justify-content: space-between;
    background-color: #ffffff;
    position: absolute;
    bottom: -313px;
    padding: 20px 0px;
    /* max-width: 1920px; */
    align-items: center;
  }

  .speakderareamulit {
    display: flex;
    flex-direction: row;
    /* justify-content: space-around; */
    /* width: 100%; */
    margin-bottom: 40px;
    width: 100%;
    justify-content: center;
    border-right: none !important;
    flex-wrap: wrap;
    margin-top: 10px;
  }
}

@media screen and (max-width: 850px) {
  p.usertext {
    font-size: 10px;
    color: #8799b2;
  }

  .clickbar {
    display: flex;
    /* width: 84%; */
    width: 100%;
    margin-top: 30px;
  }

  .selectareatotal {
    width: 20%;
  }

  .clickbaritem {
    display: flex;
    justify-content: space-between;
    width: 100%;
  }

  .footerarea {
    display: flex;
    width: 100%;
    justify-content: space-between;
    background-color: #ffffff;
    position: absolute;
    /* bottom: -130px; */
    bottom: -371px;
    padding: 20px 0px;
    /* max-width: 1920px; */
    align-items: center;
    flex-direction: column;
  }
}

@media screen and (max-width: 800px) {
  .bolunsunun {
    display: none;
  }

  .bodycontent {
    width: 100%;
    max-width: 686px;
    margin: 0 auto;
    margin-top: 30px;
  }

  .withlogomenu {
    display: flex;
    width: 100%;
    justify-content: space-between;
  }

  .topnavbar {
    max-width: 686px;
    margin: 0 auto;
    display: flex;
    width: 100%;
    justify-content: space-between;
    flex-direction: column;
  }

  .logoarea img {
    width: 50%;
  }

  .bodymenuitembolun {
    width: 100%;
    max-width: 1330px;
    margin: 0 auto;
    display: flex;
    justify-content: space-between;
    gap: 30px;
    margin-bottom: 16px;
    align-items: center;
    /* max-width: 1364px; */
  }

  .clickimg {
    background: url("./images/stts/newgreenplaysm.png") no-repeat;
    background-position: center center;
    min-width: 608px;
    min-height: 126px;
    justify-content: center;
    align-items: center;
    width: 100%;
    display: flex;
  }

  .clickstop {
    background: url("./images/stts/newgreenstopwavesm.gif") no-repeat;
    background-position: center center;
    min-width: 608px;
    min-height: 126px;
    justify-content: center;
    align-items: center;
    width: 100%;
    display: flex;
  }

  .agiyejan {
    width: 30%;
    display: flex;
    justify-content: flex-end;
    /* align-items: center; */
  }

  .footerarea {
    display: flex;
    width: 100%;
    justify-content: space-between;
    background-color: #ffffff;
    position: absolute;
    /* bottom: -130px; */
    bottom: -466px;
    padding: 20px 0px;
    /* max-width: 1920px; */
    align-items: center;
    flex-direction: column;
  }

  .rightsidebarwithseelct {
    display: flex;
    flex-direction: column;
    width: 100%;
    margin-bottom: 150px;
  }

  .footermiddle {
    margin: 10px 0px;
  }

  .footerright {
    margin-right: 0px !important;
  }

  ul.brandlogo li a img {
    width: 75%;
  }

  .rightmenu {
    display: dlf;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    width: 100%;
    margin: 15px 0px;
  }

  a.readrule {
    font-family: "Noto Sans Bengali";
    /* font-style: normal; */
    /* font-weight: 500; */
    /* font-size: 14px; */
    line-height: 24px;
    margin-right: 10px;
    color: #136ee5;
    font-weight: 600;
    font-size: 18px;
  }

  .readingrule {
    display: flex;
    /* width: 100%; */
    width: 90%;
    justify-content: flex-end;
    flex-direction: column;
    text-align: left;
    margin-top: 25px;
  }

  .conitem ul {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    flex-direction: row;
  }

  .boluncotopokothon {
    display: flex;
    justify-content: flex-end;
    width: 100%;
  }

  .lacture {
    margin-left: 27px;
    margin-top: 0px;
  }

  .lecture {
    font-size: 18px;
    color: #000;
    font-family: "Noto Sans Bengali";
    background-color: #fff;
    border: 2px solid #44af4f;
    padding: 7px 16px;
    border-radius: 24px;
    cursor: pointer;
  }

  .agiyejannew {
    width: 30%;
    display: flex;
    justify-content: flex-end;
    /* align-items: center; */
    position: absolute;
    top: 28px;
    right: 40px;
  }
}

@media screen and (max-width: 720px) {
  .bodyfordoito.withalter {
    margin-top: 60px;
}
.agiyejannew.conversationskip {
  width: 30%;
  margin-top: 20px;
}

.agiyejannew.conversationskip a{
  color: red
}
  .errornote {
    font-family: "Noto Sans Bengali";
    font-style: normal;
    font-weight: 400;
    font-size: 10px;
    line-height: 21px;
    color: #ec411b;
    margin-top: 5px;
  }

  .arekjonspeakerarea {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    width: 100%;
  }

  .selectareaoption {
    margin-top: 20px;
  }

  .selectcontroll {
    width: 90%;
  }

  p.getpermission {
    font-size: 17px;
    color: #000000;
    font-family: "Noto Sans Bengali";
  }

  .bodycontent {
    width: 100%;
    max-width: 540px;
    margin: 0 auto;
    margin-top: 30px;
  }

  .topnavbar {
    max-width: 540px;
    margin: 0 auto;
    display: flex;
    width: 100%;
    flex-direction: column;
  }

  .rightsidebarwithseelct {
    display: flex;
    flex-direction: column;
    width: 100%;
    margin-bottom: 120px;
  }

  ul.brandlogo img {
    width: 100%;
  }

  .footermiddle p {
    font-size: 14px;
  }

  .footerright {
    margin-right: 0px;
  }

  .rightsidebarwithseelct {
    display: flex;
    flex-direction: column;
    width: 100%;
    margin-bottom: 150px;
  }

  .clickimg {
    background: url("./images/stts/newgreenplaysm.png") no-repeat;
    background-position: center center;
    min-width: 460px;
    min-height: 126px;
    justify-content: center;
    align-items: center;
    width: 100%;
    display: flex;
  }

  .clickstop {
    background: url("./images/stts/newgreenstopwavesm.gif") no-repeat;
    background-position: center center;
    min-width: 460px;
    min-height: 126px;
    justify-content: center;
    align-items: center;
    width: 100%;
    display: flex;
  }

  li.sununbtn {
    width: 40px;
    height: 40px;
    padding: 0px !important;
    /* border-radius: 50%; */
  }

  h6.boluntitle {
    font-size: 12px;
    color: #526281;
    font-family: "Noto Sans Bengali";
  }

  .bolunactive {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-right: 10px;
  }

  a.readrule {
    /* font-size: 10px; */
    font-size: 18px;
    color: #136ee5;
    font-family: "Noto Sans Bengali";
    font-weight: 600;
  }

  .rightmenu {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    width: 100%;
    margin-bottom: 10px;
  }

  .withlogomenu {
    display: flex;
    width: 100%;
    justify-content: space-between;
    margin-bottom: 0px;
  }

  p.bodydylog {
    font-family: "Noto Sans Bengali", sans-serif;
    font-size: 15px;
    color: #526281;
  }

  .footerright img {
    width: 70%;
  }

  .clickbaritem {
    display: flex;
    justify-content: center;
    width: 100%;
  }

  .clickbar {
    display: flex;
    width: 100%;
    margin-top: 30px;
    flex-direction: column;
  }

  .footerarea {
    display: flex;
    width: 100%;
    justify-content: space-between;
    background-color: #ffffff;
    position: absolute;
    bottom: -448px;
    padding: 20px 0px;
    /* max-width: 1920px; */
    align-items: center;
    flex-direction: column;
  }

  p.sarapovabangladesh {
    width: 100%;
    max-width: 795px;
    height: auto;
    font-size: 30px;
    font-family: "SolaimanLipi", Arial, sans-serif;
    background-color: #f4f4f8;
    padding: 20px 84px;
  }

  .porases {
    padding: 6px 20px;
    background-color: #f6f9ff;
    max-width: 520px;
    border: 1px solid #ebeff6;
    border-radius: 40px;
    margin-right: 0;
  }

  .selectareatotal {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    align-items: center;
  }

  .selectarea {
    display: flex;
    flex-direction: row;
    width: 100%;
    margin: 0;
  }

  .buttonaea {
    width: 48%;
  }

  .btnjomadin {
    display: flex;
    width: 100%;
    align-items: center;
    justify-content: center;
    background-color: #d1d6e1;
    border: transparent;
    padding: 10px 0px;
    color: #919191;
    font-size: 18px;
    font-family: "Noto Sans Bengali";
    border-radius: 9px;
  }

  .formobile {
    display: flex;
  }

  .fordesktop {
    display: none;
  }

  ul.nodesktop {
    display: none;
  }

  button.btnakok::after {
    display: inline-block;
    margin-left: 0.255em;
    vertical-align: 0.255em;
    content: "";
    border-top: 0.3em solid;
    border-right: 0.3em solid transparent;
    border-bottom: 0;
    border-left: 0.3em solid transparent;
    position: absolute;
    right: 10px;
    top: 18px;
  }

  ul.dropdown-menu.nodesktop.show {
    width: 280px;
    height: 168px;
    background: #ffffff;
    box-shadow: 0px 3px 16px rgba(205, 205, 205, 0.45);
    border-radius: 8px;
    padding: 0;
  }

  .dsingleitem {
    padding: 16px 16px 16px 28px !important;
    gap: 8px;
    background: #ffffff;
    flex: none;
    order: 1;
    align-self: stretch;
    flex-grow: 0;
    font-family: "Noto Sans Bengali";
    font-style: normal;
    font-weight: 500;
    font-size: 18px;
    line-height: 24px;
    color: #303030;
  }

  .itemactive {
    padding: 16px 16px 16px 28px !important;
    background-color: rgba(19, 110, 229, 0.12) !important;
    border-radius: 8px 8px 0px 0px !important;
    font-family: "Noto Sans Bengali";
    font-style: normal;
    font-weight: 500 !important;
    font-size: 18px;
    line-height: 24px;
    color: #136ee5 !important;
    display: flex !important;
    justify-content: space-between;
  }

  input.search {
    background: #ffffff;
    border: 1px solid #c0c3c7;
    border-radius: 8px;
    font-family: "Noto Sans Bengali";
    font-style: normal;
    font-weight: 400;
    font-size: 18px;
    line-height: 24px;
    padding: 13px 16px 12px;
    min-width: 100%;
  }

  .speakerarea {
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    /* width: 100%; */
    margin-bottom: 40px;
    flex-wrap: wrap;
  }

  .singlespeaker {
    display: flex;
    flex-direction: column;
    min-width: 108px;
    min-height: 118px;
    justify-content: center;
    align-items: center;
    border: 1px solid #dde4f1;
    padding: 15px 10px;
    border-radius: 7px;
    margin-right: 16px;
    margin-bottom: 20px;
  }

  .lacturemulti {
    background: url("./images/stts/wavexl.png") no-repeat;
    background-position: center center;
    min-width: 340px;
    min-height: 65px;
    justify-content: center;
    align-items: center;
    width: 100%;
    display: flex;
  }

  .lactureplayre {
    /* background: url("./images/stts/Player.png") no-repeat; */
    background: url("./images/stts/doubleplayxl.png") no-repeat;
    background-position: center center;
    min-width: 340px;
    min-height: 65px;
    justify-content: center;
    align-items: center;
    width: 100%;
    display: flex;
  }

  .lactureplay {
    /* background: url("./images/stts/lactureanimation.gif") no-repeat; */
    background: url("./images/stts/lactureplayxl.gif") no-repeat;
    background-position: center center;
    min-width: 340px;
    min-height: 65px;
    justify-content: center;
    align-items: center;
    width: 100%;
    display: flex;
  }

  .btnpousegreen {
    background: #fff;
    box-shadow: 0px -2px 5px 10px rgba(201, 221, 248, 0.75);
    -webkit-box-shadow: 0px -2px 5px 10px rgba(201, 221, 248, 0.75);
    -moz-box-shadow: 0px -2px 5px 10px rgba(201, 221, 248, 0.75);
    width: 45px;
    height: 45px;
    border-radius: 50%;
    margin: 0px 10px 0px 20px;
    border: none;
  }

  .lactureplayrenew {
    /* background: url("./images/stts/rerecordwave.gif") no-repeat; */
    background: url("./images/stts/relactureplayxl.gif") no-repeat;
    background-position: center center;
    min-width: 340px;
    min-height: 65px;
    justify-content: center;
    align-items: center;
    width: 100%;
    display: flex;
  }

  button.greenpause {
    width: 44px;
    height: 44px;
    background: #fff;
    border-radius: 50%;
    justify-content: center;
    box-shadow: 0px 3px 10px 14px rgba(0, 175, 76, 0.48);
    -webkit-box-shadow: 0px 3px 10px 14px rgba(0, 175, 76, 0.48);
    -moz-box-shadow: 0px 3px 10px 14px rgba(0, 175, 76, 0.48);
  }

  .btnpouse {
    background: #fff;
    box-shadow: 0px 0px 12px 0px rgba(255, 87, 103, 0.55);
    -webkit-box-shadow: 0px 0px 12px 0px rgba(255, 87, 103, 0.55);
    -moz-box-shadow: 0px 0px 12px 0px rgba(255, 87, 103, 0.55);
    width: 45px;
    height: 45px;
    border-radius: 50%;
    margin: 0px 10px 0px 25px;
  }

  .btnstop {
    background: #fff;
    width: 45px;
    height: 45px;
    border-radius: 50%;
  }

  .clickimgre {
    background: url("./images/stts/wavexl.png") no-repeat;
    background-position: center center;
    min-width: 340px;
    min-height: 65px;
    justify-content: center;
    align-items: center;
    width: 100%;
    display: flex;
  }

  .midmenuarea .nav ul li {
    list-style: none;
    padding: 0px 10px;
  }

  .clickstoplistenplay {
    /* background: url("./images/stts/loader.gif") no-repeat; */
    background: url("./images/stts/listenplaystopsm.png") no-repeat;
    background-position: center center;
    min-width: 500px;
    min-height: 150px;
    justify-content: center;
    align-items: center;
    width: 100%;
    display: flex;
    position: relative;
  }

  .clickimglistenplay {
    /* background: url("./images/stts/wave.png") no-repeat; */
    background: url("./images/stts/listenactiveplaysm.png") no-repeat;
    background-position: center center;
    min-width: 500px;
    min-height: 150px;
    justify-content: center;
    align-items: center;
    width: 100%;
    display: flex;
    position: relative;
  }

  .clickpointplay {
    margin: 0px 5%;
  }

  .buttonaea {
    width: 100%;
  }
}

@media screen and (max-width: 568px) {
  .bodycontent {
    width: 100%;
    max-width: 480px;
    margin: 0 auto;
    margin-top: 30px;
  }

  .topnavbar {
    max-width: 480px;
    margin: 0 auto;
    display: flex;
    width: 100%;
    flex-direction: column;
  }

  .rightsidebarwithseelct {
    display: flex;
    flex-direction: column;
    width: 100%;
    margin-bottom: 120px;
  }

  ul.brandlogo img {
    width: 100%;
  }

  .footermiddle p {
    font-size: 14px;
  }

  .footerright {
    margin-right: 0px;
  }

  .rightsidebarwithseelct {
    display: flex;
    flex-direction: column;
    width: 100%;
    margin-bottom: 150px;
  }

  .clickimg {
    background: url("./images/stts/newgreenplaysm.png") no-repeat;
    background-position: center center;
    min-width: 460px;
    min-height: 126px;
    justify-content: center;
    align-items: center;
    width: 100%;
    display: flex;
  }

  .clickstop {
    background: url("./images/stts/newgreenstopwavesm.gif") no-repeat;
    background-position: center center;
    min-width: 460px;
    min-height: 126px;
    justify-content: center;
    align-items: center;
    width: 100%;
    display: flex;
  }

  li.sununbtn {
    width: 40px;
    height: 40px;
    padding: 0px !important;
    /* border-radius: 50%; */
  }

  h6.boluntitle {
    font-size: 12px;
    color: #526281;
    font-family: "Noto Sans Bengali";
  }

  .bolunactive {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-right: 10px;
  }

  a.readrule {
    font-size: 18px;
    color: #136ee5;
    font-family: "Noto Sans Bengali";
    font-weight: 600;
  }

  .rightmenu {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    width: 100%;
    margin-bottom: 10px;
  }

  .withlogomenu {
    display: flex;
    width: 100%;
    justify-content: space-between;
    margin-bottom: 20px;
  }

  p.bodydylog {
    font-family: "Noto Sans Bengali", sans-serif;
    font-size: 11px;
    color: #526281;
  }

  .withlogomenu {
    display: flex;
    width: 100%;
    justify-content: space-between;
    margin-bottom: 0px;
  }

  .conitem ul {
    justify-content: space-around;
    align-items: center;
    flex-direction: row;
  }

  .readingrule {
    display: flex;
    /* width: 100%; */
    justify-content: flex-end;
    /* display: none; */
    width: 90%;
    text-align: left;
    flex-direction: column;
    margin-top: 25px;
  }

  .footerarea {
    display: flex;
    width: 100%;
    justify-content: space-between;
    background-color: #ffffff;
    position: absolute;
    /* bottom: -435px; */
    bottom: -448px;
    padding: 20px 0px;
    /* max-width: 1920px; */
    align-items: center;
    flex-direction: column;
  }

  p.usertext {
    font-size: 10px;
    color: #8799b2;
  }

  p.bodytextcontent {
    width: 100%;
    max-width: 795px;
    height: auto;
    font-size: 22px;
    font-family: "SolaimanLipi", Arial, sans-serif;
  }

  .lacturemulti {
    background: url("./images/stts/wavexl.png") no-repeat;
    background-position: center center;
    min-width: 340px;
    min-height: 65px;
    justify-content: center;
    align-items: center;
    width: 100%;
    display: flex;
  }

  .lactureplayre {
    /* background: url("./images/stts/Player.png") no-repeat; */
    background: url("./images/stts/doubleplayxl.png") no-repeat;
    background-position: center center;
    min-width: 340px;
    min-height: 65px;
    justify-content: center;
    align-items: center;
    width: 100%;
    display: flex;
  }

  .lactureplay {
    /* background: url("./images/stts/lactureanimation.gif") no-repeat; */
    background: url("./images/stts/lactureplayxl.gif") no-repeat;
    background-position: center center;
    min-width: 340px;
    min-height: 65px;
    justify-content: center;
    align-items: center;
    width: 100%;
    display: flex;
  }

  .btnpousegreen {
    background: #fff;
    box-shadow: 0px -2px 5px 10px rgba(201, 221, 248, 0.75);
    -webkit-box-shadow: 0px -2px 5px 10px rgba(201, 221, 248, 0.75);
    -moz-box-shadow: 0px -2px 5px 10px rgba(201, 221, 248, 0.75);
    width: 45px;
    height: 45px;
    border-radius: 50%;
    margin: 0px 10px 0px 20px;
    border: none;
  }

  .lactureplayrenew {
    /* background: url("./images/stts/rerecordwave.gif") no-repeat; */
    background: url("./images/stts/relactureplayxl.gif") no-repeat;
    background-position: center center;
    min-width: 340px;
    min-height: 65px;
    justify-content: center;
    align-items: center;
    width: 100%;
    display: flex;
  }

  button.greenpause {
    width: 44px;
    height: 44px;
    background: #fff;
    border-radius: 50%;
    justify-content: center;
    box-shadow: 0px 3px 10px 14px rgba(0, 175, 76, 0.48);
    -webkit-box-shadow: 0px 3px 10px 14px rgba(0, 175, 76, 0.48);
    -moz-box-shadow: 0px 3px 10px 14px rgba(0, 175, 76, 0.48);
  }

  .btnpouse {
    background: #fff;
    box-shadow: 0px 0px 12px 0px rgba(255, 87, 103, 0.55);
    -webkit-box-shadow: 0px 0px 12px 0px rgba(255, 87, 103, 0.55);
    -moz-box-shadow: 0px 0px 12px 0px rgba(255, 87, 103, 0.55);
    width: 45px;
    height: 45px;
    border-radius: 50%;
    margin: 0px 10px 0px 25px;
  }

  .btnstop {
    background: #fff;
    width: 45px;
    height: 45px;
    border-radius: 50%;
  }

  .clickimgre {
    background: url("./images/stts/wavexl.png") no-repeat;
    background-position: center center;
    min-width: 340px;
    min-height: 65px;
    justify-content: center;
    align-items: center;
    width: 100%;
    display: flex;
  }
}

@media screen and (max-width: 530px) {
  .withlogomenu {
    display: flex;
    width: 50%;
    justify-content: space-between;
    margin-bottom: 0px;
}

  .rightmenu {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    width: 50%;
    margin-bottom: 0px;
    margin-top: 0px;
  }
  span.recordstarthiden ul {
    display: flex;
    justify-content: space-around;
    align-items: center;
    padding: 0;
  }



  /* .readingrulemobile {
    display: none;
  } */

  span.recordstarthiden ul li {
    list-style: none;
    cursor: pointer;
  }

  .conversationtop {
    margin-top: 0px !important;
  }

  .bodycontent {
    width: 100%;
    max-width: 400px;
    margin: 0 auto;
    margin-top: 15px;
  }

  .topnavbar {
    max-width: 420px;
    margin: 0 auto;
    display: flex;
    width: 100%;
    flex-direction: row;
    justify-content: center;
    align-items: center;
}

  .clickimg {
    background: url("./images/stts/newgreenplayxl.png") no-repeat;
    background-position: center center;
    min-width: 340px;
    min-height: 75px;
    justify-content: center;
    align-items: center;
    width: 100%;
    display: flex;
  }

  .clickimglisten {
    /* background: url("./images/stts/wave.png") no-repeat; */
    /* background: url("./images/stts/listenplay.png") no-repeat; */
    background: url("./images/stts/Listenxl.gif") no-repeat;
    background-position: center center;
    min-width: 300px;
    min-height: 0px;
    justify-content: center;
    align-items: center;
    width: 100%;
    display: flex;
    position: relative;
  }

  .clickstoplisten {
    /* background: url("./images/stts/loader.gif") no-repeat; */
    background: url("./images/stts/listenstopxl.png") no-repeat;
    background-position: center center;
    min-width: 300px;
    min-height: 0px;
    justify-content: center;
    align-items: center;
    width: 100%;
    display: flex;
    position: relative;
  }

  .lacturemulti {
    background: url("./images/stts/wavexl.png") no-repeat;
    background-position: center center;
    min-width: 340px;
    min-height: 65px;
    justify-content: center;
    align-items: center;
    width: 100%;
    display: flex;
  }

  .lactureplayre {
    /* background: url("./images/stts/Player.png") no-repeat; */
    background: url("./images/stts/doubleplayxl.png") no-repeat;
    background-position: center center;
    min-width: 340px;
    min-height: 65px;
    justify-content: center;
    align-items: center;
    width: 100%;
    display: flex;
  }

  .lactureplay {
    /* background: url("./images/stts/lactureanimation.gif") no-repeat; */
    background: url("./images/stts/lactureplayxl.gif") no-repeat;
    background-position: center center;
    min-width: 340px;
    min-height: 65px;
    justify-content: center;
    align-items: center;
    width: 100%;
    display: flex;
  }

  .btnpousegreen {
    background: #fff;
    box-shadow: 0px -2px 5px 10px rgba(201, 221, 248, 0.75);
    -webkit-box-shadow: 0px -2px 5px 10px rgba(201, 221, 248, 0.75);
    -moz-box-shadow: 0px -2px 5px 10px rgba(201, 221, 248, 0.75);
    width: 45px;
    height: 45px;
    border-radius: 50%;
    margin: 0px 10px 0px 20px;
    border: none;
  }

  .lactureplayrenew {
    /* background: url("./images/stts/rerecordwave.gif") no-repeat; */
    background: url("./images/stts/relactureplayxl.gif") no-repeat;
    background-position: center center;
    min-width: 340px;
    min-height: 65px;
    justify-content: center;
    align-items: center;
    width: 100%;
    display: flex;
  }

  button.greenpause {
    width: 44px;
    height: 44px;
    background: #fff;
    border-radius: 50%;
    justify-content: center;
    box-shadow: 0px 3px 10px 14px rgba(0, 175, 76, 0.48);
    -webkit-box-shadow: 0px 3px 10px 14px rgba(0, 175, 76, 0.48);
    -moz-box-shadow: 0px 3px 10px 14px rgba(0, 175, 76, 0.48);
  }

  .btnpouse {
    background: #fff;
    box-shadow: 0px 0px 12px 0px rgba(255, 87, 103, 0.55);
    -webkit-box-shadow: 0px 0px 12px 0px rgba(255, 87, 103, 0.55);
    -moz-box-shadow: 0px 0px 12px 0px rgba(255, 87, 103, 0.55);
    width: 45px;
    height: 45px;
    border-radius: 50%;
    margin: 0px 10px 0px 25px;
  }

  .btnstop {
    background: #fff;
    width: 45px;
    height: 45px;
    border-radius: 50%;
  }

  .clickimgre {
    background: url("./images/stts/wavexl.png") no-repeat;
    background-position: center center;
    min-width: 340px;
    min-height: 65px;
    justify-content: center;
    align-items: center;
    width: 100%;
    display: flex;
  }

  .clickstop {
    background: url("./images/stts/newgreenstopwavexl.gif") no-repeat;
    background-position: center center;
    min-width: 340px;
    min-height: 75px;
    justify-content: center;
    align-items: center;
    width: 100%;
    display: flex;
  }

  .logoarea {
    display: flex;
    width: 100%;
}

  .logoarea img {
    width: 80%;
    /* height: 100%; */
}

  .bodymenuitem {
    width: 100%;
    max-width: 1600px;
    margin: 0 auto;
    display: flex;
    justify-content: space-between;
    gap: 30px;
    margin-bottom: 16px;
    align-items: center;
    flex-direction: column;
  }

  .boluncotopokothon {
    display: flex;
    justify-content: flex-end;
    width: 100%;
    align-items: center;
    flex-direction: column;
  }

  .loginregistration ul li {
    list-style: none;
    padding: 0px 10px;
  }

  .readingrule {
    display: flex;
    /* width: 100%; */
    justify-content: flex-end;
    text-align: left;
    width: 90%;
    flex-direction: column;
    margin-top: 25px;
    display: none;
  }

  .readingruleshow {
    display: flex;
    /* width: 100%; */
    justify-content: flex-end;
    text-align: left;
    width: 90%;
    flex-direction: column;
    margin-top: 25px;
  }

  .readingruleshow {
    display: flex;
    /* width: 100%; */
    justify-content: flex-end;
    text-align: left;
    width: 90%;
    flex-direction: column;
    margin-top: 25px;
  }

  p.sarapovabangladesh {
    width: 100%;
    max-width: 795px;
    height: auto;
    font-size: 20px;
    font-family: "SolaimanLipi", Arial, sans-serif;
    background-color: #f4f4f8;
    padding: 20px 30px;
  }

  .footerarea {
    display: none;
  }

  .selectareatotal {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding: 0;
    margin-top: 30px;
  }

  .searcharea {
    display: flex;
    justify-content: space-between;
    width: 100%;
    align-items: center;
    flex-direction: column;
  }

  .textarea {
    margin-right: 0px;
    flex-direction: column;
    display: flex;
    /* justify-content: center; */
    width: 100%;
    margin-bottom: 20px;
  }

  .sbtn {
    width: 100%;
  }

  button.btnsarea {
    background: #136ee5;
    border-radius: 8px;
    border: none;
    padding: 13px 16px 12px;
    font-family: "Noto Sans Bengali";
    font-style: normal;
    font-weight: 600;
    font-size: 17px;
    line-height: 23px;
    text-align: center;
    color: #ffffff;
    min-width: 148px;
    width: 100%;
  }

  .selectarea {
    display: flex;
    flex-direction: row;
    width: 100%;
    margin: 1px 0px;
    justify-content: space-around;
    color: #44af4f;
  }

  .nomobileview {
    display: none;
  }

  .mobileview {
    display: block;
  }

  .homereadmobile {
    display: flex;
    flex-direction: row;
    width: 100%;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 20px;
  }

  .conversation {
    display: flex;
    justify-content: flex-end;
    width: 100%;
    /* align-items: center; */
    max-height: 30px;
  }

  .rruleitem img {
    width: 15%;
  }

  a.readrule {
    font-size: 18px;
    color: #136ee5;
    font-family: "Noto Sans Bengali";
    font-weight: 600;
  }

  .clickbar {
    display: flex;
    width: 100%;
    margin-top: -80px;
    flex-direction: column;
  }

  .mobilerightsidebar ul {
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    width: 100%;
    padding-left: 0;
  }

  .mobilerightsidebar ul li.comminrecord {
    list-style: none;
    padding: 15px 23px;
    border: 2px solid #136ee5;
    border-radius: 50%;
    justify-content: center;
    align-items: center;
    /* line-height: 23px; */
    display: flex;
    position: relative;
  }

  .rightsidebar {
    background: transparent;
    box-shadow: none;
    margin-top: 0 !important;
    min-height: 70px!important;
    padding-top: 0px;
}

  .nomobile {
    display: none;
  }

  .mobilerightsidebar {
    display: block;
  }

  .rightsidebarwithseelct {
    display: flex;
    flex-direction: column;
    width: 100%;
    margin-bottom: 0px;
    margin-top: 0px;
  }

  .readbg {
    height: auto;
    position: relative;
  }

  .selectcontroll {
    min-height: 48px;
    color: #44af4f;
    font-size: 17px;
    text-align: center;
    font-family: "Noto Sans Bengali";
    border: 1px solid #d8e1f5;
    border-radius: 9px;
    padding: 0px 30px;
    font-weight: 700;
  }

  .mobilerightsidebar ul li.rinactive {
    list-style: none;
    padding: 10px 25px;
    /* border: 2px solid #136ee5; */
    border-radius: 50%;
    justify-content: center;
    align-items: center;
    line-height: 23px;
    filter: drop-shadow(0px 2px 4px rgba(0, 0, 0, 0.161));
    -webkit-filter: drop-shadow(0px 2px 4px rgba(0, 0, 0, 0.161));
    background: #ffffff;
    opacity: 0.6;
    border: none;
  }

  li.activenopading {
    padding: 5px !important;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  span.recordactive {
    background-color: #136ee5;
    color: #fff;
    padding: 10px 18px;
    border-radius: 50%;
    /* line-height: 35px; */
  }

  .btnkotopokothon {
    font-weight: 700;

    color: #000000;
  }

  .lecture {
    font-size: 18px;
    color: #000;
    font-family: "Noto Sans Bengali";
    background-color: #fff;
    border: 2px solid #44af4f;
    padding: 7px 16px;
    border-radius: 24px;
    cursor: pointer;
    font-weight: 700;
  }

  span.recordstarthiden {
    font-size: 11px;
    font-family: "Noto Sans Bengali";
    color: #526281;
    position: absolute;
    width: 150px;
    left: 40px;
    display: none;
    top: 0px;
    background: #fff;
    padding: 10px;
    border-radius: 20px;
    z-index: 999;
    justify-content: center;
    align-items: center;
    /* background: var(--white); */
    border: 2px solid #ddd;
  }

  span.recordstarthiden {
    font-size: 11px;
    font-family: "Noto Sans Bengali";
    color: #526281;
    position: absolute;
    width: 150px;
    left: -40px;
    display: none;
    top: 0px;
    background: #fff;
    padding: 10px;
    border-radius: 20px;
    z-index: 999;
    justify-content: center;
    align-items: center;
    /* background: var(--white); */
    border: 2px solid #ddd;
    /* top: -24px; */
  }

  .comminrecord:hover span.recordstarthiden {
    display: flex;
  }

  .agiyejannew {
    width: 30%;
    display: flex;
    justify-content: flex-end;
    /* align-items: center; */
    position: absolute;
    top: 0px;
    right: 40px;
}

  .buttonaea {
    width: 100%;
  }

  .btnjomadin {
    display: flex;
    width: 100%;
    align-items: center;
    justify-content: center;
    background-color: #fff;
    border: transparent;
    padding: 10px 0px;
    color: #c0c3c7;
    font-size: 18px;
    font-family: "Noto Sans Bengali";
    border-radius: 9px;
    filter: drop-shadow(0px 3px 6px rgba(193, 193, 193, 0.161));
    filter: drop-shadow(0px 3px 6px rgba(193, 193, 193, 0.161));
    border: 1px solid #d8e1f5;
  }

  .dislisten {
    display: none !important;
  }

  .clickstoplistenplay {
    /* background: url("./images/stts/loader.gif") no-repeat; */
    background: url("./images/stts/listenplaystopsm.png") no-repeat;
    background-position: center center;
    min-width: 300px;
    min-height: 150px;
    justify-content: center;
    align-items: center;
    width: 100%;
    display: flex;
    position: relative;
  }

  .clickimglistenplay {
    /* background: url("./images/stts/wave.png") no-repeat; */
    background: url("./images/stts/listenactiveplaysm.png") no-repeat;
    background-position: center center;
    min-width: 300px;
    min-height: 150px;
    justify-content: center;
    align-items: center;
    width: 100%;
    display: flex;
    position: relative;
  }

  .habtnarea {
    min-width: 140px;
    background: #fff;
    min-height: 48px;
    justify-content: center;
    align-items: center;
    display: flex;
    border-radius: 15px;
  }

  .clickbarlistencom {
    margin-top: -130px;
  }

  .boluncontent {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    max-width: 1064px;
    min-height: 400px;
    background-color: #ffffff;
    position: relative;
    box-shadow: 0px 0px 24px 0px rgba(0, 0, 0, 0.06);
    flex-direction: column;
    border-radius: 12px;
  }

  .hanacontent.show {
    display: block;
    background: linear-gradient(180deg,
        #ffffff 0%,
        #ffffff 41%,
        #d7def1 81.2%,
        #ffffff 100%);
    transform: translate(-22px, -46px) !important;
    margin-left: -40px !important;
  }

  ul.dropdown-menu.hanacontent.show.haleft {
    margin-left: 20px !important;
}

  .btnactive {
    display: flex;
    width: 100%;
    align-items: center;
    justify-content: center;
    background: #136ee5;
    border: transparent;
    padding: 10px 0px;
    color: #fff;
    font-size: 18px;
    font-family: "Noto Sans Bengali";
    border-radius: 9px;
    filter: drop-shadow(0px 3px 6px rgba(193, 193, 193, 0.161));
    filter: drop-shadow(0px 3px 6px rgba(193, 193, 193, 0.161));
    border: 1px solid #d8e1f5;
  }
}

@media screen and (max-width: 375px) {
  .bodycontent {
    width: 100%;
    max-width: 340px;
    margin: 0 auto;
    margin-top: 30px;
  }
  .hanacontent.show {
    background: linear-gradient(180deg, #fff, #fff 41%, #d7def1 81.2%, #fff);
    display: block;
    margin-left: -40px !important;
    -webkit-transform: translate(-22px, -46px) !important;
    transform: translate(-22px, -46px) !important;
  }
  ul.dropdown-menu.hanacontent.show.hacontent {
    margin-left: 25px !important;
  }
}

@media screen and (max-width: 360px) and (max-height: 640px) {
  .homereadmobile {
    align-items: center;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin-bottom: 0px;
    width: 100%;
  }

  .bodycontent {
    margin: 0px auto 0;
    max-width: 340px;
    width: 100%;
  }

  .rightsidebar {
    background: #fff;
    background: #f6f9ff;
    border-radius: 4px;
    box-shadow: 0 3px 6px 0 rgba(0, 0, 0, .16);
    display: block;
    float: left;
    margin-bottom: 35px;
    padding: 0px 30px 0;
    width: 100%;
  }

  .boluncontent {
    align-items: center;
    background-color: #fff;
    border-radius: 12px;
    box-shadow: 0 0 24px 0 rgba(0, 0, 0, .06);
    display: flex;
    flex-direction: column;
    justify-content: center;
    max-width: 1064px;
    min-height: 335px;
    position: relative;
    width: 100%;
  }

  .rightsidebar {
    margin-bottom: 0px;
    margin-top: 130px;
  }
}

/* 
@media screen and (max-width: 420px) {
  .bodycontent {
    width: 100%;
    max-width: 320px;
    margin: 0 auto;
    margin-top: 30px;
  }
  .topnavbar {
    max-width: 320px;
    margin: 0 auto;
    display: flex;
    width: 100%;
    flex-direction: column;
  }

  .clickimg {
    background: url("./images/stts/wavexl.png") no-repeat;
    background-position: center center;
    min-width: 280px;
    min-height: 60px;
    justify-content: center;
    align-items: center;
    width: 100%;
    display: flex;
  }
  .clickstop {
    background: url("./images/stts/loaderxl.gif") no-repeat;
    background-position: center center;
    min-width: 280px;
    min-height: 60px;
    justify-content: center;
    align-items: center;
    width: 100%;
    display: flex;
  }

  .logoarea {
    display: flex;
    width: 20%;
  }
  .logoarea img {
    width: 100%;
  }
  .bodymenuitem {
    width: 100%;
    max-width: 1600px;
    margin: 0 auto;
    display: flex;
    justify-content: space-between;
    gap: 30px;
    margin-bottom: 16px;
    align-items: center;
    flex-direction: column;
  }
  .boluncotopokothon {
    display: flex;
    justify-content: space-between;
    width: 100%;
    align-items: center;
    flex-direction: column;
  }
  .loginregistration ul li {
    list-style: none;
    padding: 0px 10px;
  }
  .readingrule {
    display: flex;
    width: 100%;
    justify-content: flex-end;
    // text-align: left;
    // width: 90%; 
    // flex-direction: column;
    // margin-top: 25px;
  }

  .midmenuarea .nav ul li {
    list-style: none;
    padding: 0px 15px;
  }
  .midmenuarea .nav ul li:last-child {
    list-style: none;
    padding: 0px;
  }
  li.sununbtn {
    width: 30px;
    height: 30px;
    padding: 0px !important;
  }
  li.tabs.active-tabs.sununbtn img {
    width: 50%;
  }
  li.sununbtn span img {
    width: 50%;
  }
  .bolonsununitem ul li {
    list-style: none;
    padding: 20px;
    margin-right: 7px;
  }
  p.bodydylog {
    font-family: "Noto Sans Bengali", sans-serif;
    font-size: 14px;
    color: #526281;
    padding: 0px 10px;
  }
  .bolunsunun {
    display: flex;
    width: 100%;
  }
  .bolonsununitem {
    width: 100%;
  }
  .bolonsununitem ul {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
  }
  .conversation {
    display: flex;
    justify-content: space-between;
    width: 100%;
  }
  .conitem ul {
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-direction: row-reverse;
    width: 100%;
  }
  .lacture {
    margin-left: 0;
    margin-top: 0px;
  }
  .conitem {
    width: 100%;
  }
  span.kotopokothon {
    font-size: 11px;
    color: #000;
    font-family: "Noto Sans Bengali";
    background-color: #fff;
    border: 1px solid #e50019;
    padding: 7px 15px;
    border-radius: 24px;
    cursor: pointer;
  }
  .bolunsunun {
    display: flex;
    width: 100%;
    margin-bottom: 10px;
  }
  ul.brandlogo li a img {
    width: 60%;
  }
  .footertext p {
    margin-right: 20px;
    font-size: 15px;
  }
  .agiyejan {
    width: 100%;
    display: flex;
    justify-content: flex-end;
  }
  .clickbaritem {
    display: flex;
    justify-content: flex-end;
    width: 100%;
    flex-direction: column;
  }
  p.bodytextcontent {
    width: 100%;
    max-width: 795px;
    height: auto;
    font-size: 17px;
    font-family: "SolaimanLipi", Arial, sans-serif;
  }
  .kbtactive {
    background-color: #e50019;
    color: #fff;
    border: 2px solid #fff;
  }
} */

/* ========================== Responsive Design End here ==================== */

.threedots svg {
  position: relative;
  
}
.threedots {
  position: relative;
  right: -40px;
  top: 0px;
}