
.userdashboard {
  background-color: #edf0f7;
  /* height: 100vh; */
  width: 100%;
}

.footertopimg {
  position: relative;
}

.footerleftimg {
  position: absolute;
  left: 0;
  bottom: 0;
}

/*====================== Left Menu content ============== */
.leftmenuarea {
  position: relative;
  height: auto;
  width: 100%;
}
.leftmenuarea nav ul {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: auto;
}

.leftmenuarea nav ul li {
  list-style: none;
  margin: 16px;
}
.leftmenuarea nav ul li:first-child {
  margin-top: 0px;
}

.leftmenuarea nav ul li a {
  text-decoration: none;
  font-family: "Noto Sans Bengali";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 21px;
  text-align: right;

  color: #555555;
}
.leftmenuarea nav ul li a.active {
  font-family: "Noto Sans Bengali";
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 21px;
  text-align: right;

  /* Dark_blue */

  color: #00407b;
}

/* ======================== Left Menu End ================== */

.contentarea {
  margin: 20px 0px;
  height: auto;
}
.footerleftimg img {
  width: 85%;
  height: auto;
  overflow: hidden;
  /* opacity: 0.2; */
}

.borderright {
  border-right: 1px solid #bacfe2;
  /* transform: rotate(-90deg); */
  position: absolute;
  top: 20px;
  /* height: 190px; */
  height: auto;
}

.borderacitve {
  position: absolute;
  width: 3px;
  height: 28.92px;
  left: 128px;
  top: 0px;
  background: #00407b;
  border-radius: 5px;
}
.lefttitle h4 {
  font-family: "Noto Sans Bengali";
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 24px;
  color: #ff5767;
}

.toptitle {
  display: flex;
  justify-content: space-between;
  width: 100%;
  margin-bottom: 20px;
  align-items: center;
}

.righttitle {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 0px;
  /* grid-gap: 16px;
  gap: 16px;
  height: 25px; */
  position: relative;
}

a.leftarrow {
  position: absolute;
  left: -20px;
  cursor: pointer;
}
a.rightarrow {
  /* position: absolute; */
  cursor: pointer;
}

.grapharea {
  box-sizing: border-box;
  min-width: 100%;
  height: 393.41px;
  background: #ffffff;
  box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.15);
  border-radius: 12px;
  position: relative;
  /* padding: 26px; */
  padding-top: 26px;
  padding-bottom: 26px;
  padding-left: 0px;
  padding-right: 0px;
  margin-bottom: 40px;
  /* overflow: scroll; */
}

/* .righttitle p {
  font-family: "Noto Sans Bengali";
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 24px;
  color: #00407b;
  margin-bottom: 0px;
} */

.graphtitle {
  display: flex;
  justify-content: space-between;
  width: 100%;
}

.graphtitle .lefttitle h4 {
  font-family: "Noto Sans Bengali";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 21px;
  color: #555555;
}

.graphline {
  position: absolute;
  width: 760px;
  height: 0px;
  left: 40px;
  top: 240px;
  border: 1px solid #f8e1e3;
}
.glinesnd {
  position: absolute;
  width: 760px;
  height: 0px;
  left: 40px;
  top: 240px;
  border: 1px solid #daeaff;
}

.graptext {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  padding: 0px;
  /* grid-gap: 13px; */
  /* grid-gap: 10px; */
  gap: 13px;
  position: absolute;
  width: 762px;
  height: 12px;
  left: 40px;
  top: 245px;
}

.graptext span {
  width: 12px;
  height: 12px;
  font-family: "Lato";
  font-style: normal;
  font-weight: 400;
  font-size: 10px;
  line-height: 12px;
  text-align: center;
  color: #2e3a91;
}
.gsecond span {
  width: 12px;
  height: 12px;
  font-family: "Lato";
  font-style: normal;
  font-weight: 400;
  font-size: 10px;
  line-height: 12px;
  text-align: center;
  color: #683a51;
}

span.grayname {
  width: 12px;
  height: 12px;
  font-family: "Lato";
  font-style: normal;
  font-weight: 400;
  font-size: 10px;
  line-height: 12px;
  text-align: center;
  color: #d8d8d8;
}

li.pointwo {
  position: absolute;
  width: 4px;
  height: 114.06px;
  left: 70.93px;
  top: 125.99px;
  background: #2e3a91;
  opacity: 0.2;
  border-radius: 5px 5px 0px 0px;
}

li.pointhree {
  position: absolute;
  width: 4px;
  height: 146.96px;
  left: 95.36px;
  top: 95.09px;
  background: #2e3a91;
  opacity: 0.2;
}
li.pointfour {
  position: absolute;
  width: 4px;
  height: 90px;
  left: 117px;
  top: 152px;
  background: #2e3a91;
  opacity: 0.2;
  border-radius: 5px 5px 0px 0px;
}
li.pointfive {
  position: absolute;
  width: 4px;
  height: 45.03px;
  left: 140px;
  top: 195px;
  background: #2e3a91;
  opacity: 0.2;
  border-radius: 5px 5px 0px 0px;
}

li.pointsix {
  position: absolute;
  width: 4px;
  height: 134.81px;
  left: 162px;
  top: 107px;
  background: #2e3a91;
  opacity: 0.2;
  border-radius: 5px 5px 0px 0px;
}
li.pointseven {
  position: absolute;
  width: 4px;
  height: 77px;
  left: 185px;
  top: 165px;
  background: #2e3a91;
  opacity: 0.2;
  border-radius: 5px 5px 0px 0px;
}
li.pointeight {
  position: absolute;
  width: 4px;
  height: 103px;
  left: 210px;
  top: 138px;
  background: #2e3a91;
  opacity: 0.2;
  border-radius: 5px 5px 0px 0px;
}

li.pintnine {
  position: absolute;
  width: 4px;
  height: 30px;
  left: 235px;
  top: 212px;
  background: #2e3a91;
  opacity: 0.2;
  border-radius: 5px 5px 0px 0px;
}

li.pointen {
  position: absolute;
  width: 4px;
  height: 54px;
  left: 259px;
  top: 188px;
  background: #2e3a91;
  opacity: 0.2;
  border-radius: 5px 5px 0px 0px;
}
li.pointeleven {
  position: absolute;
  width: 4px;
  height: 87.59px;
  left: 282.4px;
  top: 154.46px;
  background: #2e3a91;
  opacity: 0.2;
  border-radius: 5px 5px 0px 0px;
}

li.pointtlv {
  position: absolute;
  width: 4px;
  height: 45.03px;
  left: 306.4px;
  top: 195.02px;
  background: #2e3a91;
  opacity: 0.2;
  border-radius: 5px 5px 0px 0px;
}

li.pointthirteen {
  position: absolute;
  width: 4px;
  height: 134.81px;
  left: 333.4px;
  top: 107.25px;
  background: #2e3a91;
  border-radius: 5px 5px 0px 0px;
  opacity: 0.2;
}

li.pointthirteen:hover {
  position: absolute;
  width: 6px;
  height: 134.81px;
  left: 333.4px;
  top: 107.25px;
  background: #2e3a91;
  border-radius: 5px 5px 0px 0px;
  opacity: 1;
}
/*
li.pointthirteen.tactive {
  position: absolute;
  width: 6px;
  height: 134.81px;
  left: 333.4px;
  top: 107.25px;
  background: #2e3a91;
  border-radius: 5px 5px 0px 0px;
  opacity: 1;
} */

/* ============= Tooltips start here ============== */
/* .wrapper {
  position: relative;
  margin-left: 10%;
  margin-top: 15%;
} */

.tooltipp:hover span {
  opacity: 1;
  filter: alpha(opacity=100);
  top: -6em;
  left: 20em;
  z-index: 99;
  -webkit-transition: all 0.2s ease;
  -moz-transition: all 0.2s ease;
  -o-transition: all 0.2s ease;
  transition: all 0.2s ease;
}
/* .tooltipp.tactive span {
  opacity: 1;
  filter: alpha(opacity=100);
  top: -6em;
  left: 20em;
  z-index: 99;
  -webkit-transition: all 0.2s ease;
  -moz-transition: all 0.2s ease;
  -o-transition: all 0.2s ease;
  transition: all 0.2s ease;
} */

.mtooltip {
  position: relative;
}
.tooltipp span {
  /* background: none repeat scroll 0 0 #222; */
  color: #f0b015;
  font-family: "Helvetica";
  font-size: 0.8em;
  font-weight: normal;
  line-height: 1.5em;
  padding: 16px 15px;
  /* width: 240px; */
  top: -55px !important;
  left: -43px !important;
  margin-left: 0;
  opacity: 0;
  filter: alpha(opacity=0);
  position: absolute;
  text-align: center;
  z-index: 2;
  text-transform: none;
  transition: all 0.3s ease-in-out;
  width: 140px;
  height: 40.47px;
  background: #2e3a91;
  box-shadow: 0px 1px 1px rgba(0, 0, 0, 0.25);
  color: #fff;
  border-radius: 7px;
}

.tooltipdashboard span {
  /* background: none repeat scroll 0 0 #222; */
  color: #f0b015;
  font-family: "Helvetica";
  font-size: 0.8em;
  font-weight: normal;
  line-height: 1.5em;
  padding: 16px 15px;
  /* width: 240px; */
  top: -55px !important;
  left: -43px !important;
  margin-left: 0;
  opacity: 0;
  filter: alpha(opacity=0);
  position: absolute;
  text-align: center;
  z-index: 2;
  text-transform: none;
  transition: all 0.3s ease-in-out;
  width: 140px;
  height: 40.47px;
  background: #2e3a91;
  box-shadow: 0px 1px 1px rgba(0, 0, 0, 0.25);
  color: #fff;
  border-radius: 7px;
}
.tooltipsunun span {
  /* background: none repeat scroll 0 0 #222; */
  color: #f0b015;
  font-family: "Helvetica";
  font-size: 0.8em;
  font-weight: normal;
  line-height: 1.5em;
  padding: 16px 15px;
  /* width: 240px; */
  top: -55px !important;
  left: -43px !important;
  margin-left: 0;
  opacity: 0;
  filter: alpha(opacity=0);
  position: absolute;
  text-align: center;
  z-index: 2;
  text-transform: none;
  transition: all 0.3s ease-in-out;
  width: 140px;
  height: 40.47px;
  background: #2e3a91;
  box-shadow: 0px 1px 1px rgba(0, 0, 0, 0.25);
  color: #fff;
  border-radius: 7px;
}
.tooltipsununhide span {
  /* background: none repeat scroll 0 0 #222; */
  color: #f0b015;
  font-family: "Helvetica";
  font-size: 0.8em;
  font-weight: normal;
  line-height: 1.5em;
  padding: 16px 15px;
  /* width: 240px; */
  top: -55px !important;
  left: -43px !important;
  margin-left: 0;
  opacity: 0;
  filter: alpha(opacity=0);
  position: absolute;
  text-align: center;
  z-index: 2;
  text-transform: none;
  transition: all 0.3s ease-in-out;
  width: 140px;
  height: 40.47px;
  background: #2e3a91;
  box-shadow: 0px 1px 1px rgba(0, 0, 0, 0.25);
  color: #fff;
  border-radius: 7px;
}
.tooltipdashboardhide span {
  /* background: none repeat scroll 0 0 #222; */
  color: #f0b015;
  font-family: "Helvetica";
  font-size: 0.8em;
  font-weight: normal;
  line-height: 1.5em;
  padding: 16px 15px;
  /* width: 240px; */
  top: -55px !important;
  left: -43px !important;
  margin-left: 0;
  opacity: 0;
  filter: alpha(opacity=0);
  position: absolute;
  text-align: center;
  z-index: 2;
  text-transform: none;
  transition: all 0.3s ease-in-out;
  width: 140px;
  height: 40.47px;
  background: #2e3a91;
  box-shadow: 0px 1px 1px rgba(0, 0, 0, 0.25);
  color: #fff;
  border-radius: 7px;
}

/* arrow */

.tooltipp span:after {
  border-color: #2e3a91 rgba(0, 0, 0, 0);
  border-style: solid;
  border-width: 15px 15px 0;
  bottom: -15px;
  content: "";
  display: block;
  left: 31px;
  position: absolute;
  width: 0;
}
.tooltipdashboard span:after {
  border-color: #2e3a91 rgba(0, 0, 0, 0);
  border-style: solid;
  border-width: 15px 15px 0;
  bottom: -15px;
  content: "";
  display: block;
  left: 31px;
  position: absolute;
  width: 0;
}
.tooltipsununhide span:after {
  border-color: #2e3a91 rgba(0, 0, 0, 0);
  border-style: solid;
  border-width: 15px 15px 0;
  bottom: -15px;
  content: "";
  display: block;
  left: 31px;
  position: absolute;
  width: 0;
}
.tooltipsunun span:after {
  border-color: #2e3a91 rgba(0, 0, 0, 0);
  border-style: solid;
  border-width: 15px 15px 0;
  bottom: -15px;
  content: "";
  display: block;
  left: 31px;
  position: absolute;
  width: 0;
}
.tooltipdashboardhide span:after {
  border-color: #2e3a91 rgba(0, 0, 0, 0);
  border-style: solid;
  border-width: 15px 15px 0;
  bottom: -15px;
  content: "";
  display: block;
  left: 31px;
  position: absolute;
  width: 0;
}

.stooltipp:hover span {
  opacity: 1;
  filter: alpha(opacity=100);
  top: -6em;
  left: 20em;
  z-index: 99;
  -webkit-transition: all 0.2s ease;
  -moz-transition: all 0.2s ease;
  -o-transition: all 0.2s ease;
  transition: all 0.2s ease;
}
.tooltipdashboard:hover span {
  opacity: 1;
  filter: alpha(opacity=100);
  top: -6em;
  left: 20em;
  z-index: 99;
  -webkit-transition: all 0.2s ease;
  -moz-transition: all 0.2s ease;
  -o-transition: all 0.2s ease;
  transition: all 0.2s ease;
}
.tooltipdashboardhide:hover span {
  opacity: 1;
  filter: alpha(opacity=100);
  top: -6em;
  left: 20em;
  z-index: 99;
  -webkit-transition: all 0.2s ease;
  -moz-transition: all 0.2s ease;
  -o-transition: all 0.2s ease;
  transition: all 0.2s ease;
}
.tooltipsununhide:hover span {
  opacity: 1;
  filter: alpha(opacity=100);
  top: -6em;
  left: 20em;
  z-index: 99;
  -webkit-transition: all 0.2s ease;
  -moz-transition: all 0.2s ease;
  -o-transition: all 0.2s ease;
  transition: all 0.2s ease;
}
.tooltipsunun:hover span {
  opacity: 1;
  filter: alpha(opacity=100);
  top: -6em;
  left: 20em;
  z-index: 99;
  -webkit-transition: all 0.2s ease;
  -moz-transition: all 0.2s ease;
  -o-transition: all 0.2s ease;
  transition: all 0.2s ease;
}
/* .tooltipp.tactive span {
  opacity: 1;
  filter: alpha(opacity=100);
  top: -6em;
  left: 20em;
  z-index: 99;
  -webkit-transition: all 0.2s ease;
  -moz-transition: all 0.2s ease;
  -o-transition: all 0.2s ease;
  transition: all 0.2s ease;
} */

/* .mtooltip {
  position: relative;
} */

.stooltipp span {
  /* background: none repeat scroll 0 0 #222; */
  color: #f0b015;
  font-family: "Helvetica";
  font-size: 0.8em;
  font-weight: normal;
  line-height: 1.5em;
  padding: 16px 15px;
  /* width: 240px; */
  top: -55px !important;
  left: -43px !important;
  margin-left: 0;
  opacity: 0;
  filter: alpha(opacity=0);
  position: absolute;
  text-align: center;
  z-index: 2;
  text-transform: none;
  transition: all 0.3s ease-in-out;
  width: 140px;
  height: 40.47px;
  background: #683a51;
  box-shadow: 0px 1px 1px rgba(0, 0, 0, 0.25);
  color: #fff;
  border-radius: 7px;
}
.tooltipdashboard span {
  /* background: none repeat scroll 0 0 #222; */
  color: #f0b015;
  font-family: "Helvetica";
  font-size: 0.8em;
  font-weight: normal;
  line-height: 1.5em;
  padding: 10px 15px;
  /* width: 240px; */
  top: -55px !important;
  left: -43px !important;
  margin-left: 0;
  opacity: 0;
  filter: alpha(opacity=0);
  position: absolute;
  text-align: center;
  z-index: 2;
  text-transform: none;
  transition: all 0.3s ease-in-out;
  width: 140px;
  height: 40.47px;
  background: #8766ff;
  box-shadow: 0px 1px 1px rgba(0, 0, 0, 0.25);
  color: #fff;
  border-radius: 7px;
}
.tooltipsununhide span {
  /* background: none repeat scroll 0 0 #222; */
  color: #f0b015;
  font-family: "Helvetica";
  font-size: 0.8em;
  font-weight: normal;
  line-height: 1.5em;
  padding: 10px 15px;
  /* width: 240px; */
  top: -55px !important;
  left: -43px !important;
  margin-left: 0;
  opacity: 0;
  filter: alpha(opacity=0);
  position: absolute;
  text-align: center;
  z-index: 2;
  text-transform: none;
  transition: all 0.3s ease-in-out;
  width: 140px;
  height: 40.47px;
  background: #54bde8;
  box-shadow: 0px 1px 1px rgba(0, 0, 0, 0.25);
  color: #fff;
  border-radius: 7px;
}
.tooltipsunun span {
  /* background: none repeat scroll 0 0 #222; */
  color: #f0b015;
  font-family: "Helvetica";
  font-size: 0.8em;
  font-weight: normal;
  line-height: 1.5em;
  padding: 10px 15px;
  /* width: 240px; */
  top: -55px !important;
  left: -43px !important;
  margin-left: 0;
  opacity: 0;
  filter: alpha(opacity=0);
  position: absolute;
  text-align: center;
  z-index: 2;
  text-transform: none;
  transition: all 0.3s ease-in-out;
  width: 140px;
  height: 40.47px;
  background: #009cdc;
  box-shadow: 0px 1px 1px rgba(0, 0, 0, 0.25);
  color: #fff;
  border-radius: 7px;
}
.tooltipdashboardhide span {
  /* background: none repeat scroll 0 0 #222; */
  color: #f0b015;
  font-family: "Helvetica";
  font-size: 0.8em;
  font-weight: normal;
  line-height: 1.5em;
  padding: 10px 15px;
  /* width: 240px; */
  top: -55px !important;
  left: -43px !important;
  margin-left: 0;
  opacity: 0;
  filter: alpha(opacity=0);
  position: absolute;
  text-align: center;
  z-index: 2;
  text-transform: none;
  transition: all 0.3s ease-in-out;
  width: 140px;
  height: 40.47px;
  background: #f98be7;
  box-shadow: 0px 1px 1px rgba(0, 0, 0, 0.25);
  color: #fff;
  border-radius: 7px;
}
/* arrow */

.stooltipp span:after {
  border-color: #683a51 rgba(0, 0, 0, 0);
  border-style: solid;
  border-width: 15px 15px 0;
  bottom: -15px;
  content: "";
  display: block;
  left: 31px;
  position: absolute;
  width: 0;
}
.tooltipdashboard span:after {
  border-color: #8766ff rgba(0, 0, 0, 0);
  border-style: solid;
  border-width: 15px 15px 0;
  bottom: -15px;
  content: "";
  display: block;
  left: 34px;
  position: absolute;
  width: 0;
}
.tooltipdashboardhide span:after {
  border-color: #f98be7 rgba(0, 0, 0, 0);
  border-style: solid;
  border-width: 15px 15px 0;
  bottom: -15px;
  content: "";
  display: block;
  left: 34px;
  position: absolute;
  width: 0;
}
.tooltipsununhide span:after {
  border-color: #54bde8 rgba(0, 0, 0, 0);
  border-style: solid;
  border-width: 15px 15px 0;
  bottom: -15px;
  content: "";
  display: block;
  left: 34px;
  position: absolute;
  width: 0;
}
.tooltipsunun span:after {
  border-color: #009cdc rgba(0, 0, 0, 0);
  border-style: solid;
  border-width: 15px 15px 0;
  bottom: -15px;
  content: "";
  display: block;
  left: 34px;
  position: absolute;
  width: 0;
}

/* ============= Tooltips start here ============== */

li.pointfif {
  position: absolute;
  width: 4px;
  height: 114.06px;
  left: 356.4px;
  top: 126.99px;
  background: #2e3a91;
  opacity: 0.2;
  border-radius: 5px 5px 0px 0px;
}
li.pointfif:hover {
  position: absolute;
  width: 6px;
  height: 114.06px;
  left: 356.4px;
  top: 126.99px;
  background: #2e3a91;
  opacity: 1;
  border-radius: 5px 5px 0px 0px;
}

li.pointsixt {
  position: absolute;
  width: 4px;
  height: 126.15px;
  left: 380.4px;
  top: 115.9px;
  background: #2e3a91;
  opacity: 0.2;
  border-radius: 5px 5px 0px 0px;
}
li.pointsevent {
  position: absolute;
  width: 4px;
  height: 71.98px;
  left: 403.4px;
  top: 170.07px;
  background: #2e3a91;
  opacity: 0.2;
  border-radius: 5px 5px 0px 0px;
}
li.pointeighte {
  position: absolute;
  width: 4px;
  height: 11.62px;
  left: 425.4px;
  top: 230.43px;
  background: #2e3a91;
  opacity: 0.2;
  border-radius: 5px 5px 0px 0px;
}
li.pointninett {
  position: absolute;
  width: 4px;
  height: 158.05px;
  left: 451.4px;
  top: 83px;
  background: #2e3a91;
  opacity: 0.2;
  border-radius: 5px 5px 0px 0px;
}

/*=================================== Second graph line bar =================================== */

li.spointwo {
  position: absolute;
  width: 4px;
  height: 114.06px;
  left: 70.93px;
  top: 125.99px;
  background: #683a51;
  opacity: 0.2;
  border-radius: 5px 5px 0px 0px;
}

li.spointhree {
  position: absolute;
  width: 4px;
  height: 146.96px;
  left: 95.36px;
  top: 95.09px;
  background: #683a51;
  opacity: 0.2;
}
li.spointfour {
  position: absolute;
  width: 4px;
  height: 90px;
  left: 117px;
  top: 152px;
  background: #683a51;
  opacity: 0.2;
  border-radius: 5px 5px 0px 0px;
}
li.spointfive {
  position: absolute;
  width: 4px;
  height: 45.03px;
  left: 140px;
  top: 195px;
  background: #683a51;
  opacity: 0.2;
  border-radius: 5px 5px 0px 0px;
}

li.spointsix {
  position: absolute;
  width: 4px;
  height: 134.81px;
  left: 162px;
  top: 107px;
  background: #683a51;
  opacity: 0.2;
  border-radius: 5px 5px 0px 0px;
}

li.spointsix:hover {
  position: absolute;
  width: 6px;
  height: 134.81px;
  left: 162px;
  top: 107px;
  background: #683a51;
  opacity: 1;
  border-radius: 5px 5px 0px 0px;
}

li.spointseven {
  position: absolute;
  width: 4px;
  height: 77px;
  left: 185px;
  top: 165px;
  background: #683a51;
  opacity: 0.2;
  border-radius: 5px 5px 0px 0px;
}
li.spointeight {
  position: absolute;
  width: 4px;
  height: 103px;
  left: 210px;
  top: 138px;
  background: #683a51;
  opacity: 0.2;
  border-radius: 5px 5px 0px 0px;
}

li.spintnine {
  position: absolute;
  width: 4px;
  height: 30px;
  left: 235px;
  top: 212px;
  background: #683a51;
  opacity: 0.2;
  border-radius: 5px 5px 0px 0px;
}

li.spointen {
  position: absolute;
  width: 4px;
  height: 54px;
  left: 259px;
  top: 188px;
  background: #683a51;
  opacity: 0.2;
  border-radius: 5px 5px 0px 0px;
}
li.spointeleven {
  position: absolute;
  width: 4px;
  height: 87.59px;
  left: 282.4px;
  top: 154.46px;
  background: #683a51;
  opacity: 0.2;
  border-radius: 5px 5px 0px 0px;
}

li.spointtlv {
  position: absolute;
  width: 4px;
  height: 45.03px;
  left: 306.4px;
  top: 195.02px;
  background: #683a51;
  opacity: 0.2;
  border-radius: 5px 5px 0px 0px;
}

li.spointthirteen {
  position: absolute;
  width: 4px;
  height: 134.81px;
  left: 333.4px;
  top: 107.25px;
  background: #683a51;
  opacity: 0.2;
  border-radius: 5px 5px 0px 0px;
}
li.spointfif {
  position: absolute;
  width: 4px;
  height: 114.06px;
  left: 356.4px;
  top: 126.99px;
  background: #683a51;
  opacity: 0.2;
  border-radius: 5px 5px 0px 0px;
}
li.spointsixt {
  position: absolute;
  width: 4px;
  height: 126.15px;
  left: 380.4px;
  top: 115.9px;
  background: #683a51;
  opacity: 0.2;
  border-radius: 5px 5px 0px 0px;
}
li.spointsevent {
  position: absolute;
  width: 4px;
  height: 71.98px;
  left: 403.4px;
  top: 170.07px;
  background: #683a51;
  opacity: 0.2;
  border-radius: 5px 5px 0px 0px;
}
li.spointeighte {
  position: absolute;
  width: 4px;
  height: 11.62px;
  left: 425.4px;
  top: 230.43px;
  background: #683a51;
  opacity: 0.2;
  border-radius: 5px 5px 0px 0px;
}
li.spointninett {
  position: absolute;
  width: 4px;
  height: 158.05px;
  left: 451.4px;
  top: 83px;
  background: #683a51;
  opacity: 0.2;
  border-radius: 5px 5px 0px 0px;
}
/*=================================== Second graph line bar =================================== */

.recordaudio {
  width: 25%;
  height: 99px;
  left: 0px;
  top: 0px;
  background: #2e3a91;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.06);
  border-radius: 0px 0px 0px 12px;
  color: #fff;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.srecordaudio {
  width: 25%;
  height: 99px;
  left: 0px;
  top: 0px;
  background: #683a51;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.06);
  border-radius: 0px 0px 0px 12px;
  color: #fff;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.recordaudio p {
  /* width: 164px; */
  height: 19px;
  font-family: "Noto Sans Bengali";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 19px;
  color: #ffffff;
  margin: 10px 0px 10px 0px;
}
.recordaudio h6 {
  width: 157px;
  height: 28px;

  font-family: "Noto Sans Bengali";
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 28px;
  /* identical to box height */

  text-align: center;

  color: #ffffff;
}

.recorddata {
  display: flex;
  justify-content: start;
  width: 100%;
  position: absolute;
  bottom: 0px;
  left: 0px;
}

.revetime {
  width: 25%;
  height: 99px;
  left: 0px;
  top: 0px;

  /* Pink */

  background: #ff5767;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.06);
  color: #fff;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.srevetime {
  width: 25%;
  height: 99px;
  left: 0px;
  top: 0px;

  /* Pink */

  background: #e27400;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.06);
  color: #fff;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.revetime p {
  /* width: 157px; */
  height: 17px;
  font-family: "Lato";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 17px;
  /* identical to box height */

  text-align: center;
  color: #ffffff;
  margin: 10px 0px 10px 0px;
}
.revetime h6 {
  /* width: 157px; */
  height: 28px;

  font-family: "Noto Sans Bengali";
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 28px;
  /* identical to box height */

  text-align: center;

  color: #ffffff;
}

.totaltime {
  width: 25%;
  height: 99px;
  left: 0px;
  top: 0px;

  /* Sky-Blue */

  background: #246fdf;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.06);
  color: #fff;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.stotaltime {
  width: 25%;
  height: 99px;
  left: 0px;
  top: 0px;

  /* Sky-Blue */

  background: #1a488d;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.06);
  color: #fff;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.totaltime p {
  /* width: 157px; */
  height: 17px;
  font-family: "Lato";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 17px;
  /* identical to box height */

  text-align: center;
  color: #ffffff;
  margin: 10px 0px 10px 0px;
}
.totaltime h6 {
  /* width: 157px; */
  height: 28px;

  font-family: "Noto Sans Bengali";
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 28px;
  /* identical to box height */

  text-align: center;

  color: #ffffff;
}

.totalreducetime {
  width: 25%;
  height: 99px;
  left: 0px;
  top: 0px;
  background: #e3e3e3;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.06);
  border-radius: 0px 0px 12px 0px;
  color: #2e3a91;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.stotalreducetime {
  width: 25%;
  height: 99px;
  left: 0px;
  top: 0px;
  background: #e3e3e3;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.06);
  border-radius: 0px 0px 12px 0px;
  color: #683a51;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.totalreducetime p {
  /* width: 127px; */
  height: 17px;

  font-family: "Lato";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 17px;
  /* identical to box height */

  /* Dark Blue */

  /* color: #2e3a91; */
  margin: 10px 0px 10px 0px;
}
.totalreducetime h6 {
  /* width: 157px; */
  height: 28px;

  font-family: "Noto Sans Bengali";
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 28px;
  /* identical to box height */

  /* Dark Blue */

  /* color: #2e3a91; */
}
/*====================== Left Menu content ============== */
/*====================== Right Menu Start here ============== */
.singlesidebar {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 0px;
  gap: 20px;
  /* position: absolute; */
  /* width: 270px; */
  /* height: 870px; */
  /* left: 1005px; */
  /* top: 116px; */
}
.rightsidearea {
  width: 100%;
  /* height: 925.75px; */
  /* left: 1283px; */
  /* top: 95px; */
  background: #f9fcff;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.12);
  border-radius: 12px;
  /* transform: matrix(-1, 0, 0, 1, 0, 0); */
  margin-top: 20px;
}

.rititle {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 0px;
  grid-gap: 8px;
  gap: 8px;
  /* width: 270px; */
  width: 100%;
  height: 28px;
  flex: none;
  order: 0;
  align-self: stretch;
  flex-grow: 0;
  padding: 20px 15px 10px 10px;
}

.rititle img {
  width: 28px;
  height: 28px;
}
.rititle p {
  /* width: 49px; */
  /* height: 21px; */

  font-family: "Noto Sans Bengali";
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 21px;

  /* Dark Blue */

  color: #2e3a91;

  /* Inside auto layout */

  flex: none;
  order: 1;
  flex-grow: 0;
  margin-bottom: 0px;
}

.rcontent p {
  /*display: flex;*/
  flex-direction: row;
  align-items: flex-start;
  padding: 0px;
  /* width: 270px; */
  /* height: 52px; */
  flex: none;
  order: 0;
  align-self: stretch;
  flex-grow: 0;
  font-family: "Noto Serif Bengali";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  margin-bottom: 5px;
}

.rcontent p span {
  /* /*display: flex;* */
  flex-direction: column;
  align-items: flex-start;
  padding: 4px;
  gap: 8px;

  font-family: "Noto Serif Bengali";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 10px;
}

.rcontent {
  windth: 100%;
  max-width: 270px;
  /* height: 0px; */
  border-bottom: 1px solid #e5e5e5;
  margin: 3px 0px;
  padding: 11px 0px;
}

/*====================== Right Menu end here ============== */

/*====================== Responsive Design ============== */
@media (max-width: 1280px) {
  .borderright {
    border-right: 1px solid #bacfe2;
    /* transform: rotate(-90deg); */
    position: absolute;
    top: 20px;
    /* height: 190px; */
    height: auto;
    left: -15px;
  }
}
@media (max-width: 1199px) {
  .revetime h6 {
    font-size: 15px;
  }
  .revetime p {
    font-size: 10px;
  }
  .totaltime p {
    font-size: 10px;
  }

  .totaltime h6 {
    font-size: 15px;
  }
  .totalreducetime p {
    font-size: 10px;
  }

  .totalreducetime h6 {
    font-size: 15px;
  }
  .recordaudio p {
    font-size: 10px;
  }
  .recordaudio h6 {
    font-size: 15px;
  }
  .rititle p {
    font-size: 13px;
  }
  .rcontent p {
    font-size: 10px;
  }
  svg.recharts-surface {
    width: 100%;
  }
  .recharts-wrapper {
    width: 100% !important;
  }
}

@media (max-width: 991px) {
  .recharts-wrapper {
    position: relative !important;
    cursor: default;
    width: 100% !important;
    height: 300px;
  }

  svg.recharts-surface {
    width: 100%;
  }

  .recordaudio {
    width: 100%;
    height: 99px;
    left: 0px;
    top: 0px;
    background: #2e3a91;
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.06);
    border-radius: 0px 0px 0px 12px;
    color: #fff;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  .revetime {
    width: 100%;
    height: 99px;
    left: 0px;
    top: 0px;
    background: #ff5767;
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.06);
    color: #fff;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  .totaltime {
    width: 100%;
    height: 99px;
    left: 0px;
    top: 0px;
    background: #246fdf;
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.06);
    color: #fff;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  .totalreducetime {
    width: 100%;
    height: 99px;
    left: 0px;
    top: 0px;
    background: #e3e3e3;
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.06);
    border-radius: 0px 0px 12px 0px;
    color: #2e3a91;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  .borderright {
    border-right: 1px solid #bacfe2;
    /* transform: rotate(-90deg); */
    position: absolute;
    top: 20px;
    /* height: 190px; */
    height: auto;
    left: -45px;
  }

  .graphline {
    position: absolute;
    width: 385px;
    height: 0px;
    left: 50px;
    top: 240px;
    border: 1px solid #f8e1e3;
  }
  span.grayname {
    display: none;
  }

  .revetime h6 {
    font-size: 10px;
  }
  .revetime p {
    font-size: 10px;
  }
  .totaltime p {
    font-size: 10px;
  }

  .totaltime h6 {
    font-size: 10px;
  }
  .totalreducetime p {
    font-size: 10px;
  }

  .totalreducetime h6 {
    font-size: 10px;
  }
  .recordaudio p {
    font-size: 10px;
  }
  .recordaudio h6 {
    font-size: 10px;
  }
  .rititle p {
    font-size: 13px;
  }
  .rcontent p {
    font-size: 10px;
  }
  .leftmenuarea {
    position: relative;
    height: auto;
    width: 100%;
    display: block;
    float: left;
    width: 100%;
    margin-bottom: 35px;
  }
  .borderright {
    border-right: none;
    /* transform: rotate(-90deg); */
    position: absolute;
    top: 0px;
    /* height: 190px; */
    height: auto;
  }
  .borderacitve {
    position: absolute;
    width: 0px;
    height: 28.92px;
    left: 96px;
    top: 0px;
    background: #00407b;
    border-radius: 5px;
  }
  .leftmenuarea nav ul {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    height: auto;
  }
  .leftmenuarea nav ul li:first-child {
    margin-top: 16px;
  }
  .contentarea {
    margin: 50px 0px;
  }
}

@media (max-width: 768px) {
  .borderright {
    border-right: none;
    /* transform: rotate(-90deg); */
    position: absolute;
    top: 20px;
    /* height: 190px; */
    height: auto;
    left: -45px;
  }

  .graphline {
    position: absolute;
    width: 490px;
    height: 0px;
    left: 40px;
    top: 240px;
    border: 1px solid #f8e1e3;
  }
  span.grayname {
    display: none;
  }
  .revetime h6 {
    font-size: 10px;
  }
  .revetime p {
    font-size: 8px;
  }
  .totaltime p {
    font-size: 8px;
  }

  .totaltime h6 {
    font-size: 10px;
  }
  .totalreducetime p {
    font-size: 8px;
  }

  .totalreducetime h6 {
    font-size: 10px;
  }
  .recordaudio p {
    font-size: 8px;
  }
  .recordaudio h6 {
    font-size: 10px;
  }
  .rititle p {
    font-size: 8px;
  }
  .rcontent p {
    font-size: 8px;
  }
  .contentarea {
    margin: 80px 0px;
  }
}

@media (max-width: 640px) {
  .rightsidearea {
    margin-bottom: 5%;
  }
}
@media (max-width: 576px) {
  .leftmenuarea {
    position: relative;
    height: auto;
    width: 100%;
    display: block;
    float: left;
    width: 100%;
    margin-bottom: 35px;
  }
  .borderright {
    border-right: none;
    /* transform: rotate(-90deg); */
    position: absolute;
    top: 0px;
    /* height: 190px; */
    height: auto;
  }
  .borderacitve {
    position: absolute;
    width: 0px;
    height: 28.92px;
    left: 96px;
    top: 0px;
    background: #00407b;
    border-radius: 5px;
  }
  .leftmenuarea nav ul {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    height: auto;
  }
  .leftmenuarea nav ul li:first-child {
    margin-top: 16px;
  }
}

@media (max-width: 480px) {
  .recordaudio p {
    font-size: 7px;
  }
  .recordaudio h6 {
    font-size: 10px;
  }
  .revetime p {
    font-size: 7px;
  }
  .revetime h6 {
    font-size: 10px;
  }
  .totaltime p {
    font-size: 7px;
  }
  .totaltime h6 {
    font-size: 10px;
  }
  .totalreducetime p {
    font-size: 7px;
  }
  .totalreducetime h6 {
    font-size: 10px;
  }
}
@media (max-width: 414px) {
  .sununtitle {
    display: flex;
    justify-content: space-between;
    width: 100%;
    margin-bottom: 20px;
    margin-top: 315px;
    align-items: center;
  }

  .graphtitle {
    display: flex;
    /* justify-content: space-between; */
    width: 100%;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
  /* .righttitle {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 0px;
    grid-gap: 16px;
    grid-gap: 16px;
    gap: 16px;
    height: 25px;
    position: relative;
    margin-left: 26px;
  } */
  .recorddata {
    display: flex;
    justify-content: start;
    width: 100%;
    position: absolute;
    top: 80%;
    left: 0px;
    flex-direction: row;
    flex-wrap: wrap;
  }

  .recordaudio {
    width: 100%;
    height: 99px;
    left: 0px;
    top: 0px;
    background: #2e3a91;
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.06);
    border-radius: 0;
    color: #fff;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
  .revetime {
    width: 100%;
    height: 99px;
    left: 0px;
    top: 0px;
    background: #ff5767;
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.06);
    color: #fff;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
  .totalreducetime {
    width: 100%;
    height: 99px;
    left: 0px;
    top: 0px;
    background: #e3e3e3;
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.06);
    border-radius: 0px 0px 12px 0px;
    color: #2e3a91;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
  .totaltime {
    width: 100%;
    height: 99px;
    left: 0px;
    top: 0px;
    background: #246fdf;
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.06);
    color: #fff;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  .rightsidearea {
    width: 100%;
    background: #f9fcff;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.12);
    border-radius: 12px;
    margin-top: 20px;
    margin-bottom: 100%;
  }
  .srecordaudio {
    width: 100%;
    height: 99px;
    left: 0px;
    top: 0px;
    background: #683a51;
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.06);
    border-radius: 0px;
    color: #fff;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
  .srevetime {
    width: 100%;
    height: 99px;
    left: 0px;
    top: 0px;
    background: #e27400;
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.06);
    color: #fff;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
  .stotaltime {
    width: 100%;
    height: 99px;
    left: 0px;
    top: 0px;
    background: #1a488d;
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.06);
    color: #fff;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
  .stotalreducetime {
    width: 100%;
    height: 99px;
    left: 0px;
    top: 0px;
    background: #e3e3e3;
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.06);
    border-radius: 0px 0px 12px 0px;
    color: #683a51;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
}

@media (max-width: 360px) {
  .graphtitle {
    display: flex;
    /* justify-content: space-between; */
    width: 100%;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
  /* .righttitle {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 0px;
    grid-gap: 16px;
    grid-gap: 16px;
    gap: 16px;
    height: 25px;
    position: relative;
    margin-left: 26px;
  } */
  .recorddata {
    display: flex;
    justify-content: start;
    width: 100%;
    position: absolute;
    top: 70%;
    left: 0px;
    flex-direction: row;
    flex-wrap: wrap;
  }

  .recordaudio {
    width: 100%;
    height: 99px;
    left: 0px;
    top: 0px;
    background: #2e3a91;
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.06);
    border-radius: 0;
    color: #fff;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
  .revetime {
    width: 100%;
    height: 99px;
    left: 0px;
    top: 0px;
    background: #ff5767;
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.06);
    color: #fff;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
  .totalreducetime {
    width: 100%;
    height: 99px;
    left: 0px;
    top: 0px;
    background: #e3e3e3;
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.06);
    border-radius: 0px 0px 12px 0px;
    color: #2e3a91;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
  .totaltime {
    width: 100%;
    height: 99px;
    left: 0px;
    top: 0px;
    background: #246fdf;
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.06);
    color: #fff;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  .rightsidearea {
    width: 100%;
    background: #f9fcff;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.12);
    border-radius: 12px;
    margin-top: 20px;
    margin-bottom: 100%;
  }
  .srecordaudio {
    width: 100%;
    height: 99px;
    left: 0px;
    top: 0px;
    background: #683a51;
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.06);
    border-radius: 0px;
    color: #fff;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
  .srevetime {
    width: 100%;
    height: 99px;
    left: 0px;
    top: 0px;
    background: #e27400;
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.06);
    color: #fff;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
  .stotaltime {
    width: 100%;
    height: 99px;
    left: 0px;
    top: 0px;
    background: #1a488d;
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.06);
    color: #fff;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
  .stotalreducetime {
    width: 100%;
    height: 99px;
    left: 0px;
    top: 0px;
    background: #e3e3e3;
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.06);
    border-radius: 0px 0px 12px 0px;
    color: #683a51;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
}

@media (max-width: 320px) {
  .lefttitle h4 {
    font-size: 10px;
  }
  .leftmenuarea nav ul li a {
    font-size: 14px;
  }
}
/*====================== Responsive Design ============== */
.maingraph{
  width: 100%;
}