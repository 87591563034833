.singleitem {
  background-color: #ffffff;
  padding: 5px 10px;
  border: 1px solid #dde4f1;
  border-radius: 12px;
  margin: 0px 10px 0px;
}

span.recordstart {
  font-size: 11px;
  font-family: "Noto Sans Bengali";
  color: #526281;
}

span.countitem {
  font-size: 16px;
  font-family: "Roboto Flex", sans-serif;
  color: #136ee5;
  font-weight: 700;
}

.bodytextcontent {
  display: flex;
  align-items:center;
  justify-content:center;
  width: 100%;
  max-width: 795px;
  height: 300px;
  font-size: 31px;
  font-family: "SolaimanLipi", Arial, sans-serif;
  color: #161a31;
  font-weight: 400;
  margin-bottom: 0px;
  text-align: center;
  overflow-y: scroll;
}


@media screen and (max-width: 1180px) {
  .bodytextcontent {
    display: flex;
    align-items:center;
    justify-content:center;
    width: 100%;
    max-width: 795px;
    height: 260px;
    font-size: 25px;
    font-family: "SolaimanLipi", Arial, sans-serif;
    color: #161a31;
    font-weight: 400;
    margin-bottom: 0px;
    text-align: center;
    overflow-y: scroll;
  }
}

@media screen and (max-width: 991px) {
  .bodytextcontent {
    display: flex;
    align-items:center;
    justify-content:center;
    width: 100%;
    max-width: 600px;
    height: 260px;
    font-size: 25px;
    font-family: "SolaimanLipi", Arial, sans-serif;
    text-align: center;
    overflow-y: scroll;
  }
}

@media screen and (max-width: 568px) {
  .bodytextcontent {
    display: flex;
    align-items:center;
    justify-content:center;
    width: 100%;
    height: 210px;
    font-size: 19px;
    font-family: "SolaimanLipi", Arial, sans-serif;
    text-align: center;
    overflow-y: auto;
  }
}
.setmargin{
  margin: auto;
}
.bodytextcontent::-webkit-scrollbar {
  display: none;
}

.bodytextcontent {
  -ms-overflow-style: none;  /* IE and Edge */
  scrollbar-width: none;  /* Firefox */
}