///* -------------------------------------------
//
//Name: 		Text to speach
//Version:    1.0
//Author:		Rabiul
//Portfolio:  https://themeforest.net/user/alam_robi
//
//p.s. I am available for Freelance hire (UI design, web development). mail: miller.themes@gmail.com
//
// ----------------------------------------------*/
//
//@import url("https://fonts.googleapis.com/css2?family=Hind+Siliguri:wght@300;400;500;600;700&display=swap");
//@import url("https://fonts.maateen.me/solaiman-lipi/font.css");
//@import url("https://fonts.googleapis.com/css2?family=Noto+Sans+Bengali:wght@100;400;500;600;700;800;900&display=swap");
//
///*
//1. General CSS
//
//font-family: 'Hind Siliguri', sans-serif;
//
//*/
//
//
///* body {
//  margin: 0;
//  line-height: 1.5;
//  font-size: 16px;
//  height: auto;
//  font-family: "Hind Siliguri", sans-serif;
//  background-color: #eff3f8;
//} */
//
//
///* ============================================
//1. General CSS
//=============================================== */
//
//:root {
//    --main-color: #e98b2c;
//    --dark: #1b1b22;
//    --dark-light: #32323a;
//    --white: #fff;
//    --white-light: #c8c8c8;
//}
//
//* {
//    margin: 0;
//    padding: 0;
//    outline: none;
//    -webkit-box-sizing: border-box;
//    box-sizing: border-box;
//    text-decoration: none;
//    list-style: none;
//}
//
//::before,
//::after {
//    box-sizing: border-box;
//}
//
//
///* img{
//      width: 100%;
//  } */
//
//body {
//    margin: 0;
//    line-height: 1.5;
//    font-size: 16px;
//    height: auto;
//    font-family: "Hind Siliguri", sans-serif;
//}
//
//
///* For scripting */
//
//body.hide-scrolling {
//    overflow-y: hidden;
//}
//
//ul {
//    list-style-type: none;
//}
//
//a {
//    text-decoration: none;
//}
//
//table {
//    border-collapse: collapse;
//    border-spacing: 0;
//}
//
//.section {
//    min-height: 100vh;
//    display: block;
//    width: 100%;
//}
//
//.shadow-dark2 {
//    -webkit-box-shadow: 0 0 20px rgba(48, 46, 77, 0.15);
//    box-shadow: 0 0 20px rgba(48, 46, 77, 0.15);
//}
//
//.shadow-dark {
//    /*
//    -webkit-box-shadow: 0 0 20px rgba(48,46,77,0.15);
//    box-shadow: 0 0 20px rgba(48,46,77,0.15); */
//    /* -webkit-box-shadow: 0 0 20px rgba(0,0,0,0.15);
//    box-shadow: 0 0 20px rgba(0,0,0,0.15); */
//    /*
//    box-shadow: 2px -4px 8px -3px rgba(0,0,0,0.75);
//    -webkit-box-shadow: 2px -4px 8px -3px rgba(0,0,0,0.75);
//    -moz-box-shadow: 2px -4px 8px -3px rgba(0,0,0,0.75); */
//    /* box-shadow: -2px -1px 24px -16px rgba(0,0,0,0.72) inset;
//    -webkit-box-shadow: -2px -1px 24px -16px rgba(0,0,0,0.72) inset;
//    -moz-box-shadow: -2px -1px 24px -16px rgba(0,0,0,0.72) inset; */
//    box-shadow: -2px -1px 25px -16px rgba(0, 0, 0, 0.72) inset;
//    -webkit-box-shadow: -2px -1px 25px -16px rgba(0, 0, 0, 0.72) inset;
//    -moz-box-shadow: -2px -1px 25px -16px rgba(0, 0, 0, 0.72) inset;
//}
//
//.margin-bottom {
//    margin-bottom: 60px;
//}
//
//
///* .tab-container-one {
//  margin-top: 10%;
//} */
//
//
///*=============================== Content area design ======================= */
//
//.bodybg {
//    /* display: flex; */
//    display: block;
//    // height: 140vh;
//    // height: 100vh;
//    height: 100%;
//    /* background: url("../img/bg.png") no-repeat center; */
//    // background: url("./images/stts/changedashboard.png") no-repeat center;
//    background-size: cover;
//    // background-position: 100% 65%;
//    background-position: 100% 45%;
//    flex-wrap: wrap;
//    /* width: 100%; */
//    width: 100%;
//    float: left;
//    position: relative;
//    /* padding: 137px 0px 0px 0px; */
//    justify-content: center;
//    align-items: center;
//    margin: 0 auto;
//    overflow: hidden;
//}
//
//.bodytext {
//    background-color: rgba(246, 249, 255, 0.5);
//    padding: 20px;
//    border-radius: 5px;
//    position: relative;
//}
//
//// .leftwaveimg {
////     margin: 30px 0px;
//// }
//
///* ============================= Menu Start Here ======================= */
//
//.topmenu {
//    width: 100%;
//    float: left;
//    display: block;
//    /* background: red; */
//    // position: absolute;
//    // top: 0px;
//    // left: 0;
//    background: #ededed;
//    //z-index: 9999;
//}
//
//li a.probeshkorun {
//    color: #136ee5 !important;
//    border: 1px solid #c9ddf8;
//    border-radius: 5px;
//}
//
//li a.nibondonkorun {
//    color: #44af4f !important;
//}
//
//.navbar-light .navbar-nav .nav-link {
//    color: #161a31;
//}
//
//.obodanitem li {
//    margin: 0px 30px;
//}
//
//.noactive.active {
//    background-color: transparent !important;
//    border: none;
//}
//
//ul.porarniyom {
//    display: block;
//    float: right;
//    text-align: right;
//}
//
//.leftbottomgraph {
//    margin-top: 80px;
//}
//
//.inright {
//    margin-top: 130px;
//}
//
//
///* ============================= Menu Start Here ======================= */
//
//.webcontentop {
//    padding: 40px 60px 0px !important;
//}
//
//.wavecontent {
//    display: flex;
//    flex-direction: row;
//    width: 100%;
//    height: auto;
//    justify-content: space-between;
//    /* position: absolute; */
//    /* top: 5%; */
//    margin-top: 70px;
//    padding: 40px 60px;
//    gap: 30px;
//}
//
//.wavecontentbolun {
//    display: flex;
//    flex-direction: row;
//    width: 100%;
//    height: auto;
//    justify-content: space-between;
//    /* position: absolute; */
//    /* top: 5%; */
//    /* margin-top: 60px; */
//    padding: 0px 60px;
//    grid-gap: 30px;
//    gap: 30px;
//    margin-bottom: 60px;
//}
//
//.leftwaveimg {
//    width: 50%;
//    display: flex;
//    flex-direction: column;
//    text-align: center;
//    position: relative;
//    /* margin: 30px 0; */
//    margin-bottom: 30px;
//    padding-top: 60px;
//}
//
//.leftwave {
//    width: 50%;
//    display: flex;
//    flex-direction: column;
//    text-align: center;
//    position: relative;
//    // margin: 30px 0;
//}
//
//span.leftwaveig {
//    position: absolute;
//    top: 80px;
//    left: 80px;
//}
//
//.topmargin {
//    margin-top: 20px;
//}
//
//span.rightwaveig {
//    position: absolute;
//    top: 70px;
//    left: 0px;
//}
//
//a.rightwaveig img {
//    margin-top: 24px;
//}
//
//.rightwave {
//    width: 50%;
//    display: flex;
//    flex-direction: column;
//    text-align: center;
//    justify-content: center;
//    /* margin: 0 auto; */
//    align-items: center;
//    // padding-top: 25px;
//}
//
//.rightwave img {
//    width: 810px;
//    margin: 10px 0px 40px auto;
//    height: auto;
//}
//
//.leftwaveimg img {
//    width: 810px;
//    margin: 20px auto;
//    height: auto;
//}
//
//.middletextarea {
//    display: flex;
//    justify-content: space-between;
//    flex-direction: row;
//    width: 100%;
//    flex-wrap: wrap;
//    height: auto;
//    background-color: rgba(255, 255, 255, 0.5);
//    padding: 80px 0px;
//}
//
//.righttext p {
//    color: #171717;
//    font-size: 16px;
//    font-family: "SolaimanLipi";
//}
//
//.lefttext {
//    width: 50%;
//    /* display: flex; */
//    /* flex-direction: row; */
//    text-align: justify;
//    float: right;
//    /* justify-content: stretch; */
//    padding-left: 15%;
//    /* padding-right: 20px; */
//}
//
//.righttext {
//    width: 50%;
//    display: flex;
//    flex-direction: column;
//}
//
//.righttext p {
//    text-align: left;
//    float: left;
//    padding-right: 50%;
//}
//
//
///*=============================== Content area design ======================= */
//
//
///*=============================== Custom progress bar style  design ======================= */
//
//
///* ============================ Right Side bar item =========================== */
//
//
///*=============================== Custom progress bar style  design ======================= */
//
//.footerdownload a img {
//    position: relative;
//}
//
//.threeplay {
//    position: absolute;
//    top: 50px;
//    float: left;
//    /* width: 100%; */
//    display: block;
//}
//
//.threeplay ul {
//    display: flex;
//    justify-content: space-between;
//    min-width: 605px;
//}
//
//.threeplay ul li {
//    padding: 0px 37px;
//}
//
//.error {
//    border: 2px solid red;
//}
//
//.email-error-msg {
//    font-size: 14px;
//    color: red;
//}
//
//.passerror {
//    border: 2px solid red;
//}
//
//// .readblock{
////     background: #ffffff;
////     height: 300px;
//// }
//.listenblock {
//    background: #ebeaea;
//    height: 300px;
//}
//
//.rule-list {
//    padding: 5px 5px;
//    color: #020529;
//    font-size: 16px;
//}
//
////  ====================================== New Dashboard design start here =============================
//.topgrapharea {
//    min-width: 819px;
//    min-height: 383px;
//    background: rgba(255, 255, 255, 0.9);
//    box-shadow: 2px 6px 2px rgba(191, 191, 191, 0.32);
//    border-radius: 16px;
//    position: relative;
//    // overflow-y: hidden;
//    // overflow-x: scroll;
//}
//
//.graphtop {
//    display: flex;
//    flex-direction: row;
//    justify-content: space-around;
//    width: 100%;
//    flex-wrap: wrap;
//}
//
//.righttop {
//    display: flex;
//    /* justify-content: flex-end; */
//    align-items: center;
//}
//
//.bullate {
//    width: 24px;
//    height: 24px;
//    background: #ddd4ff;
//    border-radius: 12px;
//    margin-right: 10px;
//}
//
//.bullateaudiodip {
//    width: 24px;
//    height: 24px;
//    background: #a1e6ff;
//    border-radius: 12px;
//    margin-right: 10px;
//}
//
//.bullateright {
//    background-color: #f9e1ff;
//}
//
//.bullateaudiolight {
//    background-color: #d4f0ff;
//}
//
//.graphtop h4 {
//    font-family: "Noto Sans Bengali";
//    font-style: normal;
//    font-weight: 500;
//    font-size: 18px;
//    line-height: 24px;
//    color: #8766ff;
//    padding-top: 25px;
//    margin-bottom: 0px;
//}
//
//.graphtop h4.audio {
//    color: #009cdc;
//}
//
//.bullatecontent p {
//    width: 99px;
//    height: 24px;
//    font-family: "Noto Sans Bengali";
//    font-style: normal;
//    font-weight: 400;
//    font-size: 12px;
//    line-height: 35px;
//    color: #526281;
//    margin-bottom: 0px;
//}
//
//.bullatecontent h6 {
//    width: 98px;
//    height: 24px;
//    font-family: "Noto Sans Bengali";
//    font-style: normal;
//    font-weight: 600;
//    font-size: 18px;
//    line-height: 24px;
//    /* identical to box height, or 133% */
//    color: #526281;
//}
//
//.graphbody {
//    position: relative;
//    display: flex;
//    width: 100%;
//    height: auto;
//}
//
//.leftbar {
//    position: absolute;
//    width: 243px;
//    height: 0px;
//    left: -70px;
//    top: 159px;
//    border: 1px solid #707070;
//    transform: rotate(90deg);
//}
//
//.bottombar {
//    position: absolute;
//    width: 733px;
//    height: 0px;
//    left: 52px;
//    top: 280px;
//    border: 1px solid #707070;
//}
//
//.glefttext {
//    position: absolute;
//    display: flex;
//    justify-content: space-evenly;
//    flex-direction: column;
//    height: auto;
//    top: 30px;
//    left: 15px;
//}
//
//.glefttext span {
//    font-family: "Noto Sans Bengali";
//    font-style: normal;
//    font-weight: 500;
//    font-size: 12px;
//    line-height: 16px;
//    padding: 5px 0px;
//}
//
//.graphcontent {
//    position: absolute;
//    bottom: -25px;
//    left: 25px;
//}
//
//.graphcontent span {
//    margin-right: 20px;
//    font-family: "Noto Sans Bengali";
//    font-style: normal;
//    font-weight: 500;
//    font-size: 11px;
//    line-height: 24px;
//    color: #303030;
//}
//
//.gleftborder {
//    display: flex;
//    position: absolute;
//    flex-direction: row;
//    width: 100%;
//    left: 52px;
//    top: 40px;
//}
//
//span.borderrightb {
//    border-bottom: 1px solid #dedede;
//    position: absolute;
//    display: block;
//    float: left;
//    width: 90%;
//}
//
//span.borderrightb1 {
//    border-bottom: 1px solid #dedede;
//    position: absolute;
//    display: block;
//    float: left;
//    width: 90%;
//    top: 27px;
//}
//
//span.borderrightb2 {
//    border-bottom: 1px solid #dedede;
//    position: absolute;
//    display: block;
//    float: left;
//    width: 90%;
//    top: 53px;
//}
//
//span.borderrightb3 {
//    border-bottom: 1px solid #dedede;
//    position: absolute;
//    display: block;
//    float: left;
//    width: 90%;
//    top: 81px;
//}
//
//span.borderrightb4 {
//    border-bottom: 1px solid #dedede;
//    position: absolute;
//    display: block;
//    float: left;
//    width: 90%;
//    top: 105px;
//}
//
//span.borderrightb5 {
//    border-bottom: 1px solid #dedede;
//    position: absolute;
//    display: block;
//    float: left;
//    width: 90%;
//    top: 130px;
//}
//
//span.borderrightb6 {
//    border-bottom: 1px solid #dedede;
//    position: absolute;
//    display: block;
//    float: left;
//    width: 90%;
//    top: 157px;
//}
//
//span.borderrightb7 {
//    border-bottom: 1px solid #dedede;
//    position: absolute;
//    display: block;
//    float: left;
//    width: 90%;
//    top: 185px;
//}
//
//span.borderrightb8 {
//    border-bottom: 1px solid #dedede;
//    position: absolute;
//    display: block;
//    float: left;
//    width: 90%;
//    top: 210px;
//}
//
//// span.borderrightb9 {
////     border-bottom: 1px solid #dedede;
////     position: absolute;
////     display: block;
////     float: left;
////     width: 90%;
////     top: 250px;
//// }
//// ================Chartbar area ==================
//.chartbarg1 {
//    position: absolute;
//}
//
//.chartbarg2 {
//    position: absolute;
//}
//
//.chartbarg3 {
//    position: absolute;
//}
//
//.chartbarg4 {
//    position: absolute;
//}
//
//.chartbarg5 {
//    position: absolute;
//}
//
//.chartbarg6 {
//    position: absolute;
//}
//
//.chartbarg7 {
//    position: absolute;
//}
//
//.chartbarg8 {
//    position: absolute;
//}
//
//.chartbarg9 {
//    position: absolute;
//}
//
//.chartbarg10 {
//    position: absolute;
//}
//
//.chartbarg11 {
//    position: absolute;
//}
//
//.chartbarg12 {
//    position: absolute;
//}
//
//.chartbargaudio1 {
//    position: absolute;
//}
//
//.chartbargaudio2 {
//    position: absolute;
//}
//
//.chartbargaudio3 {
//    position: absolute;
//}
//
//.chartbargaudio4 {
//    position: absolute;
//}
//
//.chartbargaudio5 {
//    position: absolute;
//}
//
//.chartbargaudio6 {
//    position: absolute;
//}
//
//.chartbargaudio7 {
//    position: absolute;
//}
//
//.chartbargaudio8 {
//    position: absolute;
//}
//
//.chartbargaudio9 {
//    position: absolute;
//}
//
//.chartbargaudio10 {
//    position: absolute;
//}
//
//.chartbargaudio11 {
//    position: absolute;
//}
//
//.chartbargaudio12 {
//    position: absolute;
//}
//
//.chartbarg1 .childbar1 {
//    position: absolute;
//    width: 12px;
//    height: 170px;
//    left: 30px;
//    bottom: 1px;
//    background: #ddd4ff;
//    border-radius: 12px 12px 0px 0px;
//}
//
//.chartbarg1 .childbar2 {
//    position: absolute;
//    width: 12px;
//    height: 130.98px;
//    left: 42px;
//    bottom: 1px;
//    background: #f9e1ff;
//    border-radius: 12px 12px 0px 0px;
//}
//
//.chartbarg2 .childbar1 {
//    position: absolute;
//    width: 12px;
//    height: 122px;
//    left: 85px;
//    bottom: 1px;
//    background: #ddd4ff;
//    border-radius: 12px 12px 0px 0px;
//}
//
//.chartbarg2 .childbar2 {
//    position: absolute;
//    width: 12px;
//    height: 94px;
//    left: 97px;
//    bottom: 1px;
//    background: #f9e1ff;
//    border-radius: 12px 12px 0px 0px;
//}
//
//.chartbarg3 .childbar1 {
//    position: absolute;
//    width: 12px;
//    height: 212px;
//    left: 145px;
//    bottom: 1px;
//    background: #ddd4ff;
//    border-radius: 12px 12px 0px 0px;
//}
//
//.tooltipdashboard:hover {
//    background: #8766ff;
//}
//
//.tooltipdashboardhide:hover {
//    background: #f98be7;
//}
//
//.chartbarg3 .childbar2 {
//    position: absolute;
//    width: 12px;
//    height: 168px;
//    left: 157px;
//    bottom: 1px;
//    background: #f9e1ff;
//    border-radius: 12px 12px 0px 0px;
//}
//
//.chartbarg4 .childbar1 {
//    position: absolute;
//    width: 12px;
//    height: 122px;
//    left: 205px;
//    bottom: 1px;
//    background: #ddd4ff;
//    border-radius: 12px 12px 0px 0px;
//}
//
//.chartbarg4 .childbar2 {
//    position: absolute;
//    width: 12px;
//    height: 94px;
//    left: 217px;
//    bottom: 1px;
//    background: #f9e1ff;
//    border-radius: 12px 12px 0px 0px;
//}
//
//.chartbarg5 .childbar1 {
//    position: absolute;
//    width: 12px;
//    height: 74px;
//    left: 265px;
//    bottom: 1px;
//    background: #ddd4ff;
//    border-radius: 12px 12px 0px 0px;
//}
//
//.chartbarg5 .childbar2 {
//    position: absolute;
//    width: 12px;
//    height: 57px;
//    left: 277px;
//    bottom: 1px;
//    background: #f9e1ff;
//    border-radius: 12px 12px 0px 0px;
//}
//
//.chartbarg6 .childbar1 {
//    position: absolute;
//    width: 12px;
//    height: 170px;
//    left: 325px;
//    bottom: 1px;
//    background: #ddd4ff;
//    border-radius: 12px 12px 0px 0px;
//}
//
//.chartbarg6 .childbar2 {
//    position: absolute;
//    width: 12px;
//    height: 131.98px;
//    left: 337px;
//    bottom: 1px;
//    background: #f9e1ff;
//    border-radius: 12px 12px 0px 0px;
//}
//
//.chartbarg7 .childbar1 {
//    position: absolute;
//    width: 12px;
//    height: 122px;
//    left: 385px;
//    bottom: 1px;
//    background: #ddd4ff;
//    border-radius: 12px 12px 0px 0px;
//}
//
//.chartbarg7 .childbar2 {
//    position: absolute;
//    width: 12px;
//    height: 94px;
//    left: 397px;
//    bottom: 1px;
//    background: #f9e1ff;
//    border-radius: 12px 12px 0px 0px;
//}
//
//.chartbarg8 .childbar1 {
//    position: absolute;
//    width: 12px;
//    height: 50px;
//    left: 445px;
//    bottom: 1px;
//    background: #ddd4ff;
//    border-radius: 12px 12px 0px 0px;
//}
//
//.chartbarg8 .childbar2 {
//    position: absolute;
//    width: 12px;
//    height: 38px;
//    left: 456px;
//    bottom: 1px;
//    background: #f9e1ff;
//    border-radius: 12px 12px 0px 0px;
//}
//
//.chartbarg9 .childbar1 {
//    position: absolute;
//    width: 12px;
//    height: 122px;
//    left: 500px;
//    bottom: 1px;
//    background: #ddd4ff;
//    border-radius: 12px 12px 0px 0px;
//}
//
//.chartbarg9 .childbar2 {
//    position: absolute;
//    width: 12px;
//    height: 94px;
//    left: 512px;
//    bottom: 1px;
//    background: #f9e1ff;
//    border-radius: 12px 12px 0px 0px;
//}
//
//.chartbarg10 .childbar1 {
//    position: absolute;
//    width: 12px;
//    height: 218px;
//    left: 555px;
//    bottom: 1px;
//    background: #ddd4ff;
//    border-radius: 12px 12px 0px 0px;
//}
//
//.chartbarg10 .childbar2 {
//    position: absolute;
//    width: 12px;
//    height: 168px;
//    left: 567px;
//    bottom: 1px;
//    background: #f9e1ff;
//    border-radius: 12px 12px 0px 0px;
//}
//
//.chartbarg11 .childbar1 {
//    position: absolute;
//    width: 12px;
//    height: 170px;
//    left: 620px;
//    bottom: 1px;
//    background: #ddd4ff;
//    border-radius: 12px 12px 0px 0px;
//}
//
//.chartbarg11 .childbar2 {
//    position: absolute;
//    width: 12px;
//    height: 130.98px;
//    left: 632px;
//    bottom: 1px;
//    background: #f9e1ff;
//    border-radius: 12px 12px 0px 0px;
//}
//
//.chartbarg12 .childbar1 {
//    position: absolute;
//    width: 12px;
//    height: 194px;
//    left: 673px;
//    bottom: 1px;
//    background: #ddd4ff;
//    border-radius: 12px 12px 0px 0px;
//}
//
//.chartbarg12 .childbar2 {
//    position: absolute;
//    width: 12px;
//    height: 150px;
//    left: 685px;
//    bottom: 1px;
//    background: #f9e1ff;
//    border-radius: 12px 12px 0px 0px;
//}
//
//.chartbargaudio1 .childbar1 {
//    position: absolute;
//    width: 12px;
//    height: 170px;
//    left: 30px;
//    bottom: 1px;
//    background: #a1e6ff;
//    border-radius: 12px 12px 0px 0px;
//}
//
//.chartbargaudio1 .childbar2 {
//    position: absolute;
//    width: 12px;
//    height: 130.98px;
//    left: 42px;
//    bottom: 1px;
//    background: #d4f0ff;
//    border-radius: 12px 12px 0px 0px;
//}
//
//.chartbargaudio2 .childbar1 {
//    position: absolute;
//    width: 12px;
//    height: 122px;
//    left: 85px;
//    bottom: 1px;
//    background: #a1e6ff;
//    border-radius: 12px 12px 0px 0px;
//}
//
//.chartbargaudio2 .childbar2 {
//    position: absolute;
//    width: 12px;
//    height: 94px;
//    left: 97px;
//    bottom: 1px;
//    background: #d4f0ff;
//    border-radius: 12px 12px 0px 0px;
//}
//
//.chartbargaudio3 .childbar1 {
//    position: absolute;
//    width: 12px;
//    height: 212px;
//    left: 145px;
//    bottom: 1px;
//    background: #a1e6ff;
//    border-radius: 12px 12px 0px 0px;
//}
//
//.tooltipsunun:hover {
//    position: absolute;
//    width: 12px;
//    height: 212px;
//    left: 145px;
//    bottom: 1px;
//    background: #009cdc;
//    border-radius: 12px 12px 0px 0px;
//}
//
//.chartbargaudio3 .childbar2 {
//    position: absolute;
//    width: 12px;
//    height: 168px;
//    left: 157px;
//    bottom: 1px;
//    background: #d4f0ff;
//    border-radius: 12px 12px 0px 0px;
//}
//
//.chartbargaudio4 .childbar1 {
//    position: absolute;
//    width: 12px;
//    height: 122px;
//    left: 205px;
//    bottom: 1px;
//    background: #a1e6ff;
//    border-radius: 12px 12px 0px 0px;
//}
//
//.chartbargaudio4 .childbar2 {
//    position: absolute;
//    width: 12px;
//    height: 94px;
//    left: 217px;
//    bottom: 1px;
//    background: #d4f0ff;
//    border-radius: 12px 12px 0px 0px;
//}
//
//.chartbargaudio5 .childbar1 {
//    position: absolute;
//    width: 12px;
//    height: 74px;
//    left: 265px;
//    bottom: 1px;
//    background: #a1e6ff;
//    border-radius: 12px 12px 0px 0px;
//}
//
//.chartbargaudio5 .childbar2 {
//    position: absolute;
//    width: 12px;
//    height: 57px;
//    left: 277px;
//    bottom: 1px;
//    background: #d4f0ff;
//    border-radius: 12px 12px 0px 0px;
//}
//
//.chartbargaudio6 .childbar1 {
//    position: absolute;
//    width: 12px;
//    height: 170px;
//    left: 325px;
//    bottom: 1px;
//    background: #a1e6ff;
//    border-radius: 12px 12px 0px 0px;
//}
//
//.chartbargaudio6 .childbar2 {
//    position: absolute;
//    width: 12px;
//    height: 131.98px;
//    left: 337px;
//    bottom: 1px;
//    background: #d4f0ff;
//    border-radius: 12px 12px 0px 0px;
//}
//
//.tooltipsununhide:hover {
//    position: absolute;
//    width: 12px;
//    height: 131.98px;
//    left: 337px;
//    bottom: 1px;
//    background: #54bde8;
//    border-radius: 12px 12px 0px 0px;
//}
//
//.chartbargaudio7 .childbar1 {
//    position: absolute;
//    width: 12px;
//    height: 122px;
//    left: 385px;
//    bottom: 1px;
//    background: #a1e6ff;
//    border-radius: 12px 12px 0px 0px;
//}
//
//.chartbargaudio7 .childbar2 {
//    position: absolute;
//    width: 12px;
//    height: 94px;
//    left: 397px;
//    bottom: 1px;
//    background: #d4f0ff;
//    border-radius: 12px 12px 0px 0px;
//}
//
//.chartbargaudio8 .childbar1 {
//    position: absolute;
//    width: 12px;
//    height: 50px;
//    left: 445px;
//    bottom: 1px;
//    background: #a1e6ff;
//    border-radius: 12px 12px 0px 0px;
//}
//
//.chartbargaudio8 .childbar2 {
//    position: absolute;
//    width: 12px;
//    height: 38px;
//    left: 456px;
//    bottom: 1px;
//    background: #d4f0ff;
//    border-radius: 12px 12px 0px 0px;
//}
//
//.chartbargaudio9 .childbar1 {
//    position: absolute;
//    width: 12px;
//    height: 122px;
//    left: 500px;
//    bottom: 1px;
//    background: #a1e6ff;
//    border-radius: 12px 12px 0px 0px;
//}
//
//.chartbargaudio9 .childbar2 {
//    position: absolute;
//    width: 12px;
//    height: 94px;
//    left: 512px;
//    bottom: 1px;
//    background: #d4f0ff;
//    border-radius: 12px 12px 0px 0px;
//}
//
//.chartbargaudio10 .childbar1 {
//    position: absolute;
//    width: 12px;
//    height: 218px;
//    left: 555px;
//    bottom: 1px;
//    background: #a1e6ff;
//    border-radius: 12px 12px 0px 0px;
//}
//
//.chartbargaudio10 .childbar2 {
//    position: absolute;
//    width: 12px;
//    height: 168px;
//    left: 567px;
//    bottom: 1px;
//    background: #d4f0ff;
//    border-radius: 12px 12px 0px 0px;
//}
//
//.chartbargaudio11 .childbar1 {
//    position: absolute;
//    width: 12px;
//    height: 170px;
//    left: 620px;
//    bottom: 1px;
//    background: #a1e6ff;
//    border-radius: 12px 12px 0px 0px;
//}
//
//.chartbargaudio11 .childbar2 {
//    position: absolute;
//    width: 12px;
//    height: 130.98px;
//    left: 632px;
//    bottom: 1px;
//    background: #d4f0ff;
//    border-radius: 12px 12px 0px 0px;
//}
//
//.chartbargaudio12 .childbar1 {
//    position: absolute;
//    width: 12px;
//    height: 194px;
//    left: 673px;
//    bottom: 1px;
//    background: #a1e6ff;
//    border-radius: 12px 12px 0px 0px;
//}
//
//.chartbargaudio12 .childbar2 {
//    position: absolute;
//    width: 12px;
//    height: 150px;
//    left: 685px;
//    bottom: 1px;
//    background: #d4f0ff;
//    border-radius: 12px 12px 0px 0px;
//}
//
//.sununkotha {
//    display: flex;
//    justify-content: center;
//    align-items: center;
//    max-height: 100px;
//}
//
//// ================Chartbar area ==================
////  ====================================== New Dashboard design start here =============================
//@media only screen and (max-width: 1730px) {
//    .topgrapharea {
//        min-width: 100%;
//        min-height: 383px;
//        background: rgba(255, 255, 255, 0.9);
//        box-shadow: 2px 6px 2px rgba(191, 191, 191, 0.32);
//        border-radius: 16px;
//        position: relative;
//    }
//    .topgrapharea {
//        min-width: 100%;
//        min-height: 383px;
//        background: rgba(255, 255, 255, 0.9);
//        box-shadow: 2px 6px 2px rgba(191, 191, 191, 0.32);
//        border-radius: 16px;
//        position: relative;
//        overflow-x: scroll;
//    }
//    span.rightwaveig {
//        position: absolute;
//        top: 100px;
//        left: 0px;
//    }
//    .inright {
//        margin-top: 120px;
//    }
//}
//
//@media only screen and (max-width: 1537px) and (-webkit-min-device-pixel-ratio: 1) {
//    .bodybg {
//        /* display: flex; */
//        display: block;
//        // height: 140vh;
//        // height: 100vh;
//        // height: 107%;
//        /* background: url("../img/bg.png") no-repeat center; */
//        // background: url("./images/stts/changedashboard.png") no-repeat center;
//        background-size: cover;
//        // background-position: 100% 65%;
//        background-position: 100% 10%;
//        flex-wrap: wrap;
//        /* width: 100%; */
//        width: 100%;
//        float: left;
//        position: relative;
//        /* padding: 137px 0px 0px 0px; */
//        justify-content: center;
//        align-items: center;
//        margin: 0 auto;
//        /* overflow: hidden; */
//    }
//}
//
//@media only screen and (max-width: 1024px) {
//    .wavecontent {
//        display: flex;
//        flex-direction: column;
//        width: 100%;
//        height: 85%;
//        justify-content: space-between;
//        /* position: absolute; */
//        /* top: 5%; */
//        margin-top: 100px;
//    }
//    .leftwave {
//        width: 100%;
//        display: flex;
//        flex-direction: row;
//        text-align: center;
//        position: relative;
//        justify-content: center;
//        align-items: center;
//        margin-bottom: 270px;
//    }
//    .rightwave {
//        width: 100%;
//        display: flex;
//        flex-direction: column;
//        text-align: center;
//        justify-content: center;
//        /* margin: 0 auto; */
//        align-items: center;
//    }
//    .rightwave img {
//        width: 810px;
//        margin: 286px 0px 40px auto;
//        height: auto;
//    }
//    .wavecontentbolun {
//        display: flex;
//        flex-direction: column;
//        width: 100%;
//        height: auto;
//        justify-content: space-between;
//        /* position: absolute; */
//        /* top: 5%; */
//    }
//    .leftwaveimg {
//        width: 100%;
//        display: flex;
//        flex-direction: column;
//        text-align: center;
//        position: relative;
//        /* margin: 30px 0; */
//        margin-bottom: 30px;
//        padding-top: 60px;
//    }
//    .leftwaveimg img {
//        width: 810px;
//        margin: 0 auto;
//        height: auto;
//        justify-content: center;
//        text-align: center;
//    }
//    // .bodybg {
//    //     height: 105vh;
//    // }
//    span.rightwaveig {
//        position: absolute;
//        top: 50px;
//        /* left: 0px; */
//    }
//    li.nav-item>a.nav-link {
//        font-size: 15px;
//        color: #161a31;
//        font-family: "Noto Sans Bengali", sans-serif;
//        font-weight: 500;
//    }
//}
//
//@media only screen and (max-width: 820px) {
//    .breadcrumbtop {
//        display: none;
//    }
//    .signupbradcump {
//        display: none;
//    }
//}
//
//@media only screen and (max-width: 812px) {
//    div#navbarTogglerDemo01 a img {
//        width: 100%;
//    }
//}
//
//@media only screen and (max-width: 800px) {
//    div#navbarTogglerDemo01 a img {
//        width: 100%;
//    }
//    .breadcrumbtop {
//        margin: 30px 0px;
//        display: block;
//        float: left;
//        width: 100%;
//        justify-content: space-between;
//    }
//}
//
//@media only screen and (max-width: 768px) {
//    .threeplay ul li {
//        padding: 0px 0px;
//    }
//    li.topStop {
//        position: absolute;
//        left: 250px;
//        top: 0px;
//    }
//    .breadcrumbtop {
//        display: none;
//    }
//}
//
//@media only screen and (max-width: 656px) {
//    span.leftwaveig {
//        position: absolute;
//        top: 120px;
//        left: 80px;
//    }
//    span.rightwaveig {
//        position: absolute;
//        top: 100px;
//        left: 50px;
//        width: 60%;
//    }
//    .graphtop {
//        display: flex;
//        flex-direction: row;
//        justify-content: center;
//        width: 100%;
//        flex-wrap: wrap;
//        flex-direction: column;
//        align-items: center;
//        /* padding-right: 22%; */
//    }
//    .leftwave {
//        width: 100%;
//        display: flex;
//        flex-direction: row;
//        text-align: center;
//        position: relative;
//        justify-content: center;
//        align-items: center;
//        margin-bottom: 390px;
//    }
//    .inright {
//        margin-top: 25px;
//        position: absolute;
//        top: 360px;
//        width: 100%;
//    }
//    .topmargin {
//        margin-top: 20px;
//        margin-left: 80px;
//    }
//}
//
//@media only screen and (max-width: 640px) {
//    .inright {
//        margin-top: 25px;
//        position: absolute;
//        top: 455px;
//        width: 100%;
//    }
//    .footerdownload {
//        margin-left: -17px;
//    }
//    .threeplay ul li {
//        padding: 0px 0px !important;
//    }
//    li.hali {
//        margin-left: -40px;
//        margin-top: 25px;
//    }
//    // Sunun Three Button Start Here
//    .threeplay ul {
//        display: flex;
//        justify-content: start;
//        min-width: 605px;
//        // margin-top: -25px;
//    }
//    .threeplay ul li {
//        padding: 0px 0px;
//    }
//    .threeplay {
//        position: absolute;
//        top: 25px;
//        float: left;
//        /* width: 100%; */
//        display: block;
//    }
//    li.hali img {
//        width: 80%;
//    }
//    li.hali {
//        margin-left: -40px;
//        margin-top: 22px;
//    }
//    li.topStop {
//        position: absolute;
//        left: 187px;
//        top: 18px;
//    }
//    li.noaction {
//        position: absolute;
//        left: 365px;
//        top: 25px;
//    }
//    li.noaction img {
//        width: 80%;
//    }
//    .stopbutton {
//        position: absolute;
//        left: 10px;
//        list-style: none;
//        text-decoration: none;
//        border: none;
//        font-size: 40px;
//        color: #136ee5;
//    }
//    // Sunun Three Button End Here
//    button.bottomwave img {
//        width: 64px;
//        height: auto;
//        position: absolute;
//        top: 18px;
//        left: 235px;
//        cursor: pointer;
//        background: none;
//        opacity: 0;
//    }
//    .startwate {
//        width: 100% !important;
//        height: auto !important;
//        overflow: hidden;
//        border: none;
//        margin: 0 90px;
//    }
//    .stop {
//        min-height: 132px;
//        margin: 0 80px;
//    }
//}
//
//@media only screen and (max-width: 600px) {
//    .inright {
//        margin-top: 80px;
//        position: absolute;
//        top: 410px;
//        width: 100%;
//    }
//    // Sunun Three Button Start Here
//    .threeplay ul {
//        display: flex;
//        justify-content: start;
//        min-width: 605px;
//        // margin-top: -25px;
//    }
//    .threeplay ul li {
//        padding: 0px 0px;
//    }
//    .threeplay {
//        position: absolute;
//        top: 25px;
//        float: left;
//        /* width: 100%; */
//        display: block;
//    }
//    li.hali img {
//        width: 70%;
//    }
//    li.hali {
//        margin-left: 5px;
//        margin-top: 10px;
//    }
//    li.topStop {
//        position: absolute;
//        left: 170px;
//        top: 0px;
//    }
//    li.noaction {
//        position: absolute;
//        left: 275px;
//        top: 7px;
//    }
//    li.noaction img {
//        width: 75%;
//    }
//    .stopbutton {
//        position: absolute;
//        left: 10px;
//        list-style: none;
//        text-decoration: none;
//        border: none;
//        font-size: 40px;
//        color: #136ee5;
//    }
//    // Sunun Three Button End Here
//    .startwate {
//        width: 100% !important;
//        height: auto !important;
//        overflow: hidden;
//        border: none;
//        margin: 0 0px;
//    }
//    button.bottomwave img {
//        width: 64px;
//        height: auto;
//        position: absolute;
//        top: 18px;
//        left: 205px;
//        cursor: pointer;
//        background: none;
//        opacity: 0;
//    }
//    .stop {
//        min-height: 132px;
//        margin: 0px;
//    }
//    .breadcrumbtop {
//        margin: 30px 0px;
//        display: flex;
//        float: left;
//        width: 100%;
//        justify-content: space-between;
//        display: none;
//    }
//}
//
//@media only screen and (max-width: 540px) {
//    li.hali {
//        margin-left: 5px;
//        margin-top: 3px;
//    }
//    li.topStop {
//        position: absolute;
//        left: 140px;
//        top: 0px;
//    }
//    li.noaction {
//        position: absolute;
//        left: 220px;
//        top: 2px;
//    }
//    li.topStop img {
//        width: 80%;
//    }
//}
//
//@media only screen and (max-width: 480px) {
//    .webcontentop {
//        padding: 40px 0px 0px !important;
//    }
//    span.leftwaveig {
//        position: absolute;
//        top: 120px;
//        left: 0px;
//    }
//    .inright {
//        margin-top: 35px;
//        position: absolute;
//        top: 460px;
//        width: 100%;
//        left: 0;
//    }
//    span.rightwaveig {
//        position: absolute;
//        top: 100px;
//        left: 0px;
//        width: 100%;
//    }
//    .wavecontentbolun {
//        padding: 0px 0px;
//    }
//    li.hali {
//        margin-left: -40px;
//        margin-top: 5px;
//    }
//    li.topStop {
//        position: absolute;
//        left: 110px;
//        top: 5px;
//    }
//    li.noaction {
//        position: absolute;
//        left: 205px;
//        top: 4px;
//    }
//}
//
//@media only screen and (max-width: 414px) {
//    .inright {
//        margin-top: 35px;
//        position: absolute;
//        top: 420px;
//        width: 100%;
//        left: 0;
//    }
//    .obodanitem li {
//        margin: 0px;
//    }
//    // Sunun Three Button Start Here
//    .threeplay ul {
//        display: flex;
//        justify-content: start;
//        min-width: 605px;
//        // margin-top: -25px;
//    }
//    .threeplay ul li {
//        padding: 0px 0px;
//    }
//    .threeplay {
//        position: absolute;
//        top: 25px;
//        float: left;
//        /* width: 100%; */
//        display: block;
//    }
//    li.hali img {
//        width: 70%;
//    }
//    li.hali {
//        margin-left: -40px;
//        margin-top: 8px;
//    }
//    li.topStop {
//        position: absolute;
//        left: 98px;
//        top: 5px;
//    }
//    li.noaction {
//        position: absolute;
//        left: 180px;
//        top: 7px;
//    }
//    li.noaction img {
//        width: 75%;
//    }
//    .stopbutton {
//        position: absolute;
//        left: 10px;
//        list-style: none;
//        text-decoration: none;
//        border: none;
//        font-size: 40px;
//        color: #136ee5;
//        top: 5px;
//    }
//    // Sunun Three Button End Here
//    .startwate {
//        width: 100% !important;
//        height: auto !important;
//        overflow: hidden;
//        border: none;
//        margin: 0 0px;
//    }
//    button.bottomwave img {
//        width: 64px;
//        height: auto;
//        position: absolute;
//        top: 18px;
//        left: 135px;
//        cursor: pointer;
//        background: none;
//        opacity: 0;
//    }
//    .stop {
//        min-height: 132px;
//        margin: 0 0px;
//    }
//}
//
//@media only screen and (max-width: 412px) {
//    .webcontentop {
//        padding: 40px 0px 0px !important;
//    }
//    span.leftwaveig {
//        position: absolute;
//        top: 120px;
//        left: 0px;
//    }
//    .inright {
//        margin-top: 35px;
//        position: absolute;
//        top: 440px;
//        width: 100%;
//        left: 0;
//    }
//    span.rightwaveig {
//        position: absolute;
//        top: 100px;
//        left: 0px;
//        width: 100%;
//    }
//    .wavecontentbolun {
//        padding: 0px 0px;
//    }
//    .leftbottomgraph {
//        margin-top: 0px;
//    }
//    .leftwaveimg {
//        padding-top: 30px;
//    }
//    // Sunun Three Button Start Here
//    .threeplay ul {
//        display: flex;
//        justify-content: start;
//        min-width: 605px;
//        // margin-top: -25px;
//    }
//    .threeplay ul li {
//        padding: 0px 0px;
//    }
//    .threeplay {
//        position: absolute;
//        top: 25px;
//        float: left;
//        /* width: 100%; */
//        display: block;
//    }
//    li.hali img {
//        width: 70%;
//    }
//    li.hali {
//        margin-left: -70px;
//        margin-top: 8px;
//    }
//    li.topStop {
//        position: absolute;
//        left: 96px;
//        top: 5px;
//    }
//    li.noaction {
//        position: absolute;
//        left: 203px;
//        top: 7px;
//    }
//    li.noaction img {
//        width: 75%;
//    }
//    .stopbutton {
//        position: absolute;
//        left: 10px;
//        list-style: none;
//        text-decoration: none;
//        border: none;
//        font-size: 40px;
//        color: #136ee5;
//    }
//    // Sunun Three Button End Here
//}
//
//@media only screen and (max-width: 393px) {
//    .inright {
//        margin-top: 35px;
//        position: absolute;
//        top: 420px;
//        width: 100%;
//        left: 0;
//    }
//    li.topStop {
//        position: absolute;
//        left: 85px;
//        top: 5px;
//    }
//    li.hali {
//        margin-left: -70px;
//        margin-top: 6px;
//    }
//    li.noaction {
//        position: absolute;
//        left: 180px;
//        top: 5px;
//    }
//}
//
//@media only screen and (max-width: 390px) {
//    li.topStop {
//        position: absolute;
//        left: 85px;
//        top: 4px;
//    }
//    li.hali {
//        margin-left: -70px;
//        margin-top: 6px;
//    }
//    li.noaction {
//        position: absolute;
//        left: 180px;
//        top: 5px;
//    }
//}
//
//@media only screen and (max-width: 384px) {
//    .webcontentop {
//        padding: 40px 0px 0px !important;
//    }
//    span.leftwaveig {
//        position: absolute;
//        top: 120px;
//        left: 0px;
//    }
//    .inright {
//        margin-top: 35px;
//        position: absolute;
//        top: 430px;
//        width: 100%;
//        left: 0;
//    }
//    span.rightwaveig {
//        position: absolute;
//        top: 100px;
//        left: 0px;
//        width: 100%;
//    }
//    .wavecontentbolun {
//        padding: 0px 0px;
//    }
//    // Sunun Three Button Start Here
//    .footerdownload {
//        margin-left: 60px;
//        margin-left: 0px;
//        width: 90%;
//    }
//    .threeplay ul {
//        display: flex;
//        justify-content: start;
//        min-width: 605px;
//        // margin-top: -25px;
//    }
//    .threeplay ul li {
//        padding: 0px 0px !important;
//    }
//    .threeplay {
//        position: absolute;
//        top: 25px;
//        float: left;
//        /* width: 100%; */
//        display: block;
//    }
//    li.hali img {
//        width: 70%;
//    }
//    li.hali {
//        margin-left: -67px;
//        margin-top: 3px;
//    }
//    li.topStop {
//        position: absolute;
//        left: 83px;
//        top: -2px;
//    }
//    li.topStop img {
//        width: 85%;
//    }
//    li.noaction {
//        position: absolute;
//        left: 160px;
//        top: 1px;
//    }
//    li.noaction img {
//        width: 75%;
//    }
//    .stopbutton {
//        position: absolute;
//        top: 10px;
//        left: 10px;
//        list-style: none;
//        text-decoration: none;
//        border: none;
//        font-size: 40px;
//        color: #136ee5;
//    }
//    // Sunun Three Button End Here
//}
//
//@media only screen and (max-width: 375px) {
//    ul.obodanitem {
//        display: flex;
//        flex-direction: row;
//        justify-content: space-between;
//        width: 100%;
//        /* font-size: 4px; */
//    }
//    .obodanitem li {
//        margin: 0px;
//    }
//}
//
//@media only screen and (max-width: 360px) {
//    .webcontentop {
//        padding: 40px 0px 0px !important;
//    }
//    span.leftwaveig {
//        position: absolute;
//        top: 120px;
//        left: 0px;
//    }
//    .inright {
//        margin-top: 35px;
//        position: absolute;
//        top: 460px;
//        width: 100%;
//        left: 0;
//    }
//    span.rightwaveig {
//        position: absolute;
//        top: 100px;
//        left: 0px;
//        width: 100%;
//    }
//    .wavecontentbolun {
//        padding: 0px 0px;
//    }
//    // Sunun Three Button Start Here
//    .footerdownload {
//        margin-left: 60px;
//        margin-left: 0px;
//        width: 90%;
//    }
//    .threeplay ul {
//        display: flex;
//        justify-content: start;
//        min-width: 605px;
//        // margin-top: -25px;
//    }
//    .threeplay ul li {
//        padding: 0px 0px !important;
//    }
//    .threeplay {
//        position: absolute;
//        top: 25px;
//        float: left;
//        /* width: 100%; */
//        display: block;
//    }
//    li.hali img {
//        width: 70%;
//    }
//    li.hali {
//        margin-left: -67px;
//        margin-top: 3px;
//    }
//    li.topStop {
//        position: absolute;
//        left: 71px;
//        top: 0px;
//    }
//    li.topStop img {
//        width: 85%;
//    }
//    li.noaction {
//        position: absolute;
//        left: 150px;
//        top: 2px;
//    }
//    li.noaction img {
//        width: 75%;
//    }
//    .stopbutton {
//        position: absolute;
//        top: 10px;
//        left: 10px;
//        list-style: none;
//        text-decoration: none;
//        border: none;
//        font-size: 40px;
//        color: #136ee5;
//    }
//    // Sunun Three Button End Here
//    .startwate {
//        width: 100% !important;
//        height: auto !important;
//        overflow: hidden;
//        border: none;
//        margin: 0 0px;
//    }
//    button.bottomwave img {
//        width: 64px;
//        height: auto;
//        position: absolute;
//        top: 18px;
//        left: 117px;
//        cursor: pointer;
//        background: none;
//        opacity: 0;
//    }
//    .stop {
//        min-height: 132px;
//        margin: 0 0px;
//    }
//}
//
//@media only screen and (max-width: 320px) {
//    .obodanitem li {
//        margin: 0px;
//    }
//    li.topStop {
//        position: absolute;
//        left: 53px;
//        top: 0px;
//    }
//    li.noaction {
//        position: absolute;
//        left: 114px;
//        top: -2px;
//    }
//    li.hali {
//        margin-left: -72px;
//        margin-top: 0px;
//    }
//    li.topStop img {
//        width: 70%;
//    }
//}

/* -------------------------------------------

Name: 		Text to speach
Version:    1.0
Author:		Rabiul
Portfolio:  https://themeforest.net/user/alam_robi

p.s. I am available for Freelance hire (UI design, web development). mail: miller.themes@gmail.com

 ----------------------------------------------*/

 @import url("https://fonts.googleapis.com/css2?family=Hind+Siliguri:wght@300;400;500;600;700&display=swap");
 @import url("https://fonts.maateen.me/solaiman-lipi/font.css");
 @import url("https://fonts.googleapis.com/css2?family=Noto+Sans+Bengali:wght@100;400;500;600;700;800;900&display=swap");
 
 /*
 1. General CSS
 
 font-family: 'Hind Siliguri', sans-serif;
 
 */
 
 
 /* body {
   margin: 0;
   line-height: 1.5;
   font-size: 16px;
   height: auto;
   font-family: "Hind Siliguri", sans-serif;
   background-color: #eff3f8;
 } */
 
 
 /* ============================================
 1. General CSS
 =============================================== */
 
 :root {
     --main-color: #e98b2c;
     --dark: #1b1b22;
     --dark-light: #32323a;
     --white: #fff;
     --white-light: #c8c8c8;
 }
 
 * {
     margin: 0;
     padding: 0;
     outline: none;
     -webkit-box-sizing: border-box;
     box-sizing: border-box;
     text-decoration: none;
     list-style: none;
 }
 
 ::before,
 ::after {
     box-sizing: border-box;
 }
 
 
 /* img{
       width: 100%;
   } */
 
 body {
     margin: 0;
     line-height: 1.5;
     font-size: 16px;
     height: auto;
     font-family: "Hind Siliguri", sans-serif;
 }
 
 
 /* For scripting */
 
 body.hide-scrolling {
     overflow-y: hidden;
 }
 
 ul {
     list-style-type: none;
 }
 
 a {
     text-decoration: none;
 }
 
 table {
     border-collapse: collapse;
     border-spacing: 0;
 }
 
 .section {
     min-height: 100vh;
     display: block;
     width: 100%;
 }
 
 .shadow-dark2 {
     -webkit-box-shadow: 0 0 20px rgba(48, 46, 77, 0.15);
     box-shadow: 0 0 20px rgba(48, 46, 77, 0.15);
 }
 
 .shadow-dark {
     /*
     -webkit-box-shadow: 0 0 20px rgba(48,46,77,0.15);
     box-shadow: 0 0 20px rgba(48,46,77,0.15); */
     /* -webkit-box-shadow: 0 0 20px rgba(0,0,0,0.15);
     box-shadow: 0 0 20px rgba(0,0,0,0.15); */
     /*
     box-shadow: 2px -4px 8px -3px rgba(0,0,0,0.75);
     -webkit-box-shadow: 2px -4px 8px -3px rgba(0,0,0,0.75);
     -moz-box-shadow: 2px -4px 8px -3px rgba(0,0,0,0.75); */
     /* box-shadow: -2px -1px 24px -16px rgba(0,0,0,0.72) inset;
     -webkit-box-shadow: -2px -1px 24px -16px rgba(0,0,0,0.72) inset;
     -moz-box-shadow: -2px -1px 24px -16px rgba(0,0,0,0.72) inset; */
     box-shadow: -2px -1px 25px -16px rgba(0, 0, 0, 0.72) inset;
     -webkit-box-shadow: -2px -1px 25px -16px rgba(0, 0, 0, 0.72) inset;
     -moz-box-shadow: -2px -1px 25px -16px rgba(0, 0, 0, 0.72) inset;
 }
 
 .margin-bottom {
     margin-bottom: 60px;
 }
 
 
 /* .tab-container-one {
   margin-top: 10%;
 } */
 
 
 /*=============================== Content area design ======================= */
 
 .bodybg {
     /* display: flex; */
     display: block;
     // height: 140vh;
     // height: 100vh;
     height: 100%;
     // background: url("../img/bg.png") no-repeat center;
     background: url("./images/stts/changedashboard2.png") no-repeat center;
     background-size: cover;
     // background-position: 100% 65%;
     background-position: 100% 45%;
     flex-wrap: wrap;
     /* width: 100%; */
     width: 100%;
     float: left;
     position: relative;
     /* padding: 137px 0px 0px 0px; */
     justify-content: center;
     align-items: center;
     margin: 0 auto;
     overflow: hidden;
 }
 
 .bodytext {
     background-color: #ffffff;
     padding: 20px;
     border-radius: 5px;
     position: relative;
     max-height: 460px;
     max-width: 100%;
     box-shadow: 0px 2px 24px 0px rgba(0, 0, 0, 0.24);
     -webkit-box-shadow: 0px 2px 24px 0px rgba(0, 0, 0, 0.24);
     -moz-box-shadow: 0px 2px 24px 0px rgba(0, 0, 0, 0.24);
     margin-bottom: 0px !important;
 }
 
 // .leftwaveimg {
 //     margin: 30px 0px;
 // }
 
 /* ============================= Menu Start Here ======================= */
 
 .topmenu {
     width: 100%;
     float: left;
     display: block;
     /* background: red; */
     // position: absolute;
     // top: 0px;
     // left: 0;
     background: #ffffff;
     //z-index: 9999;
 }
 
 li a.probeshkorun {
     color: #136ee5 !important;
     border: 1px solid #c9ddf8;
     border-radius: 5px;
 }
 
 li a.nibondonkorun {
     color: #44af4f !important;
 }
 
 .navbar-light .navbar-nav .nav-link {
     color: #161a31;
 }
 
 .obodanitem li {
     margin: 0px 30px;
 }
 
 .noactive.active {
     background-color: transparent !important;
     border: none;
 }
 
 ul.porarniyom {
     display: block;
     float: right;
     text-align: right;
 }
 
 .leftbottomgraph {
     margin-top: 80px;
 }
 
 .inright {
     margin-top: 130px;
 }
 
 
 /* ============================= Menu Start Here ======================= */
 
 .webcontentop {
     padding: 40px 60px 0px !important;
 }
 
 .wavecontent {
     display: flex;
     flex-direction: row;
     width: 100%;
     height: auto;
     justify-content: space-between;
     /* position: absolute; */
     /* top: 5%; */
     margin-top: 70px;
     padding: 40px 60px;
     gap: 30px;
 }
 
 .wavecontentbolun {
     display: flex;
     flex-direction: row;
     width: 100%;
     height: auto;
     justify-content: space-between;
     /* position: absolute; */
     /* top: 5%; */
     /* margin-top: 60px; */
     padding: 0px 60px;
     grid-gap: 30px;
     gap: 30px;
     margin-bottom: 60px;
 }
 
 .leftwaveimg {
     width: 50%;
     display: flex;
     flex-direction: column;
     text-align: center;
     position: relative;
     /* margin: 30px 0; */
     margin-bottom: 30px;
     padding-top: 60px;
 }
 
 .leftwave {
     width: 50%;
     display: flex;
     flex-direction: column;
     text-align: center;
     position: relative;
     // margin: 30px 0;
 }
 
 span.leftwaveig {
     position: absolute;
     top: 80px;
     left: 80px;
 }
 
 .topmargin {
     margin-top: 20px;
 }
 
 span.rightwaveig {
     position: absolute;
     top: 70px;
     left: 0px;
 }
 
 a.rightwaveig img {
     margin-top: 24px;
 }
 
 .rightwave {
     width: 50%;
     display: flex;
     flex-direction: column;
     text-align: center;
     justify-content: center;
     /* margin: 0 auto; */
     align-items: center;
     // padding-top: 25px;
 }
 
 .rightwave img {
     width: 810px;
     margin: 10px 0px 40px auto;
     height: auto;
 }
 
 .leftwaveimg img {
     width: 810px;
     margin: 20px auto;
     height: auto;
 }
 
 .middletextarea {
     display: flex;
     justify-content: space-between;
     flex-direction: row;
     width: 100%;
     flex-wrap: wrap;
     height: auto;
     background-color: rgba(255, 255, 255, 0.5);
     padding: 80px 0px;
 }
 
 .righttext p {
     color: #171717;
     font-size: 16px;
     font-family: "SolaimanLipi";
 }
 
 .lefttext {
     width: 50%;
     /* display: flex; */
     /* flex-direction: row; */
     text-align: justify;
     float: right;
     /* justify-content: stretch; */
     padding-left: 15%;
     /* padding-right: 20px; */
 }
 
 .righttext {
     width: 50%;
     display: flex;
     flex-direction: column;
 }
 
 .righttext p {
     text-align: left;
     float: left;
     padding-right: 50%;
 }
 
 
 /*=============================== Content area design ======================= */
 
 
 /*=============================== Custom progress bar style  design ======================= */
 
 
 /* ============================ Right Side bar item =========================== */
 
 
 /*=============================== Custom progress bar style  design ======================= */
 
 .footerdownload a img {
     position: relative;
 }
 
 .threeplay {
     position: absolute;
     top: 25%;
     left: 17%;
 }
 
 .threeplay ul {
     display: flex;
     justify-content: space-between;
     min-width: 605px;
 }
 
 .error {
     border: 2px solid red;
 }
 
 .email-error-msg {
     font-size: 14px;
     color: red;
 }
 
 .passerror {
     border: 2px solid red;
 }
 
 // .readblock{
 //     background: #ffffff;
 //     height: 300px;
 // }
 .listenblock {
     background: #ebeaea;
     height: 300px;
 }
 
 .rule-list {
     padding: 5px 5px;
     color: #020529;
     font-size: 16px;
 }
 
 //  ====================================== New Dashboard design start here =============================
 .topgrapharea {
     min-width: 819px;
     min-height: 383px;
     background: rgba(255, 255, 255, 0.9);
     box-shadow: 2px 6px 2px rgba(191, 191, 191, 0.32);
     border-radius: 16px;
     position: relative;
     // overflow-y: hidden;
     // overflow-x: scroll;
 }
 
 .graphtop {
     display: flex;
     flex-direction: row;
     justify-content: space-around;
     width: 100%;
     flex-wrap: wrap;
 }
 
 .righttop {
     display: flex;
     /* justify-content: flex-end; */
     align-items: center;
 }
 
 .bullate {
     width: 24px;
     height: 24px;
     background: #ddd4ff;
     border-radius: 12px;
     margin-right: 10px;
 }
 
 .bullateaudiodip {
     width: 24px;
     height: 24px;
     background: #a1e6ff;
     border-radius: 12px;
     margin-right: 10px;
 }
 
 .bullateright {
     background-color: #f9e1ff;
 }
 
 .bullateaudiolight {
     background-color: #d4f0ff;
 }
 
 .graphtop h4 {
     font-family: "Noto Sans Bengali";
     font-style: normal;
     font-weight: 500;
     font-size: 18px;
     line-height: 24px;
     color: #8766ff;
     padding-top: 25px;
     margin-bottom: 0px;
 }
 
 .graphtop h4.audio {
     color: #009cdc;
 }
 
 .bullatecontent p {
     width: 100%;
     height: 24px;
     font-family: "Noto Sans Bengali";
     font-style: normal;
     font-weight: 400;
     font-size: 12px;
     line-height: 35px;
     color: #526281;
     margin-bottom: 0px;
 }
 
 .bullatecontent h6 {
     width: 128px;
     height: 24px;
     font-family: "Noto Sans Bengali";
     font-style: normal;
     font-weight: 600;
     font-size: 18px;
     line-height: 24px;
     /* identical to box height, or 133% */
     color: #526281;
 }
 
 .graphbody {
     position: relative;
     display: flex;
     width: 100%;
     height: auto;
 }
 
 
 .leftbar {
     position: absolute;
     width: 243px;
     height: 0px;
     left: -70px;
     top: 159px;
     border: 1px solid #707070;
     transform: rotate(90deg);
 }
 
 .bottombar {
     position: absolute;
     width: 733px;
     height: 0px;
     left: 52px;
     top: 280px;
     border: 1px solid #707070;
 }
 
 .glefttext {
     position: absolute;
     display: flex;
     justify-content: space-evenly;
     flex-direction: column;
     height: auto;
     top: 30px;
     left: 15px;
 }
 
 .glefttext span {
     font-family: "Noto Sans Bengali";
     font-style: normal;
     font-weight: 500;
     font-size: 12px;
     line-height: 16px;
     padding: 5px 0px;
 }
 
 .graphcontent {
     position: absolute;
     bottom: -25px;
     left: 25px;
 }
 
 .graphcontent span {
     margin-right: 20px;
     font-family: "Noto Sans Bengali";
     font-style: normal;
     font-weight: 500;
     font-size: 11px;
     line-height: 24px;
     color: #303030;
 }
 
 .gleftborder {
     display: flex;
     position: absolute;
     flex-direction: row;
     width: 100%;
     left: 52px;
     top: 40px;
 }
 
 span.borderrightb {
     border-bottom: 1px solid #dedede;
     position: absolute;
     display: block;
     float: left;
     width: 90%;
 }
 
 span.borderrightb1 {
     border-bottom: 1px solid #dedede;
     position: absolute;
     display: block;
     float: left;
     width: 90%;
     top: 27px;
 }
 
 span.borderrightb2 {
     border-bottom: 1px solid #dedede;
     position: absolute;
     display: block;
     float: left;
     width: 90%;
     top: 53px;
 }
 
 span.borderrightb3 {
     border-bottom: 1px solid #dedede;
     position: absolute;
     display: block;
     float: left;
     width: 90%;
     top: 81px;
 }
 
 span.borderrightb4 {
     border-bottom: 1px solid #dedede;
     position: absolute;
     display: block;
     float: left;
     width: 90%;
     top: 105px;
 }
 
 span.borderrightb5 {
     border-bottom: 1px solid #dedede;
     position: absolute;
     display: block;
     float: left;
     width: 90%;
     top: 130px;
 }
 
 span.borderrightb6 {
     border-bottom: 1px solid #dedede;
     position: absolute;
     display: block;
     float: left;
     width: 90%;
     top: 157px;
 }
 
 span.borderrightb7 {
     border-bottom: 1px solid #dedede;
     position: absolute;
     display: block;
     float: left;
     width: 90%;
     top: 185px;
 }
 
 span.borderrightb8 {
     border-bottom: 1px solid #dedede;
     position: absolute;
     display: block;
     float: left;
     width: 90%;
     top: 210px;
 }
 
 // span.borderrightb9 {
 //     border-bottom: 1px solid #dedede;
 //     position: absolute;
 //     display: block;
 //     float: left;
 //     width: 90%;
 //     top: 250px;
 // }
 // ================Chartbar area ==================
 .chartbarg1 {
     position: absolute;
 }
 
 .chartbarg2 {
     position: absolute;
 }
 
 .chartbarg3 {
     position: absolute;
 }
 
 .chartbarg4 {
     position: absolute;
 }
 
 .chartbarg5 {
     position: absolute;
 }
 
 .chartbarg6 {
     position: absolute;
 }
 
 .chartbarg7 {
     position: absolute;
 }
 
 .chartbarg8 {
     position: absolute;
 }
 
 .chartbarg9 {
     position: absolute;
 }
 
 .chartbarg10 {
     position: absolute;
 }
 
 .chartbarg11 {
     position: absolute;
 }
 
 .chartbarg12 {
     position: absolute;
 }
 
 .chartbargaudio1 {
     position: absolute;
 }
 
 .chartbargaudio2 {
     position: absolute;
 }
 
 .chartbargaudio3 {
     position: absolute;
 }
 
 .chartbargaudio4 {
     position: absolute;
 }
 
 .chartbargaudio5 {
     position: absolute;
 }
 
 .chartbargaudio6 {
     position: absolute;
 }
 
 .chartbargaudio7 {
     position: absolute;
 }
 
 .chartbargaudio8 {
     position: absolute;
 }
 
 .chartbargaudio9 {
     position: absolute;
 }
 
 .chartbargaudio10 {
     position: absolute;
 }
 
 .chartbargaudio11 {
     position: absolute;
 }
 
 .chartbargaudio12 {
     position: absolute;
 }
 
 .chartbarg1 .childbar1 {
     position: absolute;
     width: 12px;
     height: 170px;
     left: 30px;
     bottom: 1px;
     background: #ddd4ff;
     border-radius: 12px 12px 0px 0px;
 }
 
 .chartbarg1 .childbar2 {
     position: absolute;
     width: 12px;
     height: 130.98px;
     left: 42px;
     bottom: 1px;
     background: #f9e1ff;
     border-radius: 12px 12px 0px 0px;
 }
 
 .chartbarg2 .childbar1 {
     position: absolute;
     width: 12px;
     height: 122px;
     left: 85px;
     bottom: 1px;
     background: #ddd4ff;
     border-radius: 12px 12px 0px 0px;
 }
 
 .chartbarg2 .childbar2 {
     position: absolute;
     width: 12px;
     height: 94px;
     left: 97px;
     bottom: 1px;
     background: #f9e1ff;
     border-radius: 12px 12px 0px 0px;
 }
 
 .chartbarg3 .childbar1 {
     position: absolute;
     width: 12px;
     height: 212px;
     left: 145px;
     bottom: 1px;
     background: #ddd4ff;
     border-radius: 12px 12px 0px 0px;
 }
 
 .tooltipdashboard:hover {
     background: #8766ff;
 }
 
 .tooltipdashboardhide:hover {
     background: #f98be7;
 }
 
 .chartbarg3 .childbar2 {
     position: absolute;
     width: 12px;
     height: 168px;
     left: 157px;
     bottom: 1px;
     background: #f9e1ff;
     border-radius: 12px 12px 0px 0px;
 }
 
 .chartbarg4 .childbar1 {
     position: absolute;
     width: 12px;
     height: 122px;
     left: 205px;
     bottom: 1px;
     background: #ddd4ff;
     border-radius: 12px 12px 0px 0px;
 }
 
 .chartbarg4 .childbar2 {
     position: absolute;
     width: 12px;
     height: 94px;
     left: 217px;
     bottom: 1px;
     background: #f9e1ff;
     border-radius: 12px 12px 0px 0px;
 }
 
 .chartbarg5 .childbar1 {
     position: absolute;
     width: 12px;
     height: 74px;
     left: 265px;
     bottom: 1px;
     background: #ddd4ff;
     border-radius: 12px 12px 0px 0px;
 }
 
 .chartbarg5 .childbar2 {
     position: absolute;
     width: 12px;
     height: 57px;
     left: 277px;
     bottom: 1px;
     background: #f9e1ff;
     border-radius: 12px 12px 0px 0px;
 }
 
 .chartbarg6 .childbar1 {
     position: absolute;
     width: 12px;
     height: 170px;
     left: 325px;
     bottom: 1px;
     background: #ddd4ff;
     border-radius: 12px 12px 0px 0px;
 }
 
 .chartbarg6 .childbar2 {
     position: absolute;
     width: 12px;
     height: 131.98px;
     left: 337px;
     bottom: 1px;
     background: #f9e1ff;
     border-radius: 12px 12px 0px 0px;
 }
 
 .chartbarg7 .childbar1 {
     position: absolute;
     width: 12px;
     height: 122px;
     left: 385px;
     bottom: 1px;
     background: #ddd4ff;
     border-radius: 12px 12px 0px 0px;
 }
 
 .chartbarg7 .childbar2 {
     position: absolute;
     width: 12px;
     height: 94px;
     left: 397px;
     bottom: 1px;
     background: #f9e1ff;
     border-radius: 12px 12px 0px 0px;
 }
 
 .chartbarg8 .childbar1 {
     position: absolute;
     width: 12px;
     height: 50px;
     left: 445px;
     bottom: 1px;
     background: #ddd4ff;
     border-radius: 12px 12px 0px 0px;
 }
 
 .chartbarg8 .childbar2 {
     position: absolute;
     width: 12px;
     height: 38px;
     left: 456px;
     bottom: 1px;
     background: #f9e1ff;
     border-radius: 12px 12px 0px 0px;
 }
 
 .chartbarg9 .childbar1 {
     position: absolute;
     width: 12px;
     height: 122px;
     left: 500px;
     bottom: 1px;
     background: #ddd4ff;
     border-radius: 12px 12px 0px 0px;
 }
 
 .chartbarg9 .childbar2 {
     position: absolute;
     width: 12px;
     height: 94px;
     left: 512px;
     bottom: 1px;
     background: #f9e1ff;
     border-radius: 12px 12px 0px 0px;
 }
 
 .chartbarg10 .childbar1 {
     position: absolute;
     width: 12px;
     height: 218px;
     left: 555px;
     bottom: 1px;
     background: #ddd4ff;
     border-radius: 12px 12px 0px 0px;
 }
 
 .chartbarg10 .childbar2 {
     position: absolute;
     width: 12px;
     height: 168px;
     left: 567px;
     bottom: 1px;
     background: #f9e1ff;
     border-radius: 12px 12px 0px 0px;
 }
 
 .chartbarg11 .childbar1 {
     position: absolute;
     width: 12px;
     height: 170px;
     left: 620px;
     bottom: 1px;
     background: #ddd4ff;
     border-radius: 12px 12px 0px 0px;
 }
 
 .chartbarg11 .childbar2 {
     position: absolute;
     width: 12px;
     height: 130.98px;
     left: 632px;
     bottom: 1px;
     background: #f9e1ff;
     border-radius: 12px 12px 0px 0px;
 }
 
 .chartbarg12 .childbar1 {
     position: absolute;
     width: 12px;
     height: 194px;
     left: 673px;
     bottom: 1px;
     background: #ddd4ff;
     border-radius: 12px 12px 0px 0px;
 }
 
 .chartbarg12 .childbar2 {
     position: absolute;
     width: 12px;
     height: 150px;
     left: 685px;
     bottom: 1px;
     background: #f9e1ff;
     border-radius: 12px 12px 0px 0px;
 }
 
 .chartbargaudio1 .childbar1 {
     position: absolute;
     width: 12px;
     height: 170px;
     left: 30px;
     bottom: 1px;
     background: #a1e6ff;
     border-radius: 12px 12px 0px 0px;
 }
 
 .chartbargaudio1 .childbar2 {
     position: absolute;
     width: 12px;
     height: 130.98px;
     left: 42px;
     bottom: 1px;
     background: #d4f0ff;
     border-radius: 12px 12px 0px 0px;
 }
 
 .chartbargaudio2 .childbar1 {
     position: absolute;
     width: 12px;
     height: 122px;
     left: 85px;
     bottom: 1px;
     background: #a1e6ff;
     border-radius: 12px 12px 0px 0px;
 }
 
 .chartbargaudio2 .childbar2 {
     position: absolute;
     width: 12px;
     height: 94px;
     left: 97px;
     bottom: 1px;
     background: #d4f0ff;
     border-radius: 12px 12px 0px 0px;
 }
 
 .chartbargaudio3 .childbar1 {
     position: absolute;
     width: 12px;
     height: 212px;
     left: 145px;
     bottom: 1px;
     background: #a1e6ff;
     border-radius: 12px 12px 0px 0px;
 }
 
 .tooltipsunun:hover {
     position: absolute;
     width: 12px;
     height: 212px;
     left: 145px;
     bottom: 1px;
     background: #009cdc;
     border-radius: 12px 12px 0px 0px;
 }
 
 .chartbargaudio3 .childbar2 {
     position: absolute;
     width: 12px;
     height: 168px;
     left: 157px;
     bottom: 1px;
     background: #d4f0ff;
     border-radius: 12px 12px 0px 0px;
 }
 
 .chartbargaudio4 .childbar1 {
     position: absolute;
     width: 12px;
     height: 122px;
     left: 205px;
     bottom: 1px;
     background: #a1e6ff;
     border-radius: 12px 12px 0px 0px;
 }
 
 .chartbargaudio4 .childbar2 {
     position: absolute;
     width: 12px;
     height: 94px;
     left: 217px;
     bottom: 1px;
     background: #d4f0ff;
     border-radius: 12px 12px 0px 0px;
 }
 
 .chartbargaudio5 .childbar1 {
     position: absolute;
     width: 12px;
     height: 74px;
     left: 265px;
     bottom: 1px;
     background: #a1e6ff;
     border-radius: 12px 12px 0px 0px;
 }
 
 .chartbargaudio5 .childbar2 {
     position: absolute;
     width: 12px;
     height: 57px;
     left: 277px;
     bottom: 1px;
     background: #d4f0ff;
     border-radius: 12px 12px 0px 0px;
 }
 
 .chartbargaudio6 .childbar1 {
     position: absolute;
     width: 12px;
     height: 170px;
     left: 325px;
     bottom: 1px;
     background: #a1e6ff;
     border-radius: 12px 12px 0px 0px;
 }
 
 .chartbargaudio6 .childbar2 {
     position: absolute;
     width: 12px;
     height: 131.98px;
     left: 337px;
     bottom: 1px;
     background: #d4f0ff;
     border-radius: 12px 12px 0px 0px;
 }
 
 .tooltipsununhide:hover {
     position: absolute;
     width: 12px;
     height: 131.98px;
     left: 337px;
     bottom: 1px;
     background: #54bde8;
     border-radius: 12px 12px 0px 0px;
 }
 
 .chartbargaudio7 .childbar1 {
     position: absolute;
     width: 12px;
     height: 122px;
     left: 385px;
     bottom: 1px;
     background: #a1e6ff;
     border-radius: 12px 12px 0px 0px;
 }
 
 .chartbargaudio7 .childbar2 {
     position: absolute;
     width: 12px;
     height: 94px;
     left: 397px;
     bottom: 1px;
     background: #d4f0ff;
     border-radius: 12px 12px 0px 0px;
 }
 
 .chartbargaudio8 .childbar1 {
     position: absolute;
     width: 12px;
     height: 50px;
     left: 445px;
     bottom: 1px;
     background: #a1e6ff;
     border-radius: 12px 12px 0px 0px;
 }
 
 .chartbargaudio8 .childbar2 {
     position: absolute;
     width: 12px;
     height: 38px;
     left: 456px;
     bottom: 1px;
     background: #d4f0ff;
     border-radius: 12px 12px 0px 0px;
 }
 
 .chartbargaudio9 .childbar1 {
     position: absolute;
     width: 12px;
     height: 122px;
     left: 500px;
     bottom: 1px;
     background: #a1e6ff;
     border-radius: 12px 12px 0px 0px;
 }
 
 .chartbargaudio9 .childbar2 {
     position: absolute;
     width: 12px;
     height: 94px;
     left: 512px;
     bottom: 1px;
     background: #d4f0ff;
     border-radius: 12px 12px 0px 0px;
 }
 
 .chartbargaudio10 .childbar1 {
     position: absolute;
     width: 12px;
     height: 218px;
     left: 555px;
     bottom: 1px;
     background: #a1e6ff;
     border-radius: 12px 12px 0px 0px;
 }
 
 .chartbargaudio10 .childbar2 {
     position: absolute;
     width: 12px;
     height: 168px;
     left: 567px;
     bottom: 1px;
     background: #d4f0ff;
     border-radius: 12px 12px 0px 0px;
 }
 
 .chartbargaudio11 .childbar1 {
     position: absolute;
     width: 12px;
     height: 170px;
     left: 620px;
     bottom: 1px;
     background: #a1e6ff;
     border-radius: 12px 12px 0px 0px;
 }
 
 .chartbargaudio11 .childbar2 {
     position: absolute;
     width: 12px;
     height: 130.98px;
     left: 632px;
     bottom: 1px;
     background: #d4f0ff;
     border-radius: 12px 12px 0px 0px;
 }
 
 .chartbargaudio12 .childbar1 {
     position: absolute;
     width: 12px;
     height: 194px;
     left: 673px;
     bottom: 1px;
     background: #a1e6ff;
     border-radius: 12px 12px 0px 0px;
 }
 
 .chartbargaudio12 .childbar2 {
     position: absolute;
     width: 12px;
     height: 150px;
     left: 685px;
     bottom: 1px;
     background: #d4f0ff;
     border-radius: 12px 12px 0px 0px;
 }
 
 .sununkotha {
     display: flex;
     justify-content: center;
     align-items: center;
     max-height: 100px;
 }

 .boluniconbtn{
    cursor: pointer;
 }
 
 // ================Chartbar area ==================
 //  ====================================== New Dashboard design start here =============================
 @media only screen and (max-width: 1730px) {
     .topgrapharea {
         min-width: 100%;
         min-height: 383px;
         background: rgba(255, 255, 255, 0.9);
         box-shadow: 2px 6px 2px rgba(191, 191, 191, 0.32);
         border-radius: 16px;
         position: relative;
     }
     .topgrapharea {
         min-width: 100%;
         min-height: 383px;
         background: rgba(255, 255, 255, 0.9);
         box-shadow: 2px 6px 2px rgba(191, 191, 191, 0.32);
         border-radius: 16px;
         position: relative;
        //  overflow-x: scroll;
     }
     span.rightwaveig {
         position: absolute;
         top: 100px;
         left: 0px;
     }
     .inright {
         margin-top: 120px;
     }
 }
 
 @media screen and (max-width: 1600px) {
     .threeplay {
         position: absolute;
         top: 25%;
         left: 14%;
     }
 }
 
 @media only screen and (max-width: 1537px) and (-webkit-min-device-pixel-ratio: 1) {
     .bodybg {
         /* display: flex; */
         display: block;
         // height: 140vh;
         // height: 100vh;
         // height: 107%;
         /* background: url("../img/bg.png") no-repeat center; */
         // background: url("./images/stts/changedashboard.png") no-repeat center;
         background-size: cover;
         // background-position: 100% 65%;
         background-position: 100% 10%;
         flex-wrap: wrap;
         /* width: 100%; */
         width: 100%;
         float: left;
         position: relative;
         /* padding: 137px 0px 0px 0px; */
         justify-content: center;
         align-items: center;
         margin: 0 auto;
         /* overflow: hidden; */
     }
 }
 
 @media screen and (max-width: 1480px) {
     .threeplay {
         position: absolute;
         top: 25%;
         left: 12%;
     }
 }
 
 @media screen and (max-width: 1380px) {
     .threeplay {
         position: absolute;
         top: 25%;
         left: 9%;
     }
 }
 
 @media screen and (max-width: 1366px) {
     .threeplay {
         position: absolute;
         top: 25%;
         left: 8%;
     }
 }
 
 @media screen and (max-width: 1330px) {
     .threeplay {
         position: absolute;
         top: 25%;
         left: 7%;
     }
 }
 
 @media screen and (max-width: 1300px) {
     .threeplay {
         position: absolute;
         top: 25%;
         left: 5%;
     }
 }
 
 @media screen and (max-width: 1285px) {
     .threeplay {
         position: absolute;
         top: 25%;
         left: 5%;
     }
     .threeplay ul {
         display: flex;
         justify-content: space-between;
         min-width: 500px;
     }
 }
 
 @media screen and (max-width: 1280px) {
    .threeplay {
        position: absolute;
        left: 4%;
        top: 25%;
    }
 }
 
 @media screen and (max-width: 1275px) {
     .threeplay {
         position: absolute;
         top: 25%;
         left: 11%;
     }
 }
   
 @media screen and (max-width: 1272px) {
    .threeplay {
        position: absolute;
        top: 25%;
        left: 3%;
    }
    .footerdownload {
        margin-top: 130px;

    }
}

  
@media screen and (max-width: 1245px) {
    .threeplay {
        position: absolute;
        top: 25%;
        left: 3%;
    }
}
 
 @media screen and (max-width: 1272px) {
    .threeplay {
        position: absolute;
        top: 25%;
        left: 3%;
    }
    .footerdownload {
        margin-top: 130px;

    }
}

  
@media screen and (max-width: 1245px) {
    .threeplay {
        position: absolute;
        top: 25%;
        left: 3%;
    }
}
 
 @media screen and (max-width: 1230px) {
    .threeplay {
        position: absolute;
        top: 25%;
        left: 1%;
    }
    .footerdownload {
        margin-top: 130px;
 
    }
 }
 
 @media screen and (max-width: 1200px) {
     .threeplay {
         position: absolute;
         top: 25%;
         left: 0%;
     }
     .threeplay ul {
         display: flex;
         justify-content: space-between;
         min-width: 500px;
     }
 }
 
 @media screen and (max-width: 1165px) {
     .threeplay {
         position: absolute;
         top: 30%;
         left: 0%;
     }
     .threeplay ul {
         display: flex;
         justify-content: space-between;
         min-width: 500px;
     }
 }
 
 @media screen and (max-width: 1080px) {
    .threeplay {
        position: absolute;
        top: 33%;
        left: 0%;
    }
     .threeplay ul {
         display: flex;
         justify-content: space-between;
         min-width: 400px;
     }
 }
 
 @media only screen and (max-width: 1024px) {
     .wavecontent {
         display: flex;
         flex-direction: column;
         width: 100%;
         height: 85%;
         justify-content: space-between;
         /* position: absolute; */
         /* top: 5%; */
         margin-top: 100px;
     }
     .leftwave {
         width: 100%;
         display: flex;
         flex-direction: row;
         text-align: center;
         position: relative;
         justify-content: center;
         align-items: center;
         margin-bottom: 270px;
     }
     .rightwave {
         width: 100%;
         display: flex;
         flex-direction: column;
         text-align: center;
         justify-content: center;
         /* margin: 0 auto; */
         align-items: center;
     }
     .rightwave img {
         width: 810px;
         margin: 286px 0px 40px auto;
         height: auto;
     }
     .wavecontentbolun {
         display: flex;
         flex-direction: column;
         width: 100%;
         height: auto;
         justify-content: space-between;
         /* position: absolute; */
         /* top: 5%; */
     }
     .leftwaveimg {
         width: 100%;
         display: flex;
         flex-direction: column;
         text-align: center;
         position: relative;
         /* margin: 30px 0; */
         margin-bottom: 30px;
         padding-top: 60px;
     }
     .leftwaveimg img {
         width: 810px;
         margin: 0 auto;
         height: auto;
         justify-content: center;
         text-align: center;
     }
     // .bodybg {
     //     height: 105vh;
     // }
     span.rightwaveig {
         position: absolute;
         top: 50px;
         /* left: 0px; */
     }
     li.nav-item>a.nav-link {
         font-size: 15px;
         color: #161a31;
         font-family: "Noto Sans Bengali", sans-serif;
         font-weight: 500;
     }
 }
 
 @media only screen and (max-width: 991px) {
     .obodanitem li {
         margin: 0px 0px;
     }
     .lesstext .container2 {
         display: flex;
         width: 120px;
         margin-left: 0px;
     }
     .signupform {
         display: block;
         float: left;
         width: 100%;
         background-color: transparent;
         border-top: none;
         border-radius: 0;
         padding: 30px 10px;
         margin: 0 auto;
         margin-bottom: 100px;
     }
     .threeplay {
        position: absolute;
        top: 25%;
        left: 4%;
    }
     .rightsidebar {
         display: block;
         float: left;
         width: 100%;
         padding: 20px 30px 0px;
         box-shadow: 0px 3px 6px 0px rgba(0, 0, 0, 0.16);
         border-radius: 4px;
         background: white;
         background: #f6f9ff;
         // margin-top: 140px;
         // margin-bottom: 160px;
     }
     .smtopm {
         margin-top: 160px;
     }
 }

 @media only screen and (max-width: 960px) {

    .threeplay {
        position: absolute;
        top: 25%;
        left: 1%;
    }

 }
 
 @media only screen and (max-width: 901px) {
     .threeplay {
         position: absolute;
         top: 25%;
         left: 15%;
     }
 }
 
 @media only screen and (max-width: 896px) {
    .threeplay {
        position: absolute;
        top: 29%;
        left: 0%;
    }
 }
 
//  @media only screen and (max-width: 891px) {
//     .threeplay {
//         position: absolute;
//         top: 28%;
//         left: -1%;
//     }
//  }
 
//  @media only screen and (max-width: 887px) {
//      .threeplay {
//          position: absolute;
//          top: 25%;
//          left: 15%;
//      }
//  }
 
 @media only screen and (max-width: 880px) {
    .threeplay {
        position: absolute;
        top: 29%;
        left: -1%;
    }
 }
 
 @media only screen and (max-width: 873px) {
     .threeplay {
         position: absolute;
         top: 25%;
         left: 14%;
     }
 }

 
 @media only screen and (max-width: 872px) {
    .threeplay {
        position: absolute;
        top: 30%;
        left: 0%;
    }
}
 
 @media only screen and (max-width: 870px) {
    .threeplay {
        position: absolute;
        top: 30%;
        left: 0%;
    }
 }

//  @media only screen and (max-width: 860px) {
//     .threeplay {
//         position: absolute;
//         top: 30%;
//         left: 0%;
//     }
// }
 
 @media only screen and (max-width: 821px) {
    .threeplay {
        position: absolute;
        top: 33%;
        left: 0%;
    }
 }
 
 @media only screen and (max-width: 820px) {
    .threeplay {
        position: absolute;
        top: 33%;
        left: -1%;
    }
     .breadcrumbtop {
         display: none;
     }
     .signupbradcump {
         display: none;
     }

 }
 
 @media only screen and (max-width: 812px) {
     div#navbarTogglerDemo01 a img {
         width: 100%;
     }
     .threeplay {
        position: absolute;
        top: 34%;
        left: 0%;
    }
 }
 
 @media only screen and (max-width: 800px) {
     div#navbarTogglerDemo01 a img {
         width: 100%;
     }
     .breadcrumbtop {
         margin: 30px 0px;
         display: block;
         float: left;
         width: 100%;
         justify-content: space-between;
     }
     .threeplay {
        position: absolute;
        top: 35%;
        left: 0%;
    }
     .leftbottomgraph {
         margin-top: 180px;
     }
 }
 
//  @media only screen and (max-width: 789px) {
//      .threeplay {
//          position: absolute;
//          top: 25%;
//          left: 7%;
//      }
//  }
 
 @media only screen and (max-width: 768px) {
    .threeplay {
        position: absolute;
        top: 30%;
        left: -1%;
    }
     .threeplay ul li {
         padding: 0px 0px;
     }
     .breadcrumbtop {
         display: none;
     }
     .obodanitem li {
         margin: 0px 10px;
     }
     .shadow-dark2 {
         box-shadow: none;
     }
     .rightsidebar {
         display: block;
         float: left;
         width: 100%;
         padding: 20px 30px 0px;
         box-shadow: 0px 3px 6px 0px rgba(0, 0, 0, 0.16);
         border-radius: 4px;
         background: white;
         background: #f6f9ff;
         // margin-top: 240px;
         // margin-bottom: 160px;
         // margin-top: 180px;
     }
 }
 
//  @media only screen and (max-width: 740px) {
//      .threeplay {
//          position: absolute;
//          top: 25%;
//          left: 10%;
//      }
//  }

@media only screen and (max-width: 705px) {
    .threeplay {
        position: absolute;
        top: 34%;
        left: -1%;
    }
}
 
 @media only screen and (max-width: 680px) {
    .threeplay {
        position: absolute;
        top: 40%;
        left: 0%;
    }
 }
 
 @media only screen and (max-width: 678px) {
     .threeplay {
         position: absolute;
         top: 25%;
         left: 5%;
     }
 }
 
 @media only screen and (max-width: 672px) {
    .threeplay {
        position: absolute;
        top: 40%;
        left: 0%;
    }
 }
 
 @media only screen and (max-width: 656px) {
     span.leftwaveig {
         position: absolute;
         top: 120px;
         left: 80px;
     }
     span.rightwaveig {
         position: absolute;
         top: 100px;
         left: 50px;
         width: 60%;
     }
     .graphtop {
         display: flex;
         flex-direction: row;
         justify-content: center;
         width: 100%;
         flex-wrap: wrap;
         flex-direction: column;
         align-items: center;
         /* padding-right: 22%; */
     }
     .leftwave {
         width: 100%;
         display: flex;
         flex-direction: column;
         text-align: center;
         position: relative;
         justify-content: center;
         align-items: center;
         margin-bottom: 390px;
     }
     .inright {
         margin-top: 25px;
         position: absolute;
         top: 360px;
         width: 100%;
     }
     .topmargin {
         margin-top: 20px;
         margin-left: 80px;
     }
 }
 
 @media only screen and (max-width: 640px) {
     .inright {
         margin-top: 25px;
         position: absolute;
         top: 455px;
         width: 100%;
     }
     .footerdownload {
         margin-left: -17px;
     }
     .threeplay ul li {
         padding: 0px 0px !important;
     }
     li.hali {
         margin-left: -40px;
         margin-top: 25px;
     }
     // Sunun Three Button Start Here
     .threeplay ul {
         display: flex;
         justify-content: space-between;
         min-width: 605px;
         // margin-top: -25px;
     }
     .threeplay ul li {
         padding: 0px 0px;
     }
     .threeplay {
         position: absolute;
         left: 16%;
         top: 14%;
         width: 100%;
     }
     li.hali img {
         width: 80%;
     }
     li.hali {
         margin-left: -40px;
         margin-top: 22px;
     }
     li.topStop {
         position: absolute;
         left: 139px;
         top: 18px;
     }
     li.noaction {
         position: absolute;
         left: 262px;
         top: 22px;
     }
     li.noaction img {
         width: 80%;
     }
     .stopbutton {
         position: absolute;
         left: 10px;
         list-style: none;
         text-decoration: none;
         border: none;
         font-size: 40px;
         color: #136ee5;
     }
     // Sunun Three Button End Here
 }
 
 @media only screen and (max-width: 631px) {
    .threeplay {
        position: absolute;
        left: 11%;
        top: 14%;
        width: 100%;
    }
 }
 
 @media only screen and (max-width: 605px) {
     .threeplay {
         position: absolute;
         left: 10%;
         top: 14%;
     }
     .footer-area {
         display: flex;
         float: left;
         width: 100%;
         padding: 20px 0px;
         justify-content: center;
         min-height: 170px;
         overflow: hidden;
         flex-direction: column;
         margin-top: 15px;
         align-items: center;
     }
 }
 
 @media only screen and (max-width: 600px) {
    .threeplay {
        position: absolute;
        left: 0%;
        top: 24%;
    }
     .inright {
         margin-top: 80px;
         position: absolute;
         top: 560px;
         width: 100%;
     }
     // Sunun Three Button Start Here
     .threeplay ul {
         display: flex;
         justify-content: start;
         min-width: 605px;
         // margin-top: -25px;
     }
     .threeplay ul li {
         padding: 0px 0px;
     }
     li.hali img {
         width: 70%;
     }
     li.hali {
         margin-left: 5px;
         margin-top: 10px;
     }
     li.topStop {
         position: absolute;
         left: 170px;
         top: 0px;
     }
     li.noaction {
         position: absolute;
         left: 275px;
         top: 7px;
         background-color: white;
         border-radius: 50px;
     }
     li.noaction img {
         width: 75%;
     }
     .stopbutton {
         position: absolute;
         left: 10px;
         list-style: none;
         text-decoration: none;
         border: none;
         font-size: 40px;
         color: #136ee5;
     }
     // Sunun Three Button End Here
     .breadcrumbtop {
         margin: 0px 0px;
         display: flex;
         float: left;
         width: 100%;
         justify-content: flex-end;
         // display: none;
     }
     .lesstext .container2 {
         display: flex;
         width: 120px;
         margin-left: 14px;
     }
     .leftbottomgraph {
         margin-top: 0px;
     }
     .rightwave {
         width: 100%;
         display: flex;
         flex-direction: column;
         text-align: center;
         justify-content: center;
         /* margin: 0 auto; */
         align-items: center;
         margin-top: 350px;
     }
     .topmargin {
         margin-top: 20px;
         margin-left: 0px;
     }
 }
 
 @media only screen and (max-width: 576px) {
     .threeplay {
         position: absolute;
         left: 0%;
         top: 25%;
     }
 }
 
 @media only screen and (max-width: 560px) {
     .threeplay {
         position: absolute;
         left: 0%;
         top: 25%;
     }
 }
 
 @media only screen and (max-width: 550px) {
     .threeplay {
         position: absolute;
         left: -3%;
         top: 25%;
     }
     li.noaction {
        background-color: white;
        border-radius: 50px;
         position: absolute;
         left: 275px;
         top: 11px;
     }
 }
 
 @media only screen and (max-width: 540px) {
    .threeplay {
        position: absolute;
        left: 4%;
        top: 30%;
    }
     li.hali {
         margin-left: 5px;
         margin-top: 3px;
     }
     li.topStop {
         position: absolute;
         left: 140px;
         top: 0px;
     }
     li.noaction {
        background-color: white;
        border-radius: 50px;
         position: absolute;
         left: 220px;
         top: 2px;
     }
     li.topStop img {
         width: 80%;
     }
 }
 @media screen and (max-width: 530px) {
    .graphbody {
        position: relative;
        display: flex;
        width: 100%;
        height: auto;
        margin-bottom: 150px;
    }
 }
 
 @media only screen and (max-width: 519px) {
     .threeplay {
         position: absolute;
         left: 1%;
         top: 30%;
     }
 }
 
 @media only screen and (max-width: 514px) {
     .threeplay {
         position: absolute;
         left: 1%;
         top: 30%;
     }
 }
 
 @media only screen and (max-width: 510px) {
     .threeplay {
         position: absolute;
         left: -2%;
         top: 30%;
     }
     .footer-area {
         display: flex;
         float: left;
         width: 100%;
         padding: 20px 0px;
         justify-content: space-between;
         min-height: 170px;
         overflow: hidden;
         flex-direction: column;
         margin-top: 0px;
     }
     .rightsidebar {
         display: block;
         float: left;
         width: 100%;
         padding: 20px 30px 0px;
         box-shadow: 0px 3px 6px 0px rgba(0, 0, 0, 0.16);
         border-radius: 4px;
         background: white;
         background: #f6f9ff;
         // margin-top: 240px;
         margin-bottom: 35px;
     }
 }
 
 @media only screen and (max-width: 480px) {
     .webcontentop {
         padding: 40px 0px 0px !important;
     }
     span.leftwaveig {
         position: absolute;
         top: 120px;
         left: 0px;
     }
     .inright {
         margin-top: 35px;
         position: absolute;
         top: 460px;
         width: 100%;
         left: 0;
     }
     span.rightwaveig {
         position: absolute;
         top: 100px;
         left: 0px;
         width: 100%;
     }
     .wavecontentbolun {
         padding: 0px 0px;
     }
     li.hali {
         margin-left: -40px;
         margin-top: 5px;
     }
     li.topStop {
         position: absolute;
         left: 110px;
         top: 5px;
     }
     li.noaction {
         position: absolute;
         left: 205px;
         top: 4px;
     }
     .threeplay {
         position: absolute;
         left: 9%;
         top: 28%;
     }
     // .rightsidebar {
     //     margin-top: 180px;
     // }
     .startwate {
         margin-top: 65px;
     }
 }
 
 @media only screen and (max-width: 414px) {
     .inright {
         margin-top: 35px;
         position: absolute;
         top: 520px;
         width: 100%;
         left: 0;
     }
     .obodanitem li {
         margin: 0px;
     }
     // Sunun Three Button Start Here
     .threeplay ul {
         display: flex;
         justify-content: start;
         min-width: 605px;
         // margin-top: -25px;
     }
     .threeplay ul li {
         padding: 0px 0px;
     }
     .threeplay {
         position: absolute;
         top: 34px;
         float: left;
         /* width: 100%; */
         display: block;
         left: 30px;
     }
     li.hali img {
         width: 70%;
     }
     li.hali {
         margin-left: -40px;
         margin-top: 8px;
     }
     li.topStop {
         position: absolute;
         left: 98px;
         top: 5px;
     }
     li.noaction {
         position: absolute;
         left: 163px;
         top: 7px;
     }
     li.noaction img {
         width: 75%;
     }
     .stopbutton {
         position: absolute;
         left: 10px;
         list-style: none;
         text-decoration: none;
         border: none;
         font-size: 40px;
         color: #136ee5;
         top: 5;
     }
     // Sunun Three Button End Here
     .startwate {
         width: 100% !important;
         height: auto !important;
         overflow: hidden;
         border: none;
         margin: 0 0px;
         margin-top: 60px;
     }
     button.bottomwave img {
         width: 64px;
         height: auto;
         position: absolute;
         top: 18px;
         left: 135px;
         cursor: pointer;
         background: none;
         opacity: 0;
     }
     .leftbottomgraph {
         margin-top: 0px;
     }
     .rightwave {
         margin-top: 200px;
     }
     .leftwaveimg {
         padding-top: 20px;
     }
     .rightsidebar {
         display: block;
         float: left;
         width: 100%;
         padding: 20px 30px 0px;
         box-shadow: 0px 3px 6px 0px rgba(0, 0, 0, 0.16);
         border-radius: 4px;
         background: white;
         background: #f6f9ff;
         // margin-top: 150px;
         margin-bottom: 35px;
     }
 }
 
 @media only screen and (max-width: 412px) {
     .webcontentop {
         padding: 40px 0px 0px !important;
     }
     span.leftwaveig {
         position: absolute;
         top: 120px;
         left: 0px;
     }
     .inright {
         margin-top: 145px;
         position: absolute;
         top: 440px;
         width: 100%;
         left: 0;
     }
     span.rightwaveig {
         position: absolute;
         top: 100px;
         left: 0px;
         width: 100%;
     }
     .wavecontentbolun {
         padding: 0px 0px;
     }
     .leftbottomgraph {
         margin-top: 0px;
     }
     .leftwaveimg {
         padding-top: 30px;
     }
     // Sunun Three Button Start Here
     .threeplay ul {
         display: flex;
         justify-content: start;
         min-width: 605px;
         // margin-top: -25px;
     }
     .threeplay ul li {
         padding: 0px 0px;
     }
     .threeplay {
         position: absolute;
         top: 25px;
         float: left;
         /* width: 100%; */
         display: block;
     }
     li.hali img {
         width: 70%;
     }
     li.hali {
         margin-left: -30px;
         margin-top: 17px;
     }
     li.topStop {
         position: absolute;
         left: 98px;
         top: 15px;
     }
     li.noaction {
         position: absolute;
         left: 162px;
         top: 14px;
     }
     li.noaction img {
         width: 75%;
     }
     .stopbutton {
         position: absolute;
         left: 10px;
         list-style: none;
         text-decoration: none;
         border: none;
         font-size: 40px;
         color: #136ee5;
     }
     // Sunun Three Button End Here
     .startwate {
         width: 100% !important;
         height: auto !important;
         overflow: hidden;
         border: none;
         margin-top: 75px;
     }
     .bodybgmicrophone {
         height: 120vh;
         background-size: cover;
         background-position: 100% 65%;
         flex-wrap: wrap;
         /* width: 100%; */
         width: 100%;
         float: left;
         position: relative;
         /* padding: 137px 0px 0px 0px; */
         justify-content: center;
         align-items: center;
         margin: 0 auto;
         overflow-y: scroll;
     }
 }
 
 @media only screen and (max-width: 393px) {
     .inright {
         margin-top: 35px;
         position: absolute;
         top: 420px;
         width: 100%;
         left: 0;
     }
     li.topStop {
         position: absolute;
         left: 85px;
         top: 5px;
     }
     li.hali {
         margin-left: -70px;
         margin-top: 6px;
     }
     li.noaction {
         position: absolute;
         left: 180px;
         top: 5px;
     }
     li.topStop {
         position: absolute;
         left: 86px;
         top: 15px;
     }
     li.hali {
         margin-left: -42px;
         margin-top: 20px;
     }
     li.noaction {
         position: absolute;
         left: 155px;
         top: 18px;
     }
     .startwate {
         width: 100% !important;
         height: auto !important;
         overflow: hidden;
         border: none;
         margin-top: 60px;
     }
     .stop {
         margin-top: 70px;
     }
 }
 
 @media only screen and (max-width: 390px) {
     li.topStop {
         position: absolute;
         left: 86px;
         top: 15px;
     }
     li.hali {
         margin-left: -42px;
         margin-top: 20px;
     }
     li.noaction {
         position: absolute;
         left: 155px;
         top: 18px;
     }
 }
 
 @media only screen and (max-width: 384px) {
     .webcontentop {
         padding: 40px 0px 0px !important;
     }
     span.leftwaveig {
         position: absolute;
         top: 120px;
         left: 0px;
     }
     .inright {
         margin-top: 35px;
         position: absolute;
         top: 500px;
         width: 100%;
         left: 0;
     }
     span.rightwaveig {
         position: absolute;
         top: 100px;
         left: 0px;
         width: 100%;
     }
     .wavecontentbolun {
         padding: 0px 0px;
     }
     // Sunun Three Button Start Here
     .footerdownload {
         margin-left: 60px;
         margin-left: 0px;
         width: 90%;
     }
     .threeplay ul {
         display: flex;
         justify-content: start;
         min-width: 605px;
         // margin-top: -25px;
     }
     .threeplay ul li {
         padding: 0px 0px !important;
     }
     .threeplay {
         position: absolute;
         top: 42px;
         float: left;
         /* width: 100%; */
         display: block;
         left: 34px;
     }
     li.hali img {
         width: 70%;
     }
     li.hali {
         margin-left: -45px;
         margin-top: 3px;
     }
     li.topStop {
         position: absolute;
         left: 83px;
         top: -2px;
     }
     li.topStop img {
         width: 85%;
     }
     li.noaction {
         position: absolute;
         left: 147px;
         top: 1px;
     }
     li.noaction img {
         width: 75%;
     }
     .stopbutton {
         position: absolute;
         top: 10px;
         left: 10px;
         list-style: none;
         text-decoration: none;
         border: none;
         font-size: 40px;
         color: #136ee5;
     }
     // Sunun Three Button End Here
     .startwate {
         width: 100% !important;
         height: auto !important;
         overflow: hidden;
         border: none;
         margin: 0 0px;
         background-position: center;
     }
     .footer-area {
         display: flex;
         float: left;
         width: 100%;
         padding: 20px 0px;
         justify-content: space-between;
         min-height: 170px;
         overflow: hidden;
         flex-direction: column;
         margin-top: 135px;
     }
     .topmargin {
         margin-top: 20px;
         margin-left: 0px;
     }
 }
 
 @media only screen and (max-width: 375px) {
     ul.obodanitem {
         display: flex;
         flex-direction: row;
         justify-content: space-between;
         width: 100%;
         /* font-size: 4px; */
     }
     .obodanitem li {
         margin: 0px;
     }
     .bodytext {
         background-color: #ffffff;
         padding: 0px;
         border-radius: 5px;
         position: relative;
         max-height: 460px;
         max-width: 100%;
         box-shadow: 0px 2px 24px 0px rgba(0, 0, 0, 0.24);
         -webkit-box-shadow: 0px 2px 24px 0px rgba(0, 0, 0, 0.24);
         -moz-box-shadow: 0px 2px 24px 0px rgba(0, 0, 0, 0.24);
         margin-bottom: 0px !important;
     }
     .footerdownload {
         margin-top: 0px;
     }
     .threeplay {
         position: absolute;
         top: 42px;
         float: left;
         /* width: 100%; */
         display: block;
         left: 46px;
     }
     .msinglecardlast {
         margin-bottom: 50px;
     }
     .stop {
         margin-top: 30px;
     }
 }
 
 @media only screen and (max-width: 360px) {
     .topmargin {
         margin-top: 20px;
         margin-left: 0px;
     }
     .webcontentop {
         padding: 40px 0px 0px !important;
     }
     span.leftwaveig {
         position: absolute;
         top: 120px;
         left: 0px;
     }
     .inright {
         margin-top: 35px;
         position: absolute;
         top: 30%;
         width: 100%;
         left: 0;
     }
     span.rightwaveig {
         position: absolute;
         top: 100px;
         left: 0px;
         width: 100%;
     }
     .wavecontentbolun {
         padding: 0px 0px;
     }
     // Sunun Three Button Start Here
     .footerdownload {
         margin-left: 60px;
         margin-left: 0px;
         width: 90%;
     }
     .threeplay ul {
         display: flex;
         justify-content: start;
         min-width: 605px;
         // margin-top: -25px;
     }
     .threeplay ul li {
         padding: 0px 0px !important;
     }
     .threeplay {
         position: absolute;
         top: 37px;
         float: left;
         /* width: 100%; */
         display: block;
         left: 50px;
     }
     li.hali img {
         width: 70%;
     }
     li.hali {
         margin-left: -67px;
         margin-top: 3px;
     }
     li.topStop {
         position: absolute;
         left: 71px;
         top: 0px;
     }
     li.topStop img {
         width: 85%;
     }
     li.noaction {
         position: absolute;
         left: 140px;
         top: 2px;
     }
     li.noaction img {
         width: 75%;
     }
     .stopbutton {
         position: absolute;
         top: 10px;
         left: 10px;
         list-style: none;
         text-decoration: none;
         border: none;
         font-size: 40px;
         color: #136ee5;
     }
     // Sunun Three Button End Here
     .startwate {
         width: 100% !important;
         height: auto !important;
         overflow: hidden;
         border: none;
         margin: 0 0px;
     }
     button.bottomwave img {
         width: 64px;
         height: auto;
         position: absolute;
         top: 18px;
         left: 117px;
         cursor: pointer;
         background: none;
         opacity: 0;
     }
     // .stop {
     //     min-height: 132px;
     //     margin: 0 0px;
     // }
     .bodytext {
         background-color: #ffffff;
         padding: 0px;
         border-radius: 5px;
         position: relative;
         max-height: 460px;
         max-width: 100%;
         box-shadow: 0px 2px 24px 0px rgba(0, 0, 0, 0.24);
         -webkit-box-shadow: 0px 2px 24px 0px rgba(0, 0, 0, 0.24);
         -moz-box-shadow: 0px 2px 24px 0px rgba(0, 0, 0, 0.24);
         margin-bottom: 0px !important;
     }
     .footer-area {
         display: flex;
         float: left;
         width: 100%;
         padding: 20px 0px;
         justify-content: space-between;
         min-height: 170px;
         overflow: hidden;
         flex-direction: column;
         margin-top: 140px;
     }
 }
 
 @media only screen and (max-width: 353px) {
     .stop {
         margin-top: 25px;
     }
 }
 
 @media only screen and (max-width: 320px) {
     .obodanitem li {
         margin: 0px;
     }
     li.topStop {
         position: absolute;
         left: 53px;
         top: 0px;
     }
     li.noaction {
         position: absolute;
         left: 114px;
         top: -2px;
     }
     li.hali {
         margin-left: -72px;
         margin-top: 0px;
     }
     li.topStop img {
         width: 70%;
     }
 }