.footertext {
  display: flex;
}

ul.brandlogo {
  display: flex;
}

ul.brandlogo li {
  list-style: none;
  margin: 0px 10px;
}
ul.brandlogo li a {
  text-decoration: none;
}

.footerarea {
  display: flex;
  width: 100%;
  justify-content: space-between;
  background-color: #ffffff;
  position: fixed;
  bottom: 0;
  padding: 20px 0px;
  /* max-width: 1920px; */
  align-items: center;
}

.footertext p {
  margin-right: 20px;
}

.footerleft {
  margin-left: 40px;
}

.footerright {
  margin-right: 40px;
}