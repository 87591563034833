@import url("https://fonts.googleapis.com/css2?family=Noto+Sans+Bengali:wght@100;400;500;600;700;800;900&display=swap");

* {
  margin: 0px;
  padding: 0px;
}

.navbg {
  width: 100%;
  background-color: #ffffff;
  padding: 15px 0px;
  justify-content: center;
  align-items: center;
  display: flex;
  /* margin-bottom: 100px; */
}
.withlogomenu {
  display: flex;
  width: 70%;
  justify-content: space-between;
}

.topnavbar {
  max-width: 1500px;
  margin: 0 auto;
  display: flex;
  width: 100%;
  justify-content: space-between;
}

.midmenuarea {
  display: flex;
  justify-content: center;
  align-items: center;
}

.midmenuarea .nav ul {
  display: flex;
  justify-content: space-around;
  align-items: center;
  width: 100%;
  margin-bottom: 0px;
}

.midmenuarea .nav ul li {
  list-style: none;
  padding: 0px 20px;
}

.midmenuarea .nav ul li a.active {
  color: #059514;
  cursor: pointer;
}

.midmenuarea .nav ul li a {
  text-decoration: none;
  font-family: Noto Sans Bengali;
  font-size: 18px;
}

.rightmenu {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  width: 30%;
}

.loginregistration ul {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  margin-bottom: 0px;
}

.loginregistration ul li {
  list-style: none;
  padding: 0px 20px;
}
.loginregistration ul li a {
  text-decoration: none;
  font-family: Noto Sans Bengali;
  font-size: 18px;
  font-weight: 700;
}

li a.probeshkorun {
  color: #136ee5 !important;
  border: 1px solid #c9ddf8;
  border-radius: 5px;
  padding: 10px;
}

li a.nibondonkorun {
  color: #44af4f !important;
}
.portfolioarea {
  display: flex;
  justify-content: center;
  align-items: center;
}

.portfolioarea p {
  justify-content: center;
  align-items: center;
  display: flex;
  margin-bottom: 0px;
  font-size: 18px;
  color: #136ee5;
  font-family: "Noto Sans Bengali";
  font-weight: 500;
}
.profileselect {
  margin-left: 20px;
}

.headerprofile {
  margin: 0px 5px;
  cursor: pointer;
}

.loginregistration {
  display: flex;
}

.ongshonin {
  text-decoration: none;
  font-family: Noto Sans Bengali;
  font-size: 18px;
  color: #136ee5;
  border: none;
  background: transparent;
}