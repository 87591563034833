///* -------------------------------------------
//
//Name: 		Standalone
//Version:    1.0
//Author:		Rabiul
//Portfolio:  https://themeforest.net/user/alam_robi
//
//p.s. I am available for Freelance hire (UI design, web development). mail: miller.themes@gmail.com
//
// ----------------------------------------------*/
//
//@import url("https://fonts.googleapis.com/css2?family=Hind+Siliguri:wght@300;400;500;600;700&display=swap");
//@import url("https://fonts.maateen.me/solaiman-lipi/font.css");
//@import url("https://fonts.googleapis.com/css2?family=Noto+Sans+Bengali:wght@100;400;500;600;700;800;900&display=swap");
//@import url("https://fonts.maateen.me/solaiman-lipi/font.css");
//
///* @import url('https://fonts.googleapis.com/css2?family=Hind+Siliguri:wght@300;400;500;600;700&display=swap'); */
//
//
///*
//1. General CSS
//
//font-family: 'Hind Siliguri', sans-serif;
//font-family: 'SolaimanLipi', Arial, sans-serif !important;
//font-family: 'Noto Sans Bengali', sans-serif;
//
//*/
//
//
///* ============================================
//1. General CSS
//=============================================== */
//
//:root {
//    --main-color: #e98b2c;
//    --dark: #1b1b22;
//    --dark-light: #32323a;
//    --white: #fff;
//    --white-light: #c8c8c8;
//}
//
//.middletext {
//    justify-content: center;
//    align-items: center;
//    display: flex;
//    min-height: 350px;
//    font-size: 30px;
//}
//
//.middletext>p {
//    font-size: 30px !important;
//}
//
//.smdeviceview {
//    max-width: 1400px;
//}
//
//* {
//    margin: 0;
//    padding: 0;
//    outline: none;
//    -webkit-box-sizing: border-box;
//    box-sizing: border-box;
//    text-decoration: none;
//    list-style: none;
//}
//
//::before,
//::after {
//    box-sizing: border-box;
//}
//
//
///* img{
//      width: 100%;
//  } */
//
//body {
//    margin: 0;
//    line-height: 1.5;
//    font-size: 16px;
//    height: auto;
//    font-family: "Hind Siliguri", sans-serif;
//}
//
//
///* For scripting */
//
//body.hide-scrolling {
//    overflow-y: hidden;
//}
//
//ul {
//    list-style-type: none;
//}
//
//a {
//    text-decoration: none;
//}
//
//table {
//    border-collapse: collapse;
//    border-spacing: 0;
//}
//
//.section {
//    min-height: 100vh;
//    display: block;
//    width: 100%;
//}
//
//.shadow-dark2 {
//    -webkit-box-shadow: 0 0 20px rgba(48, 46, 77, 0.15);
//    box-shadow: 0 0 20px rgba(48, 46, 77, 0.15);
//}
//
//.shadow-dark {
//    /*
//      -webkit-box-shadow: 0 0 20px rgba(48,46,77,0.15);
//      box-shadow: 0 0 20px rgba(48,46,77,0.15); */
//    /* -webkit-box-shadow: 0 0 20px rgba(0,0,0,0.15);
//      box-shadow: 0 0 20px rgba(0,0,0,0.15); */
//    /*
//      box-shadow: 2px -4px 8px -3px rgba(0,0,0,0.75);
//      -webkit-box-shadow: 2px -4px 8px -3px rgba(0,0,0,0.75);
//      -moz-box-shadow: 2px -4px 8px -3px rgba(0,0,0,0.75); */
//    /* box-shadow: -2px -1px 24px -16px rgba(0,0,0,0.72) inset;
//      -webkit-box-shadow: -2px -1px 24px -16px rgba(0,0,0,0.72) inset;
//      -moz-box-shadow: -2px -1px 24px -16px rgba(0,0,0,0.72) inset; */
//    box-shadow: -2px -1px 25px -16px rgba(0, 0, 0, 0.72) inset;
//    -webkit-box-shadow: -2px -1px 25px -16px rgba(0, 0, 0, 0.72) inset;
//    -moz-box-shadow: -2px -1px 25px -16px rgba(0, 0, 0, 0.72) inset;
//}
//
//.rightshadow {
//    box-shadow: 2px 0px 5px 2px rgba(0, 0, 0, 0.09);
//    -webkit-box-shadow: 2px 0px 5px 2px rgba(0, 0, 0, 0.09);
//    -moz-box-shadow: 2px 0px 5px 2px rgba(0, 0, 0, 0.09);
//}
//
//.margin-bottom {
//    /* margin-bottom: 60px; */
//    display: block;
//    float: right;
//    width: 100%;
//    overflow: hidden;
//}
//
//
///* ----------------------- Content Design stat Here ------------------------ */
//
//.allcontent {
//    width: 100%;
//    float: left;
//    /* background-color: #f6f9ff; */
//    /* margin-top: 5%; */
//}
//
//.content-body {
//    background-color: #ffffff;
//    /* min-height: 83vh; */
//    max-height: 800px;
//    margin-top: 60px;
//    display: block;
//    /* float: left; */
//    width: 100%;
//    overflow: hidden;
//    position: relative;
//}
//
//.header-area {
//    width: 100%;
//    display: block;
//    float: left;
//    /* padding-bottom: 5px; */
//}
//
//.header-left {
//    display: flex;
//    flex-wrap: wrap;
//    justify-content: left;
//}
//
//.header-right {
//    float: right;
//}
//
//.header-left img {
//    float: left;
//    width: 28px;
//    height: 21px;
//    margin: 7px 9px 0px 20px;
//}
//
//.header-left h4 {
//    font-size: 20px;
//    font-family: "Hind Siliguri", sans-serif;
//}
//
//.header-left h4 {
//    font-size: 20px;
//    font-family: "Hind Siliguri", sans-serif;
//    font-weight: 600;
//    margin-bottom: 0px;
//    line-height: 40px;
//    color: #44c1ea;
//}
//
//.breadcrumbtop {
//    //margin-top: 90px;
//    display: block;
//    float: left;
//    width: 100%;
//    padding-top: 30px;
//}
//
//.jachybradcamp {
//    //margin-top: 90px;
//    display: block;
//    float: left;
//    width: 100%;
//    padding-top: 30px;
//}
//
//.signupbradcump {
//    margin-top: 70px;
//    display: block;
//    float: left;
//    width: 100%;
//}
//
//.lefttext p {
//    font-size: 26px;
//    color: #171717;
//    font-family: "SolaimanLipi", Arial, sans-serif !important;
//    font-weight: 500;
//    line-height: 40px;
//    /* align-items: flex-end; */
//}
//
//span.amer {
//    color: #059514;
//    font-weight: 700;
//    font-family: "Noto Sans Bengali", sans-serif;
//}
//
//span.kontho {
//    color: #ec411b;
//    font-weight: 700;
//    font-family: "Noto Sans Bengali", sans-serif;
//}
//
//button.noborder {
//    border: none;
//}
//
//.noborder:focus {
//    box-shadow: none;
//}
//
//
///* --------------------------------------- main-content -------------------------- */
//
//.main-content {
//    display: block;
//    float: left;
//    width: 100%;
//    /* height: 100vh; */
//    /* overflow: hidden; */
//}
//
//
///* .editor-icon {
//  display: block;
//  float: left;
//  width: 100%;
//} */
//
//.editor-icon {
//    /* display: flex;
//    float: left; */
//    /* width: 100%; */
//    /* padding: 2px 23px 0px 0px; */
//    /* position: relative; */
//    /* background-color: #136EE5; */
//    /* margin-left: 20px; */
//    /* margin-right: 0px; */
//    /* margin-top: 20px; */
//    /* justify-content: space-between;
//    position: absolute; */
//}
//
//.editor-icon {
//    display: flex;
//    width: 100%;
//    float: left;
//    /* background: red; */
//    justify-content: space-between;
//    // max-height: 115px;
//    border: none !important;
//}
//
//.iconvoice {
//    position: absolute;
//    right: 0px;
//    top: -63px;
//}
//
//p.specific-icon a img {
//    display: block;
//    float: left;
//    /* width: 20px;
//      height: 20px; */
//}
//
//.editor-icon ul {
//    display: flex;
//    // justify-content: space-between;
//    margin-bottom: 0px;
//    // justify-content: left;
//    position: relative;
//    padding-left: 0px;
//    margin-left: -40px;
//}
//
//.editor-icon ul li {
//    /* padding: 10px 14px; */
//    // padding: 0px 14px 0px 0px;
//    /* line-height: 147px; */
//    position: relative;
//    // display: inline-block;
//    // float: left;
//}
//
//li.speakerbg {
//    // position: absolute;
//    // top: -16px;
//}
//
//.ascii_unicode {
//    background-color: #ffffff;
//    padding: 10px;
//    height: 45px;
//    padding-top: 5px;
//    border-radius: 5px;
//    margin-right: 10px;
//    /* margin-top: 5px; */
//    padding: 10px 20px !important;
//    margin-left: 15px;
//}
//
//
///* span.ascii {
//  background-color: #136EE5;
//  border-radius: 3px;
//  color: #adb1bf;
//} */
//
//span.ascii a {
//    text-decoration: none;
//    color: #fff;
//    font-family: "Hind Siliguri", sans-serif;
//    font-size: 15px;
//    padding: 0px 10px;
//}
//
//span.unicode a {
//    text-decoration: none;
//    color: #adb1bf;
//    font-family: "Hind Siliguri", sans-serif;
//    padding-left: 5px;
//    padding-right: 5px;
//}
//
//li.linebar {
//    background-color: #ffffff;
//    padding: 10px;
//    height: 30px;
//    padding-top: 5px;
//    border-radius: 5px;
//    padding: 10px 3px !important;
//}
//
//span.inlinebar {
//    border-radius: 2px;
//}
//
//span.inlinebar a {
//    text-decoration: none;
//    color: #adb1bf;
//    font-family: "Hind Siliguri", sans-serif;
//    font-size: 15px;
//    padding: 0px 10px;
//    padding-left: 2px;
//    text-transform: uppercase;
//}
//
//span.barside {
//    border-radius: 3px;
//    -webkit-border-radius: 3px;
//    -moz-border-radius: 3px;
//    -ms-border-radius: 3px;
//    -o-border-radius: 3px;
//}
//
//span.barside a {
//    text-decoration: none;
//    color: #fff;
//    font-family: "Hind Siliguri", sans-serif;
//    background-color: #136ee5;
//    border-radius: 3px;
//    padding: 0px 5px;
//    text-transform: uppercase;
//}
//
//span.barside2 {
//    border-radius: 3px;
//    -webkit-border-radius: 3px;
//    -moz-border-radius: 3px;
//    -ms-border-radius: 3px;
//    -o-border-radius: 3px;
//    text-transform: uppercase;
//}
//
//span.barside2 a {
//    text-decoration: none;
//    color: #adb1bf;
//    font-family: "Hind Siliguri", sans-serif;
//    /* background-color: #44C1EA; */
//    border-radius: 3px;
//    padding: 0px 5px;
//    text-transform: uppercase;
//}
//
//.withsidebar-content {
//    /* background-color: #f6f9ff; */
//    background-color: rgba(246, 249, 255, 0.2);
//    float: right;
//    width: 100%;
//    min-height: 55vh;
//    display: flex;
//    /* overflow-x: hidden; */
//    position: relative;
//    flex-wrap: wrap;
//    justify-content: flex-end;
//    /* overflow: hidden; */
//    /* padding: 30px 30px 0px; */
//}
//
//
///*================================= Sidebar area ================================ */
//
//.rightsidebar {
//    display: block;
//    float: left;
//    width: 100%;
//    padding: 20px 30px 0px;
//    -webkit-box-shadow: 0px 3px 6px 0px rgba(0, 0, 0, 0.16);
//    box-shadow: 0px 3px 6px 0px rgba(0, 0, 0, 0.16);
//    border-radius: 4px;
//    background: rgb(255, 255, 255);
//    background: #f6f9ff;
//    margin-bottom: 60px;
//}
//
//.msinglecard p {
//    color: #171717;
//    font-family: SolaimanLipi;
//    font-size: 16px;
//}
//
//
///* .tab-container-one {
//  margin-top: 40px;
//} */
//
//
///*================================= Sidebar area ================================ */
//
//.main-text-content {
//    width: 100%;
//    /* width: 70%; */
//    display: block;
//    float: left;
//    transition: all 0.3s ease-in;
//    -webkit-transition: all 0.3s ease-in;
//    -moz-transition: all 0.3s ease-in;
//    -ms-transition: all 0.3s ease-in;
//    -o-transition: all 0.3s ease-in;
//    /* margin: 0px 20px 0px 20px; */
//    background-color: rgba(255, 255, 255, 0.2);
//    overflow-x: hidden;
//    max-height: 350px;
//    // overflow-y: scroll;
//    /* position: relative; */
//    border-radius: 5px;
//    /* padding: 30px; */
//    background: url("./images/stts/miketest.png") no-repeat center center;
//}
//
//.main-text-content p {
//    padding: 15px 0px 0px 10px;
//    font-size: 18px;
//    font-family: "SolaimanLipi", Arial, sans-serif !important;
//    color: #303030;
//    overflow: hidden;
//    text-align: center;
//}
//
//span.textborder {
//    border-bottom: 2px solid #e88992;
//}
//
//span.textborder2 {
//    border-bottom: 2px solid #9f71ff;
//}
//
//span.textborder3 {
//    border-bottom: 2px solid #7ed1c5;
//}
//
//span.textborder3 {
//    border-bottom: 2px solid #7ed1c5;
//}
//
//span.textborder4 {
//    border-bottom: 2px solid #c8c85f;
//}
//
//.right-sidebar {
//    position: absolute;
//    top: 0;
//    right: 0px;
//    /* width: 30%; */
//    width: 0%;
//    /* height: 85vh; */
//    background: #fff;
//    padding: 0px 0px 0px 0px;
//    display: block;
//    float: left;
//    overflow: hidden;
//    max-height: 450px;
//    overflow-y: auto;
//}
//
//
///* ul.rightsidebarbook {
//    overflow-y: scroll;
//    overflow-x: hidden;
//    overflow: auto;
//    min-height: 500px;
//    display: block;
//    float: left;
//    width: 100%;
//    padding-left: 10px;
//} */
//
//ul.rightsidebarbook {
//    display: block;
//    float: left;
//    width: 100%;
//    padding-left: 10px;
//}
//
//#boxscroll::-webkit-scrollbar-track {
//    -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
//    background-color: #f5f5f5;
//}
//
//#boxscroll::-webkit-scrollbar {
//    width: 6px;
//    background-color: #f5f5f5;
//}
//
//#boxscroll::-webkit-scrollbar-thumb {
//    background-color: #c0c3c7;
//}
//
//#boxscroll2::-webkit-scrollbar-track {
//    -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
//    background-color: #f5f5f5;
//}
//
//#boxscroll2::-webkit-scrollbar {
//    width: 6px;
//    background-color: #f5f5f5;
//}
//
//#boxscroll2::-webkit-scrollbar-thumb {
//    background-color: #c0c3c7;
//}
//
//#check:checked~.container .row .withsidebar-content .right-sidebar {
//    right: 0;
//    width: 30%;
//    transition: all 0.3s ease-in;
//    -webkit-transition: all 0.3s ease-in;
//    -moz-transition: all 0.3s ease-in;
//    -ms-transition: all 0.3s ease-in;
//    -o-transition: all 0.3s ease-in;
//}
//
//#check:checked~.container .row .withsidebar-content .main-text-content {
//    width: 67%;
//    transition: all 0.3s ease-in;
//    -webkit-transition: all 0.3s ease-in;
//    -moz-transition: all 0.3s ease-in;
//    -ms-transition: all 0.3s ease-in;
//    -o-transition: all 0.3s ease-in;
//}
//
//#check:checked~.container .row .headertoptext {
//    display: block;
//}
//
//#sidebar_btn2 {
//    cursor: pointer;
//}
//
//.headertoptext {
//    display: none;
//}
//
//#check {
//    display: none;
//}
//
//ul.rightsidebarbook li a span.rightarrow img {
//    width: 5px;
//    height: 8px;
//}
//
//ul.rightsidebarbook li a span.drop_arrow img {
//    width: 6px;
//    height: 3px;
//}
//
//ul.rightsidebarbook li a span.crowsbook img {
//    width: 14px;
//    height: 34px;
//}
//
//.errorcolor img {
//    width: 10px;
//    height: 10px;
//}
//
//.errorcolor .iconimg {
//    width: 12px;
//    height: 12px;
//    background: red;
//    margin: 5px 5px 0px 0px;
//    box-shadow: inset 0px 0px 0px 3px white;
//    border: 1px solid #fff;
//    float: left;
//    border-radius: 100%;
//    text-align: left;
//    float: left;
//}
//
//ul.rightsidebarbook li {
//    margin-bottom: 10px;
//    margin-top: 10px;
//    padding: 15px 20px;
//    background-color: #fff;
//    border-radius: 4px;
//    -webkit-border-radius: 5px;
//    -moz-border-radius: 4px;
//    -ms-border-radius: 4px;
//    -o-border-radius: 4px;
//    margin-right: 20px;
//    min-height: 60px;
//}
//
//ul.rightsidebarbook .firstli {
//    margin-bottom: 10px;
//    margin-top: 10px;
//    padding: 15px 20px;
//    background-color: #fff;
//    border-radius: 4px;
//    -webkit-border-radius: 5px;
//    -moz-border-radius: 4px;
//    -ms-border-radius: 4px;
//    -o-border-radius: 4px;
//    margin-right: 20px;
//    min-height: 150px;
//}
//
//span.firstleft {
//    padding: 0px 30px 0px 0px;
//}
//
//span.firstleft img {
//    margin-left: 20px;
//}
//
//span.chrowtext {
//    color: #1a1818;
//    text-decoration: line-through;
//}
//
//span.firstleft {
//    padding: 0px 15px 0px 0px;
//    color: red;
//    text-decoration: line-through;
//    display: block;
//    float: left;
//}
//
//a.withbutton {
//    display: block;
//    float: left;
//    width: 55%;
//}
//
//span.secondleft {
//    margin-right: 108px;
//}
//
//button.btn.btnbesorkari {
//    background-color: #009cdc;
//    color: #fff;
//    border-radius: 5px;
//    font-size: 13px;
//    padding: 2px 15px;
//    line-height: 16px;
//}
//
//button.btn.btnbesorkari2 {
//    background-color: #44c1ea;
//    color: #fff;
//    border-radius: 5px;
//    font-size: 13px;
//    padding: 2px 15px;
//    line-height: 16px;
//    margin: 5px 0px;
//}
//
//ul.rightsidebarbook li a {
//    text-decoration: none;
//    font-family: "SolaimanLipi", Arial, sans-serif !important;
//    font-size: 13px;
//    float: left;
//    text-align: left;
//}
//
//.rightsidebarbook hr {
//    margin-top: 75px;
//    margin-bottom: 15px;
//    border: 0;
//    border-top: 1px solid rgba(0, 0, 0, 0.1);
//}
//
//hr.topbottomargin {
//    margin-top: 20px;
//    margin-bottom: 0px;
//    border: 0;
//    border-top: 1px solid rgba(0, 0, 0, 0.1);
//}
//
//a.errorcolor {
//    display: block;
//}
//
//span.icontent {
//    color: #6e6e6e;
//    font-size: 13px;
//}
//
//ul.dropdownmenu {
//    display: block;
//    float: left;
//    width: 100%;
//}
//
//ul.dropdownmenu li {
//    display: inline-block;
//    width: 100%;
//}
//
//p.totalerror {
//    position: absolute;
//    right: 155px;
//    top: 45px;
//    font-size: 20px;
//    font-family: "Hind Siliguri", sans-serif;
//    color: #44c1ea;
//    font-weight: 500;
//}
//
//span.count {
//    border: 1px solid #44c1ea;
//    padding: 3px 10px;
//    font-size: 13px;
//    line-height: 10px;
//    border-radius: 5px;
//    font-weight: 700;
//    /* margin-top: -18px; */
//    display: block;
//    float: left;
//    margin-top: 6px;
//    margin-left: 5px;
//}
//
//span.counttext {
//    margin-top: 0px !important;
//    display: block;
//    float: left;
//}
//
//.textthrow {
//    color: red;
//    text-decoration: line-through;
//}
//
//
///* ======================== Dropd down menu ============================ */
//
//.parentsetting {
//    position: relative;
//}
//
//.click {
//    font-size: 10px;
//    font-family: inherit;
//    border: none;
//    outline: none;
//    width: 300px;
//    color: #556170;
//    transition: 0.3s;
//    position: relative;
//}
//
//.check2 {
//    /* padding: 12px; */
//    font-size: 10px;
//    font-family: inherit;
//    border: none;
//    outline: none;
//    width: 300px;
//    color: #556170;
//    transition: 0.3s;
//    text-decoration: none;
//    position: relative;
//}
//
//.links {
//    padding: 12px 0px;
//    font-size: 13px;
//    font-family: "Hind Siliguri", sans-serif;
//    border: none;
//    outline: none;
//    width: 185px;
//    color: #fff;
//    transition: 0.3s;
//    background-color: #fff;
//}
//
//.list {
//    position: absolute;
//    transform: scaleY(0);
//    transform-origin: top;
//    transition: 0.3s;
//}
//
//.dropdown-item {
//    padding: 0.25rem 0.5rem !important;
//}
//
//span.dropleftimg {
//    display: block;
//    float: left;
//}
//
//span.droprightimg {
//    display: block;
//    float: right;
//    text-align: right;
//}
//
//.newlist {
//    transform: scaleY(1);
//    z-index: 999;
//}
//
//.links:hover {
//    /* background-color: #01579B; */
//    background-color: #ededed;
//    transform: scale(1.1);
//}
//
//img.whaticon {
//    margin-right: 25px;
//}
//
//span.borderall {
//    border: 1px solid #ededed;
//    padding: 3px 7px;
//    border-radius: 3px;
//}
//
//
///*
//span.setting_icon img {
//    position: absolute;
//    top: 20px;
//    left:10px;
//} */
//
//li.borderight {
//    border-right: 1px solid #fff;
//    /* height: 15px; */
//}
//
//li.borderleft {
//    border-left: 1px solid #fff;
//    /* height: 15px; */
//}
//
//li.topnone {
//    padding-top: 3px !important;
//}
//
//li.topenonefirst {
//    // padding-top: 0px;
//    /* margin-top: -12px; */
//    // margin-right: 5px;
//}
//
//li.topnone.rightcontent {
//    margin-left: 42px;
//}
//
//img.sametext.righimg {
//    width: 100%;
//    height: auto;
//    // padding-top: 5px;
//    //transition: 1s ease;
//    // transition: height 0.66s ease-out;
//}
//
//.bolunactive img {
//    padding-top: 0px !important;
//    //transition: 0.2s ease;
//}
//
//
///* ======================== Dropd down menu ============================ */
//
//
///*=================== Show Content ========================================= */
//
//a#dropdownMenuLink {
//    width: 300px;
//}
//
//span.errortext {
//    text-align: left;
//    display: block;
//    float: left;
//    color: #303030;
//    font-size: 20px;
//    font-family: "SolaimanLipi", Arial, sans-serif !important;
//}
//
//span.erroecount {
//    text-align: right;
//    display: block;
//    float: right;
//    background-color: #ff8000;
//    color: #fff;
//    padding: 5px 15px;
//    font-family: "SolaimanLipi", Arial, sans-serif !important;
//    border-radius: 6px;
//}
//
//.dropdown-toggle::after {
//    display: none;
//}
//
//.areaexpand:focus {
//    box-shadow: none;
//}
//
//.dropdown-menu.showcount {
//    width: 94%;
//    border: none;
//}
//
//span.banantext {
//    display: block;
//    float: left;
//    font-family: "SolaimanLipi", Arial, sans-serif !important;
//    text-align: left;
//}
//
//span.banancount {
//    display: block;
//    float: right;
//    text-align: right;
//    font-family: "SolaimanLipi", Arial, sans-serif !important;
//}
//
//a.errorcount {
//    display: block;
//    float: left;
//    width: 95%;
//    margin-left: 8px;
//    margin-bottom: 15px;
//}
//
//.rgbred {
//    background-color: rgba(236, 65, 27, 0.1);
//    padding: 20px 5px;
//    border-radius: 5px;
//    -webkit-border-radius: 5px;
//    -moz-border-radius: 5px;
//    -ms-border-radius: 5px;
//    -o-border-radius: 5px;
//}
//
//.rgbred span {
//    padding: 5px;
//}
//
//.errorcount.active,
//.errorcount:active {
//    color: #303030;
//    text-decoration: none;
//    //background-color: none !important;
//}
//
//.errorcount.active,
//.errorcount:hover {
//    color: #303030;
//    text-decoration: none;
//   // background-color: none;
//}
//
//span.rgbared {
//    background-color: #ec411b;
//    color: #fff;
//    padding: 5px 14px;
//    border-radius: 8px;
//}
//
//.rgbyellow {
//    background-color: rgba(210, 136, 60, 0.1);
//    padding: 20px 5px;
//    border-radius: 5px;
//    -webkit-border-radius: 5px;
//    -moz-border-radius: 5px;
//    -ms-border-radius: 5px;
//    -o-border-radius: 5px;
//}
//
//.rgbyellow span {
//    padding: 5px;
//}
//
//span.rgbayellow {
//    background-color: #d2883c;
//    color: #fff;
//    padding: 5px 14px;
//    border-radius: 8px;
//}
//
//.rgbbiolet {
//    background-color: rgba(159, 113, 255, 0.1);
//    padding: 20px 5px;
//    border-radius: 5px;
//    -webkit-border-radius: 5px;
//    -moz-border-radius: 5px;
//    -ms-border-radius: 5px;
//    -o-border-radius: 5px;
//}
//
//.rgbbiolet span {
//    padding: 5px;
//}
//
//span.rgbabiolet {
//    background-color: #9f71ff;
//    color: #fff;
//    padding: 5px 14px;
//    border-radius: 8px;
//}
//
//.rgbgreen {
//    background-color: rgba(36, 196, 184, 0.1);
//    padding: 20px 5px;
//    border-radius: 5px;
//    -webkit-border-radius: 5px;
//    -moz-border-radius: 5px;
//    -ms-border-radius: 5px;
//    -o-border-radius: 5px;
//}
//
//.rgbgreen span {
//    padding: 5px;
//}
//
//span.rgbagreen {
//    background-color: #136ee5;
//    color: #fff;
//    padding: 5px 14px;
//    border-radius: 8px;
//}
//
//
///*=================== Show Content ========================================= */
//
//
///*=================== setting Show Content ========================================= */
//
//.settingshow {
//    position: absolute !important;
//    transform: translate3d(-200px, 21px, 0px) !important;
//    top: 0px;
//    left: 0px;
//    will-change: transform;
//    -webkit-transform: translate3d(-200px, 21px, 0px) !important;
//    -moz-transform: translate3d(-200px, 21px, 0px) !important;
//    -ms-transform: translate3d(-200px, 21px, 0px) !important;
//    -o-transform: translate3d(-200px, 21px, 0px) !important;
//    width: 220px;
//    border: none;
//    margin-top: 10px;
//    padding: 10px;
//}
//
//span.dictionary {
//    display: block;
//    float: left;
//    width: 20px;
//    height: auto;
//    margin-right: 15px;
//}
//
//span.obidancheck {
//    display: block;
//    float: right;
//    width: 0px;
//    text-align: right;
//}
//
//a.dictionaryitem {
//    display: block;
//    float: left;
//    width: 100%;
//    /* flex-direction: row; */
//    /* float: left; */
//    margin-bottom: 15px;
//}
//
//span.obidantext {
//    font-size: 12px;
//    font-family: "SolaimanLipi", Arial, sans-serif !important;
//    /* float: left; */
//    /* text-align: left; */
//    /* display: block; */
//}
//
//input[id="cb1"]+label {
//    border: 1px solid #44c1ea !important;
//}
//
//.dictionaryitem:focus,
//.dictionaryitem:hover {
//    color: #16181b;
//    text-decoration: none;
//    background-color: transparent;
//}
//
//img.crowimage {
//    text-align: right;
//    float: right;
//    display: block;
//}
//
//.crowimage.firstcrow {
//    display: block;
//    float: right;
//    text-align: right;
//    /* margin-top: -20px; */
//}
//
//
///*=================== Active Inactive Main content Content ========================================= */
//
//.ascii_unicode.shadow-dark2 span a.active {
//    background-color: #136ee5;
//    border-radius: 3px;
//    color: #fff;
//}
//
//.ascii_unicode.shadow-dark2 span a.active1 {
//    background-color: #136ee5;
//    border-radius: 3px;
//    color: #fff;
//}
//
//.ascii_unicode.shadow-dark2 span a.active2 {
//    background-color: #136ee5;
//    border-radius: 3px;
//    color: #fff;
//}
//
//.ascii_unicode.shadow-dark2 span a.active3 {
//    background-color: #136ee5;
//    border-radius: 3px;
//    color: #fff;
//}
//
//.ascii_unicode.shadow-dark2 span a {
//    color: #136ee5;
//}
//
//ul.playstop {
//    display: block;
//    float: right;
//    text-align: right;
//}
//
//.bottomspeaker {
//    position: absolute;
//    bottom: 20px;
//    right: 20px;
//}
//
//.dropdowninner {
//    background-color: #e9edf2;
//    display: block;
//    float: left;
//    width: 100%;
//    padding: 5px;
//}
//
//h4.innertitle {
//    /* background-color: #136ee5; */
//    display: block;
//    float: left;
//    width: 100%;
//    padding: 10px;
//    color: #136ee5;
//    margin-bottom: 0px;
//    font-family: "Hind Siliguri", sans-serif;
//}
//
//.ageselect {
//    display: flex;
//    flex-direction: row;
//    justify-content: space-between;
//    float: left;
//    width: 90%;
//    margin-left: 0px;
//    /* padding: 0px 15px; */
//    margin: 0px 10px;
//}
//
//span.genter a {
//    padding: 0px 27px;
//}
//
//
///* ====================================== Progress Bar Design================================== */
//
//.progressbar {
//    display: block;
//    float: left;
//    width: 100%;
//}
//
//div#audio-player-container {
//    display: block;
//    float: right;
//    width: 90%;
//}
//
//div#audio-player-container1 {
//    display: block;
//    float: right;
//    width: 90%;
//}
//
//span.gotilevel {
//    display: block;
//    float: left;
//    width: 10%;
//    color: #fff;
//    font-weight: bold;
//}
//
//.audio-progress {
//    height: 0.5rem;
//    width: 90%;
//    background-color: #fff;
//    float: right;
//    border-radius: 10px;
//    text-align: right;
//}
//
//.audio-progress .bar {
//    height: 100%;
//    background-color: #fff;
//    border-radius: 10px;
//}
//
//.audio-progress1 {
//    height: 0.5rem;
//    width: 90%;
//    background-color: #fff;
//    float: right;
//    border-radius: 10px;
//    text-align: right;
//}
//
//.audio-progress1 .bar {
//    height: 100%;
//    background-color: #fff;
//    border-radius: 10px;
//}
//
//.progressbar {
//    display: block;
//    float: left;
//    width: 100%;
//    background-color: #136ee5;
//    padding: 10px;
//    border: 5px solid #fff;
//    border-radius: 5px;
//}
//
//#audio-progress-handle {
//    display: block;
//    position: absolute;
//    z-index: 1;
//    margin-top: -8px;
//    margin-left: -95px;
//    width: 20px;
//    height: 20px;
//    border: 4px solid #fff;
//    border-top-color: #fff;
//    border-right-color: #fff;
//    transform: rotate(45deg);
//    border-radius: 100%;
//    background-color: #fff;
//    box-shadow: 0 1px 6px rgba(0, 0, 0, 0.2);
//    cursor: pointer;
//}
//
//#audio-progress-handle1 {
//    display: block;
//    position: absolute;
//    z-index: 1;
//    margin-top: -8px;
//    margin-left: -95px;
//    width: 20px;
//    height: 20px;
//    border: 4px solid #fff;
//    border-top-color: #fff;
//    border-right-color: #fff;
//    transform: rotate(45deg);
//    border-radius: 100%;
//    background-color: #fff;
//    box-shadow: 0 1px 6px rgba(0, 0, 0, 0.2);
//    cursor: pointer;
//}
//
//.draggable {
//    float: left;
//    margin: 0 10px 10px 0;
//}
//
//div#myDIV1 {
//    display: block;
//    float: left;
//    border-left: 1px solid #fff;
//}
//
//.mobileview {
//    display: flex;
//    width: 100%;
//}
//
//.ongicernama {
//    margin-top: 25px;
//}
//
//span.mostrequired {
//    color: #ec411b;
//}
//
//.btnprofile {
//    outline: 0;
//    box-shadow: none !important;
//}
//
//.btnprofile {
//    outline: 0;
//    box-shadow: none !important;
//    padding-top: 0px;
//    margin-left: 30px;
//}
//
//.agiyejan {
//    border: none;
//    background-color: transparent;
//}
//
//
///* ====================================== Progress Bar Design================================== */
//
//
///* --------------------------------------- main-content -------------------------- */
//
//
///* --------------------------------------- footer-content -------------------------- */
//
//.footer-area {
//    display: flex;
//    float: left;
//    width: 100%;
//    padding: 20px 30px;
//    position: absolute;
//    //bottom: -55px;
//    justify-content: space-between;
//    min-height: 170px;
//    overflow: hidden;
//}
//
//
///* .footer-area a img:hover {
//  transition: all 1s ease-in-out;
//  -webkit-transition: all 1s ease-in-out;
//  display: flex;
//  float: left;
//  width: 70%;
//  padding: 20px 30px;
//  position: absolute;
//  bottom: 5px;
//  justify-content: space-between;
//  height: 285px;
//  overflow: hidden;
//  padding-left: 80px;
//} */
//
//.threeplay ul li a.agiyejansunun {
//    margin-left: 140px;
//    /* margin-bottom: 60px; */
//    padding-right: 0px;
//}
//
//ul.footer-item {
//    display: flex;
//    flex-wrap: wrap;
//    justify-content: flex-end;
//    margin-bottom: 0px;
//}
//
//ul.footer-item li {
//    margin: 0px 10px;
//    line-height: 45px;
//}
//
//p.specific-icon {
//    margin-left: 100px;
//    margin-bottom: 2px;
//}
//
//.footer-areabottom {
//    display: flex;
//    /* float: left; */
//    width: 100%;
//    padding: 0px 0px;
//    position: fixed;
//    left: 0px;
//    bottom: 0px;
//    justify-content: space-between;
//    background-color: rgba(255, 255, 255, 1);
//    -webkit-box-shadow: -11px 8px 15px -7px #000000;
//    box-shadow: -11px 8px 15px -7px #000000;
//    flex-direction: row;
//    flex-wrap: wrap;
//}
//
//.footer-areabottom ul.footer-item {
//    display: flex;
//    flex-wrap: wrap;
//    justify-content: flex-end;
//    margin-bottom: 0px;
//}
//
//.footer-areabottom ul.footer-item li {
//    margin: 0px 10px;
//    line-height: 45px;
//}
//
//.developed a {
//    color: #919191;
//    font-size: 16px;
//}
//
//.reveclass {
//    color: #0c5bc2;
//    font-size: 16px;
//}
//
//.copyclass a {
//    color: #919191;
//    font-size: 16px;
//}
//
//.signupbradcump img {
//    width: 65%;
//}
//
//
///* ============================================== Signupform  design================================== */
//
//.signupform h4 {
//    font-size: 30px;
//    color: #44af4f;
//    font-family: Noto Sans Bengali;
//}
//
//.signupform p {
//    font-size: 14px;
//    color: #707070;
//    font-family: Noto Sans Bengali;
//}
//
//.signupform {
//    display: block;
//    float: left;
//    width: 100%;
//    background-color: #fff;
//    border-radius: 10px;
//    padding: 30px;
//    margin-bottom: 100px;
//    -webkit-border-radius: 10px;
//    -moz-border-radius: 10px;
//    -ms-border-radius: 10x;
//    -o-border-radius: 10x;
//    margin-top: 30px;
//}
//
//.signupformnoborder {
//    display: block;
//    float: left;
//    width: 100%;
//    background-color: #fff;
//    /* border-top: 10px solid #44af4f; */
//    /* border-radius: 52px; */
//    padding: 30px;
//}
//
//.loginform h4 {
//    font-size: 30px;
//    color: #136ee5;
//    font-family: Noto Sans Bengali;
//}
//
//.loginform p {
//    font-size: 14px;
//    color: #707070;
//    font-family: Noto Sans Bengali;
//}
//
//.loginform {
//    display: block;
//    float: left;
//    width: 100%;
//    background-color: #fff;
//    border-top: 10px solid #116de6;
//    border-radius: 52px;
//    padding: 30px 50px;
//    margin: 0 auto;
//}
//
//h5.mowlictitle {
//    background-color: #f4f4f8;
//    padding: 10px;
//    margin: 20px 0px;
//}
//
//.submitbutton {
//    display: flex;
//    justify-content: flex-end;
//    line-height: 60px;
//}
//
//.loginbutton {
//    display: flex;
//    justify-content: flex-start;
//}
//
//.row.mtopbottom {
//    margin: 10px 0px;
//}
//
//.row.lastbottom {
//    margin: 50px 0px 0px 0px;
//}
//
//.passreset {
//    text-align: right;
//}
//
//.passreset a {
//    color: #919191;
//}
//
//h5.kenoamerkonto {
//    font-size: 32px;
//    font-family: "Noto Sans Bengali", sans-serif;
//}
//
//li.nav-item>a.nav-link {
//    font-size: 20px;
//    color: #161a31;
//    font-family: "Noto Sans Bengali", sans-serif;
//    font-weight: 500;
//}
//
//span.navbar-toggler-icon {
//    margin-right: 20px;
//}
//
//h6.porerager {
//    text-align: center;
//}
//
//h6.porersunte {
//    text-align: center;
//}
//
//
///* ============================================== Signupform design================================== */
//
//.ogicarnama {
//    color: #303030;
//    font-size: 22px;
//}
//
//.bodyongicar p {
//    color: #000000;
//    font-size: 16px;
//}
//
//.bodyongicar p {
//    color: #000000;
//    font-size: 14px;
//    font-weight: 600;
//    line-height: 25px;
//}
//
//.osomoto {
//    color: #fff;
//    background-color: #ec411b;
//    border: none;
//}
//
//.akmot {
//    color: #fff;
//    background-color: #44af4f;
//    border-color: #44af4f;
//}
//
//.chartarea {
//    display: block;
//    float: left;
//    width: 100%;
//}
//
//.chartarea img {
//    margin-bottom: 100px;
//}
//
//a.bottomwave img {
//    padding-left: 80px;
//}
//
//.confirm {
//    margin-top: 100px;
//}
//
//.startwate {
//    background: url("images/stts/wave.png") no-repeat;
//    //   background: url("images/stts/playsunun.png") no-repeat;
//    width: 100% !important;
//    height: auto !important;
//    overflow: hidden;
//    border: none;
//    // margin-bottom: -60px;
//}
//
//.stop {
//    background: url("images/stts/Loader-final.gif") no-repeat;
//    width: 100% !important;
//    height: auto !important;
//    // margin-bottom: -60px;
//    overflow: hidden;
//    // position: relative;
//}
//
//// .hoverimg {
////     background: url('images/stts/clickhover.png') no-repeat;
////     width: 100% !important;
////     height: auto !important;
////     // margin-bottom: -60px;
////     overflow: hidden;
////     // position: relative;
//// }
//// button.bottomwave img {
////     width: 64px;
////     height: auto;
////     position: absolute;
////     top: 34px;
////     left: 303px;
//// }
//button.bottomwave img {
//    width: 64px;
//    height: auto;
//    position: absolute;
//    top: 50px;
//    left: 335px;
//    /* z-index: 99999999999999999999999999; */
//    cursor: pointer;
//    background: none;
//    opacity: 0;
//}
//
//button.bottomwave {
//    display: flex;
//    width: 100%;
//    height: auto;
//    justify-content: center;
//    align-items: center;
//    // position: relative;
//    border: none;
//}
//
//// .bottomwave {
////     width: 608px;
////     height: 126px;
////     margin-left: 80px;
////     border: none;
//// }
//.audio1 {
//    border: none;
//    background: transparent;
//}
//
//.pausesize1 {
//    width: 24px;
//    height: 24px;
//}
//
//.rerecord1 {
//    border: none;
//    background: transparent;
//}
//
//.audio2 {
//    border: none;
//    background: transparent;
//}
//
//.pausesize2 {
//    width: 24px;
//    height: 24px;
//}
//
//.rerecord2 {
//    border: none;
//    background: transparent;
//}
//
//.audio3 {
//    border: none;
//    background: transparent;
//}
//
//.pausesize3 {
//    width: 24px;
//    height: 24px;
//}
//
//.rerecord3 {
//    border: none;
//    background: transparent;
//}
//
//.audio4 {
//    border: none;
//    background: transparent;
//}
//
//.pausesize4 {
//    width: 24px;
//    height: 24px;
//}
//
//.rerecord4 {
//    border: none;
//    background: transparent;
//}
//
//.audio5 {
//    border: none;
//    background: transparent;
//}
//
//.pausesize5 {
//    width: 24px;
//    height: 24px;
//}
//
//.rerecord5 {
//    border: none;
//    background: transparent;
//}
//
//.jomadinbutton {
//    border: none;
//    background: transparent;
//}
//
//.modaljomadin {
//    border: none;
//    background: transparent;
//}
//
//.probeshkorun {
//    border: none;
//    background: transparent;
//}
//
//.ha-button {
//    border: none;
//    background: transparent;
//}
//
//.na-button {
//    border: none;
//    background: transparent;
//}
//
//.play-button {
//    border: none;
//    background: transparent;
//}
//
//.ha-button {
//    border: none;
//    background: transparent;
//}
//
//.ariyejan-button {
//    border: none;
//    background: transparent;
//}
//
//.noactive {
//    border: none;
//    background: transparent;
//}
//
//.stop-button {
//    border: none;
//    background: transparent;
//}
//
//button.sunun {
//    border: none;
//    background: transparent;
//    margin-top: 7px;
//    margin-left: 0px;
//}
//
//.bolun img {
//    width: 142px;
//    height: 142px;
//    // margin-top: 20px;
//}
//
//// .bolun {
////     margin-left: 10px;
////     margin-top: -7px;
//// }
//
///* --------------------------------------- 30.06.2022  page align fix-------------------------- */
//
//.alignnav {
//    justify-content: space-between !important;
//}
//
//.footerdownload {
//    display: flex;
//    justify-content: space-between;
//    width: 100%;
//}
//
//button.jomadinbutton {
//    display: flex;
//    width: 100%;
//    justify-content: center;
//    /* text-align: center; */
//    background: #136ee5;
//    /* height: 20px; */
//    text-align: center;
//    padding: 25px;
//    color: #fff;
//    font-size: 20px;
//    position: relative;
//    border-radius: 5px;
//}
//
//button.mjomadinbutton {
//    display: flex;
//    max-width: 100%;
//    justify-content: space-around;
//    /* text-align: center; */
//    background: #136ee5 !important;
//    /* height: 20px; */
//    text-align: center;
//    color: #fff;
//    font-size: 20px;
//    position: relative;
//    border-radius: 5px;
//    border: none;
//    width: 135px;
//    /* line-height: 22px; */
//    align-items: center;
//    line-height: 45px;
//    padding: 0px 10px;
//}
//
//button.mjomadinbutton span {
//    margin-right: 20px;
//}
//
//button.jomadinbutton span img {
//    position: absolute;
//    top: 12px;
//    left: 20px;
//}
//
//.stopbutton {
//    // / padding-top: 18px; /
//    // / padding-left: 12px; /
//    position: absolute;
//    left: 280px;
//    list-style: none;
//    text-decoration: none;
//    border: none;
//    font-size: 40px;
//    color: #136ee5;
//}
//
//
///* --------------------------------------- 30.06.2022 -------------------------- */
//
//button.agiyejan {
//    display: flex;
//    justify-content: space-between;
//    max-width: 100%;
//    object-fit: cover;
//    width: 130px;
//    max-height: 30px;
//    flex-flow: row wrap;
//    flex-wrap: nowrap;
//    /* background-color: #FFFFFF; */
//    padding: 20px 5px;
//    color: #ec411b;
//    /* justify-content: center; */
//    align-items: center;
//    border-radius: 4px;
//    font-size: 20px;
//}
//
//.menuactive {
//    color: #059514 !important;
//}
//
//.readblock {
//    // background: #ffffff;
//    background: rgb(255, 255, 255);
//    background: linear-gradient( 0deg, rgba(255, 255, 255, 1) 0%, rgba(255, 255, 255, 0) 97%);
//    box-shadow: 1px 2px 7px 0px rgba(0, 0, 0, 0.16);
//    -webkit-box-shadow: 1px 2px 7px 0px rgba(0, 0, 0, 0.16);
//    -moz-box-shadow: 1px 2px 7px 0px rgba(0, 0, 0, 0.16);
//    border-radius: 16px;
//    -webkit-border-radius: 16px;
//    -moz-border-radius: 16px;
//    -ms-border-radius: 16px;
//    -o-border-radius: 16px;
//    padding: 30px;
//}
//
//// .readingrule {
////   width: 600px;
////   margin: 0 auto;
////   min-height: 425px;
//// }
//.readingrule {
//    width: 600px;
//    margin: 0 auto;
//    min-height: 425px;
//    /* float: left; */
//    max-width: 100%;
//}
//
//.readblock p {
//    font-family: "SolaimanLipi", sans-serif;
//    color: #171717;
//    font-size: 20px;
//}
//
//.readingrule h3 {
//    text-align: center;
//    font-size: 32px;
//    color: #161a31;
//    margin-bottom: 20px;
//    font-family: "Noto Sans Bengali", sans-serif;
//}
//
//.entry {
//    background-color: #44af4f !important;
//    display: flex;
//    max-width: 100%;
//    justify-content: space-around;
//    /* text-align: center; */
//    // background: #136ee5 !important;
//    /* height: 20px; */
//    text-align: center;
//    color: #fff;
//    font-size: 20px;
//    position: relative;
//    border-radius: 5px;
//    border: none;
//    width: 135px;
//    /* line-height: 22px; */
//    align-items: center;
//    line-height: 45px;
//    padding: 0px 10px;
//}
//
//
///* --------------------------------------- footer-content -------------------------- */
//
//
///*=================== Responsive design ========================================= */
//
//@media screen and (aspect-ratio: 16/9) {
//    /* @media screen and (max-width: 1536px) and (max-height: 746px) and (-webkit-min-device-pixel-ratio: 2) { */
//    .withsidebar-content {
//        padding: 30px 30px 30px;
//    }
//    .bodybgmicrophone {
//        display: flex;
//        height: 140vh !important;
//        /* background: url("../img/bg.png") no-repeat center; */
//        background: url("./images/stts/pageone.png") no-repeat center;
//        background-size: cover;
//        background-position: 100% 65%;
//        flex-wrap: wrap;
//        /* width: 100%; */
//        width: 100%;
//        float: left;
//        position: relative;
//        /* padding: 137px 0px 0px 0px; */
//        justify-content: center;
//        align-items: center;
//        margin: 0 auto;
//        overflow-y: scroll;
//    }
//    .signupbg {
//        display: flex;
//        height: 100vh;
//        /* background: url("../img/bg.png") no-repeat center; */
//        // background: url("./images/stts/sighupbg.png") no-repeat center;
//        background-size: cover;
//        background-position: 65% 65%;
//        flex-wrap: wrap;
//        /* width: 100%; */
//        width: 100%;
//        float: left;
//        position: relative;
//        /* padding: 137px 0px 0px 0px; */
//        justify-content: center;
//        align-items: center;
//        margin: 0 auto;
//        margin-top: 5%;
//        /* overflow-y: scroll; */
//    }
//    .stopbutton {
//        // / padding-top: 18px; /
//        // / padding-left: 12px; /
//        position: absolute;
//        left: 280px;
//        list-style: none;
//        text-decoration: none;
//        border: none;
//        font-size: 40px;
//        color: #136ee5;
//    }
//}
//
//@media screen and (max-width: 1537px) and (max-height: 792px) and (-webkit-min-device-pixel-ratio: 1) {
//    .withsidebar-content {
//        padding: 30px 30px 30px;
//    }
//    .bodybgmicrophone {
//        display: flex;
//        height: 140vh;
//        /* background: url("../img/bg.png") no-repeat center; */
//        background: url("./images/stts/pageone.png") no-repeat center;
//        background-size: cover;
//        background-position: 100% 65%;
//        flex-wrap: wrap;
//        /* width: 100%; */
//        width: 100%;
//        float: left;
//        position: relative;
//        /* padding: 137px 0px 0px 0px; */
//        justify-content: center;
//        align-items: center;
//        margin: 0 auto;
//        overflow-y: scroll;
//    }
//    .signupbg {
//        display: flex;
//        height: 100vh;
//        /* background: url("../img/bg.png") no-repeat center; */
//        // background: url("./images/stts/sighupbg.png") no-repeat center;
//        background-size: cover;
//        background-position: 65% 65%;
//        flex-wrap: wrap;
//        /* width: 100%; */
//        width: 100%;
//        float: left;
//        position: relative;
//        /* padding: 137px 0px 0px 0px; */
//        justify-content: center;
//        align-items: center;
//        margin: 0 auto;
//        margin-top: 5%;
//        /* overflow-y: scroll; */
//    }
//    .stopbutton {
//        /* padding-top: 18px; */
//        /* padding-left: 12px; */
//        position: absolute;
//        left: 280px;
//        list-style: none;
//        text-decoration: none;
//        border: none;
//        font-size: 40px;
//        color: #136ee5;
//    }
//}
//
//@media only screen and (max-width: 1467px) and (max-height: 764px) {
//    .middletext {
//        justify-content: center;
//        align-items: center;
//        display: flex;
//        min-height: 280px;
//    }
//    .obodanarea {
//        display: block;
//        /* justify-content: center; */
//        width: 100%;
//        min-height: 400px;
//    }
//    button.ariyejan-button.agiyejansunun {
//        position: absolute;
//        right: 30px;
//        top: 60px;
//    }
//    .threeplay ul li {
//        padding: 0px 47px !important;
//    }
//    .footerdownload {
//        margin-left: 60px;
//    }
//    .stopbutton {
//        /* padding-top: 18px; */
//        /* padding-left: 12px; */
//        position: absolute;
//        left: 280px;
//        list-style: none;
//        text-decoration: none;
//        border: none;
//        font-size: 40px;
//        color: #136ee5;
//    }
//}
//
//@media only screen and (max-width: 1440px) and (max-height: 874px) {
//    /* .bodybg {
//      width: 99%;
//    } */
//}
//
//@media only screen and (max-width: 1366px) {
//    .lefttext {
//        width: 100%;
//        /* display: flex; */
//        /* flex-direction: row; */
//        text-align: center;
//        /* float: right; */
//        /* justify-content: stretch; */
//        padding-left: 0px;
//    }
//    .righttext {
//        width: 100%;
//        display: flex;
//        flex-direction: column;
//    }
//    .righttext p {
//        text-align: left;
//        float: left;
//        padding-right: 0%;
//        padding: 0px 20px;
//    }
//}
//
//@media only screen and (max-width: 1180px) {
//    .footer-area {
//        display: flex;
//        float: left;
//        width: 100%;
//        padding: 20px 30px;
//        position: absolute;
//        /* justify-content: space-between; */
//        min-height: 170px;
//        overflow: hidden;
//        flex-direction: column;
//    }
//    .startwate {
//        min-height: 132px;
//    }
//    button.agiyejan {
//        text-align: center;
//        /* width: 100%; */
//        display: flex;
//        justify-content: center;
//        align-items: center;
//        margin-right: 170px;
//    }
//    .footerdownload {
//        flex-direction: column;
//    }
//    .mbottom {
//        margin-bottom: 200px !important;
//    }
//    .stop {
//        min-height: 132px;
//    }
//}
//
//@media only screen and (max-width: 1024px) {
//    .main-content {
//        display: block;
//        float: left;
//        width: 95.8%;
//    }
//    .navbar-collapse {
//        flex-basis: 100%;
//        flex-grow: 1;
//        align-items: center;
//        margin-top: 20px;
//    }
//    .signupform {
//        display: block;
//        float: left;
//        width: 100%;
//        background-color: #fff;
//        border-top: 10px solid #44af4f;
//        border-radius: 52px;
//        padding: 30px;
//        margin-bottom: 100px;
//        margin-top: 30px;
//    }
//    .threeplay ul li a.agiyejansunun {
//        margin-left: 0px;
//        /* margin-bottom: 60px; */
//        padding-right: 0px;
//    }
//    .breadcrumbtop {
//        margin: 30px 0px;
//        display: block;
//        float: left;
//        width: 100%;
//        text-align: center;
//    }
//    .signupbradcump {
//        margin: 30px 0px;
//        display: block;
//        float: left;
//        width: 100%;
//        text-align: center;
//    }
//    .loginform {
//        display: block;
//        float: left;
//        width: 100%;
//        background-color: transparent;
//        border-top: none;
//        border-radius: 0;
//        padding: 30px 10px;
//        margin: 0 auto;
//        margin-bottom: 100px;
//    }
//    .signupform {
//        display: block;
//        float: left;
//        width: 100%;
//        background-color: transparent;
//        border-top: none;
//        border-radius: 0;
//        padding: 30px 50px;
//        margin: 0 auto;
//        margin-bottom: 100px;
//    }
//    .lefttext {
//        width: 100%;
//        /* display: flex; */
//        /* flex-direction: row; */
//        text-align: center;
//        /* float: right; */
//        /* justify-content: stretch; */
//        padding-left: 0px;
//    }
//    .righttext {
//        width: 100%;
//        display: flex;
//        flex-direction: column;
//    }
//    .righttext p {
//        text-align: left;
//        float: left;
//        padding-right: 0%;
//        padding: 0px 20px;
//    }
//    button.agiyejan {
//        margin-right: 170px;
//    }
//    button.mjomadinbutton {
//        display: flex;
//        max-width: 100%;
//        justify-content: space-around;
//        /* text-align: center; */
//        background: #136ee5 !important;
//        /* height: 20px; */
//        text-align: center;
//        color: #fff;
//        font-size: 18px;
//        position: relative;
//        border-radius: 5px;
//        border: none;
//        width: 135px;
//        /* line-height: 22px; */
//        align-items: center;
//        line-height: 45px;
//        padding: 0px 10px;
//    }
//    .loginform h4 {
//        font-size: 20px;
//        color: #136ee5;
//        font-family: Noto Sans Bengali;
//    }
//    .signupbg {
//        height: auto;
//        /* background: url("../img/bg.png") no-repeat center; */
//        // background: url("./images/stts/sighupbg.png") no-repeat center;
//        background-size: cover;
//        background-position: 40% 65%;
//        flex-wrap: wrap;
//        /* width: 100%; */
//        width: 100%;
//        float: left;
//        position: relative;
//        /* padding: 137px 0px 0px 0px; */
//        justify-content: center;
//        align-items: center;
//        margin: 0 auto;
//        margin-top: 0%;
//        /* overflow-y: scroll; */
//    }
//    .readblock {
//        margin-bottom: 60px;
//    }
//}
//
//@media only screen and (max-width: 1367px) and (max-height: 768px) {
//    .main-text-content {
//        max-height: 448px;
//    }
//    .content-body {
//        max-height: 597px;
//    }
//    .signupform h4 {
//        font-size: 20px;
//        color: #44af4f;
//        font-family: Noto Sans Bengali;
//    }
//}
//
//@media only screen and (max-width: 1366px) and (max-height: 657px) {
//    .bodybg {
//        width: 94%;
//    }
//}
//
//@media only screen and (max-width: 1280px) and (max-height: 800px) {
//    .main-text-content {
//        max-height: 590px;
//    }
//}
//
//@media only screen and (max-width: 991px) {
//    .bodybgmicrophone .topmenu {
//        width: 100%;
//        float: left;
//        display: block;
//        /* background: red; */
//        position: inherit;
//        top: 0;
//        left: 0;
//        background: #fff;
//    }
//    .navbar-collapse {
//        flex-basis: 100%;
//        flex-grow: 1;
//        align-items: center;
//        margin-top: 20px;
//        display: flex;
//        justify-content: space-between;
//        flex-direction: row;
//    }
//    .navbar-nav {
//        display: flex;
//        flex-direction: column;
//        padding-left: 0;
//        margin-bottom: 0;
//        list-style: none;
//        display: flex;
//        flex-direction: row;
//        justify-content: space-around;
//        width: 100%;
//    }
//    a.nav-link.active.probeshkorun {
//        padding: 10px;
//    }
//    li.nav-item>a.nav-link {
//        font-size: 11px;
//        color: #161a31;
//        font-family: "Noto Sans Bengali", sans-serif;
//        font-weight: 500;
//    }
//}
//
//@media only screen and (max-width: 768px) {
//    .breadcrumbtop {
//        margin: 30px 0px;
//        display: flex;
//        /* float: left; */
//        width: 100%;
//        justify-content: space-between;
//    }
//}
//
//@media only screen and (max-width: 768px) {
//    .footer-areabottom {
//        display: flex;
//        /* float: left; */
//        width: 100%;
//        padding: 0px 0px;
//        position: fixed;
//        left: 0px;
//        bottom: 0px;
//        justify-content: center;
//        background-color: rgba(255, 255, 255, 0.5);
//        -webkit-box-shadow: 0px 0px 6px 0px rgba(0, 0, 0, 0.16);
//        box-shadow: 0px 0px 6px 0px rgba(0, 0, 0, 0.16);
//        flex-direction: row;
//        flex-wrap: wrap;
//        /* background-color: #bedaff; */
//    }
//    .developed:first-child {
//        display: none;
//    }
//    ul.footer-item:last-child {
//        display: none;
//    }
//    .signupbradcump img {
//        width: 20%;
//        margin-bottom: 50px;
//    }
//    .leftwave {
//        width: 100%;
//        display: flex;
//        flex-direction: column;
//        text-align: center;
//    }
//    .rightwave {
//        width: 100%;
//        display: flex;
//        flex-direction: column;
//        text-align: center;
//        background-color: rgba(190, 195, 201, 0.1);
//    }
//    .wavecontent {
//        display: flex;
//        flex-direction: column;
//        width: 100%;
//        height: auto;
//        justify-content: space-between;
//        margin-top: 70px;
//    }
//    .lefttext {
//        width: 100%;
//        /* display: flex; */
//        /* flex-direction: row; */
//        text-align: center;
//        /* float: right; */
//        /* justify-content: stretch; */
//        padding-left: 0px;
//    }
//    .righttext {
//        width: 100%;
//        display: flex;
//        flex-direction: column;
//    }
//    .righttext p {
//        text-align: left;
//        float: left;
//        padding-right: 0%;
//        padding: 0px 20px;
//    }
//    .chartarea {
//        display: block;
//        float: left;
//        width: 100%;
//        margin-bottom: 100px;
//    }
//    .developed a {
//        color: #919191;
//        font-size: 13px;
//    }
//    .smdeviceview {
//        max-height: 650px;
//        overflow-y: scroll;
//        overflow-x: hidden;
//    }
//    a.bottomwave img {
//        padding-left: 0px;
//    }
//    .main-text-content {
//        min-height: 250px;
//    }
//    .editor-icon {
//        display: flex;
//        width: 100%;
//        float: left;
//        /* background: red; */
//        justify-content: space-between;
//        max-height: 120px;
//        border: none !important;
//        margin-bottom: 30px;
//    }
//    .editor-icon ul {
//        display: flex;
//        justify-content: space-between;
//        margin-bottom: 0px;
//        justify-content: left;
//        position: relative;
//        padding-left: 0px;
//        margin-left: -60px;
//        /* margin-top: -30px; */
//        flex-direction: row;
//        justify-content: space-between;
//        flex-flow: row;
//    }
//    h6.porerager {
//        font-size: 10px;
//    }
//    h6.porersunte {
//        font-size: 10px;
//    }
//    .threeplay {
//        position: absolute;
//        top: 45px;
//        float: left;
//        /* width: 100%; */
//        display: block;
//        left: 48px;
//    }
//    .iconvoice {
//        position: absolute;
//        right: 0px;
//        top: -40px;
//    }
//    a.recordingprofile {
//        width: 36px;
//        height: 36px;
//        line-height: 36px;
//        border: 1px solid #bedaff;
//        padding: 5px 15px;
//        border-radius: 50%;
//        text-align: center;
//        color: #fff;
//        background-color: #bedaff;
//        margin-left: 135px;
//    }
//    .probeshkorun {
//        margin-bottom: 20px;
//    }
//    //   #navbarTogglerDemo01 {
//    //     background-color: rgba(0, 0, 0, 0.41);
//    //     height: 100vh;
//    //   }
//    .mobileview {
//        display: flex;
//        flex-direction: row;
//        flex-wrap: wrap;
//        background-color: #ffff;
//    }
//    .mnoview {
//        display: none;
//    }
//    .rightsidebar {
//        display: block;
//        float: left;
//        width: 100%;
//        padding: 20px 30px 0px;
//        -webkit-box-shadow: 0px 3px 6px 0px rgba(0, 0, 0, 0.16);
//        box-shadow: 0px 3px 6px 0px rgba(0, 0, 0, 0.16);
//        border-radius: 4px;
//        background: rgb(255, 255, 255);
//        background: #f6f9ff;
//        margin-top: 60px;
//        margin-bottom: 60px;
//    }
//    .loginlogo img {
//        width: 20%;
//    }
//    .alignnav {
//        flex-basis: 100%;
//        flex-grow: 1;
//        align-items: center;
//        margin-top: 20px;
//        display: flex;
//        flex-wrap: wrap;
//    }
//    ul.obodanitem {
//        display: flex;
//        flex-direction: row;
//        justify-content: space-between;
//        width: 70%;
//        /* font-size: 4px; */
//    }
//    .obodanitem li:last-child {
//        margin-right: 0px;
//    }
//    .navbar-brand {
//        display: inline-block;
//        padding-top: 0.3125rem;
//        padding-bottom: 0.3125rem;
//        margin-right: 1rem;
//        font-size: 1.25rem;
//        line-height: inherit;
//        white-space: nowrap;
//        width: 25%;
//    }
//    div#navbarTogglerDemo01 a img {
//        width: 100%;
//        margin-top: -20px;
//    }
//    ul.navbar-nav.me-0.mb-2.mb-lg-0 {
//        display: flex;
//        flex-direction: row;
//        justify-content: space-between;
//        width: 100%;
//    }
//    a.probeshkorun {
//        padding: 10px !important;
//    }
//    .startwate {
//        background: url("images/stts/wave.png") no-repeat;
//        // background: url("images/stts/playsunun1.png") no-repeat;
//        width: 100% !important;
//        height: auto !important;
//        overflow: hidden;
//        border: none;
//        // margin-bottom: -60px;
//    }
//    .stop {
//        // background: url("images/stts/smallload.gif") no-repeat;
//        background: url("images/stts/Loader-final.gif") no-repeat;
//        width: 100% !important;
//        height: auto !important;
//        // margin-bottom: -60px;
//        overflow: hidden;
//        // position: relative;
//    }
//    button.bottomwave img {
//        width: 64px;
//        height: auto;
//        position: absolute;
//        top: 55px;
//        left: 333px;
//        cursor: pointer;
//        background: none;
//        opacity: 0;
//    }
//    .signupbg {
//        height: auto;
//        /* background: url("../img/bg.png") no-repeat center; */
//        // background: url("./images/stts/sighupbg.png") no-repeat center;
//        background-size: cover;
//        background-position: 40% 65%;
//        flex-wrap: wrap;
//        /* width: 100%; */
//        width: 100%;
//        float: left;
//        position: relative;
//        /* padding: 137px 0px 0px 0px; */
//        justify-content: center;
//        align-items: center;
//        margin: 0 auto;
//        margin-top: 0%;
//        /* overflow-y: scroll; */
//    }
//    .signupbradcump {
//        display: none;
//    }
//    li.nav-item>a.nav-link {
//        font-size: 10px !important;
//        color: #161a31;
//        font-family: "Noto Sans Bengali", sans-serif;
//        font-weight: 500;
//    }
//}
//
//@media only screen and (max-width: 384px) {
//    .submitbutton {
//        display: flex;
//        justify-content: space-around;
//        line-height: 60px;
//    }
//    .readblock {
//        margin-bottom: 60px;
//    }
//}
//
//@media only screen and (max-width: 768px) and (max-height: 1024px) {
//    .editor-icon ul li {
//        padding: 0px 10px;
//    }
//}
//
//@media only screen and (max-width: 640px) {
//    .startwate {
//        // background: url("images/stts/wave.png") no-repeat;
//        background: url("images/stts/playsunun1.png") no-repeat;
//        width: 100% !important;
//        height: auto !important;
//        overflow: hidden;
//        border: none;
//        // margin-bottom: -60px;
//    }
//    .stop {
//        background: url("images/stts/smallload.gif") no-repeat;
//        // background: url("images/stts/Loader-final.gif") no-repeat;
//        width: 100% !important;
//        height: auto !important;
//        // margin-bottom: -60px;
//        overflow: hidden;
//        // position: relative;
//    }
//    li.borderight {
//        border-right: none;
//        /* height: 15px; */
//    }
//    li.borderleft {
//        border-left: none;
//        /* height: 15px; */
//    }
//    .main-content {
//        display: block;
//        float: left;
//        width: 88%;
//    }
//    .bottomspeaker {
//        position: fixed;
//        bottom: 0px;
//        left: 0px;
//    }
//    .main-text-content:last-child p {
//        padding-bottom: 15%;
//    }
//    .main-text-content {
//        max-height: 350px;
//    }
//    .editor-icon ul li {
//        /* padding: 10px 14px; */
//        padding: 0px 14px;
//        line-height: 10px;
//        position: relative;
//        display: inline-block;
//        float: left;
//        margin: 5px 3px;
//    }
//    div#myDIV1 {
//        display: block;
//        float: left;
//        border-left: none;
//    }
//    .readblock {
//        margin-bottom: 60px;
//    }
//}
//
//@media only screen and (max-width: 1024px) and (max-height: 600px) {
//    .main-text-content {
//        max-height: 420px;
//    }
//}
//
//@media only screen and (max-width: 640px) and (max-height: 360px) {
//    .main-content {
//        display: block;
//        float: left;
//        width: 92.5%;
//    }
//}
//
//@media only screen and (max-width: 600px) {
//    .startwate {
//        // background: url("images/stts/wave.png") no-repeat;
//        background: url("images/stts/playsunun1.png") no-repeat;
//        width: 100% !important;
//        height: auto !important;
//        overflow: hidden;
//        border: none;
//        // margin-bottom: -60px;
//    }
//    .stop {
//        background: url("images/stts/smallload.gif") no-repeat;
//        // background: url("images/stts/Loader-final.gif") no-repeat;
//        width: 100% !important;
//        height: auto !important;
//        // margin-bottom: -60px;
//        overflow: hidden;
//        // position: relative;
//    }
//    li.nav-item>a.nav-link {
//        font-size: 20px !important;
//        color: #161a31;
//        font-family: "Noto Sans Bengali", sans-serif;
//        font-weight: 500;
//    }
//    .breadcrumbtop {
//        display: none;
//    }
//    .footer-area {
//        display: flex;
//        float: left;
//        width: 100%;
//        padding: 20px 90px;
//        position: absolute;
//        /* justify-content: space-between; */
//        min-height: 170px;
//        overflow: hidden;
//        flex-direction: column;
//    }
//    .editor-icon ul {
//        display: flex;
//        justify-content: space-between;
//        margin-bottom: 0px;
//        justify-content: left;
//        position: relative;
//        padding-left: 0px;
//        /* margin-left: -60px; */
//        /* margin-top: -30px; */
//        flex-direction: row;
//        justify-content: space-between;
//        flex-flow: row;
//        width: 100%;
//        margin-left: 0px;
//    }
//    button.bottomwave img {
//        width: 64px;
//        height: auto;
//        position: absolute;
//        top: 20px;
//        left: 205px;
//        /* z-index: 99999999999999999999999999; */
//        cursor: pointer;
//        background: none;
//        opacity: 0;
//    }
//}
//
//@media only screen and (max-width: 480px) {
//    li.nav-item>a.nav-link {
//        font-size: 16px !important;
//        color: #161a31;
//        font-family: "Noto Sans Bengali", sans-serif;
//        font-weight: 500;
//    }
//    .footer-area {
//        display: flex;
//        float: left;
//        width: 100%;
//        padding: 20px 45px;
//        position: absolute;
//        /* justify-content: space-between; */
//        min-height: 170px;
//        overflow: hidden;
//        flex-direction: column;
//    }
//    button.bottomwave img {
//        width: 64px;
//        height: auto;
//        position: absolute;
//        top: 20px;
//        left: 160px;
//        /* z-index: 99999999999999999999999999; */
//        cursor: pointer;
//        background: none;
//        opacity: 0;
//    }
//    .startwate {
//        // background: url("images/stts/wave.png") no-repeat;
//        background: url("images/stts/playsunun1.png") no-repeat;
//        width: 100% !important;
//        height: auto !important;
//        overflow: hidden;
//        border: none;
//        // margin-bottom: -60px;
//    }
//    .stop {
//        background: url("images/stts/smallload.gif") no-repeat;
//        // background: url("images/stts/Loader-final.gif") no-repeat;
//        width: 100% !important;
//        height: auto !important;
//        // margin-bottom: -60px;
//        overflow: hidden;
//        // position: relative;
//    }
//    .readingrule h3 {
//        text-align: center;
//        font-size: 20px;
//        color: #161a31;
//        margin: 20px;
//        font-family: "Noto Sans Bengali", sans-serif;
//    }
//}
//
//@media only screen and (max-width: 414px) {
//    .startwate {
//        // background: url("images/stts/wave.png") no-repeat;
//        background: url("images/stts/playsunun1.png") no-repeat;
//        width: 100% !important;
//        height: auto !important;
//        overflow: hidden;
//        border: none;
//        // margin-bottom: -60px;
//    }
//    .stop {
//        background: url("images/stts/smallload.gif") no-repeat;
//        // background: url("images/stts/Loader-final.gif") no-repeat;
//        width: 100% !important;
//        height: auto !important;
//        // margin-bottom: -60px;
//        overflow: hidden;
//        // position: relative;
//    }
//    li.nav-item>a.nav-link {
//        font-size: 16px !important;
//        color: #161a31;
//        font-family: "Noto Sans Bengali", sans-serif;
//        font-weight: 500;
//    }
//    .navbar-brand {
//        display: inline-block;
//        padding-top: 1.025rem;
//        padding-bottom: 0.3125rem;
//        margin-right: 1rem;
//        font-size: 1.25rem;
//        line-height: inherit;
//        white-space: nowrap;
//        width: 25%;
//    }
//    .breadcrumbtop {
//        margin: 0px 0px;
//        display: flex;
//        float: left;
//        width: 100%;
//        justify-content: space-between;
//        padding-top: 0px;
//    }
//    .container>div {
//        padding: 0px !important;
//    }
//    .probeshkorun {
//        margin-bottom: 0px;
//    }
//    .paddinghide {
//        padding: 0px;
//    }
//    .editor-icon ul {
//        display: flex;
//        justify-content: space-between;
//        margin-bottom: 0px;
//        justify-content: left;
//        position: relative;
//        padding-left: 0px;
//        margin-left: 0px;
//        /* margin-top: -30px; */
//        flex-direction: row;
//        justify-content: space-between;
//        flex-flow: row;
//        width: 100%;
//    }
//    .editor-icon ul li {
//        /* padding: 10px 14px; */
//        padding: 0px;
//        line-height: 10px;
//        position: relative;
//        display: inline-block;
//        margin: 0px;
//    }
//    .nav-link {
//        display: block;
//        padding: 0px;
//    }
//    button.entry {
//        margin: 10px auto;
//    }
//    .submitbutton {
//        display: flex;
//        justify-content: center;
//        line-height: 60px;
//    }
//    .signupform p {
//        font-size: 12px;
//        color: #707070;
//        font-family: Noto Sans Bengali;
//    }
//    .signupbg {
//        margin-top: 0%;
//    }
//    .breadcrumbtop p img {
//        display: none;
//    }
//    .footer-area {
//        display: flex;
//        float: left;
//        width: 100%;
//        padding: 20px 20px;
//        position: absolute;
//        /* justify-content: space-between; */
//        min-height: 170px;
//        overflow: hidden;
//        flex-direction: column;
//    }
//    button.bottomwave img {
//        width: 64px;
//        height: auto;
//        position: absolute;
//        top: 20px;
//        left: 135px;
//        /* z-index: 99999999999999999999999999; */
//        cursor: pointer;
//        background: none;
//        opacity: 0;
//    }
//}
//
//@media only screen and (max-width: 412px) {
//    .readblock {
//        margin-bottom: 60px;
//    }
//}
//
//@media only screen and (max-width: 384px) {
//    li.nav-item>a.nav-link {
//        font-size: 14px !important;
//        color: #161a31;
//        font-family: "Noto Sans Bengali", sans-serif;
//        font-weight: 500;
//    }
//}
//
//@media only screen and (max-width: 360px) {
//    li.nav-item>a.nav-link {
//        font-size: 20px !important;
//        color: #161a31;
//        font-family: "Noto Sans Bengali", sans-serif;
//        font-weight: 500;
//    }
//    .navbar-brand {
//        display: inline-block;
//        padding-top: 1.025rem;
//        padding-bottom: 0.3125rem;
//        margin-right: 1rem;
//        font-size: 1.25rem;
//        line-height: inherit;
//        white-space: nowrap;
//        width: 25%;
//    }
//    .breadcrumbtop {
//        margin: 0px 0px;
//        display: flex;
//        float: left;
//        width: 100%;
//        justify-content: space-between;
//        padding-top: 0px;
//    }
//    .container>div {
//        padding: 0px !important;
//    }
//    .probeshkorun {
//        margin-bottom: 0px;
//    }
//    .paddinghide {
//        padding: 0px;
//    }
//    .editor-icon ul {
//        display: flex;
//        justify-content: space-between;
//        margin-bottom: 0px;
//        justify-content: left;
//        position: relative;
//        padding-left: 0px;
//        margin-left: 0px;
//        /* margin-top: -30px; */
//        flex-direction: row;
//        justify-content: space-between;
//        flex-flow: row;
//        width: 100%;
//    }
//    .nav-link {
//        display: block;
//        padding: 0px;
//    }
//    button.entry {
//        margin: 10px auto;
//    }
//    .submitbutton {
//        display: flex;
//        justify-content: center;
//        line-height: 60px;
//    }
//    .signupform p {
//        font-size: 12px;
//        color: #707070;
//        font-family: Noto Sans Bengali;
//    }
//    .signupbg {
//        margin-top: 0%;
//    }
//    .breadcrumbtop p img {
//        display: none;
//    }
//    .obodanitem li {
//        margin: 0px;
//    }
//    .footer-areabottom {
//        display: flex;
//        /* float: left; */
//        width: 100%;
//        padding: 0px 0px;
//        position: fixed;
//        left: 0px;
//        bottom: 0px;
//        justify-content: center;
//        background-color: rgba(255, 255, 255, 0.5);
//        -webkit-box-shadow: 0px 0px 6px 0px rgba(0, 0, 0, 0.16);
//        box-shadow: 0px 0px 6px 0px rgba(0, 0, 0, 0.16);
//        flex-direction: row;
//        flex-wrap: wrap;
//        /* background-color: #bedaff; */
//    }
//    .developed:first-child {
//        display: none;
//    }
//    ul.footer-item:last-child {
//        display: none;
//    }
//    .signupbradcump img {
//        width: 20%;
//        margin-bottom: 50px;
//    }
//    .leftwave {
//        width: 100%;
//        display: flex;
//        flex-direction: column;
//        text-align: center;
//    }
//    .rightwave {
//        width: 100%;
//        display: flex;
//        flex-direction: column;
//        text-align: center;
//        background-color: rgba(190, 195, 201, 0.1);
//    }
//    .wavecontent {
//        display: flex;
//        flex-direction: column;
//        width: 100%;
//        height: auto;
//        justify-content: space-between;
//        margin-top: 70px;
//    }
//    .lefttext {
//        width: 100%;
//        /* display: flex; */
//        /* flex-direction: row; */
//        text-align: center;
//        /* float: right; */
//        /* justify-content: stretch; */
//        padding-left: 0px;
//    }
//    .righttext {
//        width: 100%;
//        display: flex;
//        flex-direction: column;
//    }
//    .righttext p {
//        text-align: left;
//        float: left;
//        padding-right: 0%;
//        padding: 0px 20px;
//    }
//    .chartarea {
//        display: block;
//        float: left;
//        width: 100%;
//        margin-bottom: 100px;
//    }
//    .developed a {
//        color: #919191;
//        font-size: 13px;
//    }
//    .smdeviceview {
//        max-height: 650px;
//        overflow-y: scroll;
//        overflow-x: hidden;
//    }
//    a.bottomwave img {
//        padding-left: 0px;
//    }
//    .main-text-content {
//        min-height: 250px;
//    }
//    .editor-icon {
//        display: flex;
//        width: 100%;
//        float: left;
//        /* background: red; */
//        justify-content: space-between;
//        max-height: 120px;
//        border: none !important;
//        margin-bottom: 30px;
//    }
//    h6.porerager {
//        font-size: 10px;
//    }
//    h6.porersunte {
//        font-size: 10px;
//    }
//    .threeplay {
//        position: absolute;
//        top: 45px;
//        float: left;
//        /* width: 100%; */
//        display: block;
//        left: 48px;
//    }
//    .iconvoice {
//        position: absolute;
//        right: 0px;
//        top: -40px;
//    }
//    a.recordingprofile {
//        width: 36px;
//        height: 36px;
//        line-height: 36px;
//        border: 1px solid #bedaff;
//        padding: 5px 15px;
//        border-radius: 50%;
//        text-align: center;
//        color: #fff;
//        background-color: #bedaff;
//        margin-left: 135px;
//    }
//    .probeshkorun {
//        margin-bottom: 20px;
//    }
//    //   #navbarTogglerDemo01 {
//    //     background-color: rgba(0, 0, 0, 0.41);
//    //     height: 100vh;
//    //   }
//    .mobileview {
//        display: flex;
//        flex-direction: row;
//        flex-wrap: wrap;
//        background-color: #ffff;
//    }
//    .mnoview {
//        display: none;
//    }
//    .rightsidebar {
//        display: block;
//        float: left;
//        width: 100%;
//        padding: 20px 30px 0px;
//        -webkit-box-shadow: 0px 3px 6px 0px rgba(0, 0, 0, 0.16);
//        box-shadow: 0px 3px 6px 0px rgba(0, 0, 0, 0.16);
//        border-radius: 4px;
//        background: rgb(255, 255, 255);
//        background: #f6f9ff;
//        margin-top: 60px;
//        margin-bottom: 60px;
//    }
//    .loginlogo img {
//        width: 20%;
//    }
//    .alignnav {
//        flex-basis: 100%;
//        flex-grow: 1;
//        align-items: center;
//        margin-top: 20px;
//        display: flex;
//        flex-wrap: wrap;
//    }
//    ul.obodanitem {
//        display: flex;
//        flex-direction: row;
//        justify-content: space-between;
//        width: 100%;
//        /* font-size: 4px; */
//    }
//    .obodanitem li:last-child {
//        margin-right: 0px;
//    }
//    .navbar-brand {
//        display: inline-block;
//        padding-top: 0.3125rem;
//        padding-bottom: 0.3125rem;
//        margin-right: 1rem;
//        font-size: 1.25rem;
//        line-height: inherit;
//        white-space: nowrap;
//        width: 25%;
//    }
//    div#navbarTogglerDemo01 a img {
//        width: 100%;
//        margin-top: -20px;
//    }
//    ul.navbar-nav.me-0.mb-2.mb-lg-0 {
//        display: flex;
//        flex-direction: row;
//        justify-content: space-between;
//        width: 100%;
//    }
//    a.probeshkorun {
//        padding: 10px !important;
//    }
//    .startwate {
//        //   background: url("images/stts/wave.png") no-repeat;
//        background: url("images/stts/playsunun1.png") no-repeat;
//        width: 100% !important;
//        height: auto !important;
//        overflow: hidden;
//        border: none;
//        // margin-bottom: -60px;
//    }
//    button.bottomwave img {
//        width: 64px;
//        height: auto;
//        position: absolute;
//        top: 20px;
//        left: 115px;
//        /* z-index: 99999999999999999999999999; */
//        cursor: pointer;
//        background: none;
//        opacity: 0;
//    }
//    .stop {
//        background: url("images/stts/smallload.gif") no-repeat;
//        width: 100% !important;
//        height: auto !important;
//        // margin-bottom: -60px;
//        overflow: hidden;
//        // position: relative;
//    }
//    .signupbg {
//        height: auto;
//        /* background: url("../img/bg.png") no-repeat center; */
//        // background: url("./images/stts/sighupbg.png") no-repeat center;
//        background-size: cover;
//        background-position: 40% 65%;
//        flex-wrap: wrap;
//        /* width: 100%; */
//        width: 100%;
//        float: left;
//        position: relative;
//        /* padding: 137px 0px 0px 0px; */
//        justify-content: center;
//        align-items: center;
//        margin: 0 auto;
//        margin-top: 0%;
//        /* overflow-y: scroll; */
//    }
//    .signupbradcump {
//        display: none;
//    }
//    li.nav-item>a.nav-link {
//        font-size: 10px !important;
//        color: #161a31;
//        font-family: "Noto Sans Bengali", sans-serif;
//        font-weight: 500;
//    }
//    .footer-area {
//        display: flex;
//        float: left;
//        width: 100%;
//        padding: 20px 0px;
//        position: absolute;
//        /* justify-content: space-between; */
//        min-height: 170px;
//        overflow: hidden;
//        flex-direction: column;
//    }
//    li.nav-item>a.nav-link {
//        font-size: 16px !important;
//        color: #161a31;
//        font-family: "Noto Sans Bengali", sans-serif;
//        font-weight: 500;
//    }
//    .navbar-brand {
//        display: inline-block;
//        padding-top: 1.025rem;
//        padding-bottom: 0.3125rem;
//        margin-right: 1rem;
//        font-size: 1.25rem;
//        line-height: inherit;
//        white-space: nowrap;
//        width: 25%;
//    }
//    .breadcrumbtop {
//        margin: 0px 0px;
//        display: flex;
//        float: left;
//        width: 100%;
//        justify-content: space-between;
//        padding-top: 0px;
//    }
//    .container>div {
//        padding: 0px !important;
//    }
//    .probeshkorun {
//        margin-bottom: 0px;
//    }
//    .paddinghide {
//        padding: 0px;
//    }
//    .editor-icon ul {
//        display: flex;
//        justify-content: space-between;
//        margin-bottom: 0px;
//        justify-content: left;
//        position: relative;
//        padding-left: 0px;
//        margin-left: 0px;
//        /* margin-top: -30px; */
//        flex-direction: row;
//        justify-content: space-between;
//        flex-flow: row;
//        width: 100%;
//    }
//    .editor-icon ul li {
//        /* padding: 10px 14px; */
//        padding: 0px;
//        line-height: 10px;
//        position: relative;
//        display: inline-block;
//        margin: 0px;
//    }
//    .nav-link {
//        display: block;
//        padding: 0px;
//    }
//    button.entry {
//        margin: 10px auto;
//    }
//    .submitbutton {
//        display: flex;
//        justify-content: center;
//        line-height: 60px;
//    }
//    .signupform p {
//        font-size: 12px;
//        color: #707070;
//        font-family: Noto Sans Bengali;
//    }
//    .signupbg {
//        margin-top: 0%;
//    }
//    .breadcrumbtop p img {
//        display: none;
//    }
//}
//
//@media only screen and (max-width: 360px) and (max-height: 640px) {
//    li.topenonefirst {
//        padding-top: 0px;
//        margin-top: -12px;
//        margin-right: 0px;
//    }
//}
//
//@media only screen and (max-width: 320px) {
//    .footer-area {
//        display: flex;
//        float: left;
//        width: 100%;
//        padding: 20px 0px;
//        position: absolute;
//        /* justify-content: space-between; */
//        min-height: 170px;
//        overflow: hidden;
//        flex-direction: column;
//        margin: 0 -18px;
//    }
//    li.nav-item>a.nav-link {
//        font-size: 14px !important;
//        color: #161a31;
//        font-family: "Noto Sans Bengali", sans-serif;
//        font-weight: 500;
//    }
//    .readingrule h3 {
//        text-align: center;
//        font-size: 15px;
//        color: #161a31;
//        margin: 10px;
//        font-family: "Noto Sans Bengali", sans-serif;
//    }
//    .entry {
//        font-size: 14px;
//    }
//    button.mjomadinbutton {
//        font-size: 13px;
//    }
//}
//
//
///*=================== Responsive design ========================================= */

/* -------------------------------------------

Name: 		Standalone
Version:    1.0
Author:		Rabiul
Portfolio:  https://themeforest.net/user/alam_robi

p.s. I am available for Freelance hire (UI design, web development). mail: miller.themes@gmail.com

 ----------------------------------------------*/

 @import url("https://fonts.googleapis.com/css2?family=Hind+Siliguri:wght@300;400;500;600;700&display=swap");
 @import url("https://fonts.maateen.me/solaiman-lipi/font.css");
 @import url("https://fonts.googleapis.com/css2?family=Noto+Sans+Bengali:wght@100;400;500;600;700;800;900&display=swap");
 @import url("https://fonts.maateen.me/solaiman-lipi/font.css");
 
 /* @import url('https://fonts.googleapis.com/css2?family=Hind+Siliguri:wght@300;400;500;600;700&display=swap'); */
 
 
 /*
 1. General CSS
 
 font-family: 'Hind Siliguri', sans-serif;
 font-family: 'SolaimanLipi', Arial, sans-serif !important;
 font-family: 'Noto Sans Bengali', sans-serif;
 
 */
 
 
 /* ============================================
 1. General CSS
 =============================================== */
 
 
 /* -------------------------------------------
 
 Name: 		Standalone
 Version:    1.0
 Author:		Rabiul
 Portfolio:  https://themeforest.net/user/alam_robi
 
 p.s. I am available for Freelance hire (UI design, web development). mail: miller.themes@gmail.com
 
  ----------------------------------------------*/
 
 @import url("https://fonts.googleapis.com/css2?family=Hind+Siliguri:wght@300;400;500;600;700&display=swap");
 @import url("https://fonts.maateen.me/solaiman-lipi/font.css");
 @import url("https://fonts.googleapis.com/css2?family=Noto+Sans+Bengali:wght@100;400;500;600;700;800;900&display=swap");
 @import url("https://fonts.maateen.me/solaiman-lipi/font.css");
 
 /* @import url('https://fonts.googleapis.com/css2?family=Hind+Siliguri:wght@300;400;500;600;700&display=swap'); */
 
 
 /*
 1. General CSS
 
 font-family: 'Hind Siliguri', sans-serif;
 font-family: 'SolaimanLipi', Arial, sans-serif !important;
 font-family: 'Noto Sans Bengali', sans-serif;
 
 */
 
 
 /* ============================================
 1. General CSS
 =============================================== */
 
 :root {
     --main-color: #e98b2c;
     --dark: #1b1b22;
     --dark-light: #32323a;
     --white: #fff;
     --white-light: #c8c8c8;
 }
 
 .middletext {
     justify-content: center;
     align-items: center;
     display: flex;
     min-height: 350px;
     font-size: 30px;
 }
 
 .middletext>p {
     font-size: 30px !important;
 }
 
 .smdeviceview {
     // max-width: 1400px;
     max-width: 1600px;
 }
 
 * {
     margin: 0;
     padding: 0;
     outline: none;
     -webkit-box-sizing: border-box;
     box-sizing: border-box;
     text-decoration: none;
     list-style: none;
 }
 
 ::before,
 ::after {
     box-sizing: border-box;
 }
 
 
 /* img{
       width: 100%;
   } */
 
 body {
     margin: 0;
     line-height: 1.5;
     font-size: 16px;
     height: auto;
     font-family: "Hind Siliguri", sans-serif;
 }
 
 
 /* For scripting */
 
 body.hide-scrolling {
     overflow-y: hidden;
 }
 
 ul {
     list-style-type: none;
 }
 
 a {
     text-decoration: none;
 }
 
 table {
     border-collapse: collapse;
     border-spacing: 0;
 }
 
 .section {
     min-height: 100vh;
     display: block;
     width: 100%;
 }
 
 .shadow-dark2 {
     -webkit-box-shadow: 0 0 20px rgba(48, 46, 77, 0.15);
     box-shadow: 0 0 20px rgba(48, 46, 77, 0.15);
 }
 
 .shadow-dark {
     /*
       -webkit-box-shadow: 0 0 20px rgba(48,46,77,0.15);
       box-shadow: 0 0 20px rgba(48,46,77,0.15); */
     /* -webkit-box-shadow: 0 0 20px rgba(0,0,0,0.15);
       box-shadow: 0 0 20px rgba(0,0,0,0.15); */
     /*
       box-shadow: 2px -4px 8px -3px rgba(0,0,0,0.75);
       -webkit-box-shadow: 2px -4px 8px -3px rgba(0,0,0,0.75);
       -moz-box-shadow: 2px -4px 8px -3px rgba(0,0,0,0.75); */
     /* box-shadow: -2px -1px 24px -16px rgba(0,0,0,0.72) inset;
       -webkit-box-shadow: -2px -1px 24px -16px rgba(0,0,0,0.72) inset;
       -moz-box-shadow: -2px -1px 24px -16px rgba(0,0,0,0.72) inset; */
     box-shadow: -2px -1px 25px -16px rgba(0, 0, 0, 0.72) inset;
     -webkit-box-shadow: -2px -1px 25px -16px rgba(0, 0, 0, 0.72) inset;
     -moz-box-shadow: -2px -1px 25px -16px rgba(0, 0, 0, 0.72) inset;
 }
 
 .rightshadow {
     box-shadow: 2px 0px 5px 2px rgba(0, 0, 0, 0.09);
     -webkit-box-shadow: 2px 0px 5px 2px rgba(0, 0, 0, 0.09);
     -moz-box-shadow: 2px 0px 5px 2px rgba(0, 0, 0, 0.09);
 }
 
 .margin-bottom {
     /* margin-bottom: 60px; */
     display: block;
     float: right;
     width: 100%;
     overflow: hidden;
 }
 
 
 /* ----------------------- Content Design stat Here ------------------------ */
 
 .mtopbtn {
     margin-top: 10px;
 }
 
 .breadcrumbtop a:first-child {
     display: none;
 }
 
 .jachybradcamp {
     display: none !important;
 }
 
 .menuhide .hideinpage li {
     display: none;
 }
 
 .allcontent {
     width: 100%;
     float: left;
     /* background-color: #f6f9ff; */
     /* margin-top: 5%; */
 }
 
 .content-body {
     background-color: #ffffff;
     /* min-height: 83vh; */
     max-height: 800px;
     margin-top: 60px;
     display: block;
     /* float: left; */
     width: 100%;
     overflow: hidden;
     position: relative;
 }
 
 .header-area {
     width: 100%;
     display: block;
     float: left;
     /* padding-bottom: 5px; */
 }
 
 .header-left {
     display: flex;
     flex-wrap: wrap;
     justify-content: left;
 }
 
 .header-right {
     float: right;
 }
 
 .header-left img {
     float: left;
     width: 28px;
     height: 21px;
     margin: 7px 9px 0px 20px;
 }
 
 .header-left h4 {
     font-size: 20px;
     font-family: "Hind Siliguri", sans-serif;
 }
 
 .header-left h4 {
     font-size: 20px;
     font-family: "Hind Siliguri", sans-serif;
     font-weight: 600;
     margin-bottom: 0px;
     line-height: 40px;
     color: #44c1ea;
 }
 
 // .breadcrumbtop {
 //     display: block;
 //     float: left;
 //     width: 100%;
 // }
 .jachybradcamp {
     //margin-top: 90px;
     display: block;
     float: left;
     width: 100%;
     // padding-top: 30px;
 }
 
 .signupbradcump {
     margin-top: 70px;
     display: block;
     float: left;
     width: 100%;
 }
 
 .lefttext p {
     font-size: 26px;
     color: #171717;
     font-family: "SolaimanLipi", Arial, sans-serif !important;
     font-weight: 500;
     line-height: 40px;
     /* align-items: flex-end; */
 }
 
 span.amer {
     color: #059514;
     font-weight: 700;
     font-family: "Noto Sans Bengali", sans-serif;
 }
 
 span.kontho {
     color: #ec411b;
     font-weight: 700;
     font-family: "Noto Sans Bengali", sans-serif;
 }
 
 button.noborder {
     border: none;
 }
 
 .noborder:focus {
     box-shadow: none;
 }
 
 
 /* --------------------------------------- main-content -------------------------- */
 
 .main-content {
     display: block;
     float: left;
     width: 100%;
     /* height: 100vh; */
     /* overflow: hidden; */
 }
 
 
 /* .editor-icon {
   display: block;
   float: left;
   width: 100%;
 } */
 
 .editor-icon {
     /* display: flex;
     float: left; */
     /* width: 100%; */
     /* padding: 2px 23px 0px 0px; */
     /* position: relative; */
     /* background-color: #136EE5; */
     /* margin-left: 20px; */
     /* margin-right: 0px; */
     /* margin-top: 20px; */
     /* justify-content: space-between;
     position: absolute; */
 }
 
 .editor-icon {
     display: flex;
     width: 100%;
     float: left;
     /* background: red; */
     justify-content: space-between;
     // max-height: 115px;
     border: none !important;
 }
 
 .iconvoice {
     position: absolute;
     right: 0px;
     top: -63px;
 }
 
 p.specific-icon a img {
     display: block;
     float: left;
     /* width: 20px;
       height: 20px; */
 }
 
 .editor-icon ul {
     display: flex;
     // justify-content: space-between;
     margin-bottom: 0px;
     // justify-content: left;
     position: relative;
     padding-left: 0px;
     margin-left: -40px;
 }
 
 .editor-icon ul li {
     /* padding: 10px 14px; */
     // padding: 0px 14px 0px 0px;
     /* line-height: 147px; */
     position: relative;
     // display: inline-block;
     // float: left;
 }
 
 li.speakerbg {
     // position: absolute;
     // top: -16px;
 }
 
 .ascii_unicode {
     background-color: #ffffff;
     padding: 10px;
     height: 45px;
     padding-top: 5px;
     border-radius: 5px;
     margin-right: 10px;
     /* margin-top: 5px; */
     padding: 10px 20px !important;
     margin-left: 15px;
 }
 
 
 /* span.ascii {
   background-color: #136EE5;
   border-radius: 3px;
   color: #adb1bf;
 } */
 
 span.ascii a {
     text-decoration: none;
     color: #fff;
     font-family: "Hind Siliguri", sans-serif;
     font-size: 15px;
     padding: 0px 10px;
 }
 
 span.unicode a {
     text-decoration: none;
     color: #adb1bf;
     font-family: "Hind Siliguri", sans-serif;
     padding-left: 5px;
     padding-right: 5px;
 }
 
 li.linebar {
     background-color: #ffffff;
     padding: 10px;
     height: 30px;
     padding-top: 5px;
     border-radius: 5px;
     padding: 10px 3px !important;
 }
 
 span.inlinebar {
     border-radius: 2px;
 }
 
 span.inlinebar a {
     text-decoration: none;
     color: #adb1bf;
     font-family: "Hind Siliguri", sans-serif;
     font-size: 15px;
     padding: 0px 10px;
     padding-left: 2px;
     text-transform: uppercase;
 }
 
 span.barside {
     border-radius: 3px;
     -webkit-border-radius: 3px;
     -moz-border-radius: 3px;
     -ms-border-radius: 3px;
     -o-border-radius: 3px;
 }
 
 span.barside a {
     text-decoration: none;
     color: #fff;
     font-family: "Hind Siliguri", sans-serif;
     background-color: #136ee5;
     border-radius: 3px;
     padding: 0px 5px;
     text-transform: uppercase;
 }
 
 span.barside2 {
     border-radius: 3px;
     -webkit-border-radius: 3px;
     -moz-border-radius: 3px;
     -ms-border-radius: 3px;
     -o-border-radius: 3px;
     text-transform: uppercase;
 }
 
 span.barside2 a {
     text-decoration: none;
     color: #adb1bf;
     font-family: "Hind Siliguri", sans-serif;
     /* background-color: #44C1EA; */
     border-radius: 3px;
     padding: 0px 5px;
     text-transform: uppercase;
 }
 
 .withsidebar-content {
     /* background-color: #f6f9ff; */
     background-color: rgba(246, 249, 255, 0.2);
     float: right;
     width: 100%;
     min-height: 55vh;
     display: flex;
     /* overflow-x: hidden; */
     position: relative;
     flex-wrap: wrap;
     justify-content: flex-end;
     /* overflow: hidden; */
     /* padding: 30px 30px 0px; */
 }
 
 
 /*================================= Sidebar area ================================ */
 
 .rightsidebar {
     display: block;
     float: left;
     width: 100%;
     padding: 20px 20px 0px;
     -webkit-box-shadow: 0px 3px 6px 0px rgba(0, 0, 0, 0.16);
     box-shadow: 0px 3px 6px 0px rgba(0, 0, 0, 0.16);
     border-radius: 4px;
     background: rgb(255, 255, 255);
     background: #f6f9ff;
     // margin-bottom: 60px;
     min-height: 460px;
 }
 
 .msinglecard p {
     color: #171717;
     font-family: SolaimanLipi;
     font-size: 16px;
     margin-bottom: 0px;
 }
 
 
 /* .tab-container-one {
   margin-top: 40px;
 } */
 
 
 /*================================= Sidebar area ================================ */
 
 .main-text-content {
     width: 100%;
     /* width: 70%; */
     display: block;
     float: left;
     transition: all 0.3s ease-in;
     -webkit-transition: all 0.3s ease-in;
     -moz-transition: all 0.3s ease-in;
     -ms-transition: all 0.3s ease-in;
     -o-transition: all 0.3s ease-in;
     /* margin: 0px 20px 0px 20px; */
     background-color: rgba(255, 255, 255, 0.2);
     overflow-x: hidden;
     max-height: 350px;
     // overflow-y: scroll;
     /* position: relative; */
     border-radius: 5px;
     /* padding: 30px; */
     // background: url("./images/stts/miketest.png") no-repeat center center;
 }
 
 .main-text-content p {
     padding: 15px 0px 0px 10px;
     font-size: 18px;
     font-family: "SolaimanLipi", Arial, sans-serif !important;
     color: #303030;
     overflow: hidden;
     text-align: center;
 }
 
 span.textborder {
     border-bottom: 2px solid #e88992;
 }
 
 span.textborder2 {
     border-bottom: 2px solid #9f71ff;
 }
 
 span.textborder3 {
     border-bottom: 2px solid #7ed1c5;
 }
 
 span.textborder3 {
     border-bottom: 2px solid #7ed1c5;
 }
 
 span.textborder4 {
     border-bottom: 2px solid #c8c85f;
 }
 
 .right-sidebar {
     position: absolute;
     top: 0;
     right: 0px;
     /* width: 30%; */
     width: 0%;
     /* height: 85vh; */
     background: #fff;
     padding: 0px 0px 0px 0px;
     display: block;
     float: left;
     overflow: hidden;
     max-height: 450px;
     overflow-y: auto;
 }
 
 
 /* ul.rightsidebarbook {
     overflow-y: scroll;
     overflow-x: hidden;
     overflow: auto;
     min-height: 500px;
     display: block;
     float: left;
     width: 100%;
     padding-left: 10px;
 } */
 
 ul.rightsidebarbook {
     display: block;
     float: left;
     width: 100%;
     padding-left: 10px;
 }
 
 #boxscroll::-webkit-scrollbar-track {
     -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
     background-color: #f5f5f5;
 }
 
 #boxscroll::-webkit-scrollbar {
     width: 6px;
     background-color: #f5f5f5;
 }
 
 #boxscroll::-webkit-scrollbar-thumb {
     background-color: #c0c3c7;
 }
 
 #boxscroll2::-webkit-scrollbar-track {
     -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
     background-color: #f5f5f5;
 }
 
 #boxscroll2::-webkit-scrollbar {
     width: 6px;
     background-color: #f5f5f5;
 }
 
 #boxscroll2::-webkit-scrollbar-thumb {
     background-color: #c0c3c7;
 }
 
 #check:checked~.container .row .withsidebar-content .right-sidebar {
     right: 0;
     width: 30%;
     transition: all 0.3s ease-in;
     -webkit-transition: all 0.3s ease-in;
     -moz-transition: all 0.3s ease-in;
     -ms-transition: all 0.3s ease-in;
     -o-transition: all 0.3s ease-in;
 }
 
 #check:checked~.container .row .withsidebar-content .main-text-content {
     width: 67%;
     transition: all 0.3s ease-in;
     -webkit-transition: all 0.3s ease-in;
     -moz-transition: all 0.3s ease-in;
     -ms-transition: all 0.3s ease-in;
     -o-transition: all 0.3s ease-in;
 }
 
 #check:checked~.container .row .headertoptext {
     display: block;
 }
 
 #sidebar_btn2 {
     cursor: pointer;
 }
 
 .headertoptext {
     display: none;
 }
 
 #check {
     display: none;
 }
 
 ul.rightsidebarbook li a span.rightarrow img {
     width: 5px;
     height: 8px;
 }
 
 ul.rightsidebarbook li a span.drop_arrow img {
     width: 6px;
     height: 3px;
 }
 
 ul.rightsidebarbook li a span.crowsbook img {
     width: 14px;
     height: 34px;
 }
 
 .errorcolor img {
     width: 10px;
     height: 10px;
 }
 
 .errorcolor .iconimg {
     width: 12px;
     height: 12px;
     background: red;
     margin: 5px 5px 0px 0px;
     box-shadow: inset 0px 0px 0px 3px white;
     border: 1px solid #fff;
     float: left;
     border-radius: 100%;
     text-align: left;
     float: left;
 }
 
 ul.rightsidebarbook li {
     margin-bottom: 10px;
     margin-top: 10px;
     padding: 15px 20px;
     background-color: #fff;
     border-radius: 4px;
     -webkit-border-radius: 5px;
     -moz-border-radius: 4px;
     -ms-border-radius: 4px;
     -o-border-radius: 4px;
     margin-right: 20px;
     min-height: 60px;
 }
 
 ul.rightsidebarbook .firstli {
     margin-bottom: 10px;
     margin-top: 10px;
     padding: 15px 20px;
     background-color: #fff;
     border-radius: 4px;
     -webkit-border-radius: 5px;
     -moz-border-radius: 4px;
     -ms-border-radius: 4px;
     -o-border-radius: 4px;
     margin-right: 20px;
     min-height: 150px;
 }
 
 span.firstleft {
     padding: 0px 30px 0px 0px;
 }
 
 span.firstleft img {
     margin-left: 20px;
 }
 
 span.chrowtext {
     color: #1a1818;
     text-decoration: line-through;
 }
 
 span.firstleft {
     padding: 0px 15px 0px 0px;
     color: red;
     text-decoration: line-through;
     display: block;
     float: left;
 }
 
 a.withbutton {
     display: block;
     float: left;
     width: 55%;
 }
 
 span.secondleft {
     margin-right: 108px;
 }
 
 button.btn.btnbesorkari {
     background-color: #009cdc;
     color: #fff;
     border-radius: 5px;
     font-size: 13px;
     padding: 2px 15px;
     line-height: 16px;
 }
 
 button.btn.btnbesorkari2 {
     background-color: #44c1ea;
     color: #fff;
     border-radius: 5px;
     font-size: 13px;
     padding: 2px 15px;
     line-height: 16px;
     margin: 5px 0px;
 }
 
 ul.rightsidebarbook li a {
     text-decoration: none;
     font-family: "SolaimanLipi", Arial, sans-serif !important;
     font-size: 13px;
     float: left;
     text-align: left;
 }
 
 .rightsidebarbook hr {
     margin-top: 75px;
     margin-bottom: 15px;
     border: 0;
     border-top: 1px solid rgba(0, 0, 0, 0.1);
 }
 
 hr.topbottomargin {
     margin-top: 20px;
     margin-bottom: 0px;
     border: 0;
     border-top: 1px solid rgba(0, 0, 0, 0.1);
 }
 
 a.errorcolor {
     display: block;
 }
 
 span.icontent {
     color: #6e6e6e;
     font-size: 13px;
 }
 
 ul.dropdownmenu {
     display: block;
     float: left;
     width: 100%;
 }
 
 ul.dropdownmenu li {
     display: inline-block;
     width: 100%;
 }
 
 p.totalerror {
     position: absolute;
     right: 155px;
     top: 45px;
     font-size: 20px;
     font-family: "Hind Siliguri", sans-serif;
     color: #44c1ea;
     font-weight: 500;
 }
 
 span.count {
     border: 1px solid #44c1ea;
     padding: 3px 10px;
     font-size: 13px;
     line-height: 10px;
     border-radius: 5px;
     font-weight: 700;
     /* margin-top: -18px; */
     display: block;
     float: left;
     margin-top: 6px;
     margin-left: 5px;
 }
 
 span.counttext {
     margin-top: 0px !important;
     display: block;
     float: left;
 }
 
 .textthrow {
     color: red;
     text-decoration: line-through;
 }
 
 
 /* ======================== Dropd down menu ============================ */
 
 .parentsetting {
     position: relative;
 }
 
 .click {
     font-size: 10px;
     font-family: inherit;
     border: none;
     outline: none;
     width: 300px;
     color: #556170;
     transition: 0.3s;
     position: relative;
 }
 
 .check2 {
     /* padding: 12px; */
     font-size: 10px;
     font-family: inherit;
     border: none;
     outline: none;
     width: 300px;
     color: #556170;
     transition: 0.3s;
     text-decoration: none;
     position: relative;
 }
 
 .links {
     padding: 12px 0px;
     font-size: 13px;
     font-family: "Hind Siliguri", sans-serif;
     border: none;
     outline: none;
     width: 185px;
     color: #fff;
     transition: 0.3s;
     background-color: #fff;
 }
 
 .list {
     position: absolute;
     transform: scaleY(0);
     transform-origin: top;
     transition: 0.3s;
 }
 
 .dropdown-item {
     padding: 0.25rem 0.5rem !important;
 }
 
 span.dropleftimg {
     display: block;
     float: left;
 }
 
 span.droprightimg {
     display: block;
     float: right;
     text-align: right;
 }
 
 .newlist {
     transform: scaleY(1);
     z-index: 999;
 }
 
 .links:hover {
     /* background-color: #01579B; */
     background-color: #ededed;
     transform: scale(1.1);
 }
 
 img.whaticon {
     margin-right: 25px;
 }
 
 span.borderall {
     border: 1px solid #ededed;
     padding: 3px 7px;
     border-radius: 3px;
 }
 
 
 /*
 span.setting_icon img {
     position: absolute;
     top: 20px;
     left:10px;
 } */
 
 li.borderight {
     border-right: 1px solid #fff;
     /* height: 15px; */
 }
 
 li.borderleft {
     border-left: 1px solid #fff;
     /* height: 15px; */
 }
 
 li.topnone {
     padding-top: 3px !important;
 }
 
 li.topenonefirst {
     // padding-top: 0px;
     /* margin-top: -12px; */
     // margin-right: 5px;
 }
 
 // .clickposition {
 //     position: relative !important;
 // }
 // .clickposition img {
 //     left: 481px !important;
 // }
 // .footer-area.clickposition img {
 //     left: 481px;
 // }
 li.topnone.rightcontent {
     margin-left: 42px;
 }
 
 img.sametext.righimg {
     width: 100%;
     height: auto;
     // padding-top: 5px;
     //transition: 1s ease;
     // transition: height 0.66s ease-out;
 }
 
 .bolunactive img {
     padding-top: 0px !important;
     //transition: 0.2s ease;
 }
 
 
 /* ======================== Dropd down menu ============================ */
 
 
 /*=================== Show Content ========================================= */
 
 a#dropdownMenuLink {
     width: 300px;
 }
 
 span.errortext {
     text-align: left;
     display: block;
     float: left;
     color: #303030;
     font-size: 20px;
     font-family: "SolaimanLipi", Arial, sans-serif !important;
 }
 
 span.erroecount {
     text-align: right;
     display: block;
     float: right;
     background-color: #ff8000;
     color: #fff;
     padding: 5px 15px;
     font-family: "SolaimanLipi", Arial, sans-serif !important;
     border-radius: 6px;
 }
 
 .dropdown-toggle::after {
     display: none;
 }
 
 .areaexpand:focus {
     box-shadow: none;
 }
 
 .dropdown-menu.showcount {
     width: 94%;
     border: none;
 }
 
 span.banantext {
     display: block;
     float: left;
     font-family: "SolaimanLipi", Arial, sans-serif !important;
     text-align: left;
 }
 
 span.banancount {
     display: block;
     float: right;
     text-align: right;
     font-family: "SolaimanLipi", Arial, sans-serif !important;
 }
 
 a.errorcount {
     display: block;
     float: left;
     width: 95%;
     margin-left: 8px;
     margin-bottom: 15px;
 }
 
 .rgbred {
     background-color: rgba(236, 65, 27, 0.1);
     padding: 20px 5px;
     border-radius: 5px;
     -webkit-border-radius: 5px;
     -moz-border-radius: 5px;
     -ms-border-radius: 5px;
     -o-border-radius: 5px;
 }
 
 .rgbred span {
     padding: 5px;
 }
 
 .errorcount.active,
 .errorcount:active {
     color: #303030;
     text-decoration: none;
     background-color: none !important;
 }
 
 .errorcount.active,
 .errorcount:hover {
     color: #303030;
     text-decoration: none;
     background-color: none !important;
 }
 
 span.rgbared {
     background-color: #ec411b;
     color: #fff;
     padding: 5px 14px;
     border-radius: 8px;
 }
 
 .rgbyellow {
     background-color: rgba(210, 136, 60, 0.1);
     padding: 20px 5px;
     border-radius: 5px;
     -webkit-border-radius: 5px;
     -moz-border-radius: 5px;
     -ms-border-radius: 5px;
     -o-border-radius: 5px;
 }
 
 .rgbyellow span {
     padding: 5px;
 }
 
 span.rgbayellow {
     background-color: #d2883c;
     color: #fff;
     padding: 5px 14px;
     border-radius: 8px;
 }
 
 .rgbbiolet {
     background-color: rgba(159, 113, 255, 0.1);
     padding: 20px 5px;
     border-radius: 5px;
     -webkit-border-radius: 5px;
     -moz-border-radius: 5px;
     -ms-border-radius: 5px;
     -o-border-radius: 5px;
 }
 
 .rgbbiolet span {
     padding: 5px;
 }
 
 span.rgbabiolet {
     background-color: #9f71ff;
     color: #fff;
     padding: 5px 14px;
     border-radius: 8px;
 }
 
 .rgbgreen {
     background-color: rgba(36, 196, 184, 0.1);
     padding: 20px 5px;
     border-radius: 5px;
     -webkit-border-radius: 5px;
     -moz-border-radius: 5px;
     -ms-border-radius: 5px;
     -o-border-radius: 5px;
 }
 
 .rgbgreen span {
     padding: 5px;
 }
 
 span.rgbagreen {
     background-color: #136ee5;
     color: #fff;
     padding: 5px 14px;
     border-radius: 8px;
 }
 
 
 /*=================== Show Content ========================================= */
 
 
 /*=================== setting Show Content ========================================= */
 
 .settingshow {
     position: absolute !important;
     transform: translate3d(-200px, 21px, 0px) !important;
     top: 0px;
     left: 0px;
     will-change: transform;
     -webkit-transform: translate3d(-200px, 21px, 0px) !important;
     -moz-transform: translate3d(-200px, 21px, 0px) !important;
     -ms-transform: translate3d(-200px, 21px, 0px) !important;
     -o-transform: translate3d(-200px, 21px, 0px) !important;
     width: 220px;
     border: none;
     margin-top: 10px;
     padding: 10px;
 }
 
 span.dictionary {
     display: block;
     float: left;
     width: 20px;
     height: auto;
     margin-right: 15px;
 }
 
 span.obidancheck {
     display: block;
     float: right;
     width: 0px;
     text-align: right;
 }
 
 a.dictionaryitem {
     display: block;
     float: left;
     width: 100%;
     /* flex-direction: row; */
     /* float: left; */
     margin-bottom: 15px;
 }
 
 span.obidantext {
     font-size: 12px;
     font-family: "SolaimanLipi", Arial, sans-serif !important;
     /* float: left; */
     /* text-align: left; */
     /* display: block; */
 }
 
 input[id="cb1"]+label {
     border: 1px solid #44c1ea !important;
 }
 
 .dictionaryitem:focus,
 .dictionaryitem:hover {
     color: #16181b;
     text-decoration: none;
     background-color: transparent;
 }
 
 img.crowimage {
     text-align: right;
     float: right;
     display: block;
 }
 
 .crowimage.firstcrow {
     display: block;
     float: right;
     text-align: right;
     /* margin-top: -20px; */
 }
 
 
 /*=================== Active Inactive Main content Content ========================================= */
 
 .ascii_unicode.shadow-dark2 span a.active {
     background-color: #136ee5;
     border-radius: 3px;
     color: #fff;
 }
 
 .ascii_unicode.shadow-dark2 span a.active1 {
     background-color: #136ee5;
     border-radius: 3px;
     color: #fff;
 }
 
 .ascii_unicode.shadow-dark2 span a.active2 {
     background-color: #136ee5;
     border-radius: 3px;
     color: #fff;
 }
 
 .ascii_unicode.shadow-dark2 span a.active3 {
     background-color: #136ee5;
     border-radius: 3px;
     color: #fff;
 }
 
 .ascii_unicode.shadow-dark2 span a {
     color: #136ee5;
 }
 
 ul.playstop {
     display: block;
     float: right;
     text-align: right;
 }
 
 .bottomspeaker {
     position: absolute;
     bottom: 20px;
     right: 20px;
 }
 
 .dropdowninner {
     background-color: #e9edf2;
     display: block;
     float: left;
     width: 100%;
     padding: 5px;
 }
 
 h4.innertitle {
     /* background-color: #136ee5; */
     display: block;
     float: left;
     width: 100%;
     padding: 10px;
     color: #136ee5;
     margin-bottom: 0px;
     font-family: "Hind Siliguri", sans-serif;
 }
 
 .ageselect {
     display: flex;
     flex-direction: row;
     justify-content: space-between;
     float: left;
     width: 90%;
     margin-left: 0px;
     /* padding: 0px 15px; */
     margin: 0px 10px;
 }
 
 span.genter a {
     padding: 0px 27px;
 }
 
 
 /* ====================================== Progress Bar Design================================== */
 
 .progressbar {
     display: block;
     float: left;
     width: 100%;
 }
 
 div#audio-player-container {
     display: block;
     float: right;
     width: 90%;
 }
 
 div#audio-player-container1 {
     display: block;
     float: right;
     width: 90%;
 }
 
 span.gotilevel {
     display: block;
     float: left;
     width: 10%;
     color: #fff;
     font-weight: bold;
 }
 
 .audio-progress {
     height: 0.5rem;
     width: 90%;
     background-color: #fff;
     float: right;
     border-radius: 10px;
     text-align: right;
 }
 
 .audio-progress .bar {
     height: 100%;
     background-color: #fff;
     border-radius: 10px;
 }
 
 .audio-progress1 {
     height: 0.5rem;
     width: 90%;
     background-color: #fff;
     float: right;
     border-radius: 10px;
     text-align: right;
 }
 
 .audio-progress1 .bar {
     height: 100%;
     background-color: #fff;
     border-radius: 10px;
 }
 
 .progressbar {
     display: block;
     float: left;
     width: 100%;
     background-color: #136ee5;
     padding: 10px;
     border: 5px solid #fff;
     border-radius: 5px;
 }
 
 #audio-progress-handle {
     display: block;
     position: absolute;
     z-index: 1;
     margin-top: -8px;
     margin-left: -95px;
     width: 20px;
     height: 20px;
     border: 4px solid #fff;
     border-top-color: #fff;
     border-right-color: #fff;
     transform: rotate(45deg);
     border-radius: 100%;
     background-color: #fff;
     box-shadow: 0 1px 6px rgba(0, 0, 0, 0.2);
     cursor: pointer;
 }
 
 #audio-progress-handle1 {
     display: block;
     position: absolute;
     z-index: 1;
     margin-top: -8px;
     margin-left: -95px;
     width: 20px;
     height: 20px;
     border: 4px solid #fff;
     border-top-color: #fff;
     border-right-color: #fff;
     transform: rotate(45deg);
     border-radius: 100%;
     background-color: #fff;
     box-shadow: 0 1px 6px rgba(0, 0, 0, 0.2);
     cursor: pointer;
 }
 
 .draggable {
     float: left;
     margin: 0 10px 10px 0;
 }
 
 div#myDIV1 {
     display: block;
     float: left;
     border-left: 1px solid #fff;
 }
 
 .mobileview {
     display: flex;
     width: 100%;
 }
 
 .ongicernama {
     margin-top: 25px;
 }
 
 span.mostrequired {
     color: #ec411b;
 }
 
 .btnprofile {
     outline: 0;
     box-shadow: none !important;
 }
 
 .btnprofile {
     outline: 0;
     box-shadow: none !important;
     padding-top: 0px;
     margin-left: 30px;
 }
 
 .agiyejan {
     border: none;
     background-color: transparent;
 }
 
 
 /* ====================================== Progress Bar Design================================== */
 
 
 /* --------------------------------------- main-content -------------------------- */
 
 
 /* --------------------------------------- footer-content -------------------------- */
 
 .footer-area {
     display: flex;
     width: 100%;
     padding: 20px 30px;
     justify-content: center;
     min-height: 170px;
     align-items: center;
 }
 
 
 /* .footer-area a img:hover {
   transition: all 1s ease-in-out;
   -webkit-transition: all 1s ease-in-out;
   display: flex;
   float: left;
   width: 70%;
   padding: 20px 30px;
   position: absolute;
   bottom: 5px;
   justify-content: space-between;
   height: 285px;
   overflow: hidden;
   padding-left: 80px;
 } */
 
 .threeplay ul li a.agiyejansunun {
     margin-left: 140px;
     /* margin-bottom: 60px; */
     padding-right: 0px;
 }
 
 ul.footer-item {
     display: flex;
     flex-wrap: wrap;
     justify-content: flex-end;
     margin-bottom: 0px;
 }
 
 ul.footer-item li {
     margin: 0px 10px;
     line-height: 45px;
 }
 
 p.specific-icon {
     margin-left: 100px;
     margin-bottom: 2px;
 }
 
 .footer-areabottom {
     display: flex;
     /* float: left; */
     width: 100%;
     padding: 0px 0px;
     position: fixed;
     left: 0px;
     bottom: 0px;
     justify-content: space-between;
     background-color: rgba(255, 255, 255, 1);
     -webkit-box-shadow: -11px 8px 15px -7px #000000;
     box-shadow: -11px 8px 15px -7px #000000;
     flex-direction: row;
     flex-wrap: wrap;
 }
 
 .footer-areabottom ul.footer-item {
     display: flex;
     flex-wrap: wrap;
     justify-content: flex-end;
     margin-bottom: 0px;
 }
 
 .footer-areabottom ul.footer-item li {
     margin: 0px 10px;
     line-height: 45px;
 }
 
 .developed a {
     color: #919191;
     font-size: 16px;
 }
 
 .reveclass {
     color: #0c5bc2;
     font-size: 16px;
 }
 
 .copyclass a {
     color: #919191;
     font-size: 16px;
 }
 
 .signupbradcump {
     display: flex;
     justify-content: flex-start;
     align-items: center;
     /* height: 100%; */
     margin-top: 180px;
 }
 
 
 /* ============================================== Signupform  design================================== */
 
 .signupform h4 {
     font-size: 30px;
     color: #44af4f;
     font-family: Noto Sans Bengali;
 }
 
 .signupform p {
     font-size: 14px;
     color: #707070;
     font-family: Noto Sans Bengali;
 }
 
 .withcheckgroup p {
     font-size: 14px;
     color: #707070;
     font-family: Noto Sans Bengali;
     width: 40%;
 }
 
 .signupform {
     display: flex;
     float: left;
     width: 100%;
     background-color: #fff;
     border-radius: 10x;
     padding: 70px 60px;
     margin-bottom: 100px;
     -webkit-border-radius: 10x;
     -moz-border-radius: 10x;
     -ms-border-radius: 10x;
     -o-border-radius: 10x;
     /* margin-top: 30px; */
     flex-direction: column;
     min-height: 1170px;
     border-radius: 48px;
 }
 
 .signupformnoborder {
     display: block;
     float: left;
     width: 100%;
     background-color: #fff;
     /* border-top: 10px solid #44af4f; */
     /* border-radius: 52px; */
     padding: 30px;
 }
 
 .loginform h4 {
     font-size: 30px;
     color: #136ee5;
     font-family: Noto Sans Bengali;
 }
 
 .loginform p {
     font-size: 14px;
     color: #707070;
     font-family: Noto Sans Bengali;
 }
 
 .logindetails {
     display: flex;
     justify-content: flex-end;
     align-items: flex-end;
 }
 
 .loginform {
     display: flex;
     float: left;
     width: 646px;
     background-color: #fff;
     /*border-top: 10px solid #116de6;
     */
     border-radius: 48px;
     padding: 30px 50px;
     //margin: 0 auto;
     height: 685px;
     flex-direction: column;
     -webkit-border-radius: 48px;
     -moz-border-radius: 48px;
     -ms-border-radius: 48px;
     -o-border-radius: 48px;
 }
 
 .ongikercheckbox {
     display: grid;
     grid-template-columns: 1em auto;
     gap: 0.5em;
     width: 28px;
     height: 28px;
 }
 
 .errorcolor {
     color: red;
     margin-left: 5px;
 }
 
 span.ongicernamasugn {
     margin-left: 30px;
 }
 
 label.containercenter {
     display: flex;
     align-items: center;
 }
 
 .samesize {
     min-height: 52px;
 }
 
 .innerlogin {
     width: 476px;
     display: flex;
     flex-direction: column;
     height: 650px;
     justify-content: center;
     margin: 0 auto;
 }
 
 h5.mowlictitle {
     background-color: #f4f4f8;
     padding: 10px;
     margin: 20px 0px;
 }
 
 .withcheckgroup {
     display: flex;
     justify-content: space-between;
     width: 100%;
 }
 
 .submitbutton {
     display: flex;
     justify-content: flex-end;
     line-height: 60px;
 }
 
 .checkboxgroup {
     display: flex;
     justify-content: space-between;
     grid-gap: 8px;
     grid-gap: 0px;
     gap: 0px;
     width: 63%;
 }
 .chec
 
 span.leftm {
     margin-left: 10px;
 }
 
 .loginbutton {
     display: flex;
     justify-content: flex-start;
 }
 
 .row.mtopbottom {
     // margin: 25px 0px;
     margin: 0px 0px;
 }
 
 .row.mtopbottomlogin {
     // margin: 25px 0px;
     margin: 35px 0px;
 }
 
 #c1:checked+#finddata {
     display: none;
 }
 
 .checktextcontent p {
     margin-top: 30px;
     background-color: #f4f4f8;
     padding: 10px;
     color: #000000;
     font-size: 16px;
 }
 
 .row.lastbottom {
     margin: 50px 0px 0px 0px;
 }
 
 .passreset {
     text-align: right;
 }
 
 .passreset a {
     color: #919191;
 }
 
 h5.kenoamerkonto {
     font-size: 32px;
     font-family: "Noto Sans Bengali", sans-serif;
     margin-bottom: 25px;
 }
 
 li.nav-item>a.nav-link {
     font-size: 20px;
     color: #161a31;
     font-family: "Noto Sans Bengali", sans-serif;
     font-weight: 500;
 }
 
 span.navbar-toggler-icon {
     margin-right: 20px;
 }
 
 h6.porerager {
     text-align: center;
 }
 
 h6.porersunte {
     text-align: center;
 }
 
 
 /* ============================================== Signupform design================================== */
 
 .ogicarnama {
     color: #303030;
     font-size: 22px;
 }
 
 .bodyongicar p {
     color: #000000;
     font-size: 16px;
 }
 
 .bodyongicar p {
     color: #000000;
     font-size: 14px;
     font-weight: 600;
     line-height: 25px;
 }
 
 .osomoto {
     color: #fff;
     background-color: #ec411b;
     border: none;
 }
 
 .akmot {
     color: #fff;
     background-color: #44af4f;
     border-color: #44af4f;
 }
 
 .chartarea {
     display: block;
     float: left;
     width: 100%;
 }
 
 .chartarea img {
     margin-bottom: 100px;
 }
 
 a.bottomwave img {
     padding-left: 80px;
 }
 
 .confirm {
     margin-top: 100px;
 }
 
 // .startwate {
 //     background: url("images/stts/wave.png") no-repeat;
 //     width: 100% !important;
 //     height: 132px !important;
 //     border: none;
 //     background-position: center;
 //     margin-top: 50px;
 //     position: relative;
 // }
 .startwate {
     background: url("images/stts/wave.png") no-repeat;
     width: 100% !important;
     height: 132px !important;
     border: none;
     background-position: center;
     margin-top: 50px;
     position: relative;
     /* z-index: 999999999999999999999999 !important; */
     display: flex;
     align-items: center;
     justify-content: center;
 }
 
 button.bottomwave {
     cursor: pointer;
     border: none;
     background: transparent;
     align-items: center;
 }
 
 .footerdownload {
     background: url("images/stts/profilesunun.png") no-repeat;
     //   background: url("images/stts/playsunun.png") no-repeat;
     width: 100% !important;
     height: 132px !important;
     overflow: visible;
     border: none;
     // margin-bottom: -60px;
     background-position: center;
     margin-top: 50px;
     position: relative;
 }
 
 .stop {
     background: url("images/stts/Loader-final.gif") no-repeat;
     width: 100% !important;
     height: 132px !important;
     overflow: hidden;
     background-position: center;
     margin-top: 50px;
     position: relative;
     display: flex;
     align-items: center;
     justify-content: center;
 }
 
 // button.bottomwave img {
 //     width: 64px;
 //     height: auto;
 //     position: absolute;
 //     top: 34px;
 //     left: 423px;
 //     cursor: pointer;
 //     background: none;
 //     opacity: 0;
 // }
 button.bottomwave {
     display: flex;
     height: auto;
     justify-content: center;
     align-items: center;
     border: none;
     margin-right: 7px;
 }
 
 .audio1 {
     border: none;
     background: transparent;
 }
 
 .pausesize1 {
     width: 24px;
     height: 24px;
 }
 
 .rerecord1 {
     border: none;
     background: transparent;
 }
 
 .audio2 {
     border: none;
     background: transparent;
 }
 
 .pausesize2 {
     width: 24px;
     height: 24px;
 }
 
 .rerecord2 {
     border: none;
     background: transparent;
 }
 
 .audio3 {
     border: none;
     background: transparent;
 }
 
 .pausesize3 {
     width: 24px;
     height: 24px;
 }
 
 .rerecord3 {
     border: none;
     background: transparent;
 }
 
 .audio4 {
     border: none;
     background: transparent;
 }
 
 .pausesize4 {
     width: 24px;
     height: 24px;
 }
 
 .rerecord4 {
     border: none;
     background: transparent;
 }
 
 .audio5 {
     border: none;
     background: transparent;
 }
 
 .pausesize5 {
     width: 24px;
     height: 24px;
 }
 
 .rerecord5 {
     border: none;
     background: transparent;
 }
 
 .jomadinbutton {
     border: none;
     background: transparent;
 }
 
 .modaljomadin {
     border: none;
     background: transparent;
 }
 
 .probeshkorun {
     border: none;
     background: transparent;
 }
 
 .ha-button {
     border: none;
     background: transparent;
 }
 
 .na-button {
     border: none;
     background: transparent;
 }
 
 .play-button {
     border: none;
     background: transparent;
 }
 
 .ha-button {
     border: none;
     background: transparent;
 }
 
 .ariyejan-button {
     border: none;
     background: transparent;
 }
 
 .noactive {
     border: none;
     background: transparent;
 }
 
 .stop-button {
     border: none;
     background: transparent;
 }
 
 button.sunun {
     border: none;
     background: transparent;
     margin-top: 7px;
     margin-left: 0px;
 }
 
 .bolun img {
     width: 142px;
     height: 142px;
     // margin-top: 20px;
 }
 
 
 /* --------------------------------------- 30.06.2022  page align fix-------------------------- */
 
 .alignnav {
     justify-content: space-between !important;
 }
 
 .footerdownload {
     display: flex;
     justify-content: space-between;
     width: 100%;
 }
 
 button.disbutton {
     display: flex;
     width: 100%;
     justify-content: center;
     /* text-align: center; */
     background: #d1d6e1;
     /* height: 20px; */
     text-align: center;
     padding: 25px;
     color: #fff;
     font-size: 20px;
     position: relative;
     border-radius: 5px;
 }
 
 button.jomadinbutton {
     display: flex;
     width: 100%;
     justify-content: space-between;
     /* text-align: center; */
     background: #136ee5;
     /* height: 20px; */
     text-align: center;
     padding: 25px;
     color: #fff;
     font-size: 20px;
     position: relative;
     border-radius: 5px;
 }
 
 button.jomadinbuttondisable {
     display: flex;
     width: 100%;
     justify-content: center;
     /* text-align: center; */
     background: #d1d6e1;
     /* height: 20px; */
     align-items: center;
     padding: 10px 20px;
     color: #fff;
     font-size: 20px;
     position: relative;
     justify-content: space-between;
     border-color: #d1d6e1;
 }
 
 button.mjomadinbutton {
     display: flex;
     max-width: 100%;
     justify-content: space-around;
     /* text-align: center; */
     background: #136ee5;
     /* height: 20px; */
     text-align: center;
     color: #fff;
     font-size: 20px;
     position: relative;
     border-radius: 5px;
     border: none;
     width: 135px;
     /* line-height: 22px; */
     align-items: center;
     line-height: 45px;
     padding: 0px 10px;
 }
 
 .widthlow {
     width: 200px;
 }
 
 button.mjomadinbuttonhide {
     display: flex;
     max-width: 100%;
     justify-content: space-around;
     /* text-align: center; */
     background: #dedede !important;
     /* height: 20px; */
     text-align: center;
     color: #fff;
     font-size: 20px;
     position: relative;
     border-radius: 5px;
     border: none;
     width: 135px;
     /* line-height: 22px; */
     align-items: center;
     line-height: 45px;
     padding: 0px 10px;
     margin-right: 14px;
 }
 
 // .enabledisablebtn .enablebtn {
 //     display: none;
 // }
 .lesstext .container {
     display: flex;
 }
 
 .lesstext .container2 {
     display: flex;
     width: 120px;
     margin-left: 20px;
 }
 
 button.mjomadinbutton span {
     margin-right: 20px;
 }
 
 button.jomadinbutton span img {
     position: absolute;
     top: 12px;
     left: 20px;
 }
 
 .stopbutton {
     // / padding-top: 18px; /
     // / padding-left: 12px; /
     position: absolute;
     left: 280px;
     list-style: none;
     text-decoration: none;
     border: none;
     font-size: 40px;
     color: #136ee5;
 }
 
 
 /* --------------------------------------- 30.06.2022 -------------------------- */
 
//  button.agiyejan {
//      display: flex;
//      justify-content: space-between;
//      max-width: 100%;
//      object-fit: cover;
//      width: 130px;
//      max-height: 30px;
//      flex-flow: row wrap;
//      flex-wrap: nowrap;
//      /* background-color: #FFFFFF; */
//      padding: 20px 5px;
//      color: #ec411b;
//      /* justify-content: center; */
//      align-items: center;
//      border-radius: 4px;
//      font-size: 20px;
//      // margin-top: 60px;
//      // margin-top: 60px;
//  }
 
 .menuactive {
     color: #059514 !important;
 }
 
 .readblock {
     // background: #ffffff;
     background: rgb(255, 255, 255);
     background: linear-gradient( 0deg, rgba(255, 255, 255, 1) 0%, rgba(255, 255, 255, 0) 97%);
     box-shadow: 1px 2px 7px 0px rgba(0, 0, 0, 0.16);
     -webkit-box-shadow: 1px 2px 7px 0px rgba(0, 0, 0, 0.16);
     -moz-box-shadow: 1px 2px 7px 0px rgba(0, 0, 0, 0.16);
     border-radius: 16px;
     -webkit-border-radius: 16px;
     -moz-border-radius: 16px;
     -ms-border-radius: 16px;
     -o-border-radius: 16px;
     padding: 30px;
 }
 
 .aboutmedetails {
     display: flex;
     flex-direction: column;
     max-width: 672px;
     /* justify-content: center; */
     /* align-items: center; */
     margin: 0 auto;
 }
 
 // .readingrule {
 //   width: 600px;
 //   margin: 0 auto;
 //   min-height: 425px;
 // }
 .readingrule {
     width: 600px;
     margin: 0 auto;
    //  min-height: 425px;
     /* float: left; */
     max-width: 100%;
 }
 
 .readblock p {
     font-family: "SolaimanLipi", sans-serif;
     color: #171717;
     font-size: 20px;
 }
 
 .readingrule h3 {
     text-align: center;
     font-size: 32px;
     color: #161a31;
     margin-bottom: 20px;
     font-family: "Noto Sans Bengali", sans-serif;
 }
 
 .entry {
     background-color: #44af4f !important;
     display: flex;
     max-width: 100%;
     justify-content: space-around;
     /* text-align: center; */
     // background: #136ee5 !important;
     /* height: 20px; */
     text-align: center;
     color: #fff;
     font-size: 20px;
     position: relative;
     border-radius: 5px;
     border: none;
     width: 135px;
     /* line-height: 22px; */
     align-items: center;
     line-height: 45px;
     padding: 0px 10px;
 }
 
 
 /* --------------------------------------- footer-content -------------------------- */
 
 
 /*=================== Responsive design ========================================= */
 
 @media screen and (aspect-ratio: 16/9) {
     /* @media screen and (max-width: 1536px) and (max-height: 746px) and (-webkit-min-device-pixel-ratio: 2) { */
     .withsidebar-content {
         padding: 30px 30px 30px;
     }
     .bodybgmicrophone {
         display: flex;
         height: 140vh !important;
         /* background: url("../img/bg.png") no-repeat center; */
         background: url("./images/stts/pageone.png") no-repeat center;
         background-size: cover;
         background-position: 100% 65%;
         flex-wrap: wrap;
         /* width: 100%; */
         width: 100%;
         float: left;
         position: relative;
         /* padding: 137px 0px 0px 0px; */
         justify-content: center;
         align-items: center;
         margin: 0 auto;
         overflow-y: scroll;
     }
     .signupbg {
         display: flex;
         height: 100vh;
         /* background: url("../img/bg.png") no-repeat center; */
         // background: url("./images/stts/sighupbg.png") no-repeat center;
         background-size: cover;
         background-position: 65% 65%;
         flex-wrap: wrap;
         /* width: 100%; */
         width: 100%;
         float: left;
         position: relative;
         /* padding: 137px 0px 0px 0px; */
         justify-content: center;
         align-items: center;
         margin: 0 auto;
         // margin-top: 5%;
         /* overflow-y: scroll; */
     }
     .stopbutton {
         // / padding-top: 18px; /
         // / padding-left: 12px; /
         position: absolute;
         left: 280px;
         list-style: none;
         text-decoration: none;
         border: none;
         font-size: 40px;
         color: #136ee5;
     }
 }
 
 @media screen and (max-width: 1560px) {
     // button.bottomwave img {
     //     width: 64px;
     //     height: auto;
     //     position: absolute;
     //     top: 34px;
     //     left: 394px;
     //     cursor: pointer;
     //     background: none;
     //     opacity: 0;
     // }
 }
 
 @media screen and (max-width: 1537px) and (max-height: 792px) and (-webkit-min-device-pixel-ratio: 1) {
     .withsidebar-content {
         padding: 30px 30px 30px;
     }
     .bodybgmicrophone {
         // display: flex;
         height: 140vh;
         /* background: url("../img/bg.png") no-repeat center; */
         background: url("./images/stts/homepageone.png") no-repeat center;
         background-size: cover;
         background-position: 100% 65%;
         flex-wrap: wrap;
         /* width: 100%; */
         width: 100%;
         float: left;
         position: relative;
         /* padding: 137px 0px 0px 0px; */
         justify-content: center;
         align-items: center;
         margin: 0 auto;
         overflow-y: scroll;
     }
     .signupbg {
         display: flex;
         height: auto;
         /* background: url("../img/bg.png") no-repeat center; */
         // background: url("./images/stts/sighupbg.png") no-repeat center;
         background-size: cover;
         background-position: 50% 65%;
         flex-wrap: wrap;
         /* width: 100%; */
         width: 100%;
         float: left;
         position: relative;
         /* padding: 137px 0px 0px 0px; */
         justify-content: center;
         align-items: center;
         margin: 0 auto;
         // margin-top: 5%;
         /* overflow-y: scroll; */
     }
     .stopbutton {
         /* padding-top: 18px; */
         /* padding-left: 12px; */
         position: absolute;
         left: 280px;
         list-style: none;
         text-decoration: none;
         border: none;
         font-size: 40px;
         color: #136ee5;
     }
     .loginform {
         display: flex;
         float: left;
         width: 646px;
         background-color: #fff;
         border-radius: 48px;
         padding: 30px 50px;
         height: 685px;
         flex-direction: column;
         -webkit-border-radius: 48px;
         -moz-border-radius: 48px;
         -ms-border-radius: 48px;
         -o-border-radius: 48px;
         margin-bottom: 50px;
     }
 }
 
 @media only screen and (max-width: 1467px) and (max-height: 764px) {
     .middletext {
         justify-content: center;
         align-items: center;
         display: flex;
         min-height: 280px;
     }
     .obodanarea {
         display: block;
         /* justify-content: center; */
         width: 100%;
         min-height: 400px;
     }
     button.ariyejan-button.agiyejansunun {
         position: absolute;
         right: 30px;
         top: 60px;
     }
     .threeplay ul li {
         padding: 0px 47px !important;
     }

     .stopbutton {
         /* padding-top: 18px; */
         /* padding-left: 12px; */
         position: absolute;
         left: 280px;
         list-style: none;
         text-decoration: none;
         border: none;
         font-size: 40px;
         color: #136ee5;
     }

     .footerdownload {
        margin-top: 130px;
    }
 }
 
 @media only screen and (max-width: 1440px) {
     // button.bottomwave img {
     //     width: 64px;
     //     height: auto;
     //     position: absolute;
     //     top: 34px;
     //     left: 350px;
     //     cursor: pointer;
     //     background: none;
     //     opacity: 0;
     // }
     .threeplay {
        position: absolute;
        top: 25%;
        left: 10%;
    }
 }
 
 @media only screen and (max-width: 1367px) and (max-height: 768px) {
     .main-text-content {
         max-height: 448px;
     }
     .content-body {
         max-height: 597px;
     }
     .signupform h4 {
         font-size: 20px;
         color: #44af4f;
         font-family: Noto Sans Bengali;
     }
 }
 
 @media only screen and (max-width: 1366px) {
     .lefttext {
         width: 100%;
         /* display: flex; */
         /* flex-direction: row; */
         text-align: center;
         /* float: right; */
         /* justify-content: stretch; */
         padding-left: 0px;
     }
     .righttext {
         width: 100%;
         display: flex;
         flex-direction: column;
     }
     .righttext p {
         text-align: left;
         float: left;
         padding-right: 0%;
         padding: 0px 20px;
     }
     // button.bottomwave img {
     //     width: 64px;
     //     height: auto;
     //     position: absolute;
     //     top: 34px;
     //     left: 318px;
     //     cursor: pointer;
     //     background: none;
     //     opacity: 0;
     // }
 }


 
 @media only screen and (max-width: 1250px) {
     // button.bottomwave img {
     //     width: 64px;
     //     height: auto;
     //     position: absolute;
     //     top: 34px;
     //     left: 310px;
     //     cursor: pointer;
     //     background: none;
     //     opacity: 0;
     // }
     .footerdownload {
         background: url("images/stts/profilesununm.png") no-repeat;
         //   background: url("images/stts/playsunun.png") no-repeat;
         width: 100% !important;
         height: 132px !important;
         overflow: hidden;
         border: none;
         // margin-bottom: -60px;
         background-position: center;
         margin-top: 50px;
         position: relative;
     }
 }
 
 @media only screen and (max-width: 1180px) {
     .footer-area {
         display: flex;
         float: left;
         width: 100%;
         padding: 20px 30px;
         // position: absolute;
         /* justify-content: space-between; */
         min-height: 170px;
         overflow: hidden;
         flex-direction: row;
     }
     .startwate {
         min-height: 132px;
     }
     button.agiyejan {
         text-align: center;
         width: 100%; 
         display: flex;
         justify-content: center;
         align-items: center;
         // margin-right: 170px;
         color: #ec411b;
        text-decoration: none;
        font-size: 18px;
        font-family: "Noto Sans Bengali";
     }
     .footerdownload {
         flex-direction: column;
     }
     .mbottom {
         margin-bottom: 200px !important;
     }
     .stop {
         min-height: 132px;
     }
     li.nav-item>a.nav-link {
         font-size: 16px;
         color: #161a31;
         font-family: "Noto Sans Bengali", sans-serif;
         font-weight: 500;
     }
     .startwate {
         background: url("images/stts/wavem.png") no-repeat;
         //   background: url("images/stts/playsunun.png") no-repeat;
         width: 100% !important;
         height: 132px !important;
         overflow: hidden;
         border: none;
         // margin-bottom: -60px;
         background-position: center;
         margin-top: 50px;
     }
     // button.bottomwave img {
     //     width: 64px;
     //     height: auto;
     //     position: absolute;
     //     top: 34px;
     //     left: 255px;
     //     z-index: 99999999999999999999999999;
     //     cursor: pointer;
     //     background: none;
     //     opacity: 0;
     // }
     .mandondocard h5 {
         font-size: 14px;
     }
     .stop {
         background: url("images/stts/Loader-finalm.gif") no-repeat;
         width: 100% !important;
         height: 132px !important;
         overflow: hidden;
         background-position: center;
         margin-top: 50px;
         position: relative;
     }
 }
 
 @media only screen and (max-width: 1080px) {
     .footerdownload {
         background: url("images/stts/profilesununms.png") no-repeat;
         //   background: url("images/stts/playsunun.png") no-repeat;
         width: 100% !important;
         height: 132px !important;
         overflow: hidden;
         border: none;
         // margin-bottom: -60px;
         background-position: center;
         margin-top: 50px;
         position: relative;
     }
 }
 
 @media only screen and (max-width: 1024px) {
     .main-content {
         display: block;
         float: left;
         width: 95.8%;
     }
     .navbar-collapse {
         flex-basis: 100%;
         flex-grow: 1;
         align-items: center;
         // margin-top: 20px;
     }
     .signupform {
         display: block;
         float: left;
         width: 100%;
         background-color: #fff;
         border-top: 10px solid #44af4f;
         border-radius: 52px;
         padding: 30px;
         margin-bottom: 100px;
         margin-top: 30px;
     }
     .threeplay ul li a.agiyejansunun {
         margin-left: 0px;
         /* margin-bottom: 60px; */
         padding-right: 0px;
     }
     .signupbradcump {
         margin: 30px 0px;
         display: block;
         float: left;
         width: 100%;
         text-align: center;
     }
     .loginform {
         display: block;
         float: left;
         width: 100%;
         background-color: transparent;
         border-top: none;
         border-radius: 0;
         padding: 30px 10px;
         margin: 0 auto;
         margin-bottom: 100px;
     }
     .signupform {
         display: block;
         float: left;
         width: 100%;
         background-color: transparent;
         border-top: none;
         border-radius: 0;
         padding: 30px 50px;
         margin: 0 auto;
         margin-bottom: 100px;
     }
     .lefttext {
         width: 100%;
         /* display: flex; */
         /* flex-direction: row; */
         text-align: center;
         /* float: right; */
         /* justify-content: stretch; */
         padding-left: 0px;
     }
     .righttext {
         width: 100%;
         display: flex;
         flex-direction: column;
     }
     .righttext p {
         text-align: left;
         float: left;
         padding-right: 0%;
         padding: 0px 20px;
     }
     button.mjomadinbutton {
         display: flex;
         max-width: 100%;
         justify-content: space-around;
         /* text-align: center; */
         background: #136ee5 !important;
         /* height: 20px; */
         text-align: center;
         color: #fff;
         font-size: 18px;
         position: relative;
         border-radius: 5px;
         border: none;
         width: 135px;
         /* line-height: 22px; */
         align-items: center;
         line-height: 45px;
         padding: 0px 10px;
     }
     .loginform h4 {
         font-size: 20px;
         color: #136ee5;
         font-family: Noto Sans Bengali;
     }
     .signupbg {
         height: auto;
         /* background: url("../img/bg.png") no-repeat center; */
         // background: url("./images/stts/sighupbg.png") no-repeat center;
         background-size: cover;
         background-position: 40% 65%;
         flex-wrap: wrap;
         /* width: 100%; */
         width: 100%;
         float: left;
         position: relative;
         /* padding: 137px 0px 0px 0px; */
         justify-content: center;
         align-items: center;
         margin: 0 auto;
         margin-top: 0%;
         /* overflow-y: scroll; */
     }
     .readblock {
         margin-bottom: 60px;
     }
     li.nav-item>a.nav-link {
         font-size: 16px;
         color: #161a31;
         font-family: "Noto Sans Bengali", sans-serif;
         font-weight: 500;
     }
     .withcheckgroup p {
         font-size: 14px;
         color: #707070;
         font-family: Noto Sans Bengali;
         width: 100%;
     }
     .checkboxgroup {
         display: flex;
         justify-content: space-between;
         grid-gap: 8px;
         grid-gap: 0px;
         grid-gap: 0px;
         gap: 0px;
         width: 100%;
     }
     .withcheckgroup {
         display: flex;
         justify-content: space-between;
         width: 100%;
         flex-direction: column;
     }
 }
 
 @media only screen and (max-width: 991px) {
     .bodybgmicrophone .topmenu {
         width: 100%;
         float: left;
         display: block;
         /* background: red; */
         position: inherit;
         top: 0;
         left: 0;
         background: #fff;
     }
     .navbar-collapse {
         flex-basis: 100%;
         flex-grow: 1;
         align-items: center;
         // margin-top: 20px;
         display: flex;
         justify-content: space-between;
         flex-direction: row;
     }
     .navbar-nav {
         display: flex;
         flex-direction: column;
         padding-left: 0;
         margin-bottom: 0;
         list-style: none;
         display: flex;
         flex-direction: row;
         justify-content: space-around;
         width: 100%;
         align-items: center;
     }
     a.nav-link.active.probeshkorun {
         padding: 10px;
     }
     li.nav-item>a.nav-link {
         font-size: 11px;
         color: #161a31;
         font-family: "Noto Sans Bengali", sans-serif;
         font-weight: 500;
     }
     .rightsidebar {
         display: block;
         float: left;
         width: 100%;
         padding: 20px 30px 0px;
         box-shadow: 0px 3px 6px 0px rgba(0, 0, 0, 0.16);
         border-radius: 4px;
         background: white;
         background: #f6f9ff;
         // margin-top: 140px;
     }
     // button.bottomwave img {
     //     width: 64px;
     //     height: auto;
     //     position: absolute;
     //     top: 34px;
     //     left: 43%;
     //     z-index: 99999999999999999999999999;
     //     cursor: pointer;
     //     background: none;
     //     opacity: 0;
     // }
     .msinglecardlast {
         margin-bottom: 30px;
     }
 }
 
 @media only screen and (max-width: 800px) {
     // button.bottomwave img {
     //     width: 64px;
     //     height: auto;
     //     position: absolute;
     //     top: 34px;
     //     left: 220px;
     //     z-index: 99999999999999999999999999;
     //     cursor: pointer;
     //     background: none;
     //     opacity: 0;
     // }
 }
 
 @media only screen and (max-width: 768px) {
     .startwate {
         min-height: 65px;
     }
     .breadcrumbtop {
         margin: 30px 0px;
         display: flex;
         /* float: left; */
         width: 100%;
         justify-content: space-between;
     }
     .footer-areabottom {
         display: flex;
         /* float: left; */
         width: 100%;
         padding: 0px 0px;
         position: fixed;
         left: 0px;
         bottom: 0px;
         justify-content: center;
         background-color: rgba(255, 255, 255, 1);
         -webkit-box-shadow: 0px 0px 6px 0px rgba(0, 0, 0, 0.16);
         box-shadow: 0px 0px 6px 0px rgba(0, 0, 0, 0.16);
         flex-direction: row;
         flex-wrap: wrap;
         /* background-color: #bedaff; */
     }
     .developed:first-child {
         display: none;
     }
     ul.footer-item:last-child {
         display: none;
     }
     .signupbradcump img {
         width: 20%;
         margin-bottom: 50px;
     }
     .leftwave {
         width: 100%;
         display: flex;
         flex-direction: column;
         text-align: center;
     }
     .rightwave {
         width: 100%;
         display: flex;
         flex-direction: column;
         text-align: center;
         background-color: rgba(190, 195, 201, 0.1);
     }
     .wavecontent {
         display: flex;
         flex-direction: column;
         width: 100%;
         height: auto;
         justify-content: space-between;
         margin-top: 70px;
     }
     .lefttext {
         width: 100%;
         /* display: flex; */
         /* flex-direction: row; */
         text-align: center;
         /* float: right; */
         /* justify-content: stretch; */
         padding-left: 0px;
     }
     .righttext {
         width: 100%;
         display: flex;
         flex-direction: column;
     }
     .righttext p {
         text-align: left;
         float: left;
         padding-right: 0%;
         padding: 0px 20px;
     }
     .chartarea {
         display: block;
         float: left;
         width: 100%;
         margin-bottom: 100px;
     }
     .developed a {
         color: #919191;
         font-size: 13px;
     }
     .smdeviceview {
         max-height: 650px;
         overflow-y: scroll;
         overflow-x: hidden;
     }
     a.bottomwave img {
         padding-left: 0px;
     }
     .main-text-content {
         min-height: 250px;
     }
     .editor-icon {
         display: flex;
         width: 100%;
         float: left;
         /* background: red; */
         justify-content: space-between;
         max-height: 120px;
         border: none !important;
         margin-bottom: 30px;
     }
     .editor-icon ul {
         display: flex;
         justify-content: space-between;
         margin-bottom: 0px;
         justify-content: left;
         position: relative;
         padding-left: 0px;
         margin-left: -60px;
         /* margin-top: -30px; */
         flex-direction: row;
         justify-content: space-between;
         flex-flow: row;
     }
     h6.porerager {
         font-size: 10px;
     }
     h6.porersunte {
         font-size: 10px;
     }
     .threeplay {
         position: absolute;
         top: 45px;
         float: left;
         /* width: 100%; */
         display: block;
         left: 48px;
     }
     .iconvoice {
         position: absolute;
         right: 0px;
         top: -40px;
     }
     a.recordingprofile {
         width: 36px;
         height: 36px;
         line-height: 36px;
         border: 1px solid #bedaff;
         padding: 5px 15px;
         border-radius: 50%;
         text-align: center;
         color: #fff;
         background-color: #bedaff;
         margin-left: 135px;
     }
     // .probeshkorun {
     //     margin-bottom: 20px;
     // }
     .mobileview {
         display: flex;
         flex-direction: row;
         flex-wrap: wrap;
         background-color: #ffff;
     }
     .mnoview {
         display: none;
     }
     .rightsidebar {
         display: block;
         float: left;
         width: 100%;
         padding: 20px 30px 0px;
         -webkit-box-shadow: 0px 3px 6px 0px rgba(0, 0, 0, 0.16);
         box-shadow: 0px 3px 6px 0px rgba(0, 0, 0, 0.16);
         border-radius: 4px;
         background: rgb(255, 255, 255);
         background: #f6f9ff;
         // margin-top: 85px;
         margin-bottom: 30px;
     }
     .loginlogo img {
         width: 20%;
     }
     .alignnav {
         flex-basis: 100%;
         flex-grow: 1;
         align-items: center;
         // margin-top: 20px;
         display: flex;
         flex-wrap: wrap;
     }
     ul.obodanitem {
         display: flex;
         flex-direction: row;
         justify-content: flex-end;
         width: 70%;
         /* font-size: 4px; */
     }
     .obodanitem li:last-child {
         margin-right: 0px;
     }
     .navbar-brand {
         display: inline-block;
         padding-top: 0.3125rem;
         padding-bottom: 0.3125rem;
         margin-right: 1rem;
         font-size: 1.25rem;
         line-height: inherit;
         white-space: nowrap;
         width: 25%;
     }
     div#navbarTogglerDemo01 a img {
         width: 100%;
         margin-top: -20px;
     }
     ul.navbar-nav.me-0.mb-2.mb-lg-0 {
         display: flex;
         flex-direction: row;
         justify-content: flex-end;
         width: 100%;
         padding-left: 194px;
         align-items: center;
     }
     a.probeshkorun {
         padding: 10px !important;
     }
     .startwate {
         // background: url("images/stts/wave.png") no-repeat;
         background: url("images/stts/playsunun1.png") no-repeat;
         width: 100% !important;
         height: auto !important;
         overflow: hidden;
         border: none;
         // margin-bottom: -60px;
         background-position: center;
     }
     .stop {
         // background: url("images/stts/smallload.gif") no-repeat;
         background: url("images/stts/Loader-finalsm.gif") no-repeat;
         width: 100% !important;
         height: auto !important;
         // margin-bottom: -60px;
         overflow: hidden;
         // position: relative;
     }
     // button.bottomwave img {
     //     width: 64px;
     //     height: auto;
     //     position: absolute;
     //     top: 0%;
     //     left: 44%;
     //     cursor: pointer;
     //     background: none;
     //     opacity: 0;
     // }
     .signupbg {
         height: auto;
         /* background: url("../img/bg.png") no-repeat center; */
         // background: url("./images/stts/sighupbg.png") no-repeat center;
         background-size: cover;
         background-position: 100% 65%;
         flex-wrap: wrap;
         /* width: 100%; */
         width: 100%;
         float: left;
         position: relative;
         /* padding: 137px 0px 0px 0px; */
         justify-content: center;
         align-items: center;
         margin: 0 auto;
         margin-top: 0%;
         /* overflow-y: scroll; */
     }
     .signupbradcump {
         display: none;
     }
     li.nav-item>a.nav-link {
         font-size: 20px;
         color: #161a31;
         font-family: "Noto Sans Bengali", sans-serif;
         font-weight: 500;
         margin-right: 10px;
     }
     .footer-area {
         display: flex;
         float: left;
         width: 100%;
         padding: 20px 30px;
         justify-content: space-between;
         min-height: 170px;
         overflow: hidden;
         flex-direction: column;
     }
     .menuhide .hideinpage li {
         display: none;
     }
     .shadow-dark2 {
         box-shadow: none;
     }
     button.agiyejan {
         display: flex;
         justify-content: space-between;
         max-width: 100%;
         object-fit: cover;
         width: 130px;
         max-height: 30px;
         flex-flow: row wrap;
         flex-wrap: nowrap;
         /* background-color: #FFFFFF; */
         padding: 20px 5px;
         color: #ec411b;
         /* justify-content: center; */
         align-items: center;
         border-radius: 4px;
         font-size: 15px;
     }
     .rightsidebar {
         display: block;
         float: left;
         width: 100%;
         padding: 20px 30px 0px;
         box-shadow: 0px 3px 6px 0px rgba(0, 0, 0, 0.16);
         border-radius: 4px;
         background: white;
         background: #f6f9ff;
         // margin-top: 200px;
         margin-bottom: 30px;
     }
     .footerdownload {
         background: url("images/stts/profilesununxs.png") no-repeat;
         //   background: url("images/stts/playsunun.png") no-repeat;
         width: 100% !important;
         height: 132px !important;
         overflow: hidden;
         border: none;
         // margin-bottom: -60px;
         background-position: center;
         margin-top: 30px;
         position: relative;
     }
 }
 
 @media only screen and (max-width: 640px) {
     .startwate {
         // background: url("images/stts/wave.png") no-repeat;
         background: url("images/stts/playsunun1.png") no-repeat;
         width: 100% !important;
         height: auto !important;
         overflow: hidden;
         border: none;
         // margin-bottom: -60px;
         background-position: center;
         background-position: c;
     }
     .stop {
         background: url("images/stts/smallload.gif") no-repeat;
         // background: url("images/stts/Loader-final.gif") no-repeat;
         width: 100% !important;
         height: auto !important;
         // margin-bottom: -60px;
         overflow: hidden;
         // position: relative;
     }
     li.borderight {
         border-right: none;
         /* height: 15px; */
     }
     li.borderleft {
         border-left: none;
         /* height: 15px; */
     }
     .main-content {
         display: block;
         float: left;
         width: 88%;
     }
     .bottomspeaker {
         position: fixed;
         bottom: 0px;
         left: 0px;
     }
     .main-text-content:last-child p {
         padding-bottom: 15%;
     }
     .main-text-content {
         max-height: 350px;
     }
     .editor-icon ul li {
         /* padding: 10px 14px; */
         padding: 0px 14px;
         line-height: 10px;
         position: relative;
         display: inline-block;
         float: left;
         margin: 5px 3px;
     }
     div#myDIV1 {
         display: block;
         float: left;
         border-left: none;
     }
     .readblock {
         margin-bottom: 60px;
     }
     .innerlogin {
         width: 100%;
         display: flex;
         flex-direction: column;
         height: 650px;
         justify-content: center;
         margin: 0 auto;
     }
     .loginform {
         display: block;
         float: left;
         width: 100%;
         background-color: transparent;
         border-top: none;
         border-radius: 0;
         padding: 0px 10px;
         margin: 0 auto;
         margin-bottom: 0px;
     }
     .footer-area {
         display: flex;
         float: left;
         width: 100%;
         padding: 20px 0px;
         justify-content: space-between;
         min-height: 170px;
         overflow: hidden;
         flex-direction: row;
     }
     // button.bottomwave img {
     //     width: 64px;
     //     height: auto;
     //     position: absolute;
     //     top: 2%;
     //     left: 34%;
     //     cursor: pointer;
     //     background: none;
     //     opacity: 0;
     // }
     .footer-area {
         display: flex;
         float: left;
         width: 100%;
         padding: 20px 0px;
         justify-content: space-between;
         min-height: 170px;
         overflow: hidden;
         flex-direction: column;
         margin-top: 20px;
     }
 }
 
 @media only screen and (max-width: 600px) {
     .rightsidebar {
         display: block;
         float: left;
         width: 100%;
         padding: 20px 30px 0px;
         box-shadow: 0px 3px 6px 0px rgba(0, 0, 0, 0.16);
         border-radius: 4px;
         background: white;
         background: #f6f9ff;
         // margin-top: 118px;
         margin-bottom: 30px;
     }
     .startwate {
         // background: url("images/stts/wave.png") no-repeat;
         background: url("images/stts/playsunun1.png") no-repeat;
         width: 100% !important;
         height: auto !important;
         overflow: hidden;
         border: none;
         // margin-bottom: -60px;
         background-position: center;
         margin-top: 70px;
     }
     .stop {
         background: url("images/stts/smallload.gif") no-repeat;
         // background: url("images/stts/Loader-final.gif") no-repeat;
         width: 100% !important;
         height: auto !important;
         // margin-bottom: -60px;
         overflow: hidden;
         // position: relative;
         background-position: center;
         margin-top: 60px;
     }
     .stop {
         min-height: 60px;
     }
     li.nav-item>a.nav-link {
         font-size: 13px !important;
         color: #161a31;
         font-family: "Noto Sans Bengali", sans-serif;
         font-weight: 500;
     }
     .breadcrumbtop {
         display: none;
     }
     .footer-area {
         display: flex;
         /* float: left; */
         width: 100%;
         padding: 20px 0px;
         /* position: absolute; */
         /* justify-content: space-between; */
         /* min-height: 137px; */
         overflow: hidden;
         flex-direction: column;
         margin-top: 40px;
         justify-content: center;
         align-items: center;
     }
     .editor-icon ul {
         display: flex;
         justify-content: space-between;
         margin-bottom: 0px;
         justify-content: left;
         position: relative;
         padding-left: 0px;
         /* margin-left: -60px; */
         /* margin-top: -30px; */
         flex-direction: row;
         justify-content: space-between;
         flex-flow: row;
         width: 100%;
         margin-left: 0px;
     }
     // button.bottomwave img {
     //     width: 64px;
     //     height: auto;
     //     position: absolute;
     //     top: 20px;
     //     left: 205px;
     //     cursor: pointer;
     //     background: none;
     //     opacity: 0;
     // }
     .checkboxgroup {
         display: flex;
         justify-content: space-between;
         grid-gap: 8px;
         grid-gap: 0px;
         grid-gap: 0px;
         grid-gap: 0px;
         gap: 0px;
         width: 100%;
         flex-direction: column;
     }
     // button.bottomwave img {
     //     width: 64px;
     //     height: auto;
     //     position: absolute;
     //     top: 30%;
     //     left: 43%;
     //     cursor: pointer;
     //     background: none;
     //     opacity: 0;
     // }
 }
 
 @media only screen and (max-width: 575px) {
     button.agiyejan {
         display: flex;
         justify-content: space-between;
         max-width: 100%;
         object-fit: cover;
         width: 130px;
         max-height: 30px;
         flex-flow: row wrap;
         flex-wrap: nowrap;
         /* background-color: #FFFFFF; */
         padding: 20px 5px;
         color: #ec411b;
         /* justify-content: center; */
         align-items: center;
         border-radius: 4px;
         font-size: 14px;
     }
     .footer-area {
         display: flex;
         width: 100%;
         padding: 20px 0px;
         overflow: hidden;
         flex-direction: column;
         margin-top: 100px;
         justify-content: center;
         align-items: center;
     }
     .rightsidebar {
         display: block;
         float: left;
         width: 100%;
         padding: 20px 30px 0px;
         box-shadow: 0px 3px 6px 0px rgba(0, 0, 0, 0.16);
         border-radius: 4px;
         background: white;
         background: #f6f9ff;
         // margin-top: 200px;
         margin-bottom: 30px;
     }
     .stop {
         min-height: 60px;
     }
 }
 
 @media only screen and (max-width: 540px) {
     .startwate {
         margin-top: 65px;
     }
     .footerdownload {
         margin-top: 80px;
     }
     .bodybgmicrophone {
         height: 100vh;
     }
     .rightsidebar {
         margin-bottom: 30px;
     }
     .stop {
         margin-top: 110px;
     }
     .clickposition {
         position: relative !important;
     }
     .clickposition img {
         position: absolute;
     }
 }
 
 @media only screen and (max-width: 480px) {
     li.nav-item>a.nav-link {
         font-size: 16px !important;
         color: #161a31;
         font-family: "Noto Sans Bengali", sans-serif;
         font-weight: 500;
     }
     .footer-area {
         display: flex;
         float: left;
         width: 100%;
         padding: 20px 20px;
         /* justify-content: space-between; */
         min-height: 170px;
         overflow: hidden;
         flex-direction: column;
     }
     // button.bottomwave img {
     //     width: 64px;
     //     height: auto;
     //     position: absolute;
     //     top: 20px;
     //     left: 160px;
     //     cursor: pointer;
     //     background: none;
     //     opacity: 0;
     // }
     .startwate {
         // background: url("images/stts/wave.png") no-repeat;
         background: url("images/stts/playsunun1.png") no-repeat;
         width: 100% !important;
         height: auto !important;
         overflow: hidden;
         border: none;
         // margin-bottom: -60px;
     }
     .stop {
         background: url("images/stts/smallload.gif") no-repeat;
         // background: url("images/stts/Loader-final.gif") no-repeat;
         width: 100% !important;
         height: auto !important;
         // margin-bottom: -60px;
         overflow: hidden;
         // position: relative;
         background-position: center;
         margin-top: 60px;
     }
     .readingrule h3 {
         text-align: center;
         font-size: 20px;
         color: #161a31;
         margin: 20px;
         font-family: "Noto Sans Bengali", sans-serif;
     }
     .rightsidebar {
         display: block;
         float: left;
         width: 100%;
         padding: 20px 30px 0px;
         box-shadow: 0px 3px 6px 0px rgba(0, 0, 0, 0.16);
         border-radius: 4px;
         background: white;
         background: #f6f9ff;
         // margin-top: 130px;
         margin-bottom: 30px;
     }
 }
 
 @media only screen and (max-width: 414px) {
     .startwate {
         // background: url("images/stts/wave.png") no-repeat;
         background: url("images/stts/playsunun1.png") no-repeat;
         width: 100% !important;
         height: auto !important;
         overflow: hidden;
         border: none;
         // margin-bottom: -60px;
     }
     .stop {
         background: url("images/stts/smallload.gif") no-repeat;
         // background: url("images/stts/Loader-final.gif") no-repeat;
         width: 100% !important;
         height: auto !important;
         // margin-bottom: -60px;
         overflow: hidden;
         // position: relative;
         min-height: 60px;
         margin: 0 0px;
         margin-top: 80px;
     }
     li.nav-item>a.nav-link {
         font-size: 16px !important;
         color: #161a31;
         font-family: "Noto Sans Bengali", sans-serif;
         font-weight: 500;
     }
     .navbar-brand {
         display: inline-block;
         padding-top: 1.025rem;
         padding-bottom: 0.3125rem;
         margin-right: 1rem;
         font-size: 1.25rem;
         line-height: inherit;
         white-space: nowrap;
         width: 50%;
     }
     .breadcrumbtop {
         margin: 0px 0px;
         display: flex;
         float: left;
         width: 100%;
         justify-content: space-between;
         padding-top: 0px;
     }
     .container>div {
         padding: 0px !important;
     }
     .probeshkorun {
         margin-bottom: 0px;
     }
     .paddinghide {
         padding: 0px;
     }
     .editor-icon ul {
         display: flex;
         justify-content: space-between;
         margin-bottom: 0px;
         justify-content: left;
         position: relative;
         padding-left: 0px;
         margin-left: 0px;
         /* margin-top: -30px; */
         flex-direction: row;
         justify-content: space-between;
         flex-flow: row;
         width: 100%;
     }
     .editor-icon ul li {
         /* padding: 10px 14px; */
         padding: 0px;
         line-height: 10px;
         position: relative;
         display: inline-block;
         margin: 0px;
     }
     .nav-link {
         display: block;
         padding: 0px;
     }
     button.entry {
         margin: 10px auto;
     }
     .submitbutton {
         display: flex;
         justify-content: center;
         line-height: 60px;
     }
     .signupform p {
         font-size: 12px;
         color: #707070;
         font-family: Noto Sans Bengali;
     }
     .signupbg {
         margin-top: 0%;
     }
     .breadcrumbtop p img {
         display: none;
     }
     .footer-area {
         display: flex;
         float: left;
         width: 100%;
         padding: 20px 0px;
         /* position: absolute; */
         /* justify-content: space-between; */
         min-height: 170px;
         overflow: hidden;
         flex-direction: column;
     }
     // button.bottomwave img {
     //     width: 64px;
     //     height: auto;
     //     position: absolute;
     //     top: 20px;
     //     left: 135px;
     //     cursor: pointer;
     //     background: none;
     //     opacity: 0;
     // }
     .checkboxgroup {
         display: flex;
         justify-content: space-between;
         width: 100%;
         flex-direction: column;
     }
     .lesstext .container2 {
         display: flex;
         width: 120px;
         margin-left: 13px;
     }
     ul.obodanitem {
         display: flex;
         flex-direction: row;
         justify-content: flex-end;
         width: 100%;
         /* font-size: 4px; */
         justify-content: space-between;
     }
     ul.navbar-nav.me-0.mb-2.mb-lg-0 {
         display: flex;
         flex-direction: row;
         justify-content: flex-end;
         width: 100%;
         padding-left: 0px;
         align-items: center;
     }
     .rightsidebar {
         display: block;
         float: left;
         width: 100%;
         padding: 20px 30px 0px;
         box-shadow: 0px 3px 6px 0px rgba(0, 0, 0, 0.16);
         border-radius: 4px;
         background: white;
         background: #f6f9ff;
         // margin-top: 185px;
         margin-bottom: 30px;
     }
 }
 
 @media only screen and (max-width: 412px) {
     .readblock {
         margin-bottom: 300px;
     }
     .footer-area {
         display: flex;
         float: left;
         width: 100%;
         padding: 20px 0px;
         /* position: absolute; */
         /* justify-content: space-between; */
         min-height: 170px;
         overflow: hidden;
         flex-direction: column;
         /* margin: 0 -18px; */
     }
 }
 
 @media only screen and (max-width: 384px) {
     li.nav-item>a.nav-link {
         font-size: 14px !important;
         color: #161a31;
         font-family: "Noto Sans Bengali", sans-serif;
         font-weight: 500;
     }
     .footer-area {
         display: flex;
         float: left;
         width: 100%;
         padding: 20px 0px;
         /* position: absolute; */
         /* justify-content: space-between; */
         min-height: 170px;
         overflow: hidden;
         flex-direction: column;
         margin-top: 140px;
     }
 }
 
 @media only screen and (max-width: 360px) {
     li.nav-item>a.nav-link {
         font-size: 20px !important;
         color: #161a31;
         font-family: "Noto Sans Bengali", sans-serif;
         font-weight: 500;
     }
     .navbar-brand {
         display: inline-block;
         padding-top: 1.025rem;
         padding-bottom: 0.3125rem;
         margin-right: 1rem;
         font-size: 1.25rem;
         line-height: inherit;
         white-space: nowrap;
         width: 50%;
     }
     .breadcrumbtop {
         margin: 0px 0px;
         display: flex;
         float: left;
         width: 100%;
         justify-content: space-between;
         padding-top: 0px;
     }
     .container>div {
         padding: 0px !important;
     }
     .probeshkorun {
         margin-bottom: 0px;
     }
     .paddinghide {
         padding: 0px;
     }
     .editor-icon ul {
         display: flex;
         justify-content: space-between;
         margin-bottom: 0px;
         justify-content: left;
         position: relative;
         padding-left: 0px;
         margin-left: 0px;
         /* margin-top: -30px; */
         flex-direction: row;
         justify-content: space-between;
         flex-flow: row;
         width: 100%;
     }
     .nav-link {
         display: block;
         padding: 0px;
     }
     button.entry {
         margin: 10px auto;
     }
     .submitbutton {
         display: flex;
         justify-content: center;
         line-height: 60px;
     }
     .signupform p {
         font-size: 12px;
         color: #707070;
         font-family: Noto Sans Bengali;
     }
     .signupbg {
         margin-top: 0%;
     }
     .breadcrumbtop p img {
         display: none;
     }
     .obodanitem li {
         margin: 0px;
     }
     .footer-areabottom {
         display: flex;
         /* float: left; */
         width: 100%;
         padding: 0px 0px;
         position: fixed;
         left: 0px;
         bottom: 0px;
         justify-content: center;
         background-color: rgba(255, 255, 255, 1);
         -webkit-box-shadow: 0px 0px 6px 0px rgba(0, 0, 0, 0.16);
         box-shadow: 0px 0px 6px 0px rgba(0, 0, 0, 0.16);
         flex-direction: row;
         flex-wrap: wrap;
         /* background-color: #bedaff; */
     }
     .developed:first-child {
         display: none;
     }
     ul.footer-item:last-child {
         display: none;
     }
     .signupbradcump img {
         width: 20%;
         margin-bottom: 50px;
     }
     .leftwave {
         width: 100%;
         display: flex;
         flex-direction: column;
         text-align: center;
     }
     .rightwave {
         width: 100%;
         display: flex;
         flex-direction: column;
         text-align: center;
         background-color: rgba(190, 195, 201, 0.1);
     }
     .wavecontent {
         display: flex;
         flex-direction: column;
         width: 100%;
         height: auto;
         justify-content: space-between;
         margin-top: 70px;
     }
     .lefttext {
         width: 100%;
         /* display: flex; */
         /* flex-direction: row; */
         text-align: center;
         /* float: right; */
         /* justify-content: stretch; */
         padding-left: 0px;
     }
     .righttext {
         width: 100%;
         display: flex;
         flex-direction: column;
     }
     .righttext p {
         text-align: left;
         float: left;
         padding-right: 0%;
         padding: 0px 20px;
     }
     .chartarea {
         display: block;
         float: left;
         width: 100%;
         margin-bottom: 100px;
     }
     .developed a {
         color: #919191;
         font-size: 13px;
     }
     .smdeviceview {
         max-height: 650px;
         overflow-y: scroll;
         overflow-x: hidden;
     }
     a.bottomwave img {
         padding-left: 0px;
     }
     .main-text-content {
         min-height: 250px;
     }
     .editor-icon {
         display: flex;
         width: 100%;
         float: left;
         /* background: red; */
         justify-content: space-between;
         max-height: 120px;
         border: none !important;
         margin-bottom: 30px;
     }
     h6.porerager {
         font-size: 10px;
     }
     h6.porersunte {
         font-size: 10px;
     }
     .threeplay {
         position: absolute;
         top: 45px;
         float: left;
         /* width: 100%; */
         display: block;
         left: 48px;
     }
     .iconvoice {
         position: absolute;
         right: 0px;
         top: -40px;
     }
     a.recordingprofile {
         width: 36px;
         height: 36px;
         line-height: 36px;
         border: 1px solid #bedaff;
         padding: 5px 15px;
         border-radius: 50%;
         text-align: center;
         color: #fff;
         background-color: #bedaff;
         margin-left: 135px;
     }
     .probeshkorun {
         margin-bottom: 20px;
     }
     //   #navbarTogglerDemo01 {
     //     background-color: rgba(0, 0, 0, 0.41);
     //     height: 100vh;
     //   }
     .mobileview {
         display: flex;
         flex-direction: row;
         flex-wrap: wrap;
         background-color: #ffff;
     }
     .mnoview {
         display: none;
     }
     .rightsidebar {
         display: block;
         float: left;
         width: 100%;
         padding: 20px 30px 0px;
         -webkit-box-shadow: 0px 3px 6px 0px rgba(0, 0, 0, 0.16);
         box-shadow: 0px 3px 6px 0px rgba(0, 0, 0, 0.16);
         border-radius: 4px;
         background: rgb(255, 255, 255);
         background: #f6f9ff;
         // margin-top: 160px;
         margin-bottom: 30px;
     }
     .loginlogo img {
         width: 20%;
     }
     .alignnav {
         flex-basis: 100%;
         flex-grow: 1;
         align-items: center;
         // margin-top: 20px;
         display: flex;
         flex-wrap: wrap;
     }
     ul.obodanitem {
         display: flex;
         flex-direction: row;
         justify-content: space-between;
         width: 100%;
         /* font-size: 4px; */
     }
     .obodanitem li:last-child {
         margin-right: 0px;
     }
     .navbar-brand {
         display: inline-block;
         padding-top: 0.3125rem;
         padding-bottom: 0.3125rem;
         margin-right: 1rem;
         font-size: 1.25rem;
         line-height: inherit;
         white-space: nowrap;
         width: 50%;
     }
     div#navbarTogglerDemo01 a img {
         width: 100%;
         margin-top: -20px;
     }
     ul.navbar-nav.me-0.mb-2.mb-lg-0 {
         display: flex;
         flex-direction: row;
         justify-content: space-between;
         width: 100%;
     }
     a.probeshkorun {
         padding: 10px !important;
     }
     .startwate {
         //   background: url("images/stts/wave.png") no-repeat;
         background: url("images/stts/playsunun1.png") no-repeat;
         width: 100% !important;
         height: auto !important;
         overflow: hidden;
         border: none;
         // margin-bottom: -60px;
     }
     // button.bottomwave img {
     //     width: 64px;
     //     height: auto;
     //     position: absolute;
     //     top: 20px;
     //     left: 115px;
     //     cursor: pointer;
     //     background: none;
     //     opacity: 0;
     // }
     .stop {
         background: url("images/stts/smallload.gif") no-repeat;
         width: 100% !important;
         height: auto !important;
         // margin-bottom: -60px;
         overflow: hidden;
         // position: relative;
         margin-top: 10px;
     }
     .signupbg {
         height: auto;
         /* background: url("../img/bg.png") no-repeat center; */
         // background: url("./images/stts/sighupbg.png") no-repeat center;
         background-size: cover;
         background-position: 40% 65%;
         flex-wrap: wrap;
         /* width: 100%; */
         width: 100%;
         float: left;
         position: relative;
         /* padding: 137px 0px 0px 0px; */
         justify-content: center;
         align-items: center;
         margin: 0 auto;
         margin-top: 0%;
         /* overflow-y: scroll; */
     }
     .signupbradcump {
         display: none;
     }
     li.nav-item>a.nav-link {
         font-size: 10px !important;
         color: #161a31;
         font-family: "Noto Sans Bengali", sans-serif;
         font-weight: 500;
     }
     .footer-area {
         display: flex;
         float: left;
         width: 100%;
         padding: 20px 0px;
         /* position: absolute; */
         /* justify-content: space-between; */
         min-height: 170px;
         overflow: hidden;
         flex-direction: column;
         margin-top: 135px;
     }
     li.nav-item>a.nav-link {
         font-size: 16px !important;
         color: #161a31;
         font-family: "Noto Sans Bengali", sans-serif;
         font-weight: 500;
     }
     .navbar-brand {
         display: inline-block;
         padding-top: 1.025rem;
         padding-bottom: 0.3125rem;
         margin-right: 1rem;
         font-size: 1.25rem;
         line-height: inherit;
         white-space: nowrap;
         width: 50%;
     }
     .breadcrumbtop {
         margin: 0px 0px;
         display: flex;
         float: left;
         width: 100%;
         justify-content: space-between;
         padding-top: 0px;
     }
     .container>div {
         padding: 0px !important;
     }
     .probeshkorun {
         margin-bottom: 0px;
     }
     .paddinghide {
         padding: 0px;
     }
     .editor-icon ul {
         display: flex;
         justify-content: space-between;
         margin-bottom: 0px;
         justify-content: left;
         position: relative;
         padding-left: 0px;
         margin-left: 0px;
         /* margin-top: -30px; */
         flex-direction: row;
         justify-content: space-between;
         flex-flow: row;
         width: 100%;
     }
     .editor-icon ul li {
         /* padding: 10px 14px; */
         padding: 0px;
         line-height: 10px;
         position: relative;
         display: inline-block;
         margin: 0px;
     }
     .nav-link {
         display: block;
         padding: 0px;
     }
     button.entry {
         margin: 10px auto;
     }
     .submitbutton {
         display: flex;
         justify-content: center;
         line-height: 60px;
     }
     .signupform p {
         font-size: 12px;
         color: #707070;
         font-family: Noto Sans Bengali;
     }
     .signupbg {
         margin-top: 0%;
     }
     .breadcrumbtop p img {
         display: none;
     }
     .lesstext .container2 {
         display: flex;
         width: 120px;
         margin-left: 14px;
     }
 }
 
 @media only screen and (max-width: 360px) and (max-height: 640px) {
     li.topenonefirst {
         padding-top: 0px;
         margin-top: -12px;
         margin-right: 0px;
     }
 }
 
 @media only screen and (max-width: 320px) {
     .footer-area {
         display: flex;
         float: left;
         width: 100%;
         padding: 20px 0px;
         // position: absolute;
         /* justify-content: space-between; */
         min-height: 170px;
         overflow: hidden;
         flex-direction: column;
         // margin: 0 -18px;
     }
     li.nav-item>a.nav-link {
         font-size: 14px !important;
         color: #161a31;
         font-family: "Noto Sans Bengali", sans-serif;
         font-weight: 500;
     }
     .readingrule h3 {
         text-align: center;
         font-size: 15px;
         color: #161a31;
         margin: 10px;
         font-family: "Noto Sans Bengali", sans-serif;
     }
     .entry {
         font-size: 14px;
     }
     button.mjomadinbutton {
         font-size: 13px;
     }
 }
 
 
 /*=================== Responsive design ========================================= */